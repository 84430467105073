import { SubjectId } from 'domain/general.types';
import { generate, getScores } from './markov.repo';
import {
  MarkovProcessId,
  ThresholdTuple,
  SimpleGraph,
  NodeUpdate,
  SubGraph,
  MarkovNode,
  GroupName,
} from './markov.types';

import { get as getMarkovPerSubject } from 'services/markov/markov.repo';
import { indexToNodeName } from './helpers.markov';
import { AlgorithmName } from 'services/intervention/intervention.types';
import { ColAddr } from 'domain/traits.types';
import { AdvantageResponse, EdgeEdit } from './markov.types.api';

let lastGap = 0; // consider adding this to a context

// eslint-disable-next-line
export const generateStateGraph = async (
  sampleId: string,
  actions: string[],
  tuples: ThresholdTuple[],
  populationBlend: number,
  offset: number,
  gap: number
): Promise<[string, MarkovNode[], SimpleGraph]> => {
  const markovProcess = await generate(sampleId, actions, tuples, populationBlend, offset, gap);

  lastGap = gap;
  const graphKeys = Array(markovProcess.nodes.length)
    .fill('')
    .map((_, i) => indexToNodeName(i));

  const emptySubTree: SubGraph = {};

  graphKeys.forEach((key) => (emptySubTree[key] = [null]));

  const nodes = markovProcess.nodes.map((node, i) => ({ ...node, count: markovProcess.counts[i] }));

  return [
    markovProcess.markov_id,
    nodes,
    graphKeys.reduce((acc, cur) => {
      acc[cur] = { ...emptySubTree };
      return acc;
    }, {} as SimpleGraph),
  ];
};

export const getGraphPerSubject = async (
  subjectId: SubjectId,
  markovModelId: MarkovProcessId,
  groupName: string,
  updates: NodeUpdate[]
) => {
  return getMarkovPerSubject(markovModelId, subjectId, groupName, updates);
};

export const generateAdvantageHistogram = (
  tuples: ThresholdTuple[],
  budget: number,
  algoName: AlgorithmName,
  markovId: string,
  treatmentGroup: GroupName,
  updates: EdgeEdit[],
  addrs: ColAddr[],
  offset: number
): Promise<AdvantageResponse> => {
  return getScores(tuples, budget, algoName, markovId, treatmentGroup, updates, addrs, offset, lastGap);
};
