import React from 'react';

import { Props as InputContoinerProps } from 'components/CFSelect/defaults/InputContainer';
import { Option } from 'components/CFSelect';
import { Tag, TagSize, TagTypes } from 'components/Tag';

import './tag-input-container.scss';

const TagInputContainer = ({ selected }: InputContoinerProps): JSX.Element => {
  const _selected = selected as Option[];

  return (
    <div className="tag-input-container">
      {_selected.map((option) => (
        <Tag key={option.value} text={option.label} type={TagTypes.System} size={TagSize.extrasmall} maxLength={10} />
      ))}
    </div>
  );
};

export default TagInputContainer;
