import React from 'react';
import { useState } from 'react';

import { Children } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used

import './collapsible-content.scss';

interface Props {
  expandedSwitched?: (expanded: boolean) => void;
  children: JSX.Element | JSX.Element[];
  sameStyling?: boolean;
}

const Header = (props: any) => {
  return props.children;
};

const ExpandedHeader = (props: any) => {
  return props.children;
};

const CollapsedHeader = (props: any) => {
  return props.children;
};

const Content = (props: any) => {
  return props.children;
};

export const Collapsible = ({ children, sameStyling = false, expandedSwitched }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const arrayChildren = Children.toArray(children);

  let expandedHeader = arrayChildren.find((child) => (child as any).type.name === ExpandedHeader.name);
  const collapsedHeader = arrayChildren.find((child) => (child as any).type.name === CollapsedHeader.name);

  if (!expandedHeader) {
    expandedHeader = collapsedHeader;
  }

  const content = arrayChildren.find((child) => (child as any).type.name === Content.name);

  const handleExpand = () => {
    expandedSwitched && expandedSwitched(!expanded);
    setExpanded(!expanded);
  };

  return (
    <div className={`collapsible-content ${expanded ? 'expanded' : 'collapsed'}`}>
      <div
        className={`summary ${expanded ? 'selected' : ''} ${sameStyling ? 'keepStyles' : ''}  `}
        onClick={handleExpand}
      >
        <div className="content">{expanded ? expandedHeader : collapsedHeader}</div>

        <div className="arrow">
          {expanded ? (
            <FontAwesomeIcon icon={icon({ name: 'angle-up', style: 'solid' })} />
          ) : (
            <FontAwesomeIcon icon={icon({ name: 'angle-down', style: 'solid' })} />
          )}
        </div>
      </div>

      {expanded && <div className={`content ${expanded ? 'selected' : ''}`}>{content}</div>}
    </div>
  );
};

Collapsible.ExpandedHeader = ExpandedHeader;
Collapsible.CollapsedHeader = CollapsedHeader;
Collapsible.Header = Header;
Collapsible.Content = Content;
