import React from 'react';
import { ReactNode } from 'react';

import './labeled-component.scss';

interface Props {
  children: ReactNode;
  label: string;
}

const LabeledComponent = ({ children, label }: Props) => {
  return (
    <div className="labeled-component">
      <label>{label}</label>

      {children}
    </div>
  );
};

export default LabeledComponent;
