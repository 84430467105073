import React from 'react';

import InterventionContext from '../../useContext';
import NewIntervention from './new-intervention';

export enum Steps {
  Definition = 'definition',
  Participants = 'participants',
  Metrics = 'metrics',
  Nudge = 'nudge',
  Scheduling = 'scheduling',
  States = 'states',
  GroupTraits = 'group-traits',
  Score = 'score',
}

export const StepsDisplayNames: Partial<Record<Steps, string>> = {
  [Steps.Definition]: 'Definition',
  [Steps.Participants]: 'Participants',
  [Steps.Metrics]: 'Metrics',
  [Steps.Nudge]: 'Nudge',
  [Steps.Scheduling]: 'Scheduling',
  [Steps.States]: 'States',
  [Steps.GroupTraits]: 'Group traits',
};

const Intervention = () => {
  return (
    <InterventionContext>
      <NewIntervention />
    </InterventionContext>
  );
};

export default Intervention;
