import React, { Children, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { AggLevel, Series } from 'domain/stats.types';

import { tooltip, tooltipFormatter, X_AXIS_DATE_FORMAT, X_AXIS_LABEL } from '../chartCommon';
import { Units } from 'helpers/unitconversion';

import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import CFTooltip, { CFTooltipPositions } from 'components/CFTooltip';

import colors from 'common.scss';

import { EChartsOption, LinearGradient, ReactECharts } from 'components/charts/index';

import CFSwitch from 'components/CFSwitch';

import { getInstanceByDom } from 'echarts/core';
import { findMaxCeiledValue } from '../helper';

import '../charts.scss';
import './cf-line-chart.scss';

const DEFAULT_VALUE_WIDTH = 10;

dayjs.extend(duration);

interface Props {
  testId?: string;
  xLabel?: string;
  yLabel?: string;
  units?: string;
  title?: string;
  description?: string;
  color?: string;
  data: Series[];
  aggregationLevel: AggLevel;
  isLoading: boolean;
  showScaleControl?: boolean;
  scale?: ScaleType;
  showLegend?: boolean;
  children?: ReactNode;
  step?: number;
  decimalPlaces?: number;
  highlightLast?: boolean;
}

export enum ScaleType {
  Log = 'log',
  Linear = 'value',
}

export const ChartControl = (props: any) => {
  return props.children;
};

export const CFLineChart = ({
  testId,
  data,
  aggregationLevel,
  xLabel = '',
  yLabel = '',
  title = '',
  units = '',
  description = '',
  color = '#94D5DB',
  showScaleControl = false,
  scale = ScaleType.Linear,
  isLoading = true,
  showLegend = true,
  children,
  step = 1,
  decimalPlaces,
  highlightLast = false,
}: Props) => {
  const [currentScale, setCurrentScale] = useState(scale);
  const [zoomStart, setZoomStart] = useState(0);
  const [zoomEnd, setZoomEnd] = useState(100);
  const [totalWidth, setTotalWidth] = useState(0);
  const chartRef = useRef<HTMLDivElement>(null);

  const [ctrlKeyIsPressed, setCtrlKeyIsPressed] = useState(false);

  useEffect(() => {
    const getChartWidth = () => {
      const chart = getInstanceByDom((chartRef as any).current);

      setTotalWidth(chart?.getWidth() || 0);
    };

    getChartWidth();

    window.addEventListener('resize', getChartWidth);

    return () => {
      window.removeEventListener('resize', getChartWidth);
    };
  }, []);

  const valueWidth = useMemo(() => {
    if (!totalWidth) {
      return DEFAULT_VALUE_WIDTH;
    }

    if (!data || !data.length) {
      return DEFAULT_VALUE_WIDTH;
    }

    return (totalWidth / data[0].items.length) * 0.8;
  }, [data, totalWidth]);

  useEffect(() => {
    // parameter should be KeyboardEvent
    const handleKeyDown = (event: any) => {
      if (event.ctrlKey) {
        setCtrlKeyIsPressed(true);
      }
    };

    const handleKeyUp = (event: any) => {
      if (!event.ctrlKey) {
        setCtrlKeyIsPressed(false);

        // next code is needed to keep the chart in the same zoom and position
        // when ctrl key is released. Otherwise, it will come back to original
        // settings
        const chart = getInstanceByDom((chartRef as any).current);
        setZoomStart((chart?.getOption() as any).dataZoom[0].start);
        setZoomEnd((chart?.getOption() as any).dataZoom[0].end);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const maxValueY = Math.max(...data.map((seriesItem) => seriesItem.items.map((value) => value.value)).flat());

  const minValueY =
    Math.floor(Math.min(...data.map((seriesItem) => seriesItem.items.map((value) => value.value)).flat()) / step) *
    step;

  const options: EChartsOption = useMemo(() => {
    const xData = data[0]?.items.map((item) => item.time) || [];

    const option: EChartsOption = {
      dataZoom: {
        disabled: !ctrlKeyIsPressed,
        filterMode: 'none',
        type: 'inside',
        xAxisIndex: [0],
        start: zoomStart,
        end: zoomEnd,
        //...props.minZoomSpan && { minValueSpan: 3600 * 24 * 1000 } // limit to zoom span to 1 day
      },
      grid: {
        borderColor: 'red',
        left: '40px',
        right: '20px',
        bottom: '10%',
        top: '12%',
        containLabel: true,
      },
      areaStyle: {
        color: new LinearGradient(0, 0, 0, 1, [
          {
            offset: 0.5,
            color: color,
          },
          {
            offset: 1,
            color: 'rgb( 0, 0, 0)',
          },
        ]),
      },
      xAxis: {
        type: 'category',
        animation: false,
        data: xData,
        name: xLabel || X_AXIS_LABEL[aggregationLevel],
        nameLocation: 'middle',
        nameGap: 30,
        nameTextStyle: {
          color: colors.dark50,
          fontSize: 14,
          fontWeight: 600,
        },
        axisLine: {
          lineStyle: {
            color: colors.dark50,
          },
        },
        axisLabel: {
          formatter: function (value: any) {
            return dayjs(value).format(X_AXIS_DATE_FORMAT[aggregationLevel]);
          },
        },
      },
      yAxis: {
        type: currentScale,
        min: currentScale === ScaleType.Linear ? minValueY : '0.1', // log scales does not work with values negatives, zero or close to zero
        max: findMaxCeiledValue(maxValueY),
        animation: false,
        splitLine: {
          lineStyle: {
            color: colors.dark50,
          },
        },
        axisLabel: {
          color: colors.dark50,
          // @ts-expect-error: something wrong with type inference in echarts ts version:https://github.com/apache/echarts/issues/15941
          formatter: function (value: any) {
            return formatNumber(value);
          },
        },
        offset: 0,
        nameLocation: 'middle',
      },
      legend: {
        show: showLegend,
        top: 'top',
        right: '150px',
        textStyle: {
          color: '#fff',
          fontSize: 14,
        },
        inactiveColor: '#777',
        data:
          data.length < 2
            ? []
            : data.map((seriesItem) => ({
                name: seriesItem.name,
                icon: 'circle',
                itemStyle: {
                  color: seriesItem.color,
                },
              })),
      },
      tooltip: {
        ...tooltip(),
        formatter: (params: any) => tooltipFormatter(params, aggregationLevel, units as Units, decimalPlaces),
      },
      series: data.map((serie) => ({
        name: serie.name,
        data: serie.items
          .map((item) => item.value)
          .map((value) =>
            (currentScale === ScaleType.Log && value > 0) || currentScale === ScaleType.Linear ? value : null
          ) as number[],
        //symbolSize: 0.5,
        areaStyle: {
          opacity: data.length > 1 ? 0 : 0.1,
        },
        showSymbol: serie.items.length < 2 || currentScale === ScaleType.Log,

        /*symbolSize: function (value, params) {
          // Solo el último punto tendrá un símbolo grande
          return params.dataIndex === xData.length - 1 ? 10 : 0.5;
        },*/
        markPoint: {
          data: [
            {
              name: 'live-point',
              //              type: 'max', // También puedes usar 'max' o 'min'
              coord: [xData.length - 2, xData[xData.length - 2]],
              symbol: 'circle',
              symbolSize: 10,
              itemStyle: {
                color: 'red', // Color del punto
                borderColor: 'red', // Color del borde
                borderWidth: 2,
              },
              label: {
                show: false,
              },
            },
          ],
        },
        color: serie.color || color,
        lineStyle: { color: serie.color || color },
        type: 'line',
        animationDurationUpdate: 0,
        animation: false,
        markLine: {
          label: {
            formatter: function () {
              return '';
            },
          },
          symbolSize: [0, 0],
          lineStyle: {
            color: '#f7c334',
            width: valueWidth,
            type: 'solid',
            opacity: 0.1,
            symbol: 'none',
          },
          data: xData.length && highlightLast ? [{ xAxis: xData[xData.length - 1] }] : [],
          zlevel: 2,
        },
      })),
    };

    return option;
  }, [aggregationLevel, data, title, currentScale, ctrlKeyIsPressed, valueWidth]);

  const ranges = [
    { divider: 1e6, suffix: 'M' },
    { divider: 1e3, suffix: 'k' },
  ];

  function formatNumber(value: any) {
    if (typeof value === 'number') {
      for (let i = 0; i < ranges.length; i++) {
        if (value >= ranges[i].divider) {
          return (value / ranges[i].divider).toString() + ranges[i].suffix;
        }
      }
    }
    return parseFloat(parseFloat(value).toPrecision(2));
  }

  function getTitle() {
    if (!title) {
      return '';
    }

    return `${title[0].toUpperCase()}${title.slice(1)}`;
  }

  const arrayChildren = Children.toArray(children);

  return (
    <div className="cf-line-chart">
      <div className="chart-header">
        <div className="chart-header__title">
          <span data-testid={testId}>{getTitle()}</span>
          {description && (
            <CFTooltip description={description} position={CFTooltipPositions.Right}>
              <FontAwesomeIcon icon={faCircleQuestion} size="sm" />
            </CFTooltip>
          )}
        </div>
        <div className="chart-header__controls">
          {showScaleControl && (
            <div className="line-chart-controls">
              <CFSwitch
                checked={currentScale === ScaleType.Log}
                onChange={(checked) => setCurrentScale(checked ? ScaleType.Log : ScaleType.Linear)}
              />
              <span>Log scale</span>
            </div>
          )}

          {arrayChildren}
        </div>
      </div>
      <div className="titled-chart">
        <div className="yaxis-name"> {yLabel} </div>

        <ReactECharts ref={chartRef} option={options} style={{ width: `100%`, height: '330px' }} loading={isLoading} />
      </div>
    </div>
  );
};

CFLineChart.Control = ChartControl;
export default CFLineChart;
