import React, { createContext, useState, useContext } from 'react';

interface ScatterRangeContextType {
  selectedItems: Set<number>;
  setSelectedItems: React.Dispatch<React.SetStateAction<Set<number>>>;
}

interface Props extends React.PropsWithChildren {}

const ScatterRangeContext = createContext<ScatterRangeContextType | undefined>(undefined);

export const ScatterRangeProvider: React.FC<Props> = ({ children }) => {
  const [selectedItems, setSelectedItems] = useState<Set<number>>(new Set());

  return (
    <ScatterRangeContext.Provider value={{ selectedItems, setSelectedItems }}>{children}</ScatterRangeContext.Provider>
  );
};

export const useScatterRangeContext = (): ScatterRangeContextType => {
  const context = useContext(ScatterRangeContext);
  if (!context) {
    throw new Error('useMessagesContext must be used within an MessagesContextProvider');
  }
  return context;
};

export default ScatterRangeProvider;
