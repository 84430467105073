import CFInput, { CFInputType } from 'components/CFInput';
import CFSelectLegacy from 'components/CFSelectLegacy';
import { Granularity, timeConverter } from 'helpers/dates';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import './time-window-picker.scss';

interface Props {
  onChange?: (value: number) => void;
  defaultGranularity?: Granularity;
  defaultValue?: number;
}

export interface TimeWindowPickerRef {
  value: () => number;
}

const TimeWindowPicker = forwardRef<TimeWindowPickerRef, Props>(function TimeWindowPicker(
  { onChange, defaultGranularity, defaultValue = 2 }: Props,
  ref
) {
  const valueInputRef = React.createRef<HTMLInputElement>();
  const [unit, setUnit] = useState<string>(Granularity.Day);

  useImperativeHandle(ref, () => ({
    value() {
      return timeConverter[unit](parseInt((valueInputRef.current as HTMLInputElement).value));
    },
  }));

  useEffect(() => {
    if (!defaultValue || !onChange) {
      return;
    }

    onChange(timeConverter[unit](defaultValue));
  }, []);

  const options = useMemo(
    () =>
      Object.values(Granularity).map((granularity) => ({
        value: granularity,
        label: `${granularity[0].toUpperCase()}${granularity.slice(1)}s`,
      })),
    []
  );

  const defaultOption = useMemo(
    () =>
      options.find((option) => option.value === defaultGranularity) || {
        value: Granularity.Day,
        label: 'Days',
      },
    [options]
  );

  return (
    <div className="time-window-picker">
      <CFInput
        ref={valueInputRef}
        type={CFInputType.Number}
        defaultValue={defaultValue}
        onChange={
          onChange && (() => onChange(timeConverter[unit](parseInt((valueInputRef.current as HTMLInputElement).value))))
        }
      />

      <CFSelectLegacy
        options={options}
        onSelected={([selectedValue]) => {
          setUnit(selectedValue.value);
          onChange &&
            onChange(timeConverter[selectedValue.value](parseInt((valueInputRef.current as HTMLInputElement).value)));
        }}
        defaultOption={[defaultOption]}
      />
    </div>
  );
});

export default TimeWindowPicker;
