import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { AnalyticSummary, ScatterPlot } from 'domain/analytic.types';
import { get as httpGet } from '../drivers/http';
import { RepoConfig } from '../types';

const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
const path = '/v1/landing';

const repoConfig = {
  token: '',
  oid: -1,
  pid: -1,
};

export interface CFAnalyticRepository {
  init: (repoConfig: RepoConfig) => void;
  get: () => Promise<AnalyticSummary>;
}

export const init = ({ token, oid, pid }: RepoConfig) => {
  repoConfig.token = token;
  repoConfig.oid = oid;
  repoConfig.pid = pid;
};

export const get = async (): Promise<AnalyticSummary> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };

  const today = dayjs().add(-1, 'day').format('YYYY-MM-DD');

  try {
    const summaryResponse = (await httpGet(
      `${serverBaseUrl}${path}/trait-summary?date=${today}`,
      config
    )) as AxiosResponse;
    const reformattedAnalyticSummary: AnalyticSummary = {
      ...summaryResponse.data,
    };

    return reformattedAnalyticSummary;
  } catch (err) {
    console.error('error listing analytics summary: ', err);
    throw new Error('error-listing-analytics');
  }
};

export const censoringPrediction = async (): Promise<ScatterPlot> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };

  try {
    const censoringResponse = (await httpGet(`${serverBaseUrl}${path}/censoring-plot?`, config)) as AxiosResponse;

    return censoringResponse.data;
  } catch (err) {
    console.error('error getting censoring prediction: ', err);
    throw new Error('error-getting-censoring-prediction');
  }
};

export default {
  init,
  censoringPrediction,
  get,
};
