import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

import CFPortal, { ContentPosition } from 'components/CFPortal';

import './cf-tooltip.scss';

const DEFAULT_WIDTH = 400;

export enum CFTooltipPositions {
  Top = 'top',
  Right = 'right',
  RightBottom = 'right-bottom',
  Bottom = 'bottom',
  Left = 'left',
  VerticalRightAuto = 'vertical-right-auto',
  VerticalAuto = 'vertical-auto',
}

export enum CFTooltipModes {
  Hover = 'hover',
  Initial = 'initial',
}

interface Props extends React.PropsWithChildren {
  description: string | ReactNode | undefined;
  position?: CFTooltipPositions;
  anchor?: string;
  delayed?: boolean;
  keepStyles?: boolean;
  mode?: CFTooltipModes;
  forceMaxWidth?: boolean;
}

let timeoutId: any;

const PADDING = 10;
const INITIAL_VISIBLE_TIMEOUT = 5000;

const CFTooltip = ({
  description,
  children,
  position,
  anchor,
  delayed = false,
  keepStyles = false,
  mode = CFTooltipModes.Hover,
  forceMaxWidth = true,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(mode === CFTooltipModes.Initial); // tooltip is open
  const [isInside, setIsInside] = useState<boolean>(false); // mouse is over tooltip
  const [isActionableInside, setIsActionableInside] = useState<boolean>(false);
  const [tooltipWidth, setTooltipWidth] = useState(DEFAULT_WIDTH);
  const [contentHeight, setContentHeight] = useState(0);

  const tooltipRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const timeout = useMemo(() => {
    return delayed ? 1000 : 0;
  }, [delayed]);

  useEffect(() => {
    if (mode === CFTooltipModes.Initial) {
      return;
    }

    if (isInside) {
      return;
    }

    setIsOpen(false);
  }, [isInside, mode]);

  useEffect(() => {
    if (mode !== CFTooltipModes.Initial) {
      return;
    }

    setTimeout(() => {
      setIsOpen(false);
    }, INITIAL_VISIBLE_TIMEOUT);
  }, []);

  const handleMouseOverPopup = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();

    setIsInside(true);
    clearTimeout(timeoutId);
  }, []);

  const handleMouseOutPopup = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();

    setIsInside(false);
  }, []);

  const handleMouseOver = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();

      if (mode === CFTooltipModes.Initial) {
        return;
      }

      if (!description) {
        return;
      }

      setIsOpen(true);
    },
    [description]
  );

  const handleMouseOut = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();

      if (isActionableInside) {
        return;
      }

      if (isInside) {
        return;
      }

      timeoutId = setTimeout(() => {
        setIsOpen(false);
      }, timeout);

      return () => clearTimeout(timeoutId);
    },
    [isInside]
  );

  const handleOnMouseInternalLeave = useCallback(() => {
    setIsActionableInside(false);
  }, []);

  const handleOnMouseInternalEnter = useCallback(() => {
    setIsActionableInside(true);
  }, []);

  if (!description) {
    return <>{children} </>;
  }

  const bounds = useMemo(() => {
    if (!tooltipRef || !tooltipRef.current) {
      return;
    }

    if (anchor && tooltipRef.current.closest(anchor)) {
      return tooltipRef.current.closest(anchor)?.getBoundingClientRect();
    } else {
      return tooltipRef.current.getBoundingClientRect();
    }
  }, [anchor, tooltipRef, tooltipRef.current, isOpen]);

  useEffect(() => {
    const height = contentRef.current?.getBoundingClientRect().height;

    if (!height) {
      return;
    }

    setContentHeight(height);
  }, [contentRef, contentRef.current, isOpen]);

  useEffect(() => {
    if (!contentRef || !contentRef.current) {
      return;
    }

    setTooltipWidth(contentRef.current.getBoundingClientRect().width);
  }, [contentRef, contentRef.current]);

  const tooltipLeft = useMemo(() => {
    if (!bounds) {
      return 0;
    }

    if (position === CFTooltipPositions.Bottom) {
      return bounds.left;
    }

    if (position === CFTooltipPositions.Right) {
      return bounds.right + PADDING;
    }

    if (position === CFTooltipPositions.Left) {
      return bounds.left - tooltipWidth - 10;
    }

    if (position === CFTooltipPositions.VerticalAuto) {
      return bounds.left;
    }

    return Math.floor(bounds.right);
  }, [bounds, position, contentRef, contentRef.current]);

  const tooltipRight = useMemo(() => {
    return 0;
  }, []);

  const tooltipTop = useMemo(() => {
    if (!bounds) {
      return 0;
    }

    if (position === CFTooltipPositions.Bottom) {
      return bounds.bottom;
    }

    if (position === CFTooltipPositions.Right) {
      return bounds.top;
    }

    if (position === CFTooltipPositions.Left) {
      return bounds.bottom - 10;
    }

    if (position === CFTooltipPositions.VerticalRightAuto) {
      if (bounds.bottom - contentHeight < 0) {
        return 10;
      } else {
        return bounds.bottom - contentHeight;
      }
    }

    if (position === CFTooltipPositions.VerticalAuto) {
      if (bounds.bottom < 0.5 * window.innerHeight) {
        return bounds.bottom;
      } else {
        return bounds.bottom - contentHeight;
      }
    }

    return bounds.bottom;
  }, [bounds, contentHeight]);

  return (
    <div className="cf-tooltip" ref={tooltipRef}>
      <div className="inner-tooltip-container" onMouseLeave={handleMouseOut} onMouseOver={handleMouseOver}>
        <div onMouseEnter={handleOnMouseInternalEnter} onMouseLeave={handleOnMouseInternalLeave}>
          {children}
        </div>
      </div>
      {isOpen && position !== undefined && (
        <CFPortal
          top={tooltipTop}
          left={tooltipLeft}
          right={tooltipRight}
          mode={ContentPosition.Custom}
          backdrop={false}
          onClickOutside={() => {
            /**/
          }}
        >
          <div
            ref={contentRef}
            style={{ maxWidth: forceMaxWidth ? DEFAULT_WIDTH : undefined }}
            onMouseOver={handleMouseOverPopup}
            onMouseLeave={handleMouseOutPopup}
            className={classNames('cf-tooltip-description', { top: position === 'top', 'keep-styles': keepStyles })}
          >
            {description}
          </div>
        </CFPortal>
      )}

      {isOpen && position === undefined && (
        <div
          ref={contentRef}
          style={{ width: DEFAULT_WIDTH }}
          onMouseOver={handleMouseOverPopup}
          onMouseLeave={handleMouseOutPopup}
          className={classNames('cf-tooltip-description', { top: position === 'top' })}
        >
          {description}
        </div>
      )}
    </div>
  );
};

export default CFTooltip;
