import React from 'react';

import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';

import CFTooltip from 'components/CFTooltip';

import './cf-titled-component.scss';

export enum TitleSize {
  Small = 'small',
  Big = 'big',
  Extra = 'extra',
}

interface Props {
  title: string;
  subtitle?: string;
  children: JSX.Element | JSX.Element[];
  className?: string;
  size?: TitleSize;
  testid?: string;
  inline?: boolean;
  description?: string;
}

const CFTitledComponent = ({
  title,
  subtitle,
  children,
  size = TitleSize.Small,
  className,
  testid,
  inline = false,
  description,
}: Props) => {
  const id = `random-title-${(Math.random() * 1000).toFixed(0)}`;

  return (
    <div className={classNames('cf-titled-component', className, { inline })} data-testid={testid}>
      <div className={`title-size ${size}`}>
        <span className={`title ${id}`}>{title}</span> <span className="subtitle"> {subtitle} </span>
        {description && (
          <CFTooltip description={description}>
            <FontAwesomeIcon icon={faCircleQuestion} size="sm" />
          </CFTooltip>
        )}
      </div>
      {children}
    </div>
  );
};

export default CFTitledComponent;
