import React from 'react';

import './cf-option-switch.scss';
import classNames from 'classnames';

interface Option {
  label: string;
  value: any;
}

interface Props {
  options: Option[];
  value: Option;
  onChange: (option: Option) => void;
}

const CFOptionSwitch = ({ options, value, onChange }: Props): JSX.Element => {
  return (
    <div className="option-switch">
      {options.map((option) => (
        <div
          key={option.value}
          className={classNames('option-switch-option', { active: value.value === option.value })}
          onClick={() => onChange(option)}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};

export default CFOptionSwitch;
