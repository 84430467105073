import React, { useEffect, useState } from 'react';

import CFBoxPlotChart from 'components/charts/CFBoxPlotChart';
import CohortService from 'services/cohort/cohort.service';
import { ColAddr } from 'domain/traits.types';

export enum BoxplotType {
  Cohort,
  Sample,
}

interface Props {
  groupId: string;
  addr: ColAddr;
  cohortService: CohortService;
  type: BoxplotType;
  group?: string;
  title?: string;
}

const BoxplotLoader = ({ groupId, addr, type, title = '', group, cohortService }: Props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [boxplotParams, setBoxPlotParams] = useState({
    min: 0,
    q1: 0,
    q2: 0,
    q3: 0,
    max: 0,
  });

  useEffect(() => {
    (async () => {
      if (!groupId) {
        return;
      }

      try {
        const boxplotData =
          type === BoxplotType.Sample
            ? await cohortService.getTraitBasicStats(groupId, addr, group)
            : await cohortService.getTraitBasicStatsForCohort(groupId, addr);

        setBoxPlotParams(boxplotData);
        setLoading(false);
      } catch (e) {
        setError(true);
      }
    })();
  }, [groupId, group, addr]);

  return (
    <div>
      {!loading && !error && (
        <CFBoxPlotChart
          isLoading={loading}
          title={title}
          data={[
            {
              name: 'boxplot',
              data: {
                min: boxplotParams.min,
                q1: boxplotParams.q1,
                q2: boxplotParams.q2,
                q3: boxplotParams.q3,
                max: boxplotParams.max,
              },
            },
          ]}
        />
      )}
      {error && '<no detailed data>'}
    </div>
  );
};

export default BoxplotLoader;
