import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { CFRole } from 'domain/general.types';

import useSampleSize from '../../hooks/useSampleSize';

import CohortService from 'services/cohort/cohort.service';

import { useInterventionContext } from 'views/intervention/useContext';

import SampleViewer from 'connected-components/sampleViewer';

import CFButton from 'components/buttons/CFButton';
import CFDownloadButton from 'components/buttons/CFDownloadButton';
import CFInput, { CFInputType } from 'components/CFInput';
import CFTitledComponent from 'components/CFTitledComponent';
import { Collapsible } from 'components/CollapsibleContent';
import CFLoadWrapper from 'components/CFLoadWrapper';
import { SamplingType } from '../types';

interface Props {
  cohortService: CohortService;
  totalSubjects: number;
  isLoading: boolean;
  defaultSampleSize?: number;
  samplingType: SamplingType;
}

const BanditSampling = ({ cohortService, totalSubjects, isLoading, defaultSampleSize = 0, samplingType }: Props) => {
  const { generateSample, sampleId, pureSampleId, cohort, sampling } = useInterventionContext();
  const [sampleSizeValid, setSampleSizeValid] = useState(false);

  const sampleSizeInputRef = React.createRef<HTMLInputElement>();

  const computedSampleSize = useSampleSize(samplingType);

  const sampleButtonEnable = useMemo(() => {
    const isEnable = cohort !== undefined && sampleSizeValid;

    return isEnable;
  }, [sampleSizeValid, cohort]);

  const updateSampleSizeValidity = (sampleSize: string) => {
    const minSampleThreshold = 0;

    const nonEmpty = sampleSize.trim().length > 0;
    const inRange = parseInt(sampleSize) > minSampleThreshold && parseInt(sampleSize) < totalSubjects + 1;

    setSampleSizeValid(nonEmpty && inRange);
  };

  useEffect(() => {
    if (!sampleSizeInputRef || !sampleSizeInputRef.current) {
      // may be null if allowSampling is not set
      return;
    }
    updateSampleSizeValidity((sampleSizeInputRef as any).current.value);
  }, [totalSubjects]);

  const handleSampleSizeChange = useCallback(
    (newValue: any) => {
      updateSampleSizeValidity(newValue.target.value);
    },
    [totalSubjects]
  );

  const handleFetchSamplesForDownload = useCallback(async () => {
    if (!cohort) {
      return [];
    }

    const allSamples = await cohortService.sampleAll(sampleId.value);
    return allSamples || [];
  }, [cohort, sampleId]);

  const handleGenerateSample = async () => {
    if (!sampleSizeInputRef.current || !cohort) {
      return;
    }

    generateSample(samplingType, parseInt(sampleSizeInputRef.current.value));
  };

  const currentSampleId = useMemo(() => {
    return samplingType === SamplingType.Normal ? sampleId : pureSampleId;
  }, [samplingType, sampleId, pureSampleId]);

  return (
    <React.Fragment>
      <CFTitledComponent
        title={`${samplingType === SamplingType.Normal ? 'Sample size' : 'Pure sample size'} `}
        className="reduced"
      >
        <CFInput
          disabled={cohort === undefined}
          type={CFInputType.Number}
          ref={sampleSizeInputRef}
          onChange={handleSampleSizeChange}
          defaultValue={defaultSampleSize}
          message={cohort !== undefined ? `No more than ${totalSubjects}` : ''}
        />

        <CFButton
          value="Generate sample"
          isLoading={sampling}
          role={CFRole.Primary}
          disabled={!sampleButtonEnable || isLoading}
          onClick={handleGenerateSample}
        />
      </CFTitledComponent>

      <CFLoadWrapper isLoading={sampling} spinnerSize={30}>
        <Collapsible sameStyling={true}>
          <Collapsible.CollapsedHeader>
            <div className="action-header">
              <div className="subjects-title">
                {sampling ? 'Sampled subjects' : `Sampled subjects (${computedSampleSize})`}
              </div>

              <CFDownloadButton
                getData={handleFetchSamplesForDownload}
                disabled={cohort === undefined || currentSampleId.value === ''}
                name={`${cohort?.name.split(' ').join('-')}-sample.csv`}
              />
            </div>
          </Collapsible.CollapsedHeader>

          <Collapsible.Content>
            <SampleViewer
              sampleId={samplingType === SamplingType.Normal ? sampleId : pureSampleId}
              cohortService={cohortService}
            />
          </Collapsible.Content>
        </Collapsible>
      </CFLoadWrapper>
    </React.Fragment>
  );
};

export default BanditSampling;
