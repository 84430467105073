import { ColorInterval } from '.';
import colors from './constants.scss';

export const intervals: ColorInterval[] = [
  { range: [0, 0.2], from: colors.threshold_0, to: colors.threshold_1 },
  { range: [0.2, 0.4], from: colors.threshold_1, to: colors.threshold_2 },
  { range: [0.4, 0.6], from: colors.threshold_2, to: colors.threshold_3 },
  { range: [0.6, 0.8], from: colors.threshold_3, to: colors.threshold_4 },
  { range: [0.8, 1], from: colors.threshold_4, to: colors.threshold_5 },
];
