import React, { useCallback, useEffect, useState } from 'react';
import { Trait } from 'domain/traits.types';
import { CFRole, TimeRFC3999 } from 'domain/general.types';

import { InterventionId } from 'services/intervention/intervention.types';
import { getAdvantage } from 'services/markov/markov.repo';
import { getDisplayName, getIdentifier } from 'services/traits/helpers.traits';

import CFSelect, { Option } from 'components/CFSelect';
import CFTitledComponent from 'components/CFTitledComponent';
import CFButton from 'components/buttons/CFButton';
import CFScatterPlotChart, { SeriesItem } from 'components/charts/CFScatterPlotChart';

import { useServicesContext } from 'hooks/useServicesContext';
import useDecisionsPoints from '../../hooks/useDecisionsPoints';

import './restless-advantage.scss';

interface Props {
  interventionId: InterventionId;
}

const RestlessAdvantage = ({ interventionId }: Props) => {
  const { interventionService, traitSessionService } = useServicesContext();

  const [selectedTrait, setSelectedTrait] = useState<Trait>();
  const [metrics, setMetrics] = useState<Trait[]>([]);
  const [timestamp, setTimestamp] = useState<TimeRFC3999>('');
  const [series, setSeries] = useState<SeriesItem[]>([]);

  const decisionPoints = useDecisionsPoints(interventionId);

  useEffect(() => {
    (async () => {
      const addrs = interventionService.getConfiguredMetrics(interventionId);
      const traitPromises = addrs.map((addr) => traitSessionService.getTraitDefinition(addr.ptr));

      const traits = await Promise.all(traitPromises);

      setMetrics(traits);
    })();
  }, []);

  const handleSelectTrait = useCallback((evt: Option) => {
    const trait = traitSessionService.getTraitDefinition(evt.value);

    setSelectedTrait(trait);
  }, []);

  const handleSelectedDecisionPoint = useCallback((evt: Option) => {
    setTimestamp(evt.value);
  }, []);

  const handleApplySeeAdvantage = useCallback(async () => {
    if (!selectedTrait || timestamp === '') {
      return;
    }

    const advantagePoints = await getAdvantage(interventionId, selectedTrait?.addr, timestamp);

    // eslint-disable-next-line
    const values = advantagePoints.x.map((item, i) => ({
      x: advantagePoints.x[i],
      y: advantagePoints.y[i],
      id: '',
    }));

    const series = [
      {
        values,
        name: 'string',
      },
    ];

    setSeries(series);
  }, [selectedTrait, timestamp, interventionId]);

  return (
    <div className={`monitor-restless-advantage`}>
      <div className=" controls ">
        <CFTitledComponent title="Trait" className="advantage-field">
          <CFSelect
            options={metrics.map((trait) => ({ label: getDisplayName(trait), value: getIdentifier(trait) }))}
            onSelected={handleSelectTrait}
            value={{ label: getDisplayName(selectedTrait), value: selectedTrait ? getIdentifier(selectedTrait) : '' }}
          />
        </CFTitledComponent>

        <CFTitledComponent title="Decision point" className="advantage-field">
          <CFSelect
            options={(decisionPoints || []).map((point) => ({ label: point, value: point }))}
            value={{ label: timestamp, value: timestamp }}
            onSelected={handleSelectedDecisionPoint}
          />
        </CFTitledComponent>

        <CFButton role={CFRole.Primary} value={'Apply'} onClick={handleApplySeeAdvantage} />
      </div>

      <CFScatterPlotChart title="" xLabel="" yLabel="" series={series || []} />
    </div>
  );
};

export default RestlessAdvantage;
