import React from 'react';
import classNames from 'classnames';

import { FilterGroupOperation, Leaf, Tree } from 'services/cohort/cohort.types.api';

import CFButtonGroup from 'components/CFButtonGroup';
import { FilterViewer } from 'connected-components/FilterViewerTree';

import FilterSet from 'services/cohort/domain/FilterSet';

import './tree-viewer.scss';
import Filter from 'services/cohort/domain/Filter';

interface Props {
  value: FilterSet;
}

const TreeViewer = ({ value }: Props): JSX.Element => {
  const printTree = (tree: Tree<Filter>, level: number) => {
    if (!tree || !tree.nodes || tree.nodes.length === 0) {
      return null;
    }

    return (
      <>
        {tree.nodes.length > 1 && (
          <div className="filters-actions">
            <CFButtonGroup
              className="filters-actions-operator"
              options={[
                {
                  value: FilterGroupOperation.And,
                  label: 'AND',
                },
                {
                  value: FilterGroupOperation.OR,
                  label: 'OR',
                },
              ]}
              onSelect={() => void 1}
              value={{
                label: tree.op === FilterGroupOperation.And ? 'AND' : 'OR',
                value: tree.op,
              }}
            />
          </div>
        )}
        {tree.nodes.map((node, idx) => (
          <div key={idx} className={classNames('filter-group-builder', `level_${level}`)}>
            <div className="filters">
              {node.tree && printTree(node.tree, level + 1)}
              {node.leaf && printLeaf(node.leaf)}
            </div>
          </div>
        ))}
      </>
    );
  };

  const printLeaf = (leaf: Leaf) => {
    if (!leaf || !leaf.filters || leaf.filters.length === 0) {
      return null;
    }

    return (
      <>
        {leaf.filters.length > 1 && (
          <div className="filters-actions">
            <CFButtonGroup
              className="filters-actions-operator"
              options={[
                {
                  value: FilterGroupOperation.And,
                  label: 'AND',
                },
                {
                  value: FilterGroupOperation.OR,
                  label: 'OR',
                },
              ]}
              onSelect={() => void 1}
              value={{
                label: leaf.op === FilterGroupOperation.And ? 'AND' : 'OR',
                value: leaf.op,
              }}
            />
          </div>
        )}
        {leaf.filters.map((filter, i) => {
          return (
            <div className="filter-group-row" key={i}>
              <FilterViewer filter={filter} operator={''} skipOperator noPadding={leaf.filters.length === 1} />
            </div>
          );
        })}
      </>
    );
  };

  return <div className="tree-viewer">{printTree(value.tree, 1)}</div>;
};

export default TreeViewer;
