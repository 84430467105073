import React from 'react';

import './apikeymanagement.scss';
import { useEffect, useState } from 'react';

import CFButton from 'components/buttons/CFButton';
import CFTitledSection, { SectionAction } from 'components/CFTitledSection';

import { ApiKey } from 'domain/apikey.types';

import { getMine as getKeysMine } from 'repositories/apikeys';
import { remove as revokeKey } from 'repositories/apikeys';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { CFRole } from 'domain/general.types';
import { CFRoutes } from 'routes';
import CFTable, { Column, ColumnType } from 'components/CFTable';
import { CFNavListWithLinks } from 'components/CFNavListWithLinks';
import { Tabs } from '..';
import CFButtonGroup, { ButtonGroupOption } from 'components/CFButtonGroup';
import useCFNavigation from 'hooks/useCFNavigation';

enum ApiKeyTabs {
  ACTIVE = 'Active',
  REVOKED = 'Revoked',
  EXPIRED = 'Expired',
}

const NO_EXPIRATION_DATE = '0001-01-01T00:00:00Z';

const ApiKeyManagement = () => {
  const [keys, setKeys] = useState<ApiKey[]>([]);
  const [selectedKeyInfo, setSelectedKeyInfo] = useState<ApiKey | undefined>(undefined);
  const [tab, setTab] = useState({
    value: ApiKeyTabs.ACTIVE,
    label: 'Active',
  });

  const navigate = useCFNavigation();

  const updateData = () => {
    getKeysMine().then((keys: ApiKey[]) => {
      setKeys(keys);
    });
  };
  useEffect(() => {
    updateData();
  }, []);

  const handleTabClick = (option: ButtonGroupOption) => {
    setTab(option);
    setSelectedKeyInfo(undefined);
  };

  const handleKeyClick = (row: Record<string, any>) => {
    console.log(row);
    setSelectedKeyInfo(keys.find((keyInfo) => keyInfo.key === row.id));
  };

  const handleRevokeSelectedKey = () => {
    revokeKey(selectedKeyInfo?.key || '')
      .then(() => {
        setSelectedKeyInfo(undefined);
        updateData();
      })
      .catch(() => {
        console.log('error revoking api key');
      });
  };

  const keyToHiddenKey = (key: string): string => {
    return `${'*'.repeat(key.length - 4)}${key.slice(-4)}`;
  };

  let visibleKeys: ApiKey[] = [];

  if (tab.value === ApiKeyTabs.REVOKED) {
    visibleKeys = keys.filter((key) => key.revoked);
  } else {
    visibleKeys = keys.filter((key) => !key.revoked);
  }

  visibleKeys = visibleKeys.map((keyInfo) => {
    const key = keyToHiddenKey(keyInfo.key);

    return {
      id: keyInfo.key,
      ...keyInfo,
      key,
      expires: keyInfo.expired_at === NO_EXPIRATION_DATE ? 'Never' : keyInfo.expired_at,
    };
  });

  const headerItems: Column[] = [
    { title: 'Key', field: 'key', type: ColumnType.STRING },
    { title: 'Created At', field: 'created_at', type: ColumnType.DATE },
    { title: 'Scopes', field: 'scopes', type: ColumnType.OBJECT },
    { title: 'Expires At', field: 'expires', type: ColumnType.DATE },
  ];

  return (
    <div>
      <CFNavListWithLinks
        titles={[
          { title: Tabs.Monitoring, target: CFRoutes.integration },
          { title: Tabs.IngestHistory, target: CFRoutes.integration_ingest_history },
          { title: Tabs.CatalogHistory, target: CFRoutes.integration_catalog_history },
          { title: Tabs.SdkExceptions, target: CFRoutes.integration_exception_view },
          { title: Tabs.ApiKeys, target: CFRoutes.integration_keys },
        ]}
        selected={Tabs.ApiKeys}
      />
      <div className="apikeymanagement">
        <div className="controls">
          <div className="nav-adjustments">
            <CFButtonGroup
              options={[
                {
                  value: ApiKeyTabs.ACTIVE,
                  label: 'Active',
                },
                {
                  value: ApiKeyTabs.REVOKED,
                  label: 'Revoked',
                },
              ]}
              value={tab}
              onSelect={handleTabClick}
            />
          </div>

          <div className="spacer"></div>

          <CFButton
            value="Add key"
            iconName={faAdd}
            role={CFRole.Primary}
            onClick={() => navigate(CFRoutes.integration_keys_new)}
          />
        </div>

        <CFTable title={''} headers={headerItems} data={visibleKeys} onSelectRow={handleKeyClick} />

        <div>
          {selectedKeyInfo && (
            <CFTitledSection title={''}>
              <SectionAction>
                {tab.value === ApiKeyTabs.ACTIVE && <CFButton value="Revoke" onClick={handleRevokeSelectedKey} />}
              </SectionAction>
              <div>
                {Object.entries(selectedKeyInfo).map(([key, value]) => (
                  <div key={key}>
                    <span> {key} </span>: <span>{value}</span>
                  </div>
                ))}
              </div>
            </CFTitledSection>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApiKeyManagement;
