import React, { useEffect, useState } from 'react';

import CFTitledSection from 'components/CFTitledSection';
import CFScatterRangeChart, { ScatterPoint } from 'components/charts/CFScatterRange';
import { InterventionId } from 'services/intervention/intervention.types';
import { useServicesContext } from 'hooks/useServicesContext';
import { useArmsInfoContext } from '../useArmsInfo';
import { armConfidenceDescription } from '../../constants';

interface Props {
  interventionId: InterventionId;
}

const ArmConfidence = ({ interventionId }: Props) => {
  const { modelService, interventionService } = useServicesContext();
  const { armsIndexes } = useArmsInfoContext();

  const [points, setPoints] = useState<ScatterPoint[]>([]);

  useEffect(() => {
    (async () => {
      if (!interventionId) {
        return;
      }

      const interventionView = await interventionService.getView(interventionId);
      const modelId = interventionView.intervention.algo_policy.model_def_id;

      const armMetrics = await modelService.getArmClusters(`${modelId || ''}`);

      const totalPoints = armMetrics.x.length;

      const points = [];
      for (let i = 0; i < totalPoints; i++) {
        if (armsIndexes.length !== 0) {
          if (!armsIndexes.includes(armMetrics.arm_idx[i])) {
            continue;
          }
        }

        points.push({
          x: armMetrics.x[i],
          y: armMetrics.y[i],
          value: armMetrics.margin[i],
          size: Math.floor(Math.random() * 10),
        });
      }

      setPoints(points);
    })();
  }, [interventionId, armsIndexes]);

  return (
    <CFTitledSection title="Best Arm Confidence" description={armConfidenceDescription} underlined={true}>
      <CFScatterRangeChart points={[...points]} showGradient={true} />
    </CFTitledSection>
  );
};

export default ArmConfidence;
