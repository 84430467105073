import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { faEraser } from '@fortawesome/free-solid-svg-icons';

import { useToast } from 'hooks';
import { useServicesContext } from 'hooks/useServicesContext';

import { BackfillReference, ReferenceDetailItem } from 'services/traits/backfill.types.api';

import CFButton from 'components/buttons/CFButton';
import CFConfirmableButton from 'components/CFConfirmableButton';
import CFTable, { Column, ColumnType } from 'components/CFTable';
import { ToastType } from 'components/CFToast/types';

import './reference-detail.scss';

interface Props {
  backfillRef: BackfillReference;
}

const ReferenceDetail = ({ backfillRef }: Props) => {
  const [refDetail, setRefDetail] = useState<ReferenceDetailItem[]>([]);
  const { backfillService } = useServicesContext();

  const { addToast } = useToast();

  useEffect(() => {
    (async () => {
      try {
        const detail = await backfillService.getReferenceDetail(backfillRef);
        setRefDetail(detail || []);
        console.log('detail: ', detail);
      } catch {
        addToast(`Error getting detail for reference ${backfillRef}`, ToastType.ERROR);
      }
    })();
  }, []);

  const handleCancelBackfill = useCallback(() => {
    (async () => {
      try {
        await backfillService.cancel(backfillRef);
      } catch {
        addToast('Error canceling backfill', ToastType.ERROR);
      }
    })();
  }, []);

  const headers: Column[] = [
    {
      title: 'Time',
      type: ColumnType.DATE,
      field: 't',
    },
    {
      title: 'Cohort',
      type: ColumnType.NUMBER,
      field: 'cohort_id',
    },
    {
      title: 'Latency',
      type: ColumnType.NUMBER,
      field: 'latency',
    },
    {
      title: 'Trait',
      type: ColumnType.STRING,
      field: '',
      renderCell: (row) => {
        return row.ptr;
      },
    },
  ];

  const sortedItems = useMemo(() => {
    return refDetail.sort((a, b) => a.latency - b.latency);
  }, [refDetail]);

  return (
    <div className="reference-detail">
      <div className="reference-detail__controls">
        <CFConfirmableButton title={'Cancel backfill'} question={'Are you sure to cancel this backfill?'}>
          <CFButton value="Cancel backfill" iconName={faEraser} onClick={() => handleCancelBackfill()} />
        </CFConfirmableButton>
      </div>

      <div className="reference-detail__data">
        <CFTable headers={headers} data={sortedItems} indexCol="run_ref" />
      </div>
    </div>
  );
};

export default ReferenceDetail;
