import React from 'react';

import './cf-no-border-button.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
interface Props {
  value: string;
  disabled?: boolean;
  iconName?: IconDefinition;
  // icon: string,
  onClick: (evt: any) => void;
}

const CFNoBorderButton = ({ onClick, value, iconName, disabled = false }: Props) => {
  return (
    <button
      type="submit"
      className={disabled ? 'cf-no-border-button is-disabled' : 'cf-no-border-button'}
      onClick={onClick}
      aria-disabled={disabled}
    >
      {iconName !== undefined ? <FontAwesomeIcon className="button-icon" icon={iconName} /> : ''}
      {value !== '' ? <span className="cf-no-border-button-input">{value}</span> : ''}
    </button>
  );
};

export default CFNoBorderButton;
