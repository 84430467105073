import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { IngestLogItem } from 'domain/ingesthistory.types';

import { getIngestHistoryLogs } from 'repositories/ingesthistory';

import CFLoadWrapper from 'components/CFLoadWrapper';
import { ToastType } from 'components/CFToast/types';
import { CFNavListWithLinks } from 'components/CFNavListWithLinks';
import CFTable, { Column, ColumnType } from 'components/CFTable';

import { CFRoutes } from 'routes';
import { useToast } from 'hooks';

import { Tabs } from '..';

import './ingesthistory.scss';

const IngestHistory = () => {
  const { addToast } = useToast();

  const [ingestHistoryLogs, setIngestHistoryLogs] = useState<IngestLogItem[]>([]);
  const defaultLogText = 'Select a log on the left to view details.';
  const [selectedLogInfo, setSelectedLogInfo] = useState<string>(defaultLogText);
  const pageValue = 0;
  const limitPerPage = 1000;

  const [isLoading, setIsLoading] = useState(true);

  const getLogHistoryFromRemote = async () => {
    try {
      setIsLoading(true);
      const ingestHistory = await getIngestHistoryLogs(pageValue, limitPerPage);

      ingestHistory.data.sort((a, b) => Date.parse(b.ts) - Date.parse(a.ts));

      setIngestHistoryLogs(
        ingestHistory.data.map((logItem: any, index: number) => logItemToIngestListItem(index, logItem))
      );
    } catch {
      addToast(`Couldn't load ingest elements`, ToastType.ERROR, 5000);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getLogHistoryFromRemote();
  }, []);

  const handleKeyClick = (clickedLog: IngestLogItem) => {
    const loggedItem = ingestHistoryLogs.find((logItem) => logItem.id === clickedLog.id);
    if (loggedItem) {
      setSelectedLogInfo(JSON.stringify(loggedItem?.logItem, null, 2));
    } else {
      setSelectedLogInfo(defaultLogText);
    }
  };

  const headerItems: Column[] = [
    { title: 'Type', field: 'type', type: ColumnType.STRING },
    { title: 'Date', field: 'logDate', type: ColumnType.STRING },
    { title: 'Time', field: 'logTime', type: ColumnType.STRING },
  ];

  return (
    <div>
      <CFNavListWithLinks
        titles={[
          { title: Tabs.Monitoring, target: CFRoutes.integration },
          { title: Tabs.IngestHistory, target: CFRoutes.integration_ingest_history },
          { title: Tabs.CatalogHistory, target: CFRoutes.integration_catalog_history },
          { title: Tabs.SdkExceptions, target: CFRoutes.integration_exception_view },
          { title: Tabs.ApiKeys, target: CFRoutes.integration_keys },
        ]}
        selected={Tabs.IngestHistory}
      />
      <div className="ingest-history">
        <div className="container-parent">
          <CFLoadWrapper isLoading={isLoading}>
            <div className="container-child-left">
              <CFTable
                headers={headerItems}
                data={ingestHistoryLogs}
                onSelectRow={(row) => handleKeyClick(row as IngestLogItem)}
              />
            </div>
          </CFLoadWrapper>
          <div className="container-child-right">
            <div className="container-code-preview">
              <pre>{selectedLogInfo}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function logItemToIngestListItem(index: number, logItem: any): IngestLogItem {
  return {
    id: index.toString(),
    type: logItem['type'],
    logDate: dayjs(logItem['ts']).format('YYYY-MM-DD'),
    logTime: dayjs(logItem['ts']).format('HH:mm'),
    logItem: logItem,
  };
}

export default IngestHistory;
