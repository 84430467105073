import colors from 'common.scss';

// charts
export const chartLineColor1 = '#94D5DB';

// weights
export const semibold = 600;

export const CHART_COLORS = [
  colors.cfCyan,
  colors.cfYellow300,
  colors.cfOrange400,
  colors.cfRed500,
  colors.cfPink400,
  colors.cfBlue400,
  colors.cfPurple500,
  colors.cfOlive400,
  colors.cfGreen400,
  colors.cfTeal500,

  colors.cfPurple300,
  colors.cfIndigo500,
  colors.cfTeal400,
  colors.cfBlue600,
  colors.cfYellow500,
  colors.cfPink200,
  colors.cfGreen600,
  colors.cfIndigo700,
  colors.cfPink700,
  colors.cfRed300,
];

export const CHART_COLORS_LIGHT = [
  colors.cfCyan,
  colors.cfYellow300,
  colors.cfPink400,
  colors.cfGreen400,

  colors.cfBlue400,
  colors.cfPurple500,
  colors.cfRed500,

  colors.cfOlive400,
  colors.cfTeal500,
  colors.cfOrange400,

  colors.cfPurple300,
  colors.cfIndigo500,
  colors.cfTeal400,
  colors.cfBlue600,
  colors.cfYellow500,
  colors.cfPink200,
  colors.cfGreen600,
  colors.cfIndigo700,
  colors.cfPink700,
  colors.cfRed300,
];
