import React, { useCallback, useEffect, useMemo, useState } from 'react';

import CFTable, { Column, ColumnType } from 'components/CFTable';
import DataCell from './DataCell';
import HeaderCell from './HeaderCell';
import { RGBColor } from 'domain/general.types';
import CFTitledSection, { SectionAction } from 'components/CFTitledSection';

import { InterventionId, InterventionViewExtended } from 'services/intervention/intervention.types';

import { useServicesContext } from 'hooks/useServicesContext';

import { getColorForGroup } from '../helpers';

import { ArmSensitivity } from 'services/model/model.repo.types';

import { getDisplayName as getTraitDisplayName } from 'services/traits/helpers.traits';

import { sensitivityChart } from '../constants';

import './sensitivity-chart.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompress, faExpand } from '@fortawesome/free-solid-svg-icons';

interface ArmImportance {
  id: string;
  name: string;
  arms: Record<string, number>;
}

interface Props {
  interventionId: InterventionId;
}

const legendTexts = [
  'Large negative',
  'Medium negative',
  'Small negative',
  'Negligible',
  'Small positive',
  'Medium positive',
  'Large positive',
];

const SensitivityChart = ({ interventionId }: Props) => {
  const { modelService, interventionService, traitSessionService: traitService } = useServicesContext();

  const [expanded, setExpanded] = useState(false);
  const [sensitivity, setSensitivity] = useState<ArmSensitivity>();
  const [intervention, setIntervention] = useState<InterventionViewExtended>();

  useEffect(() => {
    (async () => {
      if (!interventionId) {
        return;
      }

      const interventionView = await interventionService.getView(interventionId);
      const modelId = interventionView.intervention.algo_policy.model_def_id;

      try {
        const armSensitivity = await modelService.getArmSensitivy(`${modelId || ''}`);
        setIntervention(interventionView);
        setSensitivity(armSensitivity);
      } catch (err) {
        console.log(' Error getting intervention info');
      }
    })();
  }, [interventionId]);

  const arms: Array<{ name: string; color: RGBColor }> = useMemo(() => {
    if (!sensitivity || !intervention) {
      return [];
    }

    return Object.keys(sensitivity).map((name, i) => ({
      name: name,
      color: getColorForGroup(name, i + 1, intervention.controlGroup === name),
    }));
  }, [sensitivity, intervention]);

  const traitNames = useMemo(() => {
    if (!sensitivity) {
      return;
    }

    const arms = Object.keys(sensitivity);
    if (!arms.length) {
      return [];
    }

    return Object.keys(sensitivity[arms[0]]);
  }, [sensitivity]);

  const data: ArmImportance[] = useMemo(() => {
    if (!sensitivity || !traitNames || !arms) {
      return [];
    }

    const getDisplayName = (traitName: string) => {
      // TODO: move this to a service
      const [, categoryValue] = traitName.split('#');

      const trait = traitService.getTraitDefinition(traitName);

      const displayName = getTraitDisplayName(trait);

      if (categoryValue) {
        return `${displayName} (${categoryValue})`;
      } else {
        return displayName;
      }
    };

    return traitNames.map((traitName) => ({
      id: traitName,
      name: getDisplayName(traitName),
      arms: arms.reduce((acc, current) => ({ ...acc, [current.name]: sensitivity[current.name][traitName] }), {}),
    }));
  }, [traitNames, arms, sensitivity]);

  const columns: Column[] = ['', ...arms.map((arm) => arm.name)].map((arm, index) => ({
    title: '',
    field: 'id',
    type: ColumnType.STRING,
    style: { width: index === 0 ? '150px' : '' },

    renderHeader: () => {
      if (index === 0) {
        return '';
      } else {
        return <HeaderCell name={arms[index - 1].name} color={arms[index - 1].color} />;
      }
    },

    renderCell: (row) => {
      if (index === 0) {
        return row.name;
      } else {
        return <DataCell importance={(row as any).arms[arms[index - 1].name]} />;
      }
    },
  }));

  const maxValue = useMemo(() => {
    const values = data.map((item) => Object.values(item.arms));

    return Math.max(...values.flat());
  }, [data]);

  const minValue = useMemo(() => {
    const values = data.map((item) => Object.values(item.arms));

    return Math.min(...values.flat());
  }, [data]);

  const legendItems = useMemo(() => {
    const items = [];

    for (let i = minValue; i <= maxValue; i++) {
      items.push(
        <div className="item-legend">
          <DataCell importance={i} />
          <div className="item-legend__text">
            {' '}
            {legendTexts[i + 3].split(' ').map((word) => (
              <div key={`part-${i}`}>{word}</div>
            ))}
          </div>
        </div>
      );
    }

    return items;
  }, [minValue, maxValue]);

  const handleExpand = useCallback(() => {
    setExpanded((expanded) => !expanded);
  }, []);

  return (
    <CFTitledSection title="Sensitivity chart" description={sensitivityChart} underlined={true}>
      <SectionAction>
        <FontAwesomeIcon icon={expanded ? faCompress : faExpand} onClick={handleExpand} style={{ cursor: 'pointer' }} />
      </SectionAction>

      <div className="sensitivity-chart">
        <CFTable headers={columns} data={data} maxHeight={expanded ? undefined : 400} />

        <div className="sensitivity-chart__footer">
          <div className="sensitivity-legend">{legendItems}</div>
        </div>
      </div>
    </CFTitledSection>
  );
};

export default SensitivityChart;
