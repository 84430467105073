import React, { useCallback, useEffect, useState } from 'react';

import InterventionSection from 'views/intervention/interventions/NewIntervention/interventionSection';

import { AlgorithmClass, AlgorithmName } from 'services/intervention/intervention.types';

import CFTitledComponent from 'components/CFTitledComponent';
import CFInput from 'components/CFInput';
import CFSelectLegacy, { SelectableItem } from 'components/CFSelectLegacy';

import ModelService from 'services/model/model.service';

import { Steps, useModelContext } from '../useContext';

import './definition.scss';
import { AlgoMeta } from 'domain/model.types';

interface Props {
  modelService: ModelService;
}

const ModelDefinition = ({ modelService }: Props) => {
  const { updateReadiness, setDefinition } = useModelContext();

  const [name, setName] = useState('');
  const [modelClassOptions, setModelClassOptions] = useState<string[]>([]);
  const [modelClass, setModelClass] = useState<string>('');
  const [algorithmOptions, setAlgorithmOptions] = useState<AlgoMeta[]>([]);
  const [algorithm, setAlgorithm] = useState<string>('');

  const getClassmeta = async () => {
    const classOptions = await modelService.getAlgorithmClasses();

    setModelClassOptions(classOptions);
    setModelClass(classOptions[0]);
  };

  useEffect(() => {
    getClassmeta();
  }, []);

  useEffect(() => {
    if (!modelClass) {
      return;
    }

    modelService.getAlgorithmNames(modelClass as AlgorithmClass).then((metas) => {
      setAlgorithmOptions(metas);
      setAlgorithm('');
    });
  }, [modelClass]);

  useEffect(() => {
    updateReadiness(Boolean(name.trim() && modelClass && algorithm), Steps.Definition);
    setDefinition({
      name,
      class: modelClass as AlgorithmClass,
      algorithm: algorithm as AlgorithmName,
    });
  }, [name, modelClass, algorithm]);

  const handleNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  const handleModelClassSelected = useCallback((selectedAlgorithm: SelectableItem[]) => {
    setModelClass(selectedAlgorithm[0].value);
  }, []);

  const handleAlgorithmTypeSelected = useCallback((selectedAlgorithm: SelectableItem[]) => {
    setAlgorithm(selectedAlgorithm[0].value);
  }, []);

  if (!algorithmOptions.length) {
    return <div></div>;
  }

  return (
    <InterventionSection name={Steps.Definition} title={'Definition'} subtitle="Name and define your model.">
      <div className="model-form-definition">
        <CFTitledComponent title={'Name'} className="name">
          <CFInput onChange={handleNameChange} />
        </CFTitledComponent>
        <CFTitledComponent title={'Model Class'} className="class">
          <CFSelectLegacy
            onSelected={handleModelClassSelected}
            defaultOption={[{ label: modelClassOptions[0], value: modelClassOptions[0] }]}
            options={modelClassOptions.map((type) => ({ label: type, value: type }))}
          />
        </CFTitledComponent>
        <CFTitledComponent title={'Algorithm'} className="algorithm">
          <CFSelectLegacy
            onSelected={handleAlgorithmTypeSelected}
            defaultOption={[{ label: algorithmOptions[0].alias, value: algorithmOptions[0].algo_name }]}
            value={[
              {
                label: algorithmOptions.find((item) => item.algo_name === algorithm)?.alias || algorithm,
                value: algorithm,
              },
            ]}
            options={algorithmOptions.map((type) => ({ label: type.alias, value: type.algo_name }))}
          />
        </CFTitledComponent>
      </div>
    </InterventionSection>
  );
};

export default ModelDefinition;
