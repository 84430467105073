import React, { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import moment from 'moment';

import WeekViewer from 'components/DateTime/WeekViewer';
import Month from 'components/DateTime/DatetimePicker/Month';
import { NavigationAction } from 'components/DateTime/DatetimeRangePicker/types';

import { getEdgeDateRanges, getRecurringDatesForMonth } from 'helpers/dates';

import { FixedNudgeSchedule } from 'services/intervention/intervention.types';
import { Model } from 'domain/model.types';

import MonitoringSection from '../monitoringSection';

import './schedule.scss';

interface Props {
  model: Model;
}

const ModelSchedule = ({ model }: Props) => {
  const [week, setWeek] = useState(dayjs().week());
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());

  const fixedSchedulePolicy = useMemo(() => model.schedule as FixedNudgeSchedule, [model]);

  const year = useMemo(() => dayjs().year(), []);

  const handleMonthNavigate = (action: NavigationAction) => {
    if (action === NavigationAction.Next) {
      const nextMonth = dayjs(currentDate).add(1, 'month');

      setCurrentDate(nextMonth.toDate());
    } else {
      const prevMonth = dayjs(currentDate).subtract(1, 'month');

      setCurrentDate(prevMonth.toDate());
    }
  };

  const handleClickOnDay = useCallback((day: Date) => {
    setWeek(dayjs(day).week());
    setSelectedDate(day);
  }, []);

  const schedulingSubTitle = useMemo(() => {
    const { startDate, endDate } = getEdgeDateRanges(fixedSchedulePolicy);

    return `Schedules for this model run from ${startDate.format('YYYY-MM-DD')} through ${endDate.format(
      'YYYY-MM-DD'
    )}, on ${fixedSchedulePolicy?.definition?.tz || endDate.format('Z')} time zone.`;
  }, [fixedSchedulePolicy]);

  const timestamps = useMemo(() => {
    if (fixedSchedulePolicy.definition.time_points?.pts) {
      return fixedSchedulePolicy.definition.time_points?.pts;
    } else if (fixedSchedulePolicy.definition.recurring?.pairs) {
      return Array.from(
        new Set(
          getRecurringDatesForMonth(fixedSchedulePolicy.definition.recurring.pairs, currentDate)
            .flat()
            .concat(
              getRecurringDatesForMonth(
                fixedSchedulePolicy.definition.recurring.pairs,
                dayjs(currentDate).subtract(1, 'month').toDate()
              ).flat()
            )
            .concat(
              getRecurringDatesForMonth(
                fixedSchedulePolicy.definition.recurring.pairs,
                dayjs(currentDate).add(1, 'month').toDate()
              ).flat()
            )
            .map((date) =>
              dayjs(date.toISOString()).utcOffset(fixedSchedulePolicy.definition.tz).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
            )
        )
      ).sort();
    }
  }, [fixedSchedulePolicy, currentDate]);

  return (
    <MonitoringSection title="Schedule" subTitle={schedulingSubTitle} className="model-definition-chedule">
      <div className="calendar-with-week">
        <Month
          value={currentDate}
          date={selectedDate}
          minDate={moment(0).toDate()}
          maxDate={dayjs().add(1, 'year').toDate()}
          setValue={() => ({})}
          timestamps={timestamps}
          helpers={{
            isHover: () => false,
          }}
          handlers={{
            onDayClick: handleClickOnDay,
            onDayHover: () => ({}),
            onMonthNavigate: handleMonthNavigate,
          }}
        />
        <WeekViewer week={week} year={year} timestamps={timestamps} />
      </div>
    </MonitoringSection>
  );
};

export default ModelSchedule;
