import { AxiosResponse } from 'axios';
import { get as httpGet, post as httpPost, remove as httpDelete } from '../../repositories/drivers/http';

import { CreatingNudge, Nudge, NudgeID } from 'services/nudging/nudge.types';
import { PaginatedElement } from 'types';
import { RepoConfig } from '../../repositories/types';

const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
const path = '/v1/nudge';

const repoConfig = {
  token: '',
  oid: -1,
  pid: -1,
};

export const init = ({ token, oid, pid }: RepoConfig) => {
  repoConfig.token = token;
  repoConfig.oid = oid;
  repoConfig.pid = pid;
};

export const get = async (page: number, per_page: number): Promise<PaginatedElement<Nudge>> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
      page,
      per_page,
    },
  };

  try {
    const {
      data: { total, data },
    } = (await httpGet(`${serverBaseUrl}${path}/list`, config)) as AxiosResponse;

    return { total, data: data || [] };
  } catch (err) {
    console.error('error listing nudges: ', err);
    throw new Error('error-listing-nudges');
  }
};

export const getById = async (id: NudgeID): Promise<Nudge> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };

  try {
    const nudgeResponse = (await httpGet(`${serverBaseUrl}${path}/${id}`, config)) as AxiosResponse;

    return nudgeResponse.data;
  } catch (err) {
    console.error('error getting nudge: ', err);
    throw new Error('error-getting-nudge');
  }
};

export const create = async (nudge: CreatingNudge) => {
  const config = {};

  const body = nudge;

  try {
    const res = await httpPost(
      `${serverBaseUrl}${path}/create?oid=${repoConfig.oid}&pid=${repoConfig.pid}`,
      body,
      config
    );
    return res;
  } catch (err) {
    console.error('error creating nudge: ', err);
    throw new Error('error-creating-nudge');
  }
};

export const remove = async (nudgeId: number) => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };

  try {
    (await httpDelete(`${serverBaseUrl}${path}/delete/${nudgeId}`, config)) as AxiosResponse;
  } catch (err) {
    console.error('error removing nudge: ', err);
    throw new Error('error-removing-nudge');
  }
};

export default {
  init,
  get,
  getById,
  remove,
  create,
};
