import React, { useCallback, useState } from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react';

import CFInput from 'components/CFInput';
import CFSelect, { SelectableItem } from 'components/CFSelectLegacy';
import CFTitledComponent from 'components/CFTitledComponent';

import { NudgeTag, NudgeType, RenderMethod, RenderPage } from 'services/nudging/nudge.types';

import './nudge-config-form.scss';
import CFSwitch from 'components/CFSwitch';
import { noopDescription } from './constants';

interface Props {
  nudgeType: NudgeType;
  onTypeChanged: (nudgeType: NudgeType) => void;
}

interface NudgeConfig {
  name: string;
  description: string;
  render_method?: RenderMethod;
  tags: NudgeTag[];
  render_page?: RenderPage;
  noop: boolean;
}

export interface NudgeConfigRef {
  value: () => NudgeConfig;
}

const NudgeConfigForm = forwardRef<NudgeConfigRef, Props>(function NewNudgeForm(
  { nudgeType, onTypeChanged }: Props,
  ref
) {
  const nameRef = useRef<HTMLInputElement | null>(null);
  const descriptionRef = useRef<HTMLInputElement | null>(null);
  const tagsRef = useRef<any>();
  const renderMethodRef = useRef<any>(null);
  const renderPageRef = useRef<any>(null);

  const [noop, setNoop] = useState(false);

  useImperativeHandle(ref, () => ({
    value() {
      const nudgeFormData: Partial<NudgeConfig> = {
        name: nameRef.current?.value || '',
        description: descriptionRef.current?.value || '',
        tags: tagsRef.current.getValue().map((item: SelectableItem) => item.value),
        noop,
      };

      if (nudgeType === NudgeType.Message) {
        nudgeFormData.render_method = (renderMethodRef.current.getValue() as SelectableItem[])[0].value as RenderMethod;
        nudgeFormData.render_page = (renderPageRef.current.getValue() as SelectableItem[])[0].value as RenderPage;
      }

      return nudgeFormData as NudgeConfig;
    },
  }));

  const handleNudgeTypeChange = useCallback((items: SelectableItem[]) => {
    onTypeChanged(items[0].value as NudgeType);
  }, []);

  const handleNoopChange = useCallback((value: any) => {
    setNoop(value);
  }, []);

  return (
    <div className={`new-nudge-form`}>
      <CFTitledComponent title="Type">
        <CFSelect
          defaultOption={[
            {
              label: 'Message',
              value: NudgeType.Message,
            },
          ]}
          options={[
            {
              label: 'Message',
              value: NudgeType.Message,
            },
            {
              label: 'Recommend',
              value: NudgeType.Recommender,
            },
          ]}
          onSelected={handleNudgeTypeChange}
        />
      </CFTitledComponent>

      <CFTitledComponent title="Name">
        <CFInput ref={(el) => (nameRef.current = el)} />
      </CFTitledComponent>

      <CFTitledComponent title="Description">
        <CFInput ref={(el) => (descriptionRef.current = el)} />
      </CFTitledComponent>

      {nudgeType === NudgeType.Message && (
        <>
          <CFTitledComponent title="Render method">
            <CFSelect
              ref={(el) => (renderMethodRef.current = el)}
              defaultOption={[
                {
                  label: 'Push notification',
                  value: RenderMethod.PushNotification,
                },
              ]}
              options={[
                {
                  label: 'Push notification',
                  value: RenderMethod.PushNotification,
                },
                {
                  label: 'In App Message',
                  value: RenderMethod.InApp,
                },
              ]}
            />
          </CFTitledComponent>

          <CFTitledComponent title="Render page">
            <CFSelect
              ref={(el) => (renderPageRef.current = el)}
              defaultOption={[{ label: RenderPage.None, value: RenderPage.None }]}
              options={Object.values(RenderPage).map((page: RenderPage) => ({ label: page, value: page }))}
            />
          </CFTitledComponent>
        </>
      )}

      <CFTitledComponent title="Tags">
        <CFSelect
          ref={(el) => (tagsRef.current = el)}
          isMulti={true}
          options={Object.values(NudgeTag).map((tag) => ({
            label: tag,
            value: tag,
          }))}
        />
      </CFTitledComponent>

      <CFTitledComponent title="Stealth nudge" description={noopDescription}>
        <div className="named-control">
          <CFSwitch checked={noop} onChange={handleNoopChange} />
          {noop ? <span>Enable</span> : <span>Disable</span>}
        </div>
      </CFTitledComponent>
    </div>
  );
});

export default NudgeConfigForm;
