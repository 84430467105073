import React, { useCallback, useEffect, useState } from 'react';

import CFDataTable from 'components/CFDataTable';
import { Column, ColumnType } from 'components/CFTable';
import { AlgorithmType, InterventionDraft } from 'services/intervention/intervention.types';
import CFEditButton from 'components/buttons/CFEditButton';
import CFTrashButton from 'components/buttons/CFTrashButton';

import { useServicesContext } from 'hooks/useServicesContext';

import CFConfirmableButton from 'components/CFConfirmableButton';

import { CFRoutes } from 'routes';

import useCFNavigation from 'hooks/useCFNavigation';

import './draft-table.scss';

const DraftTable = () => {
  const { interventionService } = useServicesContext();
  const [drafts, setDrafts] = useState<InterventionDraft[]>([]);
  const navigate = useCFNavigation();

  useEffect(() => {
    interventionService.getDrafts().then((drafts) => {
      setDrafts(drafts);
    });
  }, []);

  const handleDeleteDraft = useCallback(
    async (draftID: number) => {
      await interventionService.deleteDraft(draftID);
      const index = drafts.findIndex((draft) => draft.ID === draftID);
      const newDrafts = [...drafts];
      newDrafts.splice(index, 1);
      setDrafts(newDrafts);
    },
    [drafts]
  );

  const columns: Column[] = [
    {
      title: 'ID',
      field: 'ID',
      type: ColumnType.STRING,
    },
    {
      title: 'Name',
      field: '',
      type: ColumnType.STRING,
      renderCell: (row) => {
        return <div className="intv-title">{row.Draft.intervention.name || 'unnamed'}</div>;
      },
      style: {
        // minWidth: '250px',
      },
    },
    {
      title: 'Type',
      type: ColumnType.STRING,
      field: '',
      renderCell: (row) => {
        const rowWithType = row as InterventionDraft;
        const algoTypeMap = {
          [AlgorithmType.ABTest]: 'AB',
          [AlgorithmType.Bandit]: 'Bandit',
          [AlgorithmType.RestlessBandit]: 'Restless bandit',
        };

        if (rowWithType.Draft.intervention.algo_policy?.type) {
          return algoTypeMap[rowWithType.Draft.intervention.algo_policy?.type];
        }

        return rowWithType.Draft.intervention.algo_policy?.spec?.algo_name ?? '';
      },
    },
    {
      title: 'Created by',
      field: 'CreateBy',
      type: ColumnType.STRING,
    },
    {
      title: 'Created at',
      field: 'CreatedAt',
      type: ColumnType.DATE,
    },
    {
      title: '',
      field: '',
      type: ColumnType.OBJECT,
      style: {
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
      },
      renderCell: (row) => {
        return (
          <>
            <CFEditButton
              onClick={() => navigate(`${CFRoutes.intervention_new}?draft=${(row as InterventionDraft).ID}`)}
            />
            <CFConfirmableButton title={'Delete draft'} question={'Are you sure to delete this draft?'}>
              <CFTrashButton onClick={() => handleDeleteDraft(row.ID)} />
            </CFConfirmableButton>
          </>
        );
      },
    },
  ];

  return (
    <CFDataTable
      headers={columns}
      total={drafts.length}
      data={drafts.map((data) => ({ ...data, id: data.ID }))}
      onPaginated={() => ({})}
    />
  );
};

export default DraftTable;
