import { useEffect } from 'react';

import { useState } from 'react';
import { getAvailableSubjectTypes } from '../traits.repo';

const useSubjectTypes = () => {
  const [subjectTypes, setSubjectTypes] = useState([]);

  useEffect(() => {
    (async () => {
      const subjectTypes = await getAvailableSubjectTypes();
      setSubjectTypes(subjectTypes);
    })();
  }, []);

  return subjectTypes;
};

export default useSubjectTypes;
