import React from 'react';

import RoleDistributionChart from '../components/RoleDistributionChart';
import KPIMatrix from '../components/KPIMatrix';
import UserEngagementLayout from '../layout';
import { Tabs } from '../tabs';

import useEngagementData from './useEngagementData';
import useGlobalKpi from './useGlobalKpi';

import './active-users-per-role.scss';

const ActiveUsersPerRole = () => {
  const kpis = useEngagementData();
  const globalKPI = useGlobalKpi();

  return (
    <UserEngagementLayout className="active-users-per-role" tab={Tabs.ActiveUsersPerRole}>
      <KPIMatrix kpis={kpis} totalKPI={globalKPI} />
      <RoleDistributionChart kpis={kpis} />
    </UserEngagementLayout>
  );
};

export default ActiveUsersPerRole;
