import { CohortID } from 'services/cohort/cohort.types';
import CohortService from 'services/cohort/cohort.service';
import { getCohortNameFromTag } from '../helpers/cohortTagName';
import Cohort from 'services/cohort/domain/Cohort';

export const selectCohort = (id: CohortID, currentSelectedCohorts: CohortID[], availableCohorts: Cohort[]) => {
  const newSelectedCohortIDs = currentSelectedCohorts.filter((value) => value !== id);

  if (currentSelectedCohorts.length === newSelectedCohortIDs.length) {
    newSelectedCohortIDs.push(id);
  } else if (newSelectedCohortIDs.length === 0) {
    const startsByAll = availableCohorts.find((cohort) => cohort.name.startsWith('all')) || availableCohorts[0];

    return [startsByAll.id];
  }

  return newSelectedCohortIDs;
};

export const removeCohortByName = (
  cohortService: CohortService,
  name: string,
  currentSelectedCohorts: CohortID[],
  availableCohorts: Cohort[]
) => {
  let filteredIds = currentSelectedCohorts.filter(
    (cid) => cohortService.getCohort(`${cid}`)?.name !== getCohortNameFromTag(name)
  );

  if (!filteredIds.length) {
    const startsByAll = availableCohorts.find((cohort) => cohort.name.startsWith('all')) || availableCohorts[0];
    filteredIds = [startsByAll?.id];
  }

  return filteredIds;
};
