import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';

import CFSelectLegacy from 'components/CFSelectLegacy';
import CFButton from 'components/buttons/CFButton';
import { DateRange, DefinedRange } from 'components/DateTime/DatetimeRangePicker/types';
import DatetimeRangePicker from 'components/DateTime/DatetimeRangePicker';

import { toString as dateToString, oneWeekAgo, getEndDate } from 'helpers/dates';

import { TraitSubject, TraitType } from 'domain/traits.types';
import { AggLevel } from 'domain/stats.types';

import { CFRole } from 'domain/general.types';
import CFTitledComponent from 'components/CFTitledComponent';
import { getCurrentProject } from 'services/session/session.service';
import { defaultRanges } from 'components/DateTime/DatetimeRangePicker/default';
import moment from 'moment';
import dayjs from 'dayjs';
import { useServicesContext } from 'hooks/useServicesContext';
import { CohortID } from 'services/cohort/cohort.types';
import CFSelect, { Option } from 'components/CFSelect';

import CFCheckbox from 'components/CFCheckbox';

import Cohort from 'services/cohort/domain/Cohort';

import './backfill.scss';

interface Props {
  handleBackfill: (
    startDate: string,
    endDate: string,
    aggLevel: AggLevel,
    selectedSubject: TraitSubject,
    tableType: TraitType,
    cohortIDs: CohortID[],
    recomputeSubjects: boolean
  ) => Promise<void>;
  isBackfilling: boolean;
  isOpen: boolean;
  position: { top: number; left: number };
  onCancel: () => void;
}

const PAGE_SIZE = 100;

const TraitTypesForBackfill = [TraitType.All, TraitType.Dynamic, TraitType.Static, TraitType.GroupDynamic];

const selectCohortInBackfll = (id: CohortID, currentSelectedCohorts: CohortID[], availableCohorts: Cohort[]) => {
  const newSelectedCohortIDs = currentSelectedCohorts.filter((value) => value !== id);

  if (currentSelectedCohorts.length === newSelectedCohortIDs.length) {
    newSelectedCohortIDs.push(id);
  } else if (newSelectedCohortIDs.length === 0) {
    const startsByAll = availableCohorts.find((cohort) => cohort.name.startsWith('all')) || availableCohorts[0];

    return [startsByAll.id];
  }

  return newSelectedCohortIDs;
};

const BackfillModal = forwardRef<HTMLDivElement, Props>(function BackfillModal(
  { handleBackfill, isBackfilling, onCancel },
  ref
): JSX.Element {
  const project = getCurrentProject();
  const { cohortService } = useServicesContext();

  const [aggLevel, setAggLevel] = useState<AggLevel>(AggLevel.Day);
  const [selectedSubject, setSelectedSubject] = useState<TraitSubject>(TraitSubject.User);
  const [tableType, setTableType] = useState<TraitType>(TraitType.All);

  const [startDate, setStartDate] = useState<string>(project?.created_at ?? dateToString(oneWeekAgo()));

  const [endDate, setEndDate] = useState<string>(getEndDate(project?.created_at));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cohorts, setCohorts] = useState<Cohort[]>([]);
  const [cohortSearch, setCohortSearch] = useState('');
  const [selectedCohortIDs, setSelectedCohortIDs] = useState<string[]>([]);
  const [cohortValues, setCohortValues] = useState<Option[]>([]);

  const [recomputeSubjects, setRecomputeSubjects] = useState(false);

  useEffect(() => {
    (async () => {
      const cohortList = await cohortService.getListOfCohorts(0, PAGE_SIZE, selectedSubject);

      setCohorts(cohortList.data);
      setSelectedCohortIDs([]);
    })();
  }, [selectedSubject]);

  useEffect(() => {
    setSelectedCohortIDs([]);
  }, [tableType]);

  const defaultDateRanges: DefinedRange[] = useMemo(
    () => [
      {
        label: 'Yesterday',
        startDate: dayjs().subtract(1, 'day').toDate(),
        endDate: new Date(),
      },
      ...defaultRanges,
      {
        label: 'Full history',
        startDate: moment(project?.created_at ?? 0).toDate(),
        endDate: new Date(),
      },
    ],
    [project]
  );

  const handleRangeChange = useCallback((dateRange: DateRange) => {
    if (dateRange.startDate && dateRange.endDate) {
      setStartDate(dateToString(dateRange.startDate));
      setEndDate(dateToString(dateRange.endDate));
    }
  }, []);

  const handleApply = useCallback(async () => {
    setIsLoading(true);

    await handleBackfill(
      startDate,
      endDate,
      aggLevel,
      selectedSubject,
      tableType,
      selectedCohortIDs.map((id) => parseInt(id)),
      recomputeSubjects
    );
    setIsLoading(false);
    onCancel();
  }, [startDate, endDate, aggLevel, selectedSubject, tableType, selectedCohortIDs, recomputeSubjects]);

  const handleSelectCohort = useCallback(
    (item: Option) => {
      setSelectedCohortIDs((prev) => {
        const newIDs = selectCohortInBackfll(
          parseInt(item.value),
          prev.map((item) => parseInt(item)),
          cohorts
        );

        return newIDs.map((item) => `${item}`);
      });
    },
    [cohorts]
  );

  const selectableCohorts = cohorts.map((item) => ({
    label: `${item.name} (${item.size})`,
    value: item.id.toString(),
  }));

  useEffect(() => {
    (async () => {
      const cohortOptions = selectedCohortIDs.map(async (id) => {
        const cohort = await cohortService.getRemoteCohort(parseInt(id));

        return {
          label: cohort?.name || 'unknown',
          value: id,
        };
      });

      const options = await Promise.all(cohortOptions);
      setCohortValues(options);
    })();
  }, [selectedCohortIDs]);

  return (
    <div className="backfill-modal-wrapper" ref={ref}>
      <CFTitledComponent title="Set Subject">
        <CFSelectLegacy
          options={(getCurrentProject()?.subjects || []).map((item) => ({
            label: item,
            value: item,
          }))}
          defaultOption={[{ label: TraitSubject.User, value: TraitSubject.User }]}
          onSelected={([selected]) => setSelectedSubject(selected.value as TraitSubject)}
        />
      </CFTitledComponent>

      <CFTitledComponent title="Set Trait">
        <CFSelectLegacy
          options={TraitTypesForBackfill.map((item) => ({
            label: item,
            value: item,
          }))}
          defaultOption={[{ label: TraitType.All, value: TraitType.All }]}
          onSelected={([selected]) => setTableType(selected.value as TraitType)}
        />
      </CFTitledComponent>

      {tableType === TraitType.GroupDynamic && (
        <>
          <CFTitledComponent title="Recompute Subjects" inline>
            <CFCheckbox onChange={(val) => setRecomputeSubjects(val)} />
          </CFTitledComponent>
          <CFTitledComponent title="Cohort">
            <CFSelect
              options={selectableCohorts.sort().filter((option) => {
                return !cohortSearch.trim() || option.label.toLowerCase().includes(cohortSearch.toLowerCase());
              })}
              value={cohortValues}
              isMulti
              onSelected={handleSelectCohort}
              searchable
              onSearch={setCohortSearch}
            />
          </CFTitledComponent>
        </>
      )}

      <CFTitledComponent title="Set Aggregation Level">
        <CFSelectLegacy
          onSelected={([selected]) => setAggLevel(selected.value as AggLevel)}
          defaultOption={[
            {
              value: AggLevel.Day,
              label: AggLevel.Day,
            },
          ]}
          options={[
            { value: AggLevel.Day, label: 'Day' },
            { value: AggLevel.Week, label: 'Week' },
            { value: AggLevel.Month, label: 'Month' },
          ]}
        />
      </CFTitledComponent>
      <CFTitledComponent title="Set Date">
        <DatetimeRangePicker
          onChange={handleRangeChange}
          definedRanges={defaultDateRanges}
          initialDateRange={{
            startDate: new Date(project?.created_at ?? oneWeekAgo()),
            endDate: new Date(getEndDate(project?.created_at)),
          }}
          maxDate={new Date(getEndDate(project?.created_at))}
          showTime={false}
        />
      </CFTitledComponent>
      <div className="backfill-actions">
        <CFButton value="Cancel" onClick={onCancel} />
        <CFButton
          value="Apply"
          role={CFRole.Cyan}
          isLoading={isLoading}
          onClick={handleApply}
          disabled={isBackfilling}
        />
      </div>
    </div>
  );
});

export default BackfillModal;
