import React from 'react';

import { AuthAction, isAllowedTo } from 'services/authorization.service';

interface Props extends React.PropsWithChildren {
  authAction: AuthAction;
}

const ProtectedElement = ({ authAction, children }: Props) => {
  if (isAllowedTo(authAction)) {
    return <>{children}</>;
  } else {
    return <div></div>;
  }
};

export default ProtectedElement;
