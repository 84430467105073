import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'debounce';
import classNames from 'classnames';

import chroma from 'chroma-js';

import { intervals as defaultIntervals } from '../../constants';
import { ColorInterval, ScatterPoint } from '../..';
import { useScatterRangeContext } from '../../useScatterRange';

import { regionAlphaHex, regionBackground, regionStroke } from './constants';

import colors from 'common.scss';

interface Dimension {
  width: number;
  height: number;
}

interface ScreenScatterPoint extends ScatterPoint {
  screenX: number;
  screenY: number;
}

export interface PointsOvered {
  position: {
    x: number;
    y: number;
  };
  points: ScatterPoint[];
}

export interface ScatterPlotProps {
  className?: string;
  points: ScatterPoint[];
  intervals?: ColorInterval[];
  onPointsOver?: (points: PointsOvered) => void;
}

const MIN_HEIGHT = 200;
const SIZE_DOWNSCALING = 0.8;
const DEFAULT_RADIUS = 5;

interface Rect {
  startX: number;
  startY: number;
  w: number;
  h: number;
}

const CFScatterRangeChart = ({ intervals = defaultIntervals, points, className, onPointsOver }: ScatterPlotProps) => {
  const { selectedItems } = useScatterRangeContext();
  const canvasContainerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [dimensions, setDimensions] = useState<Dimension>({ width: 1, height: 1 });

  const [isSelectingRegion, setIsSelectingRegion] = useState(false);
  //const [inside, setInside] = useState(false);

  const [region, setRegion] = useState<Rect>({
    startX: 0,
    startY: 0,
    w: 0,
    h: 0,
  });

  const handleElementResized = (entries: any) => {
    for (const entry of entries) {
      if (!canvasContainerRef) {
        continue;
      }

      // keep height unchanged due weird behavior: height in contentRect is higher
      // than real canvas height, even if it does not change
      setDimensions((dimensions) => {
        const size = Math.min(Math.floor(entry.contentRect.width), dimensions.height);

        return {
          width: size,
          height: size,
        };
      });
    }
  };

  const screenPoints: ScreenScatterPoint[] = useMemo(() => {
    if (!points) {
      return [];
    }

    const marginPercentage = 0.9;
    const shiftPercentage = (1 - marginPercentage) / 2;

    const convertToScreen = ([x, y]: [number, number]) => [x, -(y - dimensions.height)];
    const scale = ([x, y]: number[]) => [x * marginPercentage, y * marginPercentage];
    const shift = ([x, y]: number[]) => [
      x + dimensions.width * shiftPercentage,
      y + dimensions.height * shiftPercentage,
    ];

    return points.map((point) => {
      const [x, y] = [Math.floor(point.x * dimensions.width), Math.floor(point.y * dimensions.height)];

      const [screenX, screenY] = shift(scale(convertToScreen([x, y])));

      return { ...point, screenX, screenY };
    });
  }, [points, dimensions]);

  useEffect(() => {
    // eslint-disable-next-line
    const handleMouseUpOutside = (evt: MouseEvent) => {
      setIsSelectingRegion(false);
    };

    document.addEventListener('mouseup', handleMouseUpOutside);

    return () => document.removeEventListener('mouseup', handleMouseUpOutside);
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(debouncedUpdateDimensions);

    if (canvasContainerRef.current) {
      resizeObserver.observe(canvasContainerRef.current);
    }

    return () => {
      if (canvasContainerRef.current) {
        resizeObserver.unobserve(canvasContainerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!canvasContainerRef.current) {
      return;
    }

    const size =
      Math.max(
        Math.floor(canvasContainerRef.current.offsetWidth),
        Math.max(Math.floor(canvasContainerRef.current.offsetHeight), MIN_HEIGHT)
      ) * SIZE_DOWNSCALING;

    setDimensions({
      width: size,
      height: size,
    });
  }, []);

  useEffect(() => {
    if (!canvasRef || !canvasRef.current) {
      return;
    }

    const ctx = canvasRef.current.getContext('2d');

    if (!ctx) {
      return;
    }

    ctx.clearRect(0, 0, dimensions.width, dimensions.height);

    drawPointSeries(ctx, screenPoints, false);
    drawPointSeries(ctx, screenPoints, true);

    drawRegion();
  }, [dimensions, screenPoints, selectedItems, canvasRef, canvasRef.current, region]);

  useEffect(() => {
    if (isSelectingRegion) {
      document.addEventListener('mousemove', updateSelectedRegion);
    } else {
      document.removeEventListener('mousemove', updateSelectedRegion);
    }
  }, [isSelectingRegion]);

  useEffect(() => {
    debouncedUpdateCounterOfItems(region);
  }, [region, screenPoints]);

  const updateCounterOfItems = (region: Rect) => {
    const [startX, startY] = [
      region.w > 0 ? region.startX : region.startX + region.w,
      region.h > 0 ? region.startY : region.startY + region.h,
    ];

    const [endX, endY] = [
      region.w > 0 ? region.startX + region.w : region.startX,
      region.h > 0 ? region.startY + region.h : region.startY,
    ];

    const pointsInRegion = screenPoints.filter((point) => {
      return point.screenX > startX && point.screenX < endX && point.screenY > startY && point.screenY < endY;
    });

    console.log('Pointer counter: ', pointsInRegion);
  };

  const debouncedUpdateCounterOfItems = debounce(updateCounterOfItems, 100);

  const drawPoint = (ctx: CanvasRenderingContext2D, circle: Path2D, point: ScreenScatterPoint) => {
    ctx.translate(point.screenX, point.screenY);
    ctx.fill(circle);
    ctx.translate(-point.screenX, -point.screenY);
  };

  const drawPointSeries = (ctx: CanvasRenderingContext2D, points: ScreenScatterPoint[], onlySelected = true) => {
    const circle = new Path2D();
    circle.arc(0, 0, DEFAULT_RADIUS, 0, 2 * Math.PI);

    const findInterval = (point: ScreenScatterPoint): [number, ColorInterval | undefined] => {
      for (let intervalIndex = 0; intervalIndex < intervals.length; intervalIndex++) {
        const interval = intervals[intervalIndex];

        if (point.value >= interval.range[0] && point.value < interval.range[1]) {
          return [intervalIndex, interval];
        }
      }

      if (point.value === intervals[intervals.length - 1].range[1]) {
        return [intervals.length - 1, intervals[intervals.length - 1]];
      }

      return [-1, undefined];
    };

    points.forEach((point) => {
      // find out corresponding interval
      // TODO: OPTIMIZATION -> sort points by value to avoid look for interval
      const [intervalIndex, interval] = findInterval(point);

      if (!interval || intervalIndex === -1) {
        return;
      }

      if (onlySelected && (selectedItems.size === 0 || selectedItems.has(intervalIndex))) {
        const scaleFactor = (point.value - interval.range[0]) / (interval.range[1] - interval.range[0]);
        const scaledColor = chroma.mix(interval.from, interval.to, scaleFactor).hex();

        ctx.fillStyle = scaledColor;

        drawPoint(ctx, circle, point);
      }

      if (!onlySelected) {
        const unselectedColor = chroma.scale([interval.from, colors.cardBackground]).colors(12)[10];

        ctx.fillStyle = unselectedColor;
        drawPoint(ctx, circle, point);
      }
    });
  };

  const handleOnMouseUp = useCallback(() => {
    if (!canvasRef || !canvasRef.current) {
      return;
    }

    setIsSelectingRegion(false);
  }, [canvasRef, canvasRef.current]);

  const updateSelectedRegion = useCallback((e: MouseEvent) => {
    if (!canvasRef || !canvasRef.current) {
      return;
    }

    const offsetY = canvasRef.current.getBoundingClientRect().top;
    const offsetX = canvasRef.current.offsetLeft;

    setRegion((rect) => {
      rect.w = e.clientX - offsetX - rect.startX;
      rect.h = e.clientY - offsetY - rect.startY;
      return { ...rect };
    });
  }, []);

  const drawRegion = () => {
    if (!canvasRef || !canvasRef.current) {
      return;
    }

    const ctx = canvasRef.current.getContext('2d');

    if (!ctx) {
      return;
    }

    ctx.strokeStyle = regionStroke;
    ctx.lineWidth = 0.5;
    ctx.fillStyle = ctx.fillStyle = `${regionBackground}${regionAlphaHex}`;

    ctx.fillRect(region.startX, region.startY, region.w, region.h);
    ctx.strokeRect(region.startX, region.startY, region.w, region.h);
  };

  const handleOnMouseDown = useCallback(
    (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
      setIsSelectingRegion(true);

      if (!canvasRef || !canvasRef.current) {
        return;
      }

      const ctx = canvasRef.current.getContext('2d');
      if (!ctx) {
        return;
      }

      const offsetY = canvasRef.current.getBoundingClientRect().top;
      const offsetX = canvasRef.current.offsetLeft;

      setRegion({ startX: e.clientX - offsetX, startY: e.clientY - offsetY, w: 0, h: 0 });
    },
    [canvasRef, canvasRef.current]
  );

  const handleOnMouseMove = useCallback(
    (evt: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
      if (isSelectingRegion) {
        // console.log('mouse move');
      } else {
        detectFigureUnderMouse(evt);
      }
    },
    [screenPoints, canvasRef, canvasRef.current, isSelectingRegion]
  );

  const detectFigureUnderMouse = (evt: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    const rect = canvasRef.current?.getBoundingClientRect();

    if (!rect) {
      return;
    }

    // eslint-disable-next-line
    const pointsUnderPointer = screenPoints.filter((point) => {
      const [x_evt, y_evt] = [evt.clientX - rect.x, evt.clientY - rect.y];

      const x_distance = Math.abs(point.screenX - x_evt);
      const y_distance = Math.abs(point.screenY - y_evt);

      return x_distance < DEFAULT_RADIUS && y_distance < DEFAULT_RADIUS;
    });

    onPointsOver && onPointsOver({ position: { x: evt.clientX, y: evt.clientY }, points: pointsUnderPointer });
  };

  const debouncedUpdateDimensions = useCallback(debounce(handleElementResized, 30), []);

  const debouncedMouseOver = useCallback(debounce(handleOnMouseMove, 50), [
    screenPoints,
    canvasRef,
    canvasRef.current,
    isSelectingRegion,
  ]);

  return (
    <div ref={canvasContainerRef} className={classNames(className, 'scatter-range-chart')}>
      <canvas
        ref={canvasRef}
        width={dimensions.width}
        height={dimensions.height}
        onMouseMove={debouncedMouseOver}
        onMouseDown={handleOnMouseDown}
        onMouseUp={handleOnMouseUp}
        //onMouseEnter={() => setInside(true)}
        //onMouseLeave={() => setInside(false)}
      />
    </div>
  );
};

export default CFScatterRangeChart;
