import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

import './cf-portal.scss';

interface Props {
  children: JSX.Element | JSX.Element[];
  mode?: ContentPosition;
  size?: ContainerSize;
  backdrop?: boolean;
  top?: number;
  left?: number;
  right?: number;

  onClickOutside: () => void;
}

export enum ContainerSize {
  Fit = 'fit',
  Full = 'full',
}

export enum ContentPosition {
  Center,
  Custom,
  None,
}

const CFPortal = ({
  children,
  mode = ContentPosition.None,
  size = ContainerSize.Full,
  onClickOutside,
  backdrop = true,
  top,
  left,
  right,
}: Props) => {
  const handleClick = (e: any) => {
    if (e.target !== e.currentTarget) {
      return;
    }

    onClickOutside();
  };

  return ReactDOM.createPortal(
    <div
      style={{ top, left, right }}
      className={classNames('cf-portal', {
        center: mode === ContentPosition.Center,
        custom: mode === ContentPosition.Custom,
        backdrop: backdrop,
        fit: size === ContainerSize.Fit,
      })}
      onClick={handleClick}
    >
      {children}
    </div>,
    document.querySelector('body') as Element
  );
};

export default CFPortal;
