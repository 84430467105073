import { Ptr } from 'services/cohort/cohort.types.api';
import { Module } from './general.types';
import { ModelId } from './model.types';

export type TraitCode = string; // <schema>.<table>.<name>

export enum TraitType {
  All = 'all',
  Static = 'st',
  Dynamic = 'dt',
  GroupDynamic = 'gdt',
  CatalogTrait = 'ct',
}

export enum TraitUsage {
  Cohort = 'cohort',
  Feature = 'feature',
  InvContext = 'inv_context',
  Filter = 'filter',
  InvMetric = 'inv_metric',
  Event = 'event',
  CensoringMetric = 'censoring_metric',
  TimeToEvent = 'time_to_event',
  BanditMetric = 'bandit_metric',
  ABMetric = 'ab_metric',
  BanditContext = 'bandit_context',
  Restless = 'restless',
}

export enum TraitCategory {
  Catalogue = 'ct',
  Dynamic = 'dt',
  Grouped = 'gdt',
  Static = 'st',
  MLT = 'mlt',
}

export enum TraitSubject {
  User = 'user',
  Device = 'device',

  // E-commerce
  Drug = 'drug',
  Grocery = 'grocery',
  Blood = 'blood',
  Oxygen = 'oxygen',
  MedicalEquipment = 'medical_equipment',

  // CHW MGMT
  Chwsite = 'chwsite',
  Patient = 'patient',

  // Loyalty
  Survey = 'survey',
  Reward = 'reward',

  Facility = 'facility',
}

export enum TraitDataType {
  Bool = 'bool',
  Boolean = 'boolean',
  Varchar = 'varchar',
  Int4 = 'int4',
  Int2 = 'int2',
  Float4 = 'float4',
  Number = 'number',
  Timestamp = 'timestamp',
}

export interface Trait {
  addr: ColAddr;
  meta: Meta;
}

export interface AppTrait extends Trait {
  models?: ModelId[];
}

export interface TraitDefinition {
  name: string;
  display_name: string;
  description: string;
  unit: string;
  category: TraitCategory;
  ptr: Ptr;
}

export interface ColAddr {
  ptr: Ptr;
  name?: string;
  table?: string;
  table_class?: string;
  schema?: string;
  dtype: string;
  pks?: string[];
  display_dtype?: string;
}

/*
type ColAddr struct {
	Ptr   Ptr    `yaml:"ptr" json:"ptr"`
	Dtype string `yaml:"dtype" json:"dtype" validate:"required,oneof=int2 int4 float4 bool varchar _varchar date timestamptz timestamp"`

	Deltas       Deltas       `yaml:"deltas" json:"-"`
	AvailableAgg []u.AggLevel `json:"available_agg,omitempty"`

	// cache
	Schema     string     `json:"schema"`
	Table      string     `json:"table"`
	Name       string     `json:"name"`
	TableClass TableClass `json:"-"` // without exact agg level
}
*/

export type TimeseriesItem = [string, string, number];

export interface TraitTimeseries {
  header: string[];
  data_type: string[];
  rows: TimeseriesItem[];
}

export enum TraitMetricsNames {
  AppRenderingTime = 'gdt_user_general.screen_rendering_time__mean#1d',
  AppStartTime = 'gdt_user_general.app_start_time__mean#1d',
  ConnectionInterval = 'gdt_user_general.connection_interval__mean#60d',
  ConnectionTime = 'gdt_user_general.connection_time__mean#1d',
  PushOpenRate = 'gdt_user_general.push_open_rate#1d',
  SpeedDown = 'gdt_user_general.log_download_speed__mean#1d',
  SpeedUp = 'gdt_user_general.log_upload_speed__mean#1d',

  ActiveUserCountDay = 'gdt_user_general.active_user_count#1d',
  ActiveUserCountMonth = 'gdt_user_general.active_user_count#1m',
  NewUserCountDay = 'gdt_user_general.new_user_count#1d',
  ChurnRatio = 'gdt_user_general.churn_ratio',
}

export enum TraitTimeseriesTypesDaily {
  ActiveUserCount = 'gdt_user_general.active_user_count#1d',
  ConnectionTimeMean = 'gdt_user_general.connection_time__mean#1d',
  ChurnedUserCount = 'gdt_user_general.churned_user_count#1d',
  NewUserCount = 'gdt_user_general.new_user_count#1d',
}

export enum TraitTimeseriesTypesMonthly {
  ActiveUserCount = 'gdt_user_general.active_user_count#1m',
  ConnectionTimeMean = 'gdt_user_general.connection_time__mean#1m',
  ChurnedUserCount = 'gdt_user_general.churned_user_count#1m',
}

export enum TraitAddr {
  ActiveDeviceCount = 'active_device_count',
  UserCountMean = 'user_count__mean',

  // gdt_day_user_general
  ChurnedUserCount = 'churned_user_count',
  ChurnRate = 'churn_rate',

  // gdt_day_chwsite_general.yml
  UniquePatientScreenCountMean = 'unique_patient_screen_count__mean',
  UniquePatientScreenCountSum = 'unique_patient_screen_count__sum',
  UniquePatientEnrolCountMean = 'unique_patient_enrol_count__mean',
  UniquePatientEnrolCountSum = 'unique_patient_enrol_count__sum',
  UniquePatientAssessCountMean = 'unique_patient_assess_count__mean',
  UniquePatientAssessCountSum = 'unique_patient_assess_count__sum',
  UniquePatientMedicalReviewCountMean = 'unique_patient_medical_review_count__mean',
  UniquePatientMedicalReviewCountSum = 'unique_patient_medical_review_count__sum',
  UniquePatientNewPrescriptionCountMean = 'unique_patient_new_prescription_count__mean',
  UniquePatientNewPrescriptionCountSum = 'unique_patient_new_prescription_count__sum',
  UniquePatientDispenseCountMean = 'unique_patient_dispense_count__mean',
  UniquePatientDispenseCountSum = 'unique_patient_dispense_count__sum',
  UniqueActiveChwCountMean = 'unique_active_chw_count__mean',
  UniqueActiveChwCountSum = 'unique_active_chw_count__sum',
  UniqueDeviceCountMean = 'unique_device_count__mean',
  UniqueDeviceCountSum = 'unique_device_count__sum',
  TotalPatientScreenCountMean = 'total_patient_screen_count__mean',
  TotalPatientScreenCountSum = 'total_patient_screen_count__sum',
  TotalPatientAssessCountMean = 'total_patient_assess_count__mean',
  TotalPatientAssessCountSum = 'total_patient_assess_count__sum',
  TotalPatientMedicalReviewCountMean = 'total_patient_medical_review_count__mean',
  TotalPatientMedicalReviewCountSum = 'total_patient_medical_review_count__sum',
  TotalPatientNewPrescriptionCountMean = 'total_patient_new_prescription_count__mean',
  TotalPatientNewPrescriptionCountSum = 'total_patient_new_prescription_count__sum',
  TotalPatientDispenseCountMean = 'total_patient_dispense_count__mean',
  TotalPatientDispenseCountSum = 'total_patient_dispense_count__sum',
  UniquePatientTestCountSum = 'unique_patient_test_count__sum',
  TotalPatientTestCountSum = 'total_patient_test_count__sum',

  ActionCountSum = 'action_count__sum',
  PatientScreenCount = 'patient_screen_count__sum',
  PatientEnrolCount = 'patient_enrol_count__sum',
  PatientPrescriptionCount = 'patient_prescription_count__sum',
  PatientMedicalReviewCount = 'patient_medical_review_count__sum',
  PatientDispenseCount = 'patient_dispense_count__sum',
  ActiveUserCount = 'active_user_count',
  ConnectionTimeMean = 'connection_time__mean',
  NewUserCount = 'new_user_count',
  PushOpenRate = 'push_open_rate',
  ConnectionIntervalMean = 'connection_interval__mean',
  RewardReceiveCount = 'reward_receive_count__sum',
  PatientAssesCount = 'patient_assess_count__sum',

  // gdt_day_drug_ecomm
  PurchasedItemCount = 'purchased_item_count',
  CheckoutCountSum = 'checkout_count__sum', // also for gdt_day_user_checkout
  CheckoutCountMean = 'checkout_count__mean', // also for gdt_day_user_checkout
  UniqueUserCheckoutCountSum = 'unique_user_checkout_count__sum',
  CheckoutFrequencyRatio = 'checkout_frequency_ratio',
  PromoCheckoutCountSum = 'promo_checkout_count__sum',
  NoPromoCheckoutCountSum = 'no_promo_checkout_count__sum',
  SingleItemCheckoutCountSum = 'single_item_checkout_count__sum',
  MultipleItemCheckoutCountSum = 'multiple_item_checkout_count__sum',
  CheckoutQuantityCountSum = 'checkout_quantity_count__sum',
  CheckoutAmountSpentMean = 'checkout_amount_spent__mean',
  CheckoutAmountSpentSum = 'checkout_amount_spent__sum',
  AddedToCartCountSum = 'added_to_cart_count__sum',
  UniqueAddedToCartCountSum = 'unique_added_to_cart_count__sum',
  CartToCheckoutConversion = 'cart_to_checkout_conversion',
  ViewCountSum = 'view_count__sum',
  ImpressionToCartConversion = 'impression_to_cart_conversion',
  ImpressionToCheckoutConversion = 'impression_to_checkout_conversion',
  ImpressionToViewConversion = 'impression_to_view_conversion',
}

export interface Meta {
  description: string;
  display_name: string;
  display_unit: string;
  subject: string;
  unit: string;
  usage: TraitUsage[];
  template: string;
  template_params: Record<string, string[]> | null;
  modules: Module[];
  Show?: boolean; // pick one when backend ready
  show?: boolean; // pick one when backend ready
  extra_pks?: string[];
}

export type TraitName = string;
