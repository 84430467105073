import React, { useMemo, useState } from 'react';

import CFLineChart from 'components/charts/CFLineChart';
import { AggLevel } from 'domain/stats.types';
import CFSelect from 'components/CFSelect';

import './dynamic-chart.scss';

interface Props {
  response: Record<string, string>[];
}

const DynamicChart = ({ response }: Props) => {
  const [horizontalAxisField, setHorizontalAxisField] = useState('');
  const [verticalAxisField, setVerticalAxisField] = useState('');

  const horizontalOptions = useMemo(() => {
    return Object.keys(response[0]).filter((item) => item !== verticalAxisField);
  }, [response, verticalAxisField]);

  const verticalOptions = useMemo(() => {
    return Object.keys(response[0]).filter((item) => item !== horizontalAxisField);
  }, [response, horizontalAxisField]);

  const series = [
    {
      items: response.map((row) => ({ time: row[horizontalAxisField], value: Number(row[verticalAxisField]) })),
      name: verticalAxisField,
    },
  ];

  return (
    <div className="dynamic-chart">
      <div className="dynamic-chart__controls">
        X-Axis
        <CFSelect
          options={horizontalOptions.map((option) => ({ value: option, label: option }))}
          isMulti={false}
          value={{ value: horizontalAxisField, label: horizontalAxisField }}
          onSelected={(item) => setHorizontalAxisField(item.value)}
        />
        Y-Axis
        <CFSelect
          options={verticalOptions.map((option) => ({ value: option, label: option }))}
          isMulti={false}
          value={{ value: verticalAxisField, label: verticalAxisField }}
          onSelected={(item) => setVerticalAxisField(item.value)}
        />
      </div>
      <CFLineChart data={series} aggregationLevel={AggLevel.Day} isLoading={false} />
    </div>
  );
};

export default DynamicChart;
