import React from 'react';

import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CameraImage from './images/camera.png';

import './android-nudge-preview.scss';

interface Props {
  text: string;
  title: string;
}

const AndroidNudgePreview = ({ text, title }: Props) => {
  return (
    <div className="android-nudge-preview">
      <div className="title"> Android Devices</div>

      <div className="phone">
        <div className="screen">
          <div className="header">
            <img src={CameraImage} alt={'Camera icon'} />
          </div>
          <div className="notification">
            <div className="header">
              <FontAwesomeIcon className="alert-icon" icon={icon({ name: 'circle-dot', style: 'solid' })} />
              Aplication name <span className="time">now</span>
              <FontAwesomeIcon className="alert-icon more" icon={icon({ name: 'chevron-down', style: 'solid' })} />
            </div>
            <div className="title"> {title}</div>
            <div className="text"> {text} </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AndroidNudgePreview;
