import { DataType, Operators } from 'domain/general.types';

const OperatorForNumbers = [
  Operators.Equal,
  Operators.NotEqual,
  Operators.HigherThan,
  Operators.HigherOrEqualThan,
  Operators.LessThan,
  Operators.LessOrEqualThan,
];

const OperatorForTimestamps = OperatorForNumbers;

const operatorsPerType = {
  [DataType.Boolean]: [Operators.Equal, Operators.NotEqual],
  [DataType.Bool]: [Operators.Equal, Operators.NotEqual],
  [DataType.Number]: OperatorForNumbers,
  [DataType.Float4]: OperatorForNumbers,
  [DataType.Int4]: OperatorForNumbers,
  [DataType.Int2]: OperatorForNumbers,
  [DataType.Varchar]: [Operators.Equal, Operators.NotEqual, Operators.IncludedIn, Operators.NotIncludedIn],
  [DataType.VarcharArray]: [Operators.Equal],
  [DataType.Timestamp]: OperatorForTimestamps,
};

export const getOperators = (type: DataType) => {
  return operatorsPerType[type] || [Operators.Equal];
};
