import { useEffect, useState } from 'react';
import { useServicesContext } from 'hooks/useServicesContext';
import VOChurn from 'services/landing/domain/VOChurn';

const useChurnRatio = () => {
  const { landingService } = useServicesContext();

  const [churnRatio, setChurnRatio] = useState<VOChurn>();

  useEffect(() => {
    (async () => {
      const churnRatio = await landingService.getChurnDefinition();
      setChurnRatio(churnRatio);
    })();
  }, []);

  return churnRatio;
};

export default useChurnRatio;
