import { Module, TimeRFC3999 } from 'domain/general.types';
import { TraitSubject } from 'domain/traits.types';
import { CohortAPI as CohortAPI, CohortID } from 'services/cohort/cohort.types';
import FilterSet from './FilterSet';

class Cohort {
  public id: CohortID;
  public pid: number;
  public name: string;
  public description: string;
  public tree: FilterSet;
  public subject_type: TraitSubject;
  public uses: number;
  public latestDate: TimeRFC3999;
  public size: number;

  public created_by: string;
  public created_at: string;
  public modules: Module[];

  constructor(cohort: CohortAPI) {
    this.id = cohort.id;
    this.pid = cohort.pid;
    this.name = cohort.name;
    this.description = cohort.description;
    this.subject_type = cohort.subject_type;
    this.uses = cohort.uses;
    this.size = cohort.meta?.sub_cnt || 0;
    this.latestDate = cohort.meta?.latest || '';
    this.created_at = cohort.created_at;
    this.created_by = cohort.created_by;
    this.modules = cohort.modules;

    this.tree = new FilterSet(cohort.tree);
  }
}

export default Cohort;
