import React, { useEffect, useMemo, useState } from 'react';

import CFGenericErrorBoundary from 'components/CFGenericErrorBoundary';
import CFSummaryBox from 'components/CFSummaryBox';
import { NudgeStat, NudgeState } from 'services/nudging/nudge.types';

import { stats as getNudgeStats } from 'services/intervention/intervention.repo'; // TODO: move this to a service
import { InterventionId } from 'services/intervention/intervention.types';

import colors from 'common.scss';
import CFStackedBarChart from 'components/charts/CFStackedBarChart';

const nudgeStatsVisibleKeys = [
  NudgeState.Block,
  NudgeState.Discard,
  NudgeState.Error,
  NudgeState.Open,
  NudgeState.Shown,
  NudgeState.Unknown,
];

const colorsByNudgeState: Record<NudgeState, string> = {
  [NudgeState.Block]: colors.cfError,
  [NudgeState.Discard]: colors.cfYellow,
  [NudgeState.Open]: colors.cfCyan,
  [NudgeState.Error]: colors.cfOrange400,
  [NudgeState.Shown]: colors.cfGreen,
  [NudgeState.Unknown]: colors.cfIndigo500,
};

interface Props {
  id: InterventionId;
}

const NudgeStats = ({ id }: Props) => {
  const [nudgeStats, setNudgeStats] = useState<NudgeStat>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      const stats = await getNudgeStats(id);
      setNudgeStats(stats);
      setIsLoading(false);
    })();
  }, []);

  const nudgeStatsSeries = useMemo(
    () =>
      nudgeStatsVisibleKeys.map((nudgeState) => ({
        color: colorsByNudgeState[nudgeState as NudgeState],
        name: nudgeState as string,
        values: ((nudgeStats || {})[nudgeState as NudgeState] ?? []).map((item) => ({
          value: item.v,
          time: item.t,
        })),
      })),
    [nudgeStats]
  );

  const nudgeStatsSeriesSummary = useMemo(
    () =>
      nudgeStatsSeries.map((item) => ({
        title: item.name,
        value: item.values.reduce((acc, curr) => acc + curr.value, 0),
        color: item.color,
      })),
    [nudgeStatsSeries]
  );
  return (
    <>
      <CFGenericErrorBoundary>
        <CFStackedBarChart
          title=""
          xLabel="Decision point"
          xAxis={nudgeStats?.block?.map((point) => point.t) || []}
          series={nudgeStatsSeries}
          isLoading={isLoading}
        />
      </CFGenericErrorBoundary>

      <CFSummaryBox values={nudgeStatsSeriesSummary} />
    </>
  );
};

export default NudgeStats;
