import React, { useImperativeHandle, useMemo, useState } from 'react';

import CFInput, { CFInputType } from 'components/CFInput';
import CFTitledComponent from 'components/CFTitledComponent';

import './cf-split-ratios.scss';

interface Props {
  defaultValue?: SplitRatio;
  disabled?: boolean;
}

export interface SplitRatio {
  train: number;
  validate: number;
  test: number;
}

export interface CFSplitRatiosRef {
  value: () => SplitRatio;
}

const NullSplitRatio: SplitRatio = {
  train: 0.8,
  validate: 0,
  test: 0.2,
};

const CFSplitRatios = React.forwardRef<CFSplitRatiosRef, Props>(function CFSplitRatios(
  { defaultValue, disabled = false }: Props,
  ref
) {
  const [ratios, setRatios] = useState<SplitRatio>(defaultValue || NullSplitRatio);

  useImperativeHandle(ref, () => {
    return {
      value: () => {
        if (isValid) {
          return ratios;
        } else {
          return NullSplitRatio;
        }
      },
    };
  });

  const isValid = useMemo(() => {
    const total = ratios.test * 100 + ratios.train * 100 + ratios.validate * 100;

    return total === 100;
  }, [ratios]);

  return (
    <div className="cf-split-ratios">
      <CFTitledComponent title="Train" className="input-ratio">
        <CFInput
          type={CFInputType.Number}
          defaultValue={ratios.train}
          onChange={(e) => setRatios((ratios) => ({ ...ratios, train: parseFloat(e.target.value) }))}
          disabled={disabled}
        />
      </CFTitledComponent>
      <CFTitledComponent title="Validate" className="input-ratio">
        <CFInput
          type={CFInputType.Number}
          defaultValue={ratios.validate}
          onChange={(e) => setRatios((ratios) => ({ ...ratios, validate: parseFloat(e.target.value) }))}
          disabled={disabled}
        />
      </CFTitledComponent>
      <CFTitledComponent title="Test" className="input-ratio">
        <CFInput
          type={CFInputType.Number}
          defaultValue={ratios.test}
          onChange={(e) => setRatios((ratios) => ({ ...ratios, test: parseFloat(e.target.value) }))}
          disabled={disabled}
        />
      </CFTitledComponent>
    </div>
  );
});

export default CFSplitRatios;
