import React from 'react';
import { ScatterPlotProps } from './components/Chart';

import { RGBColor } from 'domain/general.types';

import ScatterRangeContext from './useScatterRange';

import CFScatterRangeCore from './cf-scatter-range';

import './cf-scatter-range.scss';

export interface ScatterPoint<T = any> {
  x: number;
  y: number;
  size: number;
  value: number;
  meta?: T;
}

export interface ColorInterval {
  range: [number, number];
  from: RGBColor;
  to: RGBColor;
}

export interface LegendItem {
  name: string;
  color: RGBColor;
}

export interface ScatterRangeProps extends ScatterPlotProps {
  points: ScatterPoint[];
  legend?: LegendItem[];
  showGradient: boolean;
  onSelectedChanged?: (arms: number[]) => void;
}

const CFScatterRange = (props: ScatterRangeProps) => {
  return (
    <ScatterRangeContext>
      <CFScatterRangeCore {...props} />
    </ScatterRangeContext>
  );
};

export default CFScatterRange;
