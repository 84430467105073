import React, { useMemo } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';

import './cf-pagination.scss';

interface CFPaginationProps {
  pageCount: number;
  currentPage: number;
  onPageChange: (e: React.MouseEvent, n: number) => void;
}

interface UsePaginationProps extends Omit<CFPaginationProps, 'onPageChange'> {
  siblingCount: number;
  visibleItemCount: number;
}

const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const DOTS = '...';

const usePagination = ({
  pageCount,
  currentPage,
  siblingCount,
  visibleItemCount,
}: UsePaginationProps) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = siblingCount + 5;
    if (totalPageCount >= pageCount) {
      return range(1, pageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - 1, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, pageCount);

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < pageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = pageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = visibleItemCount + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, pageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = visibleItemCount + 2 * siblingCount;
      const rightRange = range(pageCount - rightItemCount + 1, pageCount);
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [pageCount, siblingCount, currentPage, visibleItemCount]);

  return paginationRange;
};

const CFPagination = ({
  pageCount,
  currentPage,
  onPageChange,
}: CFPaginationProps) => {
  const paginationRange = usePagination({
    currentPage,
    pageCount,
    siblingCount: 1,
    visibleItemCount: 3,
  });
  return (
    <ul className="pagination">
      <li
        className={classNames('pagination-item', {
          'item-invisible': currentPage === 1,
        })}
        onClick={(e) => onPageChange(e, currentPage - 1)}
        aria-disabled={currentPage === 1}
        data-testid="left-arrow"
      >
        <FontAwesomeIcon
          className="button-icon"
          icon={faChevronLeft}
          size="sm"
        />
      </li>
      {(paginationRange || []).map((pageNumber) => {
        if (pageNumber === DOTS) {
          return (
            <li
              key={pageNumber + Math.random()}
              className="pagination-item item-disabled"
            >
              {DOTS}
            </li>
          );
        }

        return (
          <li
            key={pageNumber}
            className={classNames('pagination-item', {
              'item-focused': currentPage === pageNumber,
            })}
            onClick={(e) => onPageChange(e, Number(pageNumber))}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={classNames('pagination-item', {
          'item-invisible': currentPage === pageCount,
        })}
        onClick={(e) => onPageChange(e, currentPage + 1)}
        aria-disabled={currentPage !== pageCount}
        data-testid="right-arrow"
      >
        <FontAwesomeIcon
          className="button-icon"
          icon={faChevronRight}
          size="sm"
        />
      </li>
    </ul>
  );
};

export default CFPagination;
