import React, { useEffect, useMemo, useState } from 'react';

import { Trait } from 'domain/traits.types';

import { Tag, TagSize, TagTypes } from 'components/Tag';

import { useServicesContext } from 'hooks/useServicesContext';

import { getDisplayName, getSubType, getTraitCategory } from 'services/traits/helpers.traits';

import { tableColors, TraitItemProps } from '../traits.common';

import './cf-trait-item.scss';

const CFTraitItemSimple = ({ addr, disabled }: TraitItemProps) => {
  const { traitSessionService: traitService } = useServicesContext();
  const [trait, setTrait] = useState<Trait>();

  const tableType = useMemo(() => getTraitCategory(addr), [addr]);
  const type = useMemo(() => getSubType(addr), [trait]);
  const displayName = useMemo(() => getDisplayName(trait), [trait]);

  useEffect(() => {
    (async () => {
      let trait: Trait;

      if (typeof addr === 'object') {
        trait = await traitService.getTraitFromAddr(addr);
      } else {
        trait = await traitService.getTraitDefinition(addr);
      }

      setTrait(trait);
    })();
  }, [addr]);

  const color = !disabled ? tableColors[tableType] : undefined;

  return (
    <div className="cf-trait-item">
      <span> {displayName}</span>

      {type && <Tag text={type} type={TagTypes.SwitchedColors} color={color} size={TagSize.extrasmall} />}
    </div>
  );
};

export default CFTraitItemSimple;
