import React, { PropsWithChildren } from 'react';
import { HideType, useTabContext } from './TabContext';
import classNames from 'classnames';

interface Props extends PropsWithChildren {
  value: string;
  className?: string;
}

const TabPanel: React.FC<Props> = ({ children, value, className }) => {
  const { currentTab, hideType } = useTabContext();

  if (hideType === HideType.Removed && value !== currentTab) {
    return null;
  }

  return <div className={classNames('cf-tab-panel', { hidden: value !== currentTab }, className)}>{children}</div>;
};

export default TabPanel;
