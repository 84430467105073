import React, { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { TimeRFC3999 } from 'domain/general.types';
import { getEndDate, oneWeekAgo, toString as dateToString } from 'helpers/dates';

import sessionService, { getCurrentProject } from 'services/session/session.service';
import { URL_PARAMS } from '../constants';

export const SESSION_RANGE_START = 'range_start';
export const SESSION_RANGE_END = 'range_end';

const useInitializedRange = (): [
  TimeRFC3999,
  TimeRFC3999,
  React.Dispatch<React.SetStateAction<TimeRFC3999>>,
  React.Dispatch<React.SetStateAction<TimeRFC3999>>
] => {
  const [searchParams] = useSearchParams();
  const project = getCurrentProject();

  const defaultStartDate =
    searchParams.get(URL_PARAMS.StartDate) || (project?.created_at ?? dateToString(oneWeekAgo()));
  const defaultEndDate = searchParams.get(URL_PARAMS.EndDate) || getEndDate(project?.created_at);

  const [startDate, setStartDate] = useState<TimeRFC3999>(() => {
    const startDate = sessionService.getCustomValue(SESSION_RANGE_START);

    if (startDate) {
      return startDate;
    } else {
      return defaultStartDate;
    }
  });

  const [endDate, setEndDate] = useState<TimeRFC3999>(() => {
    const endDate = sessionService.getCustomValue(SESSION_RANGE_END);

    if (endDate) {
      return endDate;
    } else {
      return defaultEndDate;
    }
  });

  useEffect(() => {
    sessionService.saveCustomValue(SESSION_RANGE_START, startDate);
  }, [startDate]);

  useEffect(() => {
    sessionService.saveCustomValue(SESSION_RANGE_END, endDate);
  }, [endDate]);

  return [startDate, endDate, setStartDate, setEndDate];
};

export default useInitializedRange;
