import { AxiosResponse } from 'axios';
import {
  get as httpGet,
  post as httpPost,
  remove as httpDelete,
  put as httpPut,
} from '../../repositories/drivers/http';

import {
  CreatingIntervention,
  GroupedSubjects,
  InterventionId,
  InterventionViewAPI,
  MetricData,
  NewInterventionRequest,
  PauseRequest,
  Schedule,
} from 'services/intervention/intervention.types';

import { PaginatedElement } from 'types';
import { RepoConfig } from '../../repositories/types';
import { NudgeStat } from 'services/nudging/nudge.types';
import { TraitSubject } from 'domain/traits.types';
import { SamplePurpose } from 'services/cohort/cohort.types';
import { SubjectId } from 'domain/general.types';
import { IndividualMetricAPI } from './types.api';

export interface CFInterventionRepository {
  init: (config: RepoConfig) => void;
  get: (page: number, per_page: number) => Promise<PaginatedElement<InterventionViewAPI>>;
  getView: (id: InterventionId) => Promise<InterventionViewAPI>;
  saveDeterminedSample: (
    groupedSubjects: GroupedSubjects,
    sampleId: string | undefined,
    subjectType: TraitSubject
  ) => void;
  checkSample: (groupedSubjects: GroupedSubjects, sampleId: string | undefined, subjectType: TraitSubject.User) => void;
  create: (intervention: CreatingIntervention, schedule: Schedule, draftId?: number) => void;
  remove: (interventionId: number) => void;
  pause: (interventionId: InterventionId, status: boolean) => void;
  terminate: (interventionId: InterventionId) => void;
  stats: (interventionId: InterventionId) => Promise<NudgeStat>;
  participants: (interventionId: InterventionId) => Promise<string>;
}

const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
const path = '/v1/inv';

const repoConfig = {
  token: '',
  oid: -1,
  pid: -1,
};

export const init = ({ token, oid, pid }: RepoConfig) => {
  repoConfig.token = token;
  repoConfig.oid = oid;
  repoConfig.pid = pid;
};

export const get = async (page: number, per_page: number): Promise<PaginatedElement<InterventionViewAPI>> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
      page,
      per_page,
    },
  };

  try {
    const {
      data: { total, data },
    } = (await httpGet(`${serverBaseUrl}${path}/list`, config)) as AxiosResponse;

    return { total, data: data || [] };
  } catch (err) {
    console.error('error listing interventions: ', err);
    throw new Error('error-listing-interventions');
  }
};

export const getView = async (id: InterventionId): Promise<InterventionViewAPI> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };

  try {
    const data = (await httpGet(`${serverBaseUrl}${path}/get/${id}`, config)) as AxiosResponse;

    return data.data;
  } catch (err) {
    throw new Error('error-fetching-intervention');
  }
};

export const saveDeterminedSample = async (
  groupedSubjects: GroupedSubjects,
  sampleId: string | undefined,
  subjectType = TraitSubject.User
) => {
  const config = {};

  const body = {
    id: sampleId,
    sample: groupedSubjects,
    subject_type: subjectType,
    purpose: SamplePurpose.Intervention,
  };

  try {
    const data = (await httpPost(
      `${serverBaseUrl}${path}/save-manual-sample?oid=${repoConfig.oid}&pid=${repoConfig.pid}`,
      body,
      config
    )) as AxiosResponse;
    return data.data;
  } catch (err) {
    console.error('error saving sample: ', err);
    throw new Error('error-saving-sample');
  }
};

export const checkSample = async (
  groupedSubjects: GroupedSubjects,
  sampleId: string | undefined,
  subjectType = TraitSubject.User
) => {
  const config = {};

  const body = {
    id: sampleId,
    sample: groupedSubjects,
    subject_type: subjectType,
    purpose: SamplePurpose.Intervention,
  };

  try {
    const data = (await httpPost(
      `${serverBaseUrl}${path}/check-sample?oid=${repoConfig.oid}&pid=${repoConfig.pid}`,
      body,
      config
    )) as AxiosResponse;
    return { not_exist: data.data.not_exist || [], used: data.data.used || [] };
  } catch (err) {
    console.error('error checking sample: ', err);
    throw new Error('error-checking-sample');
  }
};

export const create = async (intervention: CreatingIntervention, schedule: Schedule, draftId?: number) => {
  const config = {};

  const extraFilters = intervention.participant_policy.static_sample.extra_filters.map((filter) => ({
    op: filter.op,
    ptr: filter.ptr,
    val: filter.val,
  }));

  const interventionAPI = intervention;
  interventionAPI.participant_policy.static_sample.extra_filters = extraFilters;

  const body: NewInterventionRequest = {
    intervention: interventionAPI,
    schedule: schedule,
    draft_id: draftId,
  };

  try {
    const data = (await httpPost(
      `${serverBaseUrl}${path}/save?oid=${repoConfig.oid}&pid=${repoConfig.pid}`,
      body,
      config
    )) as AxiosResponse;
    return data.data;
  } catch (err) {
    console.error('error saving intervention: ', err);
    throw new Error('error-intervention-save');
  }
};

export const remove = async (interventionId: number) => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };

  try {
    (await httpDelete(`${serverBaseUrl}${path}/delete/${interventionId}`, config)) as AxiosResponse;
  } catch (err) {
    console.error('error remove intervention: ', err);
    throw new Error('error-removing-intervention');
  }
};

export const getMetric = async (
  interventionId: InterventionId
): /*interventionId: InterventionId*/
Promise<MetricData> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };

  try {
    const metrics = await httpGet(`${serverBaseUrl}${path}/metric/${interventionId}`, config);

    return metrics?.data;
  } catch (err) {
    console.error('error listing intervention metrics: ', err);
    throw new Error('error-listing-interventions-metrics');
  }
};

export const stats = async (interventionId: InterventionId): Promise<NudgeStat> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };

  try {
    const stats = (await httpGet(`${serverBaseUrl}${path}/nudge-stats/${interventionId}`, config)) as AxiosResponse;

    return stats.data;
  } catch (err) {
    console.error('error listing nudges: ', err);
    throw new Error('error-listing-nudges');
  }
};

export const pause = async (interventionId: InterventionId, status: boolean) => {
  const config = {};

  const body: PauseRequest = {
    status,
  };

  try {
    const data = (await httpPut(
      `${serverBaseUrl}${path}/pause/${interventionId}?oid=${repoConfig.oid}&pid=${repoConfig.pid}`,
      body,
      config
    )) as AxiosResponse;
    return data.data;
  } catch (err) {
    console.error('error pausing intervention: ', err);
    throw new Error('error-intervention-pause');
  }
};

export const terminate = async (interventionId: InterventionId) => {
  const config = {};

  console.log('[repo] terminating...');
  try {
    const data = (await httpPut(
      `${serverBaseUrl}${path}/terminate/${interventionId}?oid=${repoConfig.oid}&pid=${repoConfig.pid}`,
      {},
      config
    )) as AxiosResponse;
    return data.data;
  } catch (err) {
    console.error('error terminating intervention: ', err);
    throw new Error('error-terminating-intervention');
  }
};

export const participants = async (interventionId: InterventionId): Promise<string> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };

  try {
    const stats = (await httpGet(
      `${serverBaseUrl}${path}/participants/download/${interventionId}`,
      config
    )) as AxiosResponse;

    return stats.data;
  } catch (err) {
    console.error('error listing participants in intervention: ', err);
    throw new Error('error-listing-participants');
  }
};

export const getMetricsBySubject = async (interventionId: InterventionId, subjects: SubjectId[], offset: number) => {
  // /metric/by-subs
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
      ids: subjects.join(','),
      inv_id: interventionId,
      offset,
    },
  };

  try {
    const stats = (await httpGet(`${serverBaseUrl}${path}/metric/by-subs`, config)) as AxiosResponse<
      IndividualMetricAPI[]
    >;

    return stats.data;
  } catch (err) {
    console.error('error getting metrics by subjects: ', err);
    throw new Error('error-getting-metrics-by-subject');
  }
};

export default {
  init,
  get,
  getView,
  saveDeterminedSample,
  checkSample,
  create,
  remove,
  pause,
  stats,
  terminate,
  participants,
};
