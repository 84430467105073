import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import InterventionSection from '../interventionSection';
import CFTitledSection, { SectionAction } from 'components/CFTitledSection';
import CFTitledComponent from 'components/CFTitledComponent';
import CFDataTable from 'components/CFDataTable';
import CFTable, { Column, ColumnType, TableType } from 'components/CFTable';
import TimeWindowPicker, { TimeWindowPickerRef } from 'components/TimeWindowPicker';
import CFEditableField from 'components/CFEditableField';
import CFTrashButton from 'components/buttons/CFTrashButton';
import CFCheckbox from 'components/CFCheckbox';
import CFButton from 'components/buttons/CFButton';
import CFPortal from 'components/CFPortal';
import CFButtonGroup from 'components/CFButtonGroup';

import NewNudge from 'views/intervention/nudges/NewNudge';
import { Steps } from '..';

import { CFRole } from 'domain/general.types';
import { Nudge, NudgeType } from 'services/nudging/nudge.types';
import { AlgorithmType, NudgePolicy, NudgeScheduleType } from 'services/intervention/intervention.types';

import { defaultNudge } from './constants';

import NudgeService from 'services/nudging/nudge.service';
import { useInterventionContext } from 'views/intervention/useContext';

import pagePlusIcon from 'assets/icons/pagePlus.svg';

import TraitService from 'services/traits/traitSession.service';

import { getDurationFromNanos, Granularity, timeConverter } from 'helpers/dates';

import './nudge-policy.scss';

interface Props {
  onReady: (ready: boolean) => void;
  defaultValue?: NudgePolicy;
  nudgeService: NudgeService;
  traitService: TraitService;
  minNumberOfNudges?: number;
  maxNumberOfNudges?: number;
  includeDefaultNudge?: boolean;
}

const NullNudgePolicy: NudgePolicy = {
  schedule: {
    type: NudgeScheduleType.NudgeScheduleFixedType,
  },
  expire_dur: timeConverter[Granularity.Day](3),
  group_allocation: {},
};

export interface InterventionNudgePolicyRef {
  value: () => NudgePolicy;
}

const InterventionNudgePolicy = forwardRef<InterventionNudgePolicyRef, Props>(function InterventionNudgePolicy(
  {
    onReady,
    nudgeService,
    traitService,
    defaultValue = NullNudgePolicy,
    minNumberOfNudges = 2,
    maxNumberOfNudges = Number.MAX_SAFE_INTEGER,
    includeDefaultNudge = true,
  }: Props,
  ref
) {
  const [nudges, setNudges] = useState<Nudge[]>([]);
  const [customNames, setCustomNames] = useState<Record<number, string>>({});
  const [selectedAll, setSelectedAll] = useState<boolean>(false);

  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [nudgeType, setNudgeType] = useState(NudgeType.Message);

  const durationRef = useRef<TimeWindowPickerRef | null>();

  const [newNudgeModal, setNewNudgeModal] = useState<boolean>(false);

  const { algorithmType, selectedNudges, setSelectedNudges } = useInterventionContext();

  const nudgeCommonTableHeader: Column[] = [
    {
      title: '',
      field: 'id',
      type: ColumnType.NUMBER,
      renderCell: (row) => {
        const hasBeenSelected = selectedNudges.some((nudge) => nudge.id === row.id);

        const isABTesting = algorithmType === AlgorithmType.ABTest && row.name === defaultNudge.name;

        const isChecked = hasBeenSelected || isABTesting;

        return <CFCheckbox disabled={isABTesting} checked={isChecked} onChange={() => handleSelectedNudge(row)} />;
      },
      renderHeader: () => <CFCheckbox checked={selectedAll} onChange={handleSelectAll} />,
    },
    { title: 'ID', field: 'id', type: ColumnType.STRING, style: { width: '5%' } },
    {
      title: 'Name',
      field: 'name',
      type: ColumnType.STRING,
      style: { width: '15%' },
    },

    { title: 'Created', type: ColumnType.DATE, field: 'created_at' },
  ];

  const nudgeMessageTableHeader = [
    {
      title: 'Title',
      field: 'title',
      type: ColumnType.STRING,
      style: { width: '15%' },
    },
    {
      title: 'Render',
      field: 'render_method',
      type: ColumnType.STRING,
      style: { width: '15%' },
    },
    { title: 'Tags', type: ColumnType.OBJECT, field: 'definition.message.tags' },
    { title: 'Nudge Message', type: ColumnType.STRING, field: 'definition.message.message' },
  ];

  const nudgeTableHeader = useMemo(() => {
    if (nudgeType === NudgeType.Message) {
      return [...nudgeCommonTableHeader, ...nudgeMessageTableHeader];
    } else {
      return [...nudgeCommonTableHeader];
    }
  }, [nudgeType, algorithmType, selectedNudges]);

  const selectedNudgesColumns: Column[] = [
    {
      title: '',
      field: 'id',
      type: ColumnType.NUMBER,
      renderCell: (row, _, indexCol) => {
        if (row.id === defaultNudge.id && algorithmType === AlgorithmType.ABTest) {
          return null;
        }

        return <CFTrashButton onClick={() => handleDelSelectedNudge(row, indexCol)}></CFTrashButton>;
      },
      style: { width: '5%' },
    },
    {
      title: 'Groupname',
      field: 'name',
      type: ColumnType.STRING,
      renderCell: (row, column) => {
        if (row.id === defaultNudge.id) {
          return row[column.field];
        }

        return (
          <CFEditableField
            defaultValue={customNames[row.id] || row[column.field]}
            onSave={(value) => {
              handleEditCustomTitle?.(row, column, value);
            }}
          />
        );
      },
      style: { width: '20%' },
    },
    { title: 'ID', field: 'id', type: ColumnType.STRING, style: { width: '10%' } },
    {
      title: 'Title',
      field: 'definition.message.title',
      type: ColumnType.STRING,
      style: { width: '20%' },
    },
    { title: 'Tags', field: 'definition.message.tags', type: ColumnType.OBJECT, style: { width: '20%' } },
    {
      title: 'Nudge Message',
      field: 'definition.message.body',
      type: ColumnType.STRING,
      style: { width: '30%' },
    },
  ];

  useImperativeHandle(ref, () => ({
    value() {
      const groups = selectedNudges.reduce((acc, nudge) => {
        const customTitle = customNames[nudge.id];
        return { ...acc, [customTitle || nudge.name]: nudge.id };
      }, {});

      return {
        schedule: {
          type: NudgeScheduleType.NudgeScheduleFixedType,
        },
        expire_dur: durationRef.current?.value() || 0,
        group_allocation: groups,
      };
    },
  }));

  const fetchNudges = useCallback(async () => {
    await nudgeService
      .list(page, pageSize)
      .then(({ total, data }) => {
        setTotal(total);
        if (includeDefaultNudge) {
          data = [defaultNudge, ...data];
        }
        setNudges(data);
      })
      .catch((err) => {
        console.log('Error getting nudges: ', err);
      });
  }, [page, pageSize, includeDefaultNudge]);

  useEffect(() => {
    fetchNudges();
  }, [fetchNudges]);

  useEffect(() => {
    (async () => {
      const nudgesIDs = Object.values(defaultValue.group_allocation);
      const defaultNudges = await Promise.all(nudgesIDs.map((id) => nudgeService.get(id)));

      const customNudgeNames: Record<number, string> = {};
      setSelectedNudges(defaultNudges);

      for (const [title, id] of Object.entries(defaultValue.group_allocation)) {
        // find nudge to get the custom title from a previous intervention
        const nudge = defaultNudges.find((nudge) => nudge.id === id);

        if (!nudge) {
          continue;
        }

        customNudgeNames[id] = title;
      }

      setCustomNames(customNudgeNames);
    })();
  }, [defaultValue.group_allocation]);

  useEffect(() => {
    if (algorithmType !== AlgorithmType.ABTest) {
      return;
    }

    setSelectedNudges([defaultNudge]);
    setCustomNames({});
  }, [algorithmType]);

  const handleSelectAll = () => {
    if (!selectedAll) {
      nudges
        .filter((row) => !selectedNudges.includes(row))
        .forEach((row) => {
          handleSelectedNudge(row);
        });
    } else {
      selectedNudges
        .filter((nudge) => algorithmType !== AlgorithmType.ABTest || nudge.id !== defaultNudge.id)
        .forEach((row) => {
          handleSelectedNudge(row);
        });
      const newNudges = [];

      if (algorithmType === AlgorithmType.ABTest) {
        newNudges.push(defaultNudge);
      }

      setSelectedNudges(newNudges);
    }
    setSelectedAll(!selectedAll);
  };

  useEffect(() => {
    const currentRows = nudges.filter((row) => selectedNudges.includes(row));
    if (!!nudges.length && currentRows.length === nudges.length) setSelectedAll(true);
    else setSelectedAll(false);
  }, [selectedNudges]);

  const filteredNugesByType = useMemo(() => {
    return nudges.filter((nudge) => nudge?.definition?.type === nudgeType || nudge.id === defaultNudge.id);
  }, [nudges, nudgeType]);

  const isReadyCheck = () => {
    const isAllGroup = Object.values(customNames).some((name) => name === 'all_groups');

    const groupNames = selectedNudges.map((nudge) => customNames[nudge.id] || nudge.name);
    const uniqueGroupNames = Array.from(new Set(groupNames));

    const hasDuplicatedNames = groupNames.length !== uniqueGroupNames.length;

    if (hasDuplicatedNames) {
      return false;
    }

    return selectedNudges.length >= minNumberOfNudges && selectedNudges.length <= maxNumberOfNudges && !isAllGroup;
  };

  const onPaginated = (page: number, size: number) => {
    setPage(page - 1);
    setPageSize(size);
  };

  const handleSelectedNudge = (row: Record<string, any>) => {
    const currentNudge = row as Nudge;
    setSelectedNudges((prevSelectedNudges) => {
      const isExist = prevSelectedNudges.some((nudge) => nudge.id === currentNudge.id);
      if (isExist) {
        return prevSelectedNudges.filter((nudge) => nudge.id !== currentNudge.id);
      } else {
        return [...prevSelectedNudges, currentNudge];
      }
    });

    onReady(isReadyCheck());
  };

  useEffect(() => onReady(isReadyCheck()), [selectedNudges]);

  const handleEditCustomTitle = (row: Record<string, any>, column: Column, value: string) => {
    setCustomNames((oldNames) => ({ ...oldNames, [row.id]: value }));

    onReady(isReadyCheck());
  };

  const handleDelSelectedNudge = (row: Record<string, any>, indexCol: string) => {
    setCustomNames((oldNames) => {
      const newNames = { ...oldNames };

      delete newNames[row.id];

      return newNames;
    });
    setSelectedNudges((prevNudges) => prevNudges.filter((nudge) => nudge.id !== row[indexCol]));
  };

  const handleToggleModal = () => setNewNudgeModal((prevFlag) => !prevFlag);

  const handleNudgeCancel = async () => {
    handleToggleModal();
  };

  const handleNudgeReady = async () => {
    // const handleNudgeReady = async (nudge: Record<string, any>) => {
    // const newNudge = {
    //   id: nudge.id,
    //   name: nudge.name,
    //   title: nudge.definition.message.title,
    //   customTitle: nudge.name,
    //   render_method: nudge.definition.render_method,
    //   created_at: nudge.created_at,
    //   tags: nudge.definition.message.tags,
    //   message: nudge.definition.message.body,
    //   source: nudge as Nudge,
    // };

    await fetchNudges();
    // setSelectedNudges((prevNudges) => [...prevNudges, newNudge]);
    handleToggleModal();
  };

  const handleClick = (e: any) => {
    if (e.target !== e.currentTarget) {
      return;
    }

    handleToggleModal();
  };

  return (
    <InterventionSection name={Steps.Nudge} title={'Nudges'}>
      <SectionAction>
        <CFButton
          value="Create Nudge"
          iconCustom={<img src={pagePlusIcon} />}
          role={CFRole.Primary}
          onClick={handleToggleModal}
        />
      </SectionAction>
      <div className="nudge-policy-container">
        <CFButtonGroup
          className="agg-selector"
          options={[
            {
              label: 'Message',
              value: NudgeType.Message,
            },
            {
              label: 'Recommender',
              value: NudgeType.Recommender,
            },
          ]}
          value={{ label: nudgeType, value: nudgeType }}
          onSelect={(option) => setNudgeType(option.value)}
        />
        <CFDataTable
          total={total}
          headers={nudgeTableHeader}
          data={filteredNugesByType}
          multiSelect={true}
          onPaginated={onPaginated}
          selected={selectedNudges}
          onSelectRow={handleSelectedNudge}
        />
        <CFTitledSection nested={true} title={'Selected Nudges'}>
          <div className="selected-nudge-section">
            <CFTitledComponent title={`Expiration`}>
              <TimeWindowPicker
                ref={(el) => (durationRef.current = el)}
                defaultGranularity={Granularity.Day}
                defaultValue={getDurationFromNanos(defaultValue.expire_dur, Granularity.Day)}
              />
            </CFTitledComponent>
            <CFTable
              variant={TableType.Secondary}
              headers={selectedNudgesColumns}
              data={selectedNudges}
              emptyLabel="No Selected Nudges"
            />
          </div>
        </CFTitledSection>
      </div>
      {newNudgeModal ? (
        <CFPortal onClickOutside={handleToggleModal}>
          <div className="new-nudge-wrapper" onClick={handleClick}>
            <NewNudge
              nudgeService={nudgeService}
              traitService={traitService}
              handleReady={handleNudgeReady}
              handleCancel={handleNudgeCancel}
            />
          </div>
        </CFPortal>
      ) : (
        <></>
      )}
    </InterventionSection>
  );
});

export default InterventionNudgePolicy;
