import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { AssistantMessageType } from 'services/assistant/assistant.types';

import Message from './components/Message';
import CFSpinner from 'components/CFSpinner';
import SubstitutionRequestMessage from './components/SubstitutionRequestMessage';
import StaticText from './components/Message/StaticText';
import WaitingText from './components/Message/WaitingText';
import TableResponse from './components/Message/TableResponse';

import { useMessagesContext } from '../context/useMessagesContext';

import colors from 'common.scss';

import './assistant-wall.scss';
import DynamicChart from './components/Message/DynamicChart';

const AssistantWall = () => {
  const [scrollOnBottom, setScrollOnBottom] = useState(true);
  const scrollRef = useRef<HTMLDivElement>(null);
  const { messages, waitingResponse, loadMore, hasMoreMessages } = useMessagesContext();

  const [initialized, setInitialized] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [lastMessageID, setLastMessageID] = useState(0);

  useEffect(() => {
    if (initialized) {
      return;
    }

    if (messages.length !== 0) {
      setLastMessageID(messages[0].id);
    }

    setInitialized(true);
  }, [messages, initialized]);

  const isNewResponse = useMemo(() => {
    if (messages.length === 0) {
      return false;
    }

    return (
      (messages[0].type === AssistantMessageType.Answer ||
        messages[0].type === AssistantMessageType.ValidationRequest) &&
      messages[0].id !== lastMessageID
    );
  }, [messages, lastMessageID]);

  const lastMessage = useMemo(() => {
    if (messages[0].error) {
      return (
        <Message message={messages[0]} key={messages[0].id}>
          <WaitingText steps={messages[0].steps || []} animated={false} />
          <StaticText text={messages[0].text} />
        </Message>
      );
    }
    /*
    if (isNewResponse && messages[0].type !== AssistantMessageType.ValidationRequest) {
      return (
        <Message message={messages[0]} key={messages[0].id}>
          {messages[0].structuredText && (
            <Message.Table>
              <TableResponse response={messages[0].structuredText} />
            </Message.Table>
          )}
          {messages[0].structuredText && (
            <Message.Chart>
              <DynamicChart response={messages[0].structuredText} />
            </Message.Chart>
          )}

          {!messages[0].structuredText && <StaticText text={messages[0].text} />}
        </Message>
      );

      // <TypewriterMessage key={messages[0].id} text={messages[0].text} payloads={messages[0].payloads} />;
    }*/

    if (isNewResponse && messages[0].type === AssistantMessageType.ValidationRequest) {
      return (
        <Message message={messages[0]} key={messages[0].id}>
          <SubstitutionRequestMessage message={messages[0]} />
        </Message>
      );
    }

    return (
      <Message message={messages[0]} key={messages[0].id}>
        {messages[0].structuredText && (
          <Message.Table>
            <TableResponse response={messages[0].structuredText} />
          </Message.Table>
        )}
        {messages[0].structuredText && (
          <Message.Chart>
            <DynamicChart response={messages[0].structuredText} />
          </Message.Chart>
        )}

        {!messages[0].structuredText && <StaticText text={messages[0].text} />}
      </Message>
    );
  }, [messages, isNewResponse]);

  useLayoutEffect(() => {
    if (!scrollRef || !scrollRef.current) {
      return;
    }

    const checkScroll = () => {
      if (!scrollOnBottom) {
        return;
      }

      const divElement = scrollRef.current;
      if (!divElement) {
        return;
      }

      const isScrollNeeded = divElement.scrollHeight > divElement.clientHeight;

      if (!isScrollNeeded) {
        return;
      }

      scrollRef.current.scrollTo &&
        scrollRef.current.scrollTo({ left: 0, top: divElement.scrollHeight, behavior: 'smooth' });
    };

    checkScroll();

    const observer = new MutationObserver(checkScroll);
    const config = { childList: true, subtree: true };
    observer.observe(scrollRef.current, config);

    return () => {
      observer.disconnect();
    };
  }, [scrollRef, scrollOnBottom]);

  useEffect(() => {
    const divElement = scrollRef.current;
    if (!divElement) {
      return;
    }

    scrollRef.current.scrollTo &&
      scrollRef.current.scrollTo({ left: 0, top: divElement.scrollHeight, behavior: 'smooth' });

    setScrollOnBottom(true);
  }, [waitingResponse]);

  const handleContainerScrolled = useCallback(async () => {
    const divElement = scrollRef.current;
    if (!divElement) {
      return;
    }

    const scrollableHeight = divElement.scrollHeight - divElement.clientHeight;
    const currentScroll = divElement.scrollTop;

    if (currentScroll === 0) {
      loadMoreItems();
    }

    setScrollOnBottom(currentScroll === scrollableHeight);
  }, [hasMoreMessages]);

  const loadMoreItems = () => {
    setLoadingMore(true);
    loadMore().then(() => {
      setLoadingMore(false);
    });
  };

  const history = useMemo(() => {
    const wallMessages = [...messages.slice(1, messages.length)];
    wallMessages.reverse();

    return wallMessages;
  }, [messages]);

  if (messages.length === 0) {
    // this shouldnt happen because parent container
    // decides to show Splash instead of Wall
    return <div></div>;
  }

  return (
    <div ref={scrollRef} className="assistant-wall" onScroll={handleContainerScrolled} data-testid="assistant-wall">
      <div className="assistant-wall__content">
        {loadingMore && (
          <div className="assistant-wall__spinner">
            <CFSpinner size={20} color={colors.cfCyan} stroke={4} />
          </div>
        )}
        {history.map((message) => (
          <Message message={message} key={message.id}>
            {message.structuredText && (
              <Message.Table>
                <TableResponse response={message.structuredText} />
              </Message.Table>
            )}
            {message.structuredText && (
              <Message.Chart>
                <DynamicChart response={message.structuredText} />
              </Message.Chart>
            )}

            {!message.structuredText && <StaticText text={message.text} />}
          </Message>
        ))}

        {lastMessage}

        {waitingResponse && (
          <Message
            message={{
              type: AssistantMessageType.Answer,
              tid: 0,
              text: '...',
              structuredText: undefined,
              error: false,
              timestamp: '',
              id: Date.now(),
            }}
          >
            <WaitingText steps={waitingResponse} />
          </Message>
        )}
      </div>
    </div>
  );
};

export default AssistantWall;
