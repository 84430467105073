import React from 'react';

import { Option } from '..';

import './input-container.scss';

export interface Props {
  selected: Option | Option[];
}

const InputContainer = ({ selected }: Props): JSX.Element => {
  let toRender;

  if (!selected) {
    toRender = '-';
  } else if (Array.isArray(selected)) {
    toRender = `${selected.length} selected`;
  } else {
    toRender = selected.label;
  }

  return <div className="cf-select-inputContainer">{toRender}</div>;
};

export default InputContainer;
