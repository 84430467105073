import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';

import { OrgInfo, ProjectDefinition } from 'types';

import { ProjectRole, Role, UserRole, UserRolesInOrganization } from 'services/admin/users/user.types';

import CFCheckbox from 'components/CFCheckbox';

import CFTitledSection from 'components/CFTitledSection';

import './cf-roles-editor.scss';

interface Props {
  availableRoles: Role[];
  availableAdminRoles: Role[];
  currentRoles: UserRolesInOrganization;
  currentAdminRoles: Role[];
  orgprojs: OrgInfo[];
  orgId: number;
  includeAdmin?: boolean;
}

export interface CFRolesEditorRef {
  value: () => UserRolesInOrganization;
}

const rolIdForProject = (proj: ProjectDefinition, role: string) => `${proj.oid}-${proj.id}-${role}`;
const orgProjId = (proj: ProjectDefinition) => `${proj.oid}-${proj.id}`;
const projIdFromOrgProgId = (orgproj: string): number => parseInt(orgproj.split('-')[1]);

const CFRolesEditor = forwardRef<CFRolesEditorRef, Props>(function CFRolesEditor(
  { orgprojs, orgId, currentRoles, availableRoles, availableAdminRoles, includeAdmin = true, currentAdminRoles }: Props,
  ref
) {
  const isAdminRef = React.createRef<HTMLInputElement>();
  const isUserRef = React.createRef<HTMLInputElement>();

  const projects = useMemo(
    () =>
      orgprojs
        .filter((orgproj) => orgproj.org.id === orgId) // only my org
        .map((orgproj) => orgproj.projs.map((project) => orgProjId(project))) // list of projects within this org
        .flat()
        .reduce((acc, curr) => {
          const roles = availableRoles.reduce((accRoles, role) => ({ ...accRoles, [role]: undefined }), {});
          const newState = { ...acc, [curr]: roles };

          return newState;
        }, {}),
    [orgprojs]
  );

  const projectsRef = useRef<Record<string, Record<string, HTMLInputElement>>>(projects);

  useImperativeHandle(ref, () => {
    return {
      value() {
        const proj_roles: Record<number, Role[]> = {};
        const org_roles: UserRole[] = [];

        if (isAdminRef.current?.checked) {
          org_roles.push(UserRole.Admin);
        }

        if (isUserRef.current?.checked) {
          org_roles.push(UserRole.User);
        }

        Object.keys(projectsRef.current).forEach((projectId) => {
          proj_roles[projIdFromOrgProgId(projectId)] = [];

          availableRoles.forEach((role) => {
            if (projectsRef.current[projectId][role].checked) {
              proj_roles[projIdFromOrgProgId(projectId)].push(role as ProjectRole);
            }
          });
        });

        return {
          oid: currentRoles.oid,
          org_roles,
          proj_roles,
        };
      },
    };
  });

  return (
    <div className="cf-roles-editor">
      <CFTitledSection title={'User roles'} underlined={true} nested={true}>
        {orgprojs
          .filter((orgproj) => orgproj.org.id === orgId)
          .map((orgproj) => {
            return (
              <div key={orgproj.org.name}>
                <div className="attributes">
                  {orgproj.org.name}
                  <CFCheckbox
                    ref={isAdminRef}
                    label="Admin"
                    checked={currentRoles.org_roles.includes(UserRole.Admin)}
                  />

                  <CFCheckbox ref={isUserRef} label="User" checked={currentRoles.org_roles.includes(UserRole.User)} />
                </div>

                <div className="list-of-projects">
                  {orgproj.projs.map((proj) => (
                    <div key={proj.name} className="project-roles">
                      <div>{proj.name}</div>

                      <div className="list-of-roles">
                        {availableRoles.map((role) => (
                          <span key={rolIdForProject(proj, role)}>
                            <CFCheckbox
                              ref={(el) => (projectsRef.current[orgProjId(proj)][role] = el as HTMLInputElement)}
                              label={role}
                              checked={(currentRoles.proj_roles[proj.id] || []).includes(role as ProjectRole)}
                              name={`${proj.id}-${role}`}
                            />
                          </span>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
      </CFTitledSection>

      {includeAdmin && (
        <CFTitledSection title="Admin roles" nested={true} underlined={true}>
          {availableAdminRoles.map((role) => (
            <CFCheckbox key={role} label={role} name={role} defaultCheck={currentAdminRoles.includes(role)} />
          ))}
        </CFTitledSection>
      )}
    </div>
  );
});

export default CFRolesEditor;
