import React from 'react';

import { RGBColor } from 'domain/general.types';

import './cf-summary-box.scss';

interface SummaryItem {
  value: number;
  title: string;
  color: RGBColor;
}

interface Props {
  values: SummaryItem[];
}

const CFSummaryBox = ({ values }: Props) => {
  return (
    <div className="cf-summary-box">
      {values.map((item) => (
        <div key={`${item.title}-${Math.random()}`}>
          <div className="value" style={{ color: item.color }}>
            {' '}
            {item.value}
          </div>
          <span>{item.title}</span>
        </div>
      ))}
    </div>
  );
};

export default CFSummaryBox;
