import React, { forwardRef } from 'react';
import classNames from 'classnames';

import { Props as ItemProps } from 'components/CFSelect/defaults/Item';
import CFCheckbox from 'components/CFCheckbox';
import CFTraitItem from 'connected-components/traits/CFTraitItem';

import { getDisplayName } from 'services/traits/helpers.traits';

import CFTraitItemSimple from 'connected-components/traits/CFTraitItemSimple';

import { ColAddr } from 'domain/traits.types';

import './trait-item.scss';

interface TraitComponentProps {
  addr: ColAddr;
  display_name: string;
  disabled?: boolean;
  showAggLevel?: boolean;
  omitDisplayName?: boolean;
}

type TraitComponent = React.ComponentType<TraitComponentProps>;

const TraitItem = forwardRef<HTMLDivElement, ItemProps>(function TraitItem(
  { option, active, disabled, onClick, isMulti, focused }: ItemProps,
  ref
) {
  const TraitComponent: TraitComponent = option.meta?.simple ? CFTraitItemSimple : CFTraitItem;

  return (
    <div
      ref={ref}
      key={option.value}
      role="option"
      className={classNames('cf-select-item trait-item', { active, focused })}
      onClick={() => onClick?.(option)}
    >
      {isMulti && <CFCheckbox variant="secondary" checked={active} onChange={() => onClick?.(option)} />}
      {option.meta?.trait?.addr ? (
        <div>
          <TraitComponent
            addr={option.meta?.trait?.addr}
            display_name={getDisplayName(option.meta?.trait)}
            disabled={disabled}
            showAggLevel={true}
            omitDisplayName={option.meta?.wrap}
          />
          {option.meta?.wrap && getDisplayName(option.meta?.trait)}
        </div>
      ) : (
        option.label
      )}
    </div>
  );
});

export default TraitItem;
