import React from 'react';

import InterventionContext from '../../useContext';
import NudgeAdoptionCreator from './nudge-adoption-creator';
import { useServicesContext } from 'hooks/useServicesContext';

const NudgeAdoption = () => {
  const { cohortService, nudgeService, traitSessionService: traitService } = useServicesContext();
  return (
    <InterventionContext>
      <NudgeAdoptionCreator cohortService={cohortService} nudgeService={nudgeService} traitService={traitService} />
    </InterventionContext>
  );
};

export default NudgeAdoption;
