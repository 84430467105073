import { ColumnType } from 'components/CFTable';

export const globalTableHeader = [
  {
    title: 'Types22',
    field: 'dtype',
    style: { minWidth: '100px' },
    type: ColumnType.STRING,
  },
  {
    title: 'Description',
    field: 'description',
    type: ColumnType.STRING,
    style: {
      width: '100%',
    },
  },
];
