import { AxiosResponse } from 'axios';
import { get as httpGet } from 'repositories/drivers/http';

import { ModelId } from 'domain/model.types';
import { RepoConfig } from 'repositories/types';
import { SurvivalFunctionContainer } from './censoring.types';
import { ScatterPlot } from 'domain/analytic.types';

const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
const path = '/v1/model/censoring';

export interface CFCensoringRepository {}

const repoConfig = {
  token: '',
  oid: -1,
  pid: -1,
};

export const init = ({ token, oid, pid }: RepoConfig) => {
  repoConfig.token = token;
  repoConfig.oid = oid;
  repoConfig.pid = pid;
};

export const getSurvivalCurves = async (
  modelId: ModelId,
  clusterId: number | undefined
): Promise<SurvivalFunctionContainer[]> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
      root_idx: clusterId,
    },
  };

  try {
    const { data } = (await httpGet(`${serverBaseUrl}${path}/surv_funcs/${modelId}`, config)) as AxiosResponse<
      SurvivalFunctionContainer[]
    >;

    return data;
  } catch (err) {
    console.error('error getting survival functions: ', err);
    throw new Error('error-getting-survival-functions');
  }
};

export const getLifetimePlots = async (modelId: ModelId, nextDays: number): Promise<ScatterPlot> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
      next_days: nextDays,
    },
  };

  try {
    const { data } = (await httpGet(
      `${serverBaseUrl}${path}/prob_lifetime_plot/${modelId}`,
      config
    )) as AxiosResponse<ScatterPlot>;

    return data;
  } catch (err) {
    console.error('error getting lifetime plots: ', err);
    throw new Error('error-getting-lifetime-plots');
  }
};
