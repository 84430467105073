import React, { useEffect, useState } from 'react';

import { Module, Modules } from 'domain/general.types';
import { useSearchParams } from 'react-router-dom';

import sessionService, { getCurrentProject } from 'services/session/session.service';
import { URL_PARAMS } from '../constants';

export const SESSION_MODULE_KEY = 'module';

const useInitializedModule = (): [Module, React.Dispatch<React.SetStateAction<Module>>] => {
  const [searchParams] = useSearchParams();
  const project = getCurrentProject();

  const defaultModule = searchParams.get(URL_PARAMS.Module) || project?.modules[0] || Modules.Core;

  const [module, setModule] = useState<Module>(() => {
    const sessionModule = sessionService.getCustomValue(SESSION_MODULE_KEY) as Module;

    if (sessionModule) {
      return sessionModule as Module;
    } else {
      return defaultModule as Module;
    }
  });

  useEffect(() => {
    sessionService.saveCustomValue(SESSION_MODULE_KEY, module);
  }, [module]);

  return [module, setModule];
};

export default useInitializedModule;
