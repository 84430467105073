import React, { useMemo } from 'react';

import CFTable, { Column, ColumnType } from 'components/CFTable';

import { GroupPreviewResponse } from 'services/markov/markov.types.api';
import { getDisplayName, getIdentifier } from 'services/traits/helpers.traits';

import { Trait } from 'domain/traits.types';

interface Props {
  groupView: GroupPreviewResponse;
  traits: Trait[];
}

const escapePoints = (str: string) => str.split('.').join('-');

const GroupPreview = ({ traits, groupView }: Props) => {
  const headers: Column[] = useMemo(
    () => [
      ...traits.map((trait) => ({
        title: getDisplayName(trait),
        field: escapePoints(getIdentifier(trait)),
        type: ColumnType.STRING,
      })),
      {
        title: 'Count',
        field: 'value',
        type: ColumnType.NUMBER,
      },
    ],
    [traits]
  );

  const data = useMemo(
    () =>
      groupView.map((item) => ({
        ...traits.reduce((acc, cur, i) => {
          acc[escapePoints(getIdentifier(cur))] = item.group[i];
          return acc;
        }, {} as any),
        value: item.count,
      })),
    [groupView, traits]
  );

  return (
    <div>
      <CFTable headers={headers} data={data} />
    </div>
  );
};

export default GroupPreview;

/*


{
      title: '',
      field: 'id',
      type: ColumnType.NUMBER
    },
*/
