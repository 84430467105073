import React from 'react';

import UserEngagementLayout from '../layout';
import { Tabs } from '../tabs';

const PatientEncountersPerActiveUsers = () => {
  return (
    <UserEngagementLayout className="patient-encounters-per-active-users" tab={Tabs.PatientEncountersPerActiveUsers}>
      <></>
    </UserEngagementLayout>
  );
};

export default PatientEncountersPerActiveUsers;
