import React from 'react';

export default function ArrowDown(props: Record<string, any>) {
  return (
    <span className="arrow-down " {...props}>
      <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9 5.83329L5 1.83329L1 5.83329M1 11.1666L5 15.1666L9 11.1666"
          stroke="#88888D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}
