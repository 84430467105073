import React, { useCallback, useEffect, useMemo } from 'react';

import CFSubmitTextarea from 'components/textarea/CFSubmitTextarea';
import FollowUpMessage from '../AssistantWall/components/Message/FollowUpMessage';
import { useMessagesContext } from '../context/useMessagesContext';

import { inputPlaceholder } from './constants';

import './assistant-input.scss';

const AssistantInput = () => {
  const textareaRef = React.createRef<HTMLTextAreaElement>();

  const {
    messages,
    waitingResponse,
    currentMessage,
    selectedParentId,
    setCurrentMessage,
    addQuestion,
    setSelectedParentId,
    followUpLastMessage,
  } = useMessagesContext();

  useEffect(() => {
    textareaRef.current?.focus();
  }, [currentMessage]);

  useEffect(() => {
    textareaRef.current?.focus();
  }, [selectedParentId]);

  const handleTextInput = useCallback(
    (evt: any) => {
      if (evt.target.value.includes('/f')) {
        followUpLastMessage();

        const newValue = evt.target.value.slice(0, -2);
        (textareaRef.current as HTMLTextAreaElement).value = evt.target.value.slice(0, -1);

        setCurrentMessage(newValue);
      } else {
        setCurrentMessage(evt.target.value);
      }
    },
    [messages, textareaRef]
  );

  const handleNewMessage = useCallback(async () => {
    setCurrentMessage('');
    setSelectedParentId(undefined);

    await addQuestion(currentMessage, selectedParentId);
  }, [currentMessage, selectedParentId]);

  const handleDiscardFollowUp = useCallback(() => {
    setSelectedParentId(undefined);
  }, []);

  const followUpMessage = useMemo(() => {
    const message = messages.find((message) => message.id === selectedParentId);

    return message;
  }, [selectedParentId]);

  return (
    <div className="assistant__input">
      <CFSubmitTextarea
        ref={textareaRef}
        onChange={handleTextInput}
        onSubmit={handleNewMessage}
        disabled={waitingResponse !== undefined}
        placeholder={inputPlaceholder}
        value={currentMessage}
      >
        {followUpMessage && (
          <FollowUpMessage message={followUpMessage} onDiscard={handleDiscardFollowUp} removable={true} />
        )}
      </CFSubmitTextarea>
    </div>
  );
};

export default AssistantInput;
