import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';

import { getDaysForWeek } from 'helpers/dates';

import { TimeRFC3999 } from 'domain/general.types';

import WeekPicker from './WeekPicker';

import './week-viewer.scss';

interface WeekViewerProps {
  week: number;
  year: number;
  timestamps?: TimeRFC3999[];
}

const daysOfTheWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const WeekViewer = ({ week, year, timestamps = [] }: WeekViewerProps) => {
  const [date, setDate] = useState(dayjs(`${year}-01-01`).week(week).toDate());

  const days = useMemo(() => getDaysForWeek(week, year), []);

  useEffect(() => {
    setDate(dayjs(`${year}-01-01`).week(week).toDate());
  }, [week]);

  const handleNextWeek = useCallback(() => {
    const nextWeek = dayjs(date).add(1, 'week');

    setDate(nextWeek.toDate());
  }, [date]);

  const handlePreviousWeek = useCallback(() => {
    const prevWeek = dayjs(date).subtract(1, 'week');

    setDate(prevWeek.toDate());
  }, [date]);

  const timestampsByDay: Record<string, string[]> = useMemo(
    () =>
      timestamps.reduce((acc, curr) => {
        const currNotimezone = curr.slice(0, -6);

        const date = dayjs(currNotimezone).format('YYYY-MM-DD');
        const time = dayjs(currNotimezone).format('HH:mm');

        if (!acc[date]) {
          acc[date] = [];
        }

        acc[date].push(time);

        return acc;
      }, {} as Record<string, string[]>),
    [timestamps]
  );

  return (
    <div className="week-viewer">
      <WeekPicker
        date={date}
        nextDisabled={false}
        prevDisabled={false}
        onClickNext={handleNextWeek}
        onClickPrevious={handlePreviousWeek}
      />
      <div className="week-navigator"></div>
      <div className="week-days">
        {days.map((day, i) => {
          const currentDay = dayjs(date).add(i, 'day');
          return (
            <div className="day" key={daysOfTheWeek[i]}>
              <div className="name">{daysOfTheWeek[i]}</div>
              <div className={`value ${currentDay.isToday() ? 'today' : ''}`}> {currentDay.date()} </div>
              <div className="hours">
                {(timestampsByDay[currentDay.format('YYYY-MM-DD')] || []).map((hour) => (
                  <div key={hour} className="scheduled-hour">
                    {hour}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WeekViewer;
