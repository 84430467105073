import { ToastType } from 'components/CFToast/types';
import { LeafRef } from 'connected-components/LeafBuilder';
import { SamplePurpose } from 'services/cohort/cohort.types';
import { AlgorithmType } from 'services/intervention/intervention.types';
import { Nudge } from 'services/nudging/nudge.types';
import { TraitSubject } from 'domain/traits.types';
import { useToast } from 'hooks';
import React, { createContext, useState, useContext, useRef, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { generateStaticSample } from 'services/cohort/cohort.repo';
import {
  QUERY_PARAM_CLONE,
  QUERY_PARAM_DRAFT,
  defaultReadinessStates,
} from './interventions/NewIntervention/constants';
import Cohort from 'services/cohort/domain/Cohort';
import { InterventionDefinitionRef } from './interventions/NewIntervention/definition';
import { InterventionParticipantsRef } from './interventions/NewIntervention/participants/participants-bandit';
import { InterventionNudgePolicyRef } from './interventions/NewIntervention/nudgePolicy';
import { InterventionMetricsPolicyRef } from './interventions/NewIntervention/metricsPolicy/metrics-policy.typings';
import { InterventionSchedulingRef } from 'connected-components/SchedulingBuilder';
import { MarkovStatesRef } from './interventions/NewIntervention/states';
import { Steps } from './interventions/NewIntervention';
import { GroupTraitsRef } from './interventions/NewIntervention/groupTraits';
import { TupleCreatorRef } from './interventions/NewIntervention/states/TupleCreator';
import VOSampleId from 'services/intervention/domain/VOSampleId';
import { Leaf } from 'services/cohort/cohort.types.api';
import Filter from 'services/cohort/domain/Filter';
import { SamplingType } from './interventions/NewIntervention/participants/types';

interface InterventionContextValue {
  filterGroupRef: React.MutableRefObject<LeafRef>;
  sampling: boolean;
  setSampling: React.Dispatch<React.SetStateAction<boolean>>;
  cohort: Cohort | undefined;
  setCohort: React.Dispatch<React.SetStateAction<Cohort | undefined>>;
  pureSampleId: VOSampleId;
  sampleId: VOSampleId;
  setSampleId: React.Dispatch<React.SetStateAction<VOSampleId>>;
  generateSample: (type: SamplingType, size: number) => Promise<void>;
  regenerateSample: () => Promise<void>;
  algorithmType: AlgorithmType;
  setAlgorithmType: React.Dispatch<React.SetStateAction<AlgorithmType>>;

  algorithmName: string;
  setAlgorithmName: React.Dispatch<React.SetStateAction<string>>;

  extraFilter: Leaf<Filter> | undefined;
  setExtraFilter: React.Dispatch<React.SetStateAction<Leaf<Filter> | undefined>>;

  selectedNudges: Nudge[];
  setSelectedNudges: React.Dispatch<React.SetStateAction<Nudge[]>>;
  subject: TraitSubject | undefined;
  setSubject: React.Dispatch<React.SetStateAction<TraitSubject | undefined>>;
  isCloning: boolean | undefined;
  isDraft: boolean | undefined;
  isLoading: boolean;
  readiness: Record<Steps, boolean>;
  setReadiness: React.Dispatch<React.SetStateAction<Record<Steps, boolean>>>;
  skipClonning: boolean;
  setSkipClonning: React.Dispatch<React.SetStateAction<boolean>>;
  offset: number;
  setOffset: React.Dispatch<React.SetStateAction<number>>;
  tupleItems: number[];
  setTupleItemsList: React.Dispatch<React.SetStateAction<number[]>>;

  definitionRef: React.MutableRefObject<InterventionDefinitionRef>;
  participantsRef: React.MutableRefObject<InterventionParticipantsRef>;
  nudgePolicyRef: React.MutableRefObject<InterventionNudgePolicyRef>;
  metricsPolicyRef: React.MutableRefObject<InterventionMetricsPolicyRef>;
  schedulingRef: React.MutableRefObject<InterventionSchedulingRef>;
  markovStatesRef: React.MutableRefObject<MarkovStatesRef>;
  groupFiltersRef: React.MutableRefObject<GroupTraitsRef>;
  tuplesRef: React.MutableRefObject<TupleCreatorRef[]>;
}

const InterventionContext = createContext<InterventionContextValue | undefined>(undefined);

interface Props extends React.PropsWithChildren {}

const DEFAULT_OFFSET = 30; // days

const ContextProvider: React.FC<Props> = ({ children }) => {
  const filterGroupRef = useRef<LeafRef>() as React.MutableRefObject<LeafRef>;
  const [searchParams] = useSearchParams();

  const [extraFilter, setExtraFilter] = useState<Leaf<Filter>>();

  const [sampling, setSampling] = useState(false);
  const [cohort, setCohort] = useState<Cohort | undefined>(undefined);
  const [isCloning, setIsCloning] = useState<boolean | undefined>(undefined);
  const [isDraft, setIsDraft] = useState<boolean | undefined>(undefined);

  const [sampleId, setSampleId] = useState(new VOSampleId(''));
  const [pureSampleId, setPureSampleId] = useState(new VOSampleId(''));

  const [sampleSize, setSampleSize] = useState<number>(0);

  const [algorithmType, setAlgorithmType] = useState<AlgorithmType>(AlgorithmType.Bandit);
  const [algorithmName, setAlgorithmName] = useState<string>('');

  const [selectedNudges, setSelectedNudges] = useState<Nudge[]>([]);
  const [subject, setSubject] = useState<TraitSubject>();
  const [skipClonning, setSkipClonning] = useState(false);

  const [offset, setOffset] = useState<number>(DEFAULT_OFFSET);

  const tuplesRef = useRef<TupleCreatorRef[]>([]);
  const [tupleItems, setTupleItemsList] = useState<number[]>([]);

  const definitionRef = useRef<InterventionDefinitionRef>() as React.MutableRefObject<InterventionDefinitionRef>;
  const participantsRef = useRef<InterventionParticipantsRef>() as React.MutableRefObject<InterventionParticipantsRef>;
  const nudgePolicyRef = useRef<InterventionNudgePolicyRef>() as React.MutableRefObject<InterventionNudgePolicyRef>;
  const metricsPolicyRef =
    useRef<InterventionMetricsPolicyRef>() as React.MutableRefObject<InterventionMetricsPolicyRef>;
  const schedulingRef = useRef<InterventionSchedulingRef>() as React.MutableRefObject<InterventionSchedulingRef>;
  const markovStatesRef = useRef<MarkovStatesRef>() as React.MutableRefObject<MarkovStatesRef>;
  const groupFiltersRef = useRef<GroupTraitsRef>() as React.MutableRefObject<GroupTraitsRef>;

  const [readiness, setReadiness] = useState({
    [Steps.Definition]: false,
    [Steps.Participants]: false,
    [Steps.Nudge]: false,
    [Steps.Metrics]: false,
    [Steps.Scheduling]: false,
    [Steps.States]: false,
    [Steps.GroupTraits]: false,
    [Steps.Score]: true,
  });

  const { addToast } = useToast();

  useEffect(() => {
    setReadiness(defaultReadinessStates[algorithmType]);
  }, [algorithmType]);

  useEffect(() => {
    const draftId = searchParams.get(QUERY_PARAM_DRAFT);
    setIsDraft(!!draftId);
  }, [searchParams]);

  useEffect(() => {
    const cloneId = searchParams.get(QUERY_PARAM_CLONE);
    setIsCloning(!!cloneId);
  }, [searchParams]);

  const isLoading = useMemo(() => {
    return !(isDraft !== undefined && isCloning !== undefined);
  }, [isDraft, isCloning]);

  const regenerateSample = async () => {
    generateNormalSample(sampleSize);
  };

  const generateSample = async (type: SamplingType, size: number) => {
    if (type === SamplingType.Normal) {
      setSampleSize(size);
      generateNormalSample(size);
    } else {
      generatePureControlSample(size);
    }
  };

  const generatePureControlSample = async (size: number) => {
    generateSampleCommon(pureSampleId, setPureSampleId, size);
  };

  const generateNormalSample = async (size: number) => {
    generateSampleCommon(sampleId, setSampleId, size);
  };

  const generateSampleCommon = async (
    sampleId: VOSampleId,
    setSampleId: React.Dispatch<React.SetStateAction<VOSampleId>>,
    size: number
  ) => {
    if (!cohort) {
      return;
    }

    setSampling(true);

    try {
      const { id } = await generateStaticSample(
        cohort,
        extraFilter?.filters || [],
        size,
        sampleId ? sampleId.value : undefined,
        SamplePurpose.Intervention
      );

      setSampleId(new VOSampleId(id));
    } catch (err) {
      addToast('Error generating sample', ToastType.ERROR, 5000);
    } finally {
      setSampling(false);
    }
  };

  return (
    <InterventionContext.Provider
      value={{
        filterGroupRef,

        extraFilter,
        setExtraFilter,

        sampling,
        setSampling,
        cohort,
        setCohort,
        sampleId,
        pureSampleId,
        setSampleId,
        generateSample,
        regenerateSample,
        algorithmType,
        setAlgorithmType,
        algorithmName,
        setAlgorithmName,
        selectedNudges,
        setSelectedNudges,
        subject,
        setSubject,
        isCloning,
        isDraft,
        isLoading,
        readiness,
        setReadiness,
        skipClonning,
        setSkipClonning,
        offset,
        setOffset,
        tupleItems,
        setTupleItemsList,

        definitionRef,
        participantsRef,
        nudgePolicyRef,
        metricsPolicyRef,
        schedulingRef,
        markovStatesRef,
        groupFiltersRef,
        tuplesRef,
      }}
    >
      {children}
    </InterventionContext.Provider>
  );
};

export const useInterventionContext = (): InterventionContextValue => {
  const context = useContext(InterventionContext);
  if (!context) {
    throw new Error('useInterventionContext must be used within a InterventionContextProvider');
  }
  return context;
};

export default ContextProvider;
