import React, { useCallback, useEffect, useMemo, useState } from 'react';

import CFTitledSection from 'components/CFTitledSection';
import CFScatterRangeChart, { ColorInterval, LegendItem, ScatterPoint } from 'components/charts/CFScatterRange';
import { InterventionId } from 'services/intervention/intervention.types';
import { useServicesContext } from 'hooks/useServicesContext';
import { getColorForGroup } from '../../helpers';
import { useArmsInfoContext } from '../useArmsInfo';
import { ArmCluster } from 'services/model/model.repo.types';
import { bestArmAssignments } from '../../constants';
import CFPortal, { ContentPosition } from 'components/CFPortal';
import { PointsOvered } from 'components/charts/CFScatterRange/components/Chart';

import './arm-assignments.scss';
import { SubjectId } from 'domain/general.types';
import TraitInfoPerSubject from './TraitInfoPerSubject';

interface Props {
  interventionId: InterventionId;
}

const ArmConfidence = ({ interventionId }: Props) => {
  const { modelService, interventionService } = useServicesContext();
  const { setArmIndexes } = useArmsInfoContext();

  const [points, setPoints] = useState<ScatterPoint[]>([]);
  const [intervals, setIntervals] = useState<ColorInterval[] | undefined>(undefined);

  const [armClusters, setArmClusters] = useState<ArmCluster>();
  const [pointsOvered, setPointsOvered] = useState<PointsOvered | undefined>(undefined);

  const [selectedSubjectId, setSelectedSubjectId] = useState<SubjectId | undefined>(undefined);

  useEffect(() => {
    (async () => {
      if (!interventionId) {
        return;
      }

      const interventionView = await interventionService.getView(interventionId);
      const modelId = interventionView.intervention.algo_policy.model_def_id;

      const armClusters = await modelService.getArmClusters(`${modelId || ''}`);

      setArmClusters(armClusters);
    })();
  }, [interventionId]);

  useEffect(() => {
    (async () => {
      if (!armClusters) {
        return;
      }

      const totalPoints = armClusters.x.length;

      const points = [];
      for (let i = 0; i < totalPoints; i++) {
        points.push({
          x: armClusters.x[i],
          y: armClusters.y[i],
          value: armClusters.arm_idx[i],
          size: Math.floor(Math.random() * 10),
          meta: {
            id: armClusters.index[i],
          },
        });
      }

      setPoints(points);
    })();
  }, [armClusters]);

  useEffect(() => {
    (async () => {
      if (!armClusters) {
        return;
      }

      const interventionView = await interventionService.getView(interventionId);

      const colorIntervals = armClusters.arms.map((arm, index) => {
        const color = getColorForGroup(arm, index, interventionView.controlGroup === arm);
        const colorConfig = { range: [index, index + 1], from: color, to: color };
        return colorConfig as ColorInterval;
      });

      setIntervals(colorIntervals);
    })();
  }, [armClusters]);

  const handleArmsSelectionChange = useCallback((armsIndexes: number[]) => {
    setArmIndexes(armsIndexes);
  }, []);

  const handleSubjectSelected = useCallback(
    (subjects: PointsOvered) => {
      if (pointsOvered?.points.length === 0 && subjects.points.length === 0) {
        // avoid rerendering
        return;
      }

      if (subjects.points.length) {
        const firstSubjectId = subjects.points[0].meta.id;

        interventionService.getMetricsForSubject(interventionId, [firstSubjectId], -1);
      }

      console.log('points hovered... ', subjects);

      setPointsOvered(subjects);
    },
    [pointsOvered]
  );

  const handleClickOnSubject = (subjectId: SubjectId) => {
    setSelectedSubjectId(subjectId);
  };

  const legendItems: LegendItem[] = useMemo(() => {
    if (!armClusters || !intervals) {
      return [];
    }

    return intervals?.map((interval, index) => ({
      name: armClusters.arms[index],
      color: interval.from,
    }));
  }, [armClusters, intervals]);

  return (
    <CFTitledSection title="Best Arm Assigments" description={bestArmAssignments} underlined={true}>
      {selectedSubjectId ? (
        <CFPortal onClickOutside={() => setSelectedSubjectId(undefined)} mode={ContentPosition.Center}>
          <TraitInfoPerSubject interventionId={interventionId} subjectId={selectedSubjectId} />
        </CFPortal>
      ) : (
        <div></div>
      )}

      {pointsOvered?.points.length ? (
        <CFPortal
          top={pointsOvered?.position.y}
          left={pointsOvered?.position.x}
          right={0}
          mode={ContentPosition.Custom}
          backdrop={false}
          onClickOutside={() => {
            setPointsOvered(undefined);
          }}
        >
          <div className="hovered-subjects">
            {pointsOvered?.points.map((point, i) => (
              <div key={i} className="hovered-subjects__item" onClick={() => handleClickOnSubject(point.meta.id)}>
                {point.meta.id}
              </div>
            ))}{' '}
          </div>
        </CFPortal>
      ) : (
        <div></div>
      )}
      <CFScatterRangeChart
        points={points}
        showGradient={false}
        intervals={intervals}
        legend={legendItems}
        onSelectedChanged={handleArmsSelectionChange}
        onPointsOver={handleSubjectSelected}
      />
    </CFTitledSection>
  );
};

export default ArmConfidence;
