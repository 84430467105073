import React, { useCallback, useRef, useState } from 'react';

import CFInput from 'components/CFInput';
import CFButton from 'components/buttons/CFButton';
import ScaledSubjectGraph from './ScaledSubjectGraph';

import { GroupName, MarkovProcessId, NodeUpdate } from 'services/markov/markov.types';

import { SubjectId } from 'domain/general.types';

import './markov-subject-viewer.scss';

interface Props {
  processId: MarkovProcessId;
  updates: Record<GroupName, NodeUpdate[]>;
  selectedNudgeNames: string[];
}

const MarkovSubjectViewer = React.memo(function MarkovSubjectViewer({ processId, selectedNudgeNames, updates }: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [subjectId, setSubjectId] = useState<SubjectId>();

  const handleSeeSubjectGraph = useCallback(async () => {
    if (!inputRef || !inputRef.current) {
      return;
    }

    const subjectId = inputRef.current.value;
    setSubjectId(subjectId);
  }, [inputRef, inputRef.current, processId]);

  return (
    <div className="markov-subject-viewer">
      <div className="input">
        <CFInput ref={inputRef} />
        <CFButton value={'See subject graphs'} onClick={handleSeeSubjectGraph} />
      </div>

      {subjectId && (
        <div className="graphs">
          {selectedNudgeNames.map((nudgeName) => (
            <ScaledSubjectGraph
              key={`nudge-${nudgeName}`}
              processId={processId}
              groupName={nudgeName}
              subjectId={subjectId}
              updates={nudgeName !== 'control' ? updates[nudgeName] : []}
            />
          ))}
        </div>
      )}
    </div>
  );
});

export default MarkovSubjectViewer;
