import React from 'react';

import classNames from 'classnames';
import CFTitledSection from 'components/CFTitledSection';
import CFScatterPlotChart from 'components/charts/CFScatterPlotChart';
import { ModelMetrics } from 'domain/model.types';
import { lifetimeDescription } from '../../constants';

interface Props {
  className: string;
  modelMetrics: ModelMetrics;
}

import './predicted-observed-lifetime.scss';

const PredictedVSObservedLifetime = ({ className, modelMetrics }: Props) => {
  return (
    <div className={classNames('predicted-observed-lifetime', className)}>
      <CFTitledSection
        title="Predicted vs observed lifetime plots for churned users"
        className="inrow"
        description={lifetimeDescription}
      >
        <CFScatterPlotChart
          title={''}
          series={[
            {
              name: 'diag',
              values:
                modelMetrics?.censoring.diag.x.map((value, i) => ({
                  x: value,
                  y: modelMetrics?.censoring.diag.y[i],
                  id: '',
                })) || [],
            },
          ]}
          xLabel={`${modelMetrics?.censoring.diag.x_label} (in ${modelMetrics?.censoring.diag.x_unit}s)`}
          yLabel={`${modelMetrics?.censoring.diag.y_label} (in ${modelMetrics?.censoring.diag.y_unit}s)`}
          step={20}
          square={true}
          size={675}
        />

        <CFScatterPlotChart
          title={''}
          series={[
            {
              name: 'horz',
              values:
                modelMetrics?.censoring.horz.x.map((value, i) => ({
                  x: value,
                  y: modelMetrics?.censoring.horz.y[i],
                  id: '',
                })) || [],
            },
          ]}
          xLabel={`${modelMetrics?.censoring.horz.x_label} (in ${modelMetrics?.censoring.horz.x_unit}s)`}
          yLabel={`${modelMetrics?.censoring.horz.y_label} (in ${modelMetrics?.censoring.horz.y_unit}s)`}
          step={20}
          square={true}
          size={675}
        />
      </CFTitledSection>
    </div>
  );
};

export default PredictedVSObservedLifetime;
