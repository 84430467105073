import React, { FC, useMemo, useRef } from 'react';

import { RGBColor } from 'domain/general.types';

import colors from 'common.scss';
import { capitalize } from 'helpers/misc';
import { EChartsOption, ReactECharts } from '..';

import './cf-box-plot-chart.scss';

export interface DataItem {
  min: number;
  q1: number;
  q2: number;
  q3: number;
  max: number;
}

export enum LineStyle {
  DOTTED = 'dotted',
  SOLID = 'solid',
}

interface DataSeries {
  name: string;
  data: DataItem;
  lineStyle?: LineStyle;
  color?: RGBColor;
}

interface Props {
  title: string;
  data: DataSeries[];
  isLoading?: boolean;
}

const CFBoxPlotChart: FC<Props> = ({ title, data, isLoading }) => {
  const chartRef = useRef<HTMLDivElement>(null);

  const options: EChartsOption = useMemo(
    () => ({
      animation: false,
      grid: {
        top: '30%',
        bottom: '30%',
        left: '1%',
        right: '10%',
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: colors.dark100,
        borderColor: colors.dark100,
      },
      xAxis: {
        type: 'value',
        name: '',
        nameLocation: 'middle',
        position: 'top',
        nameTextStyle: {
          color: colors.dark50,
          fontSize: 14,
          fontWeight: 600,
        },
        axisLine: {
          lineStyle: {
            color: colors.dark50,
          },
        },
        splitLine: {
          lineStyle: {
            color: colors.dark50,
            type: 'dashed',
          },
        },
      },
      yAxis: {
        type: 'category',
        show: false,
      },
      series: [
        {
          name: 'boxplot',
          type: 'boxplot',
          itemStyle: {
            color: colors.cfCyan,
            opacity: 0.35,
            borderColor: colors.cfCyan,
            borderWidth: 3,
          },
          encode: {
            y: ['min', 'q1', 'q2', 'q3', 'max'],
            tooltip: ['min', 'q1', 'q2', 'q3', 'max'],
          },
          data: data.map(({ data }) => ({
            value: [data.min, data.q1, data.q2, data.q3, data.max],
          })),
        },
      ],
    }),
    [data]
  );

  const style = useMemo(() => ({ width: `100%`, height: '200px' }), []);

  return (
    <div className="cf-box-plot-chart">
      <span className="chart-title"> {capitalize(title)} </span>
      <ReactECharts ref={chartRef} option={options} style={style} loading={isLoading} />
    </div>
  );
};

export default CFBoxPlotChart;
