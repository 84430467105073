import { AxiosResponse } from 'axios';

import { RepoConfig } from '../../repositories/types';
import { get as httpGet, remove as httpDelete, streamPost } from '../../repositories/drivers/http';
import { AssistantMessage, AssistantMessageType, CFMessage, CFMessageRole, ChatAPIResponse } from './assistant.types';

import {
  assistantMessageToChatWithValidationReq,
  chatWithValidationRespToAssistantMessage,
  parseDatabaseTable,
} from './mappers';

const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
const path = '/v1/chat';

const repoConfig = {
  token: '',
  oid: -1,
  pid: -1,
};

export interface CFAssistantRepository {
  init: (repoConfig: RepoConfig) => void;
  getHistory: (from: number, per_page: number, thread_id: number) => Promise<AssistantMessage[]>;
  questionWithValidation: (
    question: string | AssistantMessage,
    parentId: number | undefined,
    onProgress?: (text: string) => void
  ) => Promise<AssistantMessage>;
  reset: (threadId: number) => Promise<void>;
}

export const init = ({ token, oid, pid }: RepoConfig) => {
  repoConfig.token = token;
  repoConfig.oid = oid;
  repoConfig.pid = pid;
};

export const questionWithValidation = async (
  message: string | AssistantMessage,
  parentId: number | undefined,
  onProgress: (text: string) => void
): Promise<AssistantMessage> => {
  const body = assistantMessageToChatWithValidationReq(message, parentId);

  return new Promise((resolve, reject) => {
    const handleProgress = (text: string) => {
      // proxy might send us both messages and final response in the same chunk
      // so we need to distinguish them
      text
        .split('\n')
        .filter((step) => step.trim())
        .forEach((step) => {
          try {
            const data = JSON.parse(step);
            const message = chatWithValidationRespToAssistantMessage(data as ChatAPIResponse);

            resolve(message);
          } catch {
            onProgress && onProgress(step);
          }
        });
    };

    streamPost(
      `${serverBaseUrl}${path}?stream=${true}&pid=${repoConfig.pid}&oid=${repoConfig.oid}`,
      body,
      handleProgress
    ).catch(() => {
      reject();
    });
  });
};

export const getHistory = async (last_id: number, limit: number, thread_id: number): Promise<AssistantMessage[]> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
      limit,
      last_id: last_id || 0,
      thread_id,
    },
  };

  try {
    const data = (await httpGet(`${serverBaseUrl}${path}/msg/list`, config)) as AxiosResponse<CFMessage[]>;

    return (
      (data.data || []).map((msg: CFMessage) => ({
        id: msg.id,
        tid: msg.tid,
        parentId: msg.parent_id,
        type: msg.role === CFMessageRole.Assistant ? AssistantMessageType.Answer : AssistantMessageType.Question,
        text: msg.content,
        structuredText: parseDatabaseTable(msg.content),
        timestamp: msg.time,
        payloads: msg.payloads,
        error: false,
      })) || []
    );
  } catch (err) {
    console.error('error getting chat history: ', err);
    throw new Error('error-chat-history');
  }
};

export const reset = async (threadId: number) => {
  const config = {};

  try {
    await httpDelete(
      `${serverBaseUrl}${path}/thread/reset?oid=${repoConfig.oid}&pid=${repoConfig.pid}&thread_id=${threadId}`,
      config
    );
  } catch (err) {
    throw new Error((err as any).response.data.message);
  }
};

export default {
  init,
  getHistory,
  questionWithValidation,
  reset,
} as CFAssistantRepository;
