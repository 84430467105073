import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Trait, TraitCategory, TraitSubject } from 'domain/traits.types';

import { getDisplayName, getIdentifier } from 'services/traits/helpers.traits';

import CFInput from 'components/CFInput';
import CFTitledComponent from 'components/CFTitledComponent';
import TimeWindowPicker from 'components/TimeWindowPicker';
import CFSelect, { Option } from 'components/CFSelect';
import TraitItem from 'components/CFSelect/common/TraitItem';

import { Steps, useModelContext } from '../../useContext';
import { useServicesContext } from 'hooks/useServicesContext';
import { Granularity } from 'helpers/dates';

const BanditTarget = () => {
  const { setTarget, setAggDuration, updateReadiness } = useModelContext();
  const { traitSessionService: traitService } = useServicesContext();
  const [groupNames, setGroupNames] = useState<string[]>([]);

  const [selectedReward, setSelectedReward] = useState<Trait | null>(null);
  const [rewardTraits, setRewardTraits] = useState<Trait[]>([]);

  const fetchTraits = async () => {
    setRewardTraits(await traitService.getTraits({ subject: TraitSubject.User, category: TraitCategory.Dynamic }));
  };

  useEffect(() => {
    fetchTraits();
  }, []);

  useEffect(() => {
    if (groupNames.length === 0) {
      updateReadiness(false, Steps.Target);
      return;
    }

    if (!selectedReward) {
      updateReadiness(false, Steps.Target);
      return;
    }

    setTarget({
      reward: selectedReward.addr,
      action: groupNames,
    });

    updateReadiness(true, Steps.Target);
  }, [selectedReward, groupNames]);

  const indexedRewardTraits = useMemo(
    () => rewardTraits.reduce<Record<string, Trait>>((acc, trait) => ({ ...acc, [getIdentifier(trait)]: trait }), {}),
    [rewardTraits]
  );

  const handleSelectRewardTrait = useCallback(
    (option: Option) => {
      setSelectedReward(indexedRewardTraits[option.value]);
    },
    [indexedRewardTraits]
  );

  const handleGroupNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const separator = ',';
    const groupNames = e.currentTarget.value
      .split(separator)
      .map((groupName) => groupName.trim())
      .filter((groupName) => groupName !== '');

    setGroupNames(groupNames);
  }, []);

  return (
    <>
      <CFTitledComponent title={'Reward'}>
        <CFSelect
          options={rewardTraits.map((trait) => ({
            value: getIdentifier(trait),
            label: getDisplayName(trait),
            meta: {
              trait,
              simple: false,
              wrap: true,
            },
          }))}
          Item={TraitItem}
          isMulti={false}
          onSelected={handleSelectRewardTrait}
          value={{
            value: selectedReward ? getIdentifier(selectedReward) : '',
            label: selectedReward ? getDisplayName(selectedReward) : '',
          }}
        />
      </CFTitledComponent>

      <CFTitledComponent title="Action" subtitle="(comma-separated strings)">
        <CFInput onChange={handleGroupNameChange} />
      </CFTitledComponent>

      <CFTitledComponent title="Agg duration">
        <TimeWindowPicker
          defaultGranularity={Granularity.Day}
          defaultValue={1}
          onChange={(time) => setAggDuration(time)}
        />
      </CFTitledComponent>
    </>
  );
};

export default BanditTarget;
