import { Module, TimeRFC3999 } from '../../domain/general.types';
import { GroupName } from '../intervention/intervention.types';
import { Query } from '../../domain/query.types';
import { TraitSubject } from '../../domain/traits.types';
import { FilterAPI, Tree } from './cohort.types.api';
import Filter from './domain/Filter';
import FilterSet from './domain/FilterSet';

export type CohortID = number;

export interface CohortSummary {
  id: string;
  name: string;
  counter: number;
}

export interface CohortDetail {
  id: string;
  query: Query;
}

export interface CohortAPI {
  id: CohortID;
  pid: number;
  name: string;
  description: string;
  tree: Tree;
  subject_type: TraitSubject;
  uses: number;
  meta: {
    latest: TimeRFC3999;
    sub_cnt: number;
  };
  created_by: string;
  created_at: string;
  modules: Module[];
}

export interface InternalCohort {
  name: string;
  description: string;
  tree: FilterSet;
  subject_type: TraitSubject;
}

export interface SampleItem {
  group_name: string;
  subject_id: string;
}

export interface BasicTraitStats {
  min: number;
  q1: number;
  q2: number;
  q3: number;
  max: number;
}

export enum SampleStatus {
  InUse = 'in_use',
  Done = 'done',
}

export interface SampleInfo {
  id: string;
  pid: number;
  cohort_id: number;
  count: number;
  group_cnt: Record<GroupName, number>;
  status: SampleStatus;
  created_at: TimeRFC3999;
}

export enum CohortType {
  Auto = 'auto',
  Manual = 'manual',
  All = 'all',
}

export interface SampleResponse {
  cnt: number;
  id: string;
}

export type GroupRatio = Record<GroupName, number>;

export enum SamplePurpose {
  Intervention = 'inv',
  Model = 'model',
}

export interface GenerateSampleRequestAPI {
  cohort_id: number;
  extra_filter: FilterAPI[];
  group_ratio?: GroupRatio;
  count: number;
  subject_type: TraitSubject;
  purpose: SamplePurpose;
}

export interface GenerateSampleRequest extends Omit<GenerateSampleRequestAPI, 'extra_filter'> {
  extraFilter: Filter[];
}
