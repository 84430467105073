import React, { useEffect, useMemo, useRef } from 'react';
import { EChartsOption, ReactECharts } from '..';
import { getInstanceByDom } from 'echarts';
import { SimpleGraph } from 'services/markov/markov.types';

type NodeName = string;

interface Props {
  data: SimpleGraph;
  prefix?: string;
  title?: string;
  onNodeClicked: (name: NodeName) => void;
}

const COLUMNS_COUNT = 2;

export const CFGraph = ({ data, prefix = '', title = '', onNodeClicked }: Props) => {
  const chartRef = useRef<HTMLDivElement>(null);

  const nodes = useMemo(() => {
    const startX = 100;
    const startY = 100;

    const step = 200;

    return Object.keys(data).map((name, i) => {
      const currentX = startX + step * (i % 2 === 0 ? 0 : 1);
      const currentY = startY + step * Math.floor(i / COLUMNS_COUNT);

      return {
        name,
        x: currentX,
        y: currentY,
      };
    });
  }, [data]);

  const edges = useMemo(() => {
    return Object.keys(data)
      .map((sourceNode) => {
        return Object.keys(data[sourceNode]).map((targetNode: string) => {
          return {
            source: sourceNode,
            target: targetNode,
            label: {
              show: true,
              formatter: () => {
                if (!data[sourceNode][targetNode]) {
                  return '';
                }

                if (data[sourceNode][targetNode].length > 1) {
                  return data[sourceNode][targetNode].join('\n');
                } else if (data[sourceNode][targetNode][0] !== null) {
                  return `${prefix}${data[sourceNode][targetNode][0]}`;
                } else {
                  return '';
                }
              },
              fontSize: 12,
              borderColor: '#ff0000',
              color: '#ffffff',
            },
            lineStyle: {
              curveness: 0.2,
            },
          };
        });
      })
      .flat();
  }, [data]);

  const options: EChartsOption = useMemo(() => {
    return {
      tooltip: {},
      animationDurationUpdate: 1500,
      animationEasingUpdate: 'quinticInOut',
      series: [
        {
          type: 'graph',
          layout: 'none',
          symbolSize: 50,
          roam: true,
          label: {
            show: true,
          },
          edgeSymbol: ['circle', 'arrow'],
          edgeSymbolSize: [4, 10],
          edgeLabel: {
            fontSize: 20,
          },
          data: nodes,
          links: edges,
          lineStyle: {
            opacity: 0.9,
            width: 2,
            curveness: 0,
          },
          animation: false,
        },
      ],
    };
  }, [data, nodes, edges]);

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }
    const chart = getInstanceByDom(chartRef.current);

    chart?.on('click', function (params) {
      if (params.dataType === 'node') {
        onNodeClicked((params.data as any).name);
      }
    });
  }, [chartRef, options]);

  return (
    <div className="cf-line-chart">
      <div className="chart-header">
        <div className="title"> {title}</div>
      </div>

      <div className="titled-chart">
        <div className="yaxis-name"> </div>

        <ReactECharts ref={chartRef} option={options} style={{ width: `100%`, height: '330px' }} loading={false} />
      </div>
    </div>
  );
};
