import React, { FC } from 'react';
import moment from 'moment-timezone';

import CFSelectLegacy, { SelectableItem } from 'components/CFSelectLegacy';

interface TimezonePickerProps {
  onSelect: (timezone: string) => void;
  selectedTimezone?: string;
}

const TimezonePicker: FC<TimezonePickerProps> = ({ selectedTimezone, onSelect }) => {
  const timezones = moment.tz.names().map((timezone) => ({
    value: timezone,
    label: `${timezone} ${moment().tz(timezone).format('Z')}`,
  }));

  const handleTimezoneChanged = ([selectedTimezone]: SelectableItem[]) => {
    if (onSelect) {
      onSelect(selectedTimezone.value);
    }
  };

  return (
    <div data-testid="timezone-picker">
      <CFSelectLegacy
        isSearchable={true}
        options={timezones}
        defaultOption={[
          {
            label: `${selectedTimezone || moment.tz.guess()} ${moment()
              .tz(selectedTimezone || moment.tz.guess())
              .format('Z')}`,
            value: selectedTimezone || moment.tz.guess(),
          },
        ]}
        onSelected={handleTimezoneChanged}
        placeholder="Select a timezone"
      />
    </div>
  );
};

export default TimezonePicker;
