import { CFRoutes } from 'routes';

export enum Tabs {
  ActiveUsersPerRole = 'active users per role',
  PercentageOfActiveUsers = 'percentage of active users',
  PatientEncountersPerActiveUsers = 'patient encounters per active users',
}

export const allowedTabs = () => {
  return [Tabs.ActiveUsersPerRole, Tabs.PercentageOfActiveUsers, Tabs.PatientEncountersPerActiveUsers];
};

export const tabNameToCode = (tabName: string) => tabName.split(' ').join('-');

export const monitoringByTab: Record<string, CFRoutes> = {
  [tabNameToCode(Tabs.ActiveUsersPerRole)]: CFRoutes.analytics_engagement_per_role,
  [tabNameToCode(Tabs.PercentageOfActiveUsers)]: CFRoutes.analytics_engagement_percentage,
  [tabNameToCode(Tabs.PatientEncountersPerActiveUsers)]: CFRoutes.analytics_engagement_patient_encounters,
};
