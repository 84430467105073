import React from 'react';

import CFButton from '../CFButton';

import { CFRole } from 'domain/general.types';

import { faEdit } from '@fortawesome/free-regular-svg-icons';

import './cf-edit-button.scss';

interface Props {
  disabled?: boolean;
  onClick: () => void;
}

const CFEditButton = ({ onClick, disabled = false }: Props) => {
  return (
    <div className="cf-edit-button">
      <CFButton
        value=""
        role={CFRole.Borderless}
        iconName={faEdit}
        disabled={disabled}
        onClick={onClick}
        testId="clone-button"
      />
    </div>
  );
};

export default CFEditButton;
