import { DataType, Operators } from 'domain/general.types';
import { Trait } from 'domain/traits.types';

import { FilterAPI as FilterApi, Ptr } from 'services/cohort/cohort.types.api';
import { isNumeric } from 'services/traits/helpers.traits';

class Filter {
  private _ptr: Ptr = '';
  private _op: Operators = Operators.Equal;
  private _val: any = '';

  constructor(filter: FilterApi) {
    if (filter.ptr) {
      this._ptr = filter.ptr;
    } else {
      this._ptr = filter.identifier || '';
    }
    this._op = filter.op;
    this._val = filter.val;
  }

  get ptr() {
    return this._ptr;
  }

  set ptr(value: string) {
    this._ptr = value;
  }

  set op(operator: Operators) {
    this._op = operator;
  }

  get op() {
    return this._op;
  }

  get val() {
    return this._val;
  }
  set val(value: any) {
    this._val = value;
  }

  isValid(trait?: Trait) {
    if (!this._op) {
      return false;
    }

    if (trait !== undefined) {
      if (trait.addr.dtype === DataType.Varchar && this._val === '') {
        return true;
      }

      if (isNumeric(trait) && isNaN(this._val)) {
        return false;
      }
    }

    return this._ptr !== '';
  }
}

export default Filter;
