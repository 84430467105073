import React from 'react';

import { Model } from 'domain/model.types';

import './parameters.scss';

interface Props {
  model: Model;
}

const ModelParameters = ({ model }: Props) => {
  return (
    <div>
      {!model.definition.algo_spec.params && <div className="model-definition-parameters no-params">No parameters</div>}
      {model.definition.algo_spec.params && (
        <div className="model-definition-parameters">
          <div className="key-value header">
            <div>Key</div>
            <div>Value</div>
          </div>
          {Object.entries(test).map(([key, value]) => (
            <div key={key} className="key-value">
              <div>{key}</div>
              <div>{value}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ModelParameters;
