import React from 'react';

import './cf-switch.scss';

interface Props {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const CFSwitch = ({ checked, onChange }: Props) => {
  return (
    <label className="switch">
      <input type="checkbox" defaultChecked={checked} onChange={(e) => onChange(e.currentTarget.checked)} />
      <span className="slider round"></span>
    </label>
  );
};

export default CFSwitch;
