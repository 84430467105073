import classNames from 'classnames';
import React from 'react';

import './data-cell.scss';

interface Props {
  importance: number;
}

const DataCell = ({ importance }: Props) => {
  return (
    <div className="data-cell-container">
      <div className={classNames('data-cell', `radius-${Math.abs(importance)}`, { negative: importance < 0 })}></div>
    </div>
  );
};

export default DataCell;
