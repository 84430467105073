import React, { useCallback } from 'react';

import classNames from 'classnames';
import { ReactComponent as LeftButton } from 'assets/left-button.svg';

import './cf-navigation-arrows.scss';

interface Props {
  index: number;
  windowSize: number;
  total: number;
  onNext: () => void;
  onPrevious: () => void;
}

const CFNavigationArrows = ({ index, windowSize, total, onNext, onPrevious }: Props) => {
  const handleNext = useCallback(
    (event: React.MouseEvent<SVGSVGElement>) => {
      event.stopPropagation();

      if (index === total - windowSize) {
        return;
      }

      onNext();
    },
    [total, index, windowSize]
  );

  const handlePrevious = useCallback(
    (event: React.MouseEvent<SVGSVGElement>) => {
      event.stopPropagation();
      if (index === 0) {
        return;
      }

      onPrevious();
    },
    [index]
  );
  return (
    <div className="cf-navigation-arrows">
      <LeftButton
        className={classNames('cf-navigation-arrows__nav', { disabled: index === 0 })}
        onClick={handlePrevious}
        data-testid="previous-button"
      />{' '}
      <LeftButton
        className={classNames('cf-navigation-arrows__nav rotate', {
          disabled: index + windowSize === total,
        })}
        onClick={handleNext}
        data-testid="next-button"
      />
    </div>
  );
};

export default CFNavigationArrows;
