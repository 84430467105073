import React from 'react';

import CFButton from '../CFButton';

import { CFRole } from 'domain/general.types';

import './cf-clone-button.scss';
import { faClone } from '@fortawesome/free-regular-svg-icons';

interface Props {
  disabled?: boolean;
  onClick: () => void;
}

const CFCloneButton = ({ onClick, disabled = false }: Props) => {
  return (
    <div className="cf-clone-button">
      <CFButton
        value=""
        role={CFRole.Borderless}
        iconName={faClone}
        disabled={disabled}
        onClick={onClick}
        testId="clone-button"
      />
    </div>
  );
};

export default CFCloneButton;
