import React, { useState } from 'react';

import { faSearch } from '@fortawesome/free-solid-svg-icons';

import CFButton from 'components/buttons/CFButton';
import CFTitledComponent from 'components/CFTitledComponent';
import CFInput from 'components/CFInput';
import CFSelectLegacy, { SelectableItem } from 'components/CFSelectLegacy';
import { CFNavListWithLinks } from 'components/CFNavListWithLinks';

import { CatalogHistoryItem, CatalogHistoryObject } from 'domain/cataloghistory.types';
import { CFRole } from 'domain/general.types';
import { TraitSubject } from 'domain/traits.types';

import { getCatalogHistory } from 'repositories/cataloghistory';

import { useServicesContext } from 'hooks/useServicesContext';

import { CFRoutes } from 'routes';

import './cataloghistory.scss';

import { Tabs } from '..';
import CFTable, { Column, ColumnType } from 'components/CFTable';
const CatalogHistory = () => {
  const { traitSessionService: traitService } = useServicesContext();
  const nameInputRef = React.createRef<HTMLInputElement>();

  const [selectedSubject, setSelectedSubject] = useState<TraitSubject>(TraitSubject.User);

  const [errorMessage, setErrorMessage] = useState<string>('');

  const [catalogInfo, setCatalogInfo] = useState<CatalogHistoryItem[]>([]);

  const getCatalogHistoryFromRemote = () => {
    getCatalogHistory(selectedSubject, nameInputRef.current?.value || '')
      .then((catalogHistoryObject: CatalogHistoryObject) => {
        setCatalogInfo(
          catalogHistoryObject.header.map((header, i) => ({
            headerItem: header,
            value: (catalogHistoryObject.rows?.[0][i] || '').toString(),
          }))
        );
      })
      .catch(() => {
        console.warn('error getting ingest elements');
      });
  };

  const handleKeyClick = () => {
    if (nameInputRef.current?.value !== '') {
      setErrorMessage('');
      getCatalogHistoryFromRemote();
    } else {
      setErrorMessage(selectedSubject + ' Id is required.');
    }
  };

  const handleSubjectSelected = async ([subject]: SelectableItem[]) => {
    setErrorMessage('');
    setSelectedSubject(subject.value as TraitSubject);
  };

  const headerItems: Column[] = [
    { field: 'headerItem', title: 'Header', type: ColumnType.STRING },
    { title: 'Value', field: 'value', type: ColumnType.STRING },
  ];

  let catalogSubjectItem = <div></div>;
  if (catalogInfo.length > 0 && errorMessage === '') {
    catalogSubjectItem = (
      <div className="container-parent">
        <div className="container-child-left">
          <CFTable headers={headerItems} data={catalogInfo} />
        </div>
        <div className="container-child-right"></div>
      </div>
    );
  }

  return (
    <div>
      <CFNavListWithLinks
        titles={[
          { title: Tabs.Monitoring, target: CFRoutes.integration },
          { title: Tabs.IngestHistory, target: CFRoutes.integration_ingest_history },
          { title: Tabs.CatalogHistory, target: CFRoutes.integration_catalog_history },
          { title: Tabs.SdkExceptions, target: CFRoutes.integration_exception_view },
          { title: Tabs.ApiKeys, target: CFRoutes.integration_keys },
        ]}
        selected={Tabs.CatalogHistory}
      />
      <div className="catalog-history">
        <div className="search-catalog-info">
          <CFTitledComponent title="Subject">
            <CFSelectLegacy
              options={(traitService?.getTraitsSubjects() || [])
                .map((item) => ({
                  label: item,
                  value: item,
                }))
                .concat([
                  {
                    label: 'user_chw' as TraitSubject,
                    value: 'user_chw' as TraitSubject,
                  },
                ])}
              defaultOption={(traitService?.getTraitsSubjects() || []).map((item) => ({
                label: item,
                value: item,
              }))}
              onSelected={handleSubjectSelected}
            />
          </CFTitledComponent>

          <CFTitledComponent title={selectedSubject + ' Id'}>
            <CFInput ref={nameInputRef} />
          </CFTitledComponent>

          <div className="searchCatalogButton">
            <CFButton
              value="Search Catalog"
              iconName={faSearch}
              role={CFRole.Primary}
              onClick={() => handleKeyClick()}
            />
          </div>
        </div>
        <div className="error-message">{errorMessage}</div>
        {catalogSubjectItem}
      </div>
    </div>
  );
};

export default CatalogHistory;
