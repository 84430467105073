import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { faExpand } from '@fortawesome/free-solid-svg-icons';

import Box, { BoxAlignment } from 'components/Box';
import CFModal from 'components/CFModal';
import CFGenericErrorBoundary from 'components/CFGenericErrorBoundary';
import CFNoBorderButton from 'components/buttons/CFNoBorderButton';

import CFTraitItem from 'connected-components/traits/CFTraitItem';

import { FilterAPI } from 'services/cohort/cohort.types.api';

import { useServicesContext } from 'hooks/useServicesContext';

import { TraitCode } from 'domain/traits.types';

import './filter-viewer.scss';

interface Props {
  filter: FilterAPI;
  operator: string;
  skipOperator?: boolean;
  noPadding?: boolean;
}

const VALUE_LENGTH_THRESHOLD = 50;

export const FilterViewer = ({ filter, operator, skipOperator = false, noPadding = false }: Props) => {
  const { traitSessionService: traitService } = useServicesContext();
  const [needExtendedView, setNeedExtendedView] = useState(false);
  const [showAllValues, setShowAllValues] = useState(false);

  const handleCloseExtended = useCallback(() => {
    setShowAllValues(false);
  }, []);

  const formattedValue = useMemo(() => {
    if (filter.val.toString().length > VALUE_LENGTH_THRESHOLD) {
      setNeedExtendedView(true);
      return `${filter.val.toString().slice(0, VALUE_LENGTH_THRESHOLD)}...`;
    }

    return filter.val.toString();
  }, [filter]);

  const trait = useMemo(() => {
    return traitService.getTraitDefinition(filter.ptr as TraitCode);
  }, [filter]);

  return (
    <CFGenericErrorBoundary>
      <div className={classNames('query-expression', { 'no-padding': noPadding })}>
        {showAllValues && (
          <CFModal className="extended-filter-values" onClose={handleCloseExtended}>
            <div> {filter.val.map((value: string) => `${value} `).join(', ')}</div>
          </CFModal>
        )}

        {!skipOperator && (
          <Box
            className={classNames('expressionOperator', { hidden: operator === '' })}
            alignment={BoxAlignment.Center}
          >
            {operator}
          </Box>
        )}

        <Box className="field">
          <CFTraitItem addr={trait?.addr} showAggLevel={true} />
        </Box>

        <Box className="operator" alignment={BoxAlignment.Center}>
          <span> {filter.op}</span>
        </Box>
        <Box className="value">
          <span>{formattedValue}</span>
        </Box>

        {needExtendedView && (
          <CFNoBorderButton value={''} iconName={faExpand} onClick={() => setShowAllValues((val) => !val)} />
        )}
      </div>
    </CFGenericErrorBoundary>
  );
};
