import CFService from 'services/cfservice';
import { CFAssistantRepository } from './assistant.repo';
import { HintMessage } from './assistant.types';
import { hintMessages } from './constants';
import Thread from './thread';

export default class AssistantService extends CFService {
  private thread: Thread | null = null;
  private assistantRepository: CFAssistantRepository;

  constructor(assistantRepository: CFAssistantRepository) {
    super();

    this.assistantRepository = assistantRepository;
  }

  async init() {
    //
  }

  async getHintMessages(): Promise<HintMessage[]> {
    return hintMessages;
  }

  async getThread() {
    if (!this.thread) {
      this.thread = new Thread(this.assistantRepository);

      await this.thread.init();
    }

    return this.thread;
  }
}
