import React from 'react';
import ArmAssignments from './ArmAssignments';
import ArmConfidence from './ArmConfidence';

import ArmInfoContext from './useArmsInfo';

import './arms-info.scss';

interface Props {
  interventionId: number;
}

const ArmsInfo = ({ interventionId }: Props) => {
  return (
    <ArmInfoContext>
      <div className="arms-info">
        <ArmAssignments interventionId={interventionId} />

        <ArmConfidence interventionId={interventionId} />
      </div>
    </ArmInfoContext>
  );
};

export default ArmsInfo;
