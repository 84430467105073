import React, { useMemo, forwardRef, useEffect, useState, useCallback, useImperativeHandle, useRef } from 'react';
import { faRotate } from '@fortawesome/free-solid-svg-icons';

import { ColAddr, Trait, TraitSubject, TraitUsage } from 'domain/traits.types';
import { CFRole } from 'domain/general.types';

import CohortService from 'services/cohort/cohort.service';
import { createTraitCode } from 'services/traits/helpers.traits';
import Cohort from 'services/cohort/domain/Cohort';
import TraitService from 'services/traits/traitSession.service';

import { useInterventionContext } from 'views/intervention/useContext';

import InterventionSection from '../interventionSection';
import CFTitledComponent, { TitleSize } from 'components/CFTitledComponent';
import CFTitledSection, { SectionAction } from 'components/CFTitledSection';
import { ColumnType, Column } from 'components/CFTable';
import CFButton from 'components/buttons/CFButton';
import TraitListItem from './TraitsListItem';
import SampleVSCohortComparator from './SampleVSCohortComparator';

import CFCheckbox from 'components/CFCheckbox';
import TimeWindowPicker, { TimeWindowPickerRef } from 'components/TimeWindowPicker';

import TraitsTable from 'connected-components/TraitsTable';
import CFTraitItem from 'connected-components/traits/CFTraitItem';

import { Steps } from '..';

import { globalTableHeader } from './constants';

import { InterventionMetricsPolicyRef } from './metrics-policy.typings';

import './metrics-policy.scss';

interface Props {
  subject: TraitSubject | undefined;
  sampleId?: string;
  cohortService: CohortService;
  traitService: TraitService;
  onReady: (ready: boolean) => void;
}

const InterventionRestlessMetricsPolicy = forwardRef<InterventionMetricsPolicyRef, Props>(
  function InterventionABMetricsPolicy({ subject, cohortService, traitService, onReady }: Props, ref) {
    const monitoringWindowRef = useRef<TimeWindowPickerRef | null>();

    const [totalTraits, setTotalTraits] = useState<Trait[]>([]);
    const [selected, setSelected] = useState<Trait[]>([]);

    const { regenerateSample, sampling, cohort, selectedNudges, sampleId } = useInterventionContext();

    useEffect(() => {
      (async () => {
        if (!subject) {
          return;
        }

        const traits = await traitService.getTraits({ subject, usage: TraitUsage.ABMetric });

        setTotalTraits(traits);
      })();
    }, [subject]);

    useEffect(() => {
      /*
      Do not add onReady as dependency to avoid multiple re-renderers
     */
      onReady(selected.length >= 1);
    }, [selected]);

    useImperativeHandle(ref, () => ({
      value() {
        const metrics: ColAddr[] = selected.map((trait) => trait.addr);

        return {
          restless: {
            metrics,
            decision_time_window: monitoringWindowRef.current?.value() as number,
          },
        };
      },
    }));

    const primaryTableHeader: Column[] = useMemo(
      () => [
        {
          title: '',
          field: 'id',
          type: ColumnType.NUMBER,
          style: { width: '30px' },
          renderCell: (row) => (
            <CFCheckbox
              checked={selected.some((trait) => createTraitCode(trait) === createTraitCode(row as Trait))}
              onChange={() => handleSelectTrait(row)}
            />
          ),
        },
        {
          title: 'Title',
          field: 'display_name',
          style: { minWidth: '300px' },
          type: ColumnType.STRING,
          renderCell: (row) => {
            return <CFTraitItem addr={row.addr} />;
          },
        },
        ...globalTableHeader,
        {
          title: '',
          field: 'expanded',
          type: ColumnType.OBJECT,
          expandable: true,
          style: {},
          renderCell: (row) => (
            <SampleVSCohortComparator
              addr={row.addr}
              cohort={cohort as Cohort}
              sampleId={sampleId}
              cohortService={cohortService}
              groups={selectedNudges.map((nudge) => nudge.name)}
            />
          ),
        },
      ],
      [selected, cohort, sampleId, cohortService, selectedNudges]
    );

    const handleSelectTrait = useCallback((row: Record<string, any>) => {
      const currentTrait = row as Trait;
      setSelected((prevSelected) => {
        const isExist = prevSelected.some((trait) => createTraitCode(trait) === createTraitCode(currentTrait));

        if (isExist) {
          return prevSelected.filter((trait) => createTraitCode(trait) !== createTraitCode(currentTrait));
        } else {
          return [...prevSelected, currentTrait];
        }
      });
    }, []);

    const metricsTableData = useMemo(
      () => ({
        header: primaryTableHeader,
        data: totalTraits,
        subTitle: 'Choose the metric for the intervention algorithm to optimize.',
        title: 'Metrics',
      }),
      [totalTraits, selected, cohort, sampleId, cohortService, selectedNudges]
    );

    const handleGenerateSample = useCallback(() => {
      regenerateSample();
    }, []);

    return (
      <InterventionSection name={Steps.Metrics} title={'Metrics'}>
        <SectionAction>
          <CFButton
            value="Regenerate Sample"
            iconName={faRotate}
            role={CFRole.Primary}
            onClick={handleGenerateSample}
            isLoading={sampling}
            disabled={!sampleId}
          />
        </SectionAction>

        <div className="metrics-policy intervention-section">
          <TraitsTable
            selected={selected}
            handleSelectTrait={(row) => handleSelectTrait(row)}
            traits={metricsTableData.data}
          />

          <CFTitledSection nested={true} title={`Selected Data (${selected.length})`}>
            <div className="selected-metrics-section">
              <CFTitledComponent title={`Metrics`} size={TitleSize.Big}>
                <div className="trait-list">
                  {selected.length ? (
                    selected.map((trait) => (
                      <TraitListItem
                        cohortService={cohortService}
                        sampleId={sampleId}
                        trait={trait}
                        onDelete={(trait) => handleSelectTrait(trait)}
                        key={createTraitCode(trait)}
                      />
                    ))
                  ) : (
                    <div className="empty-label">{`No Metrics Selected`}</div>
                  )}
                </div>
              </CFTitledComponent>
            </div>
          </CFTitledSection>

          <CFTitledComponent title="Monitoring window">
            <TimeWindowPicker ref={(el) => (monitoringWindowRef.current = el)} />
          </CFTitledComponent>
        </div>
      </InterventionSection>
    );
  }
);

export default InterventionRestlessMetricsPolicy;
