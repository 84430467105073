import React, { useEffect, useRef, useState } from 'react';

import { UserDetailedInfo } from 'types';
import { CFRoutes } from 'routes';
import useCFNavigation from 'hooks/useCFNavigation';

import { ProjectRole, Role } from 'services/admin/users/user.types';
import { CFRole } from 'domain/general.types';

import { invite, listRoles } from 'services/admin/users/users.repo';

import { AuthAction, isAllowedTo } from 'services/authorization.service';
import { getOrganization } from 'services/session/session.service';

import AdminLayout from 'views/admin/layout';
import { Tabs } from 'views/admin/tabs';

import CFButton from 'components/buttons/CFButton';
import CFInput from 'components/CFInput';
import CFTitledComponent from 'components/CFTitledComponent';
import CFRolesEditor, { CFRolesEditorRef } from 'components/CFRolesEditor';

import './invite-user.scss';

interface Props {
  userDetailedInfo: UserDetailedInfo;
  orgId: number;
}

const InviteUser = ({ userDetailedInfo, orgId }: Props) => {
  const navigate = useCFNavigation();
  const emailInputRef = React.createRef<HTMLInputElement>();
  const rolesEditorRef = useRef<CFRolesEditorRef>(null);

  const projectRoles = [ProjectRole.User, ProjectRole.InterventionCreator];
  const [availableRoles, setAvailableRoles] = useState<Role[]>([]);
  const [availableAdminRoles, setAvailableAdminRoles] = useState<Role[]>([]);

  useEffect(() => {
    (async () => {
      const roles = await listRoles();

      setAvailableRoles(roles.roles);
      setAvailableAdminRoles(roles.admin_roles);
    })();
  }, []);

  if (isAllowedTo(AuthAction.AddViewerRole)) {
    projectRoles.push(ProjectRole.Viewer);
  }

  const handleCancelInvite = async () => {
    navigate(CFRoutes.admin_users);
  };

  const handleInvite = async () => {
    if (!emailInputRef || !emailInputRef.current?.value) {
      return;
    }

    const newRoles = rolesEditorRef.current?.value();

    if (!newRoles) {
      return;
    }

    try {
      await invite(emailInputRef.current.value.trim(), newRoles);
    } catch (err) {
      console.log('error inviting user');
      return;
    }

    navigate(CFRoutes.admin_users);
  };

  const org = getOrganization();

  return (
    <AdminLayout tab={Tabs.Users}>
      <div className="invite-user-container">
        <CFTitledComponent title={'Email'} className="input">
          <CFInput ref={emailInputRef} />
        </CFTitledComponent>

        <div className="section-title"> Organization and project roles </div>

        <CFRolesEditor
          ref={rolesEditorRef}
          orgprojs={userDetailedInfo.available_orgprojs}
          orgId={orgId}
          currentRoles={{
            oid: parseInt(org || '-1'),
            org_roles: [],
            proj_roles: {},
          }}
          currentAdminRoles={[]}
          availableRoles={availableRoles}
          availableAdminRoles={availableAdminRoles}
          includeAdmin={false}
        />

        <div className="controls">
          <CFButton value={'Cancel'} onClick={handleCancelInvite} />
          <CFButton value={'Invite'} onClick={handleInvite} role={CFRole.Primary} />
        </div>
      </div>
    </AdminLayout>
  );
};

export default InviteUser;
