import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';

import CFButton from 'components/buttons/CFButton';
import { MENU_HEIGHT, NavigationAction } from '../DatetimeRangePicker/types';
import Month from './Month';
import TimezonePicker from '../TimezonePicker';
import TimePicker from '../TimePicker';

import { displayDate } from 'helpers/dates';
import { CFRole } from 'domain/general.types';

import { useClickOutside } from 'hooks';

import './date-time-picker.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { parseOptionalDate } from '../DatetimeRangePicker/helper';

export interface DatetimePickerRef {
  value: () => string;
}

interface DatetimePickerProps {
  initialDate?: Date;
  defaultTimezone?: string;
  minDate?: Date | string;
  maxDate?: Date | string;
  showTime?: boolean;
  format?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange: (date: Date, timezone: string) => void;
}

const DatetimePicker = forwardRef<DatetimePickerRef, DatetimePickerProps>(function DatetimePicker(
  { initialDate, defaultTimezone, minDate, maxDate, placeholder, showTime = false, format, disabled = false, onChange },
  ref
) {
  const today = new Date();

  const [open, setOpen] = useState(false);
  const [timezone, setTimezone] = useState(defaultTimezone ?? dayjs.tz.guess());
  const [time, setTime] = useState<string>(dayjs(new Date()).format('HH:mm'));

  const minDateValid = parseOptionalDate(minDate, dayjs(today).subtract(10, 'years').toDate());

  const maxDateValid = parseOptionalDate(maxDate, dayjs(today).add(10, 'years').toDate());

  const [currentMonth, setCurrentMonth] = useState<Date>(initialDate ?? today);

  const [selectedDate, setSelectedDate] = useState<Date>(initialDate ?? today);

  const [hoverDay, setHoverDay] = useState<Date>();

  const [formattedDate, setFormattedDate] = useState<string>(() => {
    if (initialDate) {
      return displayDate(initialDate, dayjs(initialDate).format('HH:mm'), true, true, timezone, format);
    }
    return '';
  });
  const [displayValue, setDisplayValue] = useState<string>(() => {
    if (initialDate) {
      return displayDate(initialDate, dayjs(initialDate).format('HH:mm'), true, true, timezone);
    }
    return '';
  });

  const menuRef = useRef<HTMLDivElement>(null);

  const [inputRect, setInputRect] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });

  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });

  const [adjustedPosition, setAdjustedPosition] = useState({
    left: 0,
    top: 0,
    opacity: 0,
  });

  useImperativeHandle(ref, () => ({
    value() {
      return formattedDate;
    },
  }));

  useClickOutside(menuRef, () => {
    if (open) {
      setOpen(false);
    }
  });

  const onDayClick = useCallback(
    (day: Date) => {
      setSelectedDate(day);
    },
    [onChange]
  );

  const onDayHover = useCallback(
    (date: Date) => {
      if (!hoverDay || !dayjs(date).isSame(hoverDay)) {
        setHoverDay(date);
      }
    },
    [hoverDay]
  );

  const onMonthNavigate = useCallback(
    (action: NavigationAction) => {
      const newMonth = dayjs(currentMonth).add(action, 'month').toDate();
      setCurrentMonth(newMonth);
    },
    [currentMonth]
  );

  const handleChangeTimezone = (tz: string) => {
    setTimezone(tz);
  };

  useEffect(() => {
    const startTime = showTime ? time : '00:00';
    const convertedDate = displayDate(selectedDate, startTime, showTime, true, timezone, format);
    setDisplayValue(displayDate(selectedDate, startTime, showTime, true, timezone));
    setFormattedDate(convertedDate);
    const newDate = dayjs(convertedDate).local().toDate();

    setSelectedDate(newDate);
    setTimezone(timezone);
  }, []);

  const isHover = useCallback(
    (day: Date): boolean => {
      return !!hoverDay && dayjs(hoverDay).isSame(day, 'day');
    },
    [hoverDay]
  );

  const helpers = useMemo(() => ({ isHover }), [isHover]);

  const handleApply = () => {
    const startTime = showTime ? time : '00:00';

    const convertedDate = displayDate(selectedDate, startTime, showTime, true, timezone, format);

    setDisplayValue(displayDate(selectedDate, startTime, showTime, true, timezone));
    setFormattedDate(convertedDate);

    const newDate = dayjs(convertedDate).local().toDate();

    setSelectedDate(newDate);
    onChange(newDate, timezone);

    setOpen(false);
  };

  const handlers = useMemo(
    () => ({
      onDayClick,
      onDayHover,
      onMonthNavigate,
    }),
    [onDayClick, onDayHover, onMonthNavigate]
  );

  useEffect(() => {
    adjustMenuPosition();
  }, [menuPosition]);

  const adjustMenuPosition = () => {
    const menuRect = menuRef.current?.getBoundingClientRect();

    if (!menuRect || !inputRect) {
      return;
    }

    const { innerWidth, innerHeight } = window;
    const buffer = 8; // add a small buffer to avoid the edges of the viewport

    const newMenuPosition = { ...menuPosition };
    // Check if the menu overflows to the right
    if (inputRect.left + menuRect.width + buffer > innerWidth) {
      newMenuPosition.left = inputRect.width - menuRect.width;
    } else {
      newMenuPosition.left = 0;
    }

    // Check if the menu overflows to the bottom
    if (
      inputRect.top + inputRect.height + menuRect.height + buffer > innerHeight &&
      inputRect.top - menuRect.height > MENU_HEIGHT
    ) {
      newMenuPosition.top = -menuRect.height - buffer;
    } else {
      newMenuPosition.top = inputRect.height + buffer;
    }

    setAdjustedPosition({ ...newMenuPosition, opacity: 1 });
  };

  return (
    <div className="date-time-picker">
      <input
        className="date-time-input"
        value={displayValue}
        readOnly
        disabled={disabled}
        onClick={(e) => {
          const rect = e.currentTarget.getBoundingClientRect();
          setInputRect(rect);
          setMenuPosition({ left: rect.left, top: rect.bottom });
          setOpen(true);
        }}
        onFocus={() => {
          if (selectedDate) onChange(selectedDate, timezone);
        }}
        placeholder={placeholder ?? 'YYYY-MM-DD'}
        data-testid="datetime-picker"
      />
      <FontAwesomeIcon className="date-time-picker-icon" icon={icon({ name: 'calendar', style: 'regular' })} />
      {open && (
        <div ref={menuRef} className={`menu-container`} style={adjustedPosition}>
          <div className="menu-months-container">
            <Month
              date={selectedDate}
              minDate={minDateValid}
              maxDate={maxDateValid}
              helpers={helpers}
              handlers={handlers}
              value={currentMonth}
              setValue={setCurrentMonth}
            />
          </div>
          {showTime && (
            <div className="menu-times-container">
              <div className="menu-time-picker-container">
                <div className="menu-time-picker">
                  <span>Set a time</span>
                  <TimePicker value={dayjs(formattedDate).tz(timezone).format('HH:mm')} onChange={(t) => setTime(t)} />
                </div>
              </div>
              <div className="menu-timezone-picker-container">
                <TimezonePicker onSelect={handleChangeTimezone} selectedTimezone={timezone} />
              </div>
            </div>
          )}

          <div className="menu-footer">
            <CFButton value="Cancel" onClick={() => setOpen(false)} />
            <CFButton value="Apply" role={CFRole.Cyan} onClick={handleApply} />
          </div>
        </div>
      )}
    </div>
  );
});

export default DatetimePicker;
