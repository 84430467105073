import React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { createSearchParams, useSearchParams } from 'react-router-dom';
import { CFRoutes } from 'routes';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import useCFNavigation, { sessionQueryInfo } from 'hooks/useCFNavigation';

import CFTitledComponent from 'components/CFTitledComponent';
import CFButton from 'components/buttons/CFButton';
import CFCard from 'components/CFCard';
import CFPaginatedList from 'components/CFPaginatedList';
import CFSelectLegacy, { SelectableItem } from 'components/CFSelectLegacy';
import CFInput from 'components/CFInput';

import { InternalCohort } from 'services/cohort/cohort.types';
import { CFRole } from 'domain/general.types';
import { Trait, TraitSubject, TraitUsage } from 'domain/traits.types';

import { preview as previewCohort } from 'services/cohort/cohort.repo';

import CFTextarea from 'components/textarea/CFTextarea';

import TraitService from 'services/traits/traitSession.service';
import useToast from 'hooks/useToast';
import { ToastType } from 'components/CFToast/types';

import { useServicesContext } from 'hooks/useServicesContext';

import pagePlusIcon from 'assets/icons/pagePlus.svg';

import TreeBuilder, { TreeBuilderRef } from 'connected-components/TreeBuilder';

import './new-cohort.scss';
import Cohort from 'services/cohort/domain/Cohort';

interface Props {
  traitService: TraitService;
  defaultSubject?: TraitSubject;
}

const NewCohortContent = ({ defaultSubject, traitService }: Props) => {
  const navigate = useCFNavigation();
  const { addToast } = useToast();
  const [searchParams] = useSearchParams();

  const { cohortService } = useServicesContext();
  const [traits, setTraits] = useState<Trait[]>([]);

  const [totalSubjects, setTotalSubjects] = useState(-1);

  const [subjects, setSubjects] = useState<string[]>([]);
  const [selectedSubject, setSelectedSubject] = useState<TraitSubject>(defaultSubject || TraitSubject.User);
  const [previewReady, setPreviewReady] = useState(false);
  const [description, showDescription] = useState(false);
  const [curPage, setCurPage] = useState<number>(0);
  const [curPageSize, setCurPageSize] = useState<number>(10);
  const [sourceCohort, setSourceCohort] = useState<Cohort>();

  const nameInputRef = React.createRef<HTMLInputElement>();
  const descriptionInputRef = React.createRef<HTMLTextAreaElement>();

  const treeRef = useRef<TreeBuilderRef>() as React.MutableRefObject<TreeBuilderRef>;

  useEffect(() => {
    (async () => {
      const clonedId = searchParams.get('from');

      if (!clonedId) {
        return;
      }

      const cohortToClone = await cohortService.getRemoteCohort(parseInt(clonedId));

      if (!cohortToClone) {
        addToast('Impossible to clone unknown cohort', ToastType.ERROR);
      }

      setSourceCohort(cohortToClone);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const traits = await traitService.getTraits({ subject: selectedSubject, usage: TraitUsage.Cohort });
      setTraits(traits);
      setPreviewReady(false);
    })();
  }, [selectedSubject]);

  useEffect(() => {
    downloadPage();
  }, [curPage, curPageSize]);

  const handleCohortCreate = async () => {
    const cohort: InternalCohort = {
      tree: treeRef.current.value(),
      subject_type: selectedSubject,
      name: nameInputRef.current?.value || '',
      description: descriptionInputRef.current?.value || '',
    };

    try {
      await cohortService.create(cohort);
    } catch (e: any) {
      addToast(`Error creating cohort: ${e.message}`, ToastType.ERROR, 5000);
      return;
    }
    addToast(`Cohort created`, ToastType.SUCCESS, 5000);

    navigate({
      pathname: CFRoutes.segmentation_cohort,
      search: `?${createSearchParams({ subject: selectedSubject, ...sessionQueryInfo() })}`,
    });
  };

  const downloadPage = useCallback(async () => {
    const tree = treeRef.current.value();

    if (tree.tree.nodes.length === 0) {
      return { data: [], total: 0 };
    }

    const { total, data } = await previewCohort(tree, curPage, curPageSize);

    setSubjects(data);
    setPreviewReady(true);

    return { data: data || [], total };
  }, [curPage, curPageSize, treeRef.current]);

  const handleCohortPreview = async () => {
    if (!isFilterGroupReady()) {
      addToast('Incorrect filters', ToastType.ERROR);
      return;
    }

    const { total } = await downloadPage();
    setTotalSubjects(total);
  };

  const isFilterGroupReady = (): boolean => {
    console.log(treeRef.current.value());
    if (!treeRef.current || treeRef.current.value().getLegacy().nodes.length === 0) {
      return false;
    }

    for (const { leaf } of treeRef.current.value().getLegacy().nodes) {
      if (leaf && leaf.filters.length === 0) {
        return false;
      }
    }

    return true;
  };

  const handleNewPageRequest = (page: number, size: number) => {
    setCurPage(page - 1);
    setCurPageSize(size);
  };

  const handleSubjectSelected = async ([subject]: SelectableItem[]) => {
    setSelectedSubject(subject.value as TraitSubject);
  };

  const handleAddDescription = () => {
    showDescription((prev) => !prev);
  };

  let paginatedSubjectList = <div></div>;

  if (totalSubjects !== -1 && totalSubjects !== 0 && previewReady) {
    paginatedSubjectList = (
      <div>
        <div className="total-text"> Total: {totalSubjects} </div>
        <CFPaginatedList total={totalSubjects} onPageChange={handleNewPageRequest}>
          {subjects.map((subject) => (
            <div key={subject}> {subject}</div>
          ))}
        </CFPaginatedList>
      </div>
    );
  }

  if (totalSubjects === 0) {
    paginatedSubjectList = <div> No data for this query</div>;
  }

  const defaultCohortName = useMemo(() => {
    if (!sourceCohort) {
      return '';
    } else {
      return `${sourceCohort.name} (cloned)`;
    }
  }, [sourceCohort]);

  return (
    <div className="new-cohort-content">
      <div className="subhead"> New cohort</div>
      <div className="main-cohort-info">
        <CFTitledComponent title="Cohort name">
          <CFInput ref={nameInputRef} defaultValue={defaultCohortName} />
        </CFTitledComponent>

        <CFTitledComponent title="Subject">
          <CFSelectLegacy
            defaultOption={[{ label: selectedSubject, value: selectedSubject }]}
            options={traitService.getTraitsSubjects().map((item) => ({ label: item, value: item }))}
            onSelected={handleSubjectSelected}
          />
        </CFTitledComponent>
      </div>

      {description ? (
        <CFTitledComponent title="Description">
          <CFTextarea ref={descriptionInputRef} />
        </CFTitledComponent>
      ) : (
        <CFButton value="Add Description" role={CFRole.Borderless} iconName={faPlus} onClick={handleAddDescription} />
      )}

      <div className="subhead"> Definition of cohort </div>

      <TreeBuilder
        ref={treeRef}
        traits={traits}
        subject={selectedSubject}
        defaultValue={sourceCohort?.tree.getLegacy()}
      />

      <br />

      <CFButton value="Preview cohort" onClick={handleCohortPreview} />

      <CFCard>{paginatedSubjectList}</CFCard>

      <CFButton
        value="Create Cohort"
        onClick={handleCohortCreate}
        disabled={!previewReady || nameInputRef.current?.value.trim() === ''}
        role={CFRole.Primary}
        iconCustom={<img src={pagePlusIcon} />}
      />
    </div>
  );
};

export default NewCohortContent;
