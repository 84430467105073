import React, { createContext, useState, useContext, useRef, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import Cohort from 'services/cohort/domain/Cohort';
import { isAggLevel } from 'services/traits/helpers.traits';
import TraitConfigManager from '../services/trait-config';

import usersRepo from 'services/admin/users/users.repo';

import { useServicesContext } from 'hooks/useServicesContext';
import { useToast } from 'hooks';

import { ColAddr, TraitSubject } from 'domain/traits.types';
import { Module, TimeRFC3999 } from 'domain/general.types';
import { AggLevel, TimeSeriesItem } from 'domain/stats.types';

import { ToastType } from 'components/CFToast/types';

import useInitializedSubject from '../hooks/useInitializedSubject';
import useInitializedModule from '../hooks/useInitializedModule';
import useInitializedCohorts from '../hooks/useInitializedCohorts';

import useInitializedRange from '../hooks/useInitializedRange';
import { sessionQueryInfo } from 'hooks/useCFNavigation';
import { URL_PARAMS } from '../constants';
import { roundEdges } from 'helpers/dates';
import { Ptr } from 'services/cohort/cohort.types.api';
import { NormalizationType } from '../types';

const PAGE_SIZE = 100;

interface AnalyticsContextValue {
  traitConfigManager: TraitConfigManager | undefined;
  selectedCharts: Ptr[];

  module: Module;
  setModule: React.Dispatch<React.SetStateAction<Module>>;

  subject: TraitSubject;
  setSubject: React.Dispatch<React.SetStateAction<TraitSubject>>;

  aggLevel: AggLevel;
  setAggLevel: React.Dispatch<React.SetStateAction<AggLevel>>;

  startDate: TimeRFC3999;
  setStartDate: React.Dispatch<React.SetStateAction<TimeRFC3999>>;

  endDate: TimeRFC3999;
  setEndDate: React.Dispatch<React.SetStateAction<TimeRFC3999>>;

  selectedCohortIDs: Ptr[];
  setSelectedCohortIDs: React.Dispatch<React.SetStateAction<Ptr[]>>;

  selectTrait: (ptr: Ptr) => Promise<void>;
  getSerie: (ptr: Ptr, cohortId: string) => Promise<TimeSeriesItem[]>;

  normalizationType: NormalizationType;
  setNormalizationType: React.Dispatch<React.SetStateAction<NormalizationType>>;

  availableCohorts: Cohort[] | null;
}

const AnalyticsContext = createContext<AnalyticsContextValue | undefined>(undefined);

interface Props extends React.PropsWithChildren {}

const ContextProvider: React.FC<Props> = ({ children }) => {
  const traitConfigManager = useRef<TraitConfigManager>();

  const { traitSessionService: traitService, cohortService } = useServicesContext();
  const [searchParams, setSearchParams] = useSearchParams();

  let defaultCharts: string[] = [];

  if (
    searchParams.get('trait') === '' ||
    searchParams.get('trait') === undefined ||
    searchParams.get('trait') === null
  ) {
    defaultCharts = [];
  } else {
    defaultCharts = searchParams.get('trait')?.split(',') as string[];
  }

  const [subject, setSubject] = useInitializedSubject();
  const [module, setModule] = useInitializedModule();
  const [selectedCohortIDs, setSelectedCohortIDs] = useInitializedCohorts();

  const [aggLevel, setAggLevel] = useState<AggLevel>(AggLevel.Day);

  const [startDate, endDate, setStartDate, setEndDate] = useInitializedRange();

  const [selectedCharts, setSelectedCharts] = useState<Ptr[]>(defaultCharts);

  const [availableCohorts, setAvailableCohorts] = useState<Cohort[] | null>(null);
  const initTimeseriePromise = useRef<Record<string, Promise<TimeSeriesItem[]>>>({});

  const [normalizationType, setNormalizationType] = useState(NormalizationType.CohortSubjects);

  const { addToast } = useToast();

  const generateTimeseriesCode = (cohortId: string, ptr: Ptr) => {
    return `${cohortId}-${ptr}-${startDate}-${endDate}`;
  };

  const generateTimeSeries = async (ptr: Ptr) => {
    try {
      for (const cohortID of selectedCohortIDs) {
        const timeSeriesKey = generateTimeseriesCode(cohortID, ptr);

        if (initTimeseriePromise.current[timeSeriesKey] !== undefined) {
          continue;
        }

        initTimeseriePromise.current[timeSeriesKey] = traitService.getTimeseries(
          startDate,
          endDate,
          traitService.getTraitDefinition(ptr)?.addr as ColAddr,
          cohortID
        );
      }
    } catch (error: any) {
      // TODO: should a context be responsible for showing an error
      addToast(`Couldn't load the charts data ${error.message}`, ToastType.ERROR, 5000);
    }
  };

  useEffect(() => {
    setSearchParams({
      ...sessionQueryInfo(),
      [URL_PARAMS.Subject]: subject,
      [URL_PARAMS.Module]: module,
      [URL_PARAMS.Cohort]: selectedCohortIDs.join(','),
      [URL_PARAMS.Trait]: selectedCharts.join(','),
      [URL_PARAMS.StartDate]: startDate,
      [URL_PARAMS.EndDate]: endDate,
    });
  }, [subject, module, selectedCohortIDs, selectedCharts, startDate, endDate]);

  useEffect(() => {
    traitConfigManager.current = new TraitConfigManager(usersRepo, traitService);

    traitConfigManager.current.subscribe((ptrs) => {
      const filteredPtrs = ptrs.filter((ptr) => isAggLevel(traitService.getTraitDefinition(ptr).addr, aggLevel)); // needed???

      setSelectedCharts(filteredPtrs);
    });

    // TODO: unscribe on output
  }, [aggLevel]);

  useEffect(() => {
    (async () => {
      if (!traitConfigManager || !traitConfigManager.current) {
        return;
      }

      let ptrs = await traitConfigManager.current.getConfiguredTraits(subject, module);

      ptrs = ptrs.filter((ptr) => {
        const trait = traitService.getTraitDefinition(ptr);
        if (!trait) {
          return false;
        }
        return isAggLevel(trait.addr, aggLevel);
      });

      setSelectedCharts(ptrs);
    })();
  }, [traitConfigManager, module, subject, aggLevel]);

  useEffect(() => {
    selectedCharts.forEach((traitName) => {
      generateTimeSeries(traitName);
    });
  }, [selectedCohortIDs, selectedCharts]);

  useEffect(() => {
    (async () => {
      const cohortList = await cohortService.getListOfCohorts(0, PAGE_SIZE, subject);
      const cohortListFiltered = cohortList.data.filter(
        (cohort) => cohort.modules.includes(module) || cohort.modules.includes('core')
      );
      setAvailableCohorts(cohortListFiltered);
    })();
  }, [subject, module]);

  const selectTrait = useCallback(
    async (ptr: Ptr) => {
      if (!traitConfigManager || !traitConfigManager.current) {
        return;
      }

      if (selectedCharts.includes(ptr)) {
        await traitConfigManager.current.removeChart(ptr, subject, module);
      } else {
        await generateTimeSeries(ptr);
        await traitConfigManager.current.addChart(ptr, subject, module);
      }
    },
    [traitConfigManager, selectedCharts, subject, module]
  );

  const getSerie = async (cohortId: string, ptr: Ptr) => {
    const code = generateTimeseriesCode(cohortId, ptr);

    const [roundedStart, roundedEnd] = roundEdges(startDate, endDate, aggLevel);

    if (initTimeseriePromise.current[code] === undefined) {
      initTimeseriePromise.current[code] = traitService.getTimeseries(
        roundedStart,
        roundedEnd,
        traitService.getTraitDefinition(ptr)?.addr as ColAddr,
        cohortId
      );
    }

    const timeserie = await initTimeseriePromise.current[code];

    return timeserie || [];
  };

  return (
    <AnalyticsContext.Provider
      value={{
        traitConfigManager: traitConfigManager.current,
        selectedCharts,
        module,
        setModule,

        subject,
        setSubject,

        aggLevel,
        setAggLevel,

        startDate,
        setStartDate,

        endDate,
        setEndDate,

        selectedCohortIDs,
        setSelectedCohortIDs,
        availableCohorts,

        selectTrait,
        getSerie,

        normalizationType,
        setNormalizationType,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalyticsContext = (): AnalyticsContextValue => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error('useAnalyticsContext must be used within an AnalyticsContextProvider');
  }
  return context;
};

export default ContextProvider;
