import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';

import { PtrWithWindows } from 'domain/model.types';
import { Trait, TraitUsage } from 'domain/traits.types';

import InterventionSection from 'views/intervention/interventions/NewIntervention/interventionSection';

import CFLoadWrapper from 'components/CFLoadWrapper';

import { Steps, useModelContext } from '../../useContext';

import FeaturePicker, { FeaturePickerRef, FeaturePickerTypes } from '../components/FeaturePicker';

import './features.scss';

interface Props {}

export interface CensoringModelFeaturesRef {
  value: () => {
    dynamic_features: PtrWithWindows[];
  };
}

const CensoringModelFeatures = forwardRef<CensoringModelFeaturesRef, Props>(function CensoringModelFeatures(
  {}: Props,
  ref
) {
  const { updateReadiness } = useModelContext();

  const [isReady, setIsReady] = useState(false);

  const featuresPickerRef = useRef<FeaturePickerRef>() as React.MutableRefObject<FeaturePickerRef>;

  useImperativeHandle(ref, () => ({
    value() {
      return {
        dynamic_features: featuresPickerRef.current.dynamic,
      };
    },
  }));

  const handleFeaturesChanged = useCallback((staticTraits: Trait[], dynamicTraits: Trait[]) => {
    const isFeatureReady = staticTraits.length !== 0 && dynamicTraits.length !== 0;
    updateReadiness(isFeatureReady, Steps.Features);
  }, []);

  return (
    <CFLoadWrapper isLoading={!isReady}>
      <InterventionSection name={Steps.Features} title={'Features'} subtitle="Add features to your model">
        <FeaturePicker
          ref={featuresPickerRef}
          onReady={() => setIsReady(true)}
          onChange={handleFeaturesChanged}
          usage={TraitUsage.CensoringMetric}
          types={[FeaturePickerTypes.Static, FeaturePickerTypes.Dynamic]}
        />
      </InterventionSection>
    </CFLoadWrapper>
  );
});

export default CensoringModelFeatures;
