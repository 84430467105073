import React from 'react';

import AssistantInternal from './assistant';
import MessagesContext from './context/useMessagesContext';

interface Props {
  embedded?: boolean;
}

const Assistant = ({ embedded = false }: Props) => {
  return (
    <MessagesContext embedded={embedded}>
      <AssistantInternal />
    </MessagesContext>
  );
};

export default Assistant;
