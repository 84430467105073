import React, { Children } from 'react';

import './cf-nav-list.scss';

interface Props {
  className?: string;
  titles: string[];
  selected: string;
  children?: JSX.Element | JSX.Element[] | boolean;
  onClick: (title: string) => void;
  style?: 'default' | 'buttons' | 'no-underline';
}

export const NavListAction = (props: any) => {
  return <div className="nav-list-action">{props.children}</div>;
};

export const CFNavList = ({ titles, selected, onClick, children, style = 'default', className = '' }: Props) => {
  const classes = ['cf-nav-list', className];

  classes.push(`style-${style}`);

  const arrayChildren = Children.toArray(children);
  const actions = arrayChildren.filter((child) => (child as any).type.name === NavListAction.name);

  return (
    <div className={classes.join(' ')}>
      {titles.map((title) => (
        <span
          key={title.split(' ').join('-')}
          className={['nav-list-item', title.toLowerCase() === selected.toLowerCase() ? 'selected' : ''].join(' ')}
          onClick={() => onClick(title.toLowerCase().split(' ').join('-'))}
        >
          {title}
        </span>
      ))}
      <div className="actions">{actions}</div>
    </div>
  );
};
