import React from 'react';

import './cf-text.scss';

interface Props {
  text: string;
}

const CFText = ({ text }: Props) => {
  return <div className="cf-text"> {text} </div>;
};

export default CFText;
