import { CFStorage, KEY } from 'repositories/storage.localstorage';
import { OrgInfo, ProjectDefinition, UserDetailedInfo } from 'types';

export interface CFSessionService {
  init: (storageRepository: CFStorage) => void;
  clearSession: () => void;
  setDetailedUserInfo: (userInfo: UserDetailedInfo) => void;
  getUserInfo: () => UserDetailedInfo;
  getProject: () => any;
  getCurrentProject: () => ProjectDefinition | undefined;
  setProject: (value: string) => void;
  getOrganization: () => string | null;
  setOrganization: (value: string) => void;
  getToken: () => string | null;
  setToken: (value: string) => void;
  getAuthToken: () => string | null;
  setAuthToken: (value: string) => void;
  startBackfilling: () => void;
  stopBackfilling: () => void;
  isBackfilling: () => void;
}

let userDetailedInfo = {};
let token: string | null = null;
let project: string | null = null;
let org: string | null = null;

type SessionKey = string;
type Callback = (value: any) => void;

const callbacks: Record<SessionKey, Callback[]> = {};

const customValues: Record<string, any> = {};

const proxy = new Proxy(customValues, {
  set: function (obj: Record<string, any>, prop: string, value: any) {
    for (const cb of callbacks[prop] || []) {
      try {
        cb(value);
      } catch {
        console.error('error populating key update: ', prop);
      }
    }

    obj[prop] = value;
    return true;
  },
});

// do not save this in localstorage since
// if the user refreshes the page, we won't
// have feedback from backend and the app may keep
// thinking that the platform is still backfilling
let backfilling = false;

let storage: CFStorage;

export const init = (storageRepository: CFStorage) => {
  storage = storageRepository;
  token = storage.get(KEY.TOKEN_KEY);
  project = storage.get(KEY.PROJECT_KEY);
  org = storage.get(KEY.ORG_KEY);
};

export const clearSession = () => {
  storage.set(KEY.TOKEN_KEY, null);
  storage.set(KEY.PROJECT_KEY, null);
  storage.set(KEY.ORG_KEY, null);

  token = project = org = null;
};

export const setDetailedUserInfo = (userInfo: UserDetailedInfo) => {
  userDetailedInfo = userInfo;
};

export const getUserInfo = (): UserDetailedInfo => {
  return userDetailedInfo as UserDetailedInfo;
};

export const getProject = () => {
  return project;
};

export const getCurrentProject = (): ProjectDefinition | undefined => {
  const userInfo = getUserInfo();
  const currentOrgId = getOrganization() || '-1';

  const org = userInfo.available_orgprojs.find((orgproj) => orgproj.org.id === parseInt(currentOrgId));

  const project = (org as OrgInfo).projs.find((proj) => `${proj.id}` === getProject());

  return project;
};

export const isLoggedFor = (oid: number, pid: number): boolean => {
  const userInfo = getUserInfo();

  const org = userInfo.available_orgprojs.find((orgInfo) => orgInfo.org.id === oid);
  const proj = org?.projs.find((proj) => proj.id === pid);

  return proj !== undefined;
};

export const setProject = (value: string) => {
  storage.set(KEY.PROJECT_KEY, value);
  project = value;
};

export const getOrganization = () => {
  return org;
};

export const setOrganization = (value: string) => {
  storage.set(KEY.ORG_KEY, value);
  org = value;
};

export const getJWTToken = () => {
  return token;
};

export const setJWTToken = (value: string) => {
  storage.set(KEY.TOKEN_KEY, value);
  token = value;
};

export const getAuthToken = () => {
  return storage.get(KEY.AUTH_TOKEN_KEY);
};

export const setAuthToken = (value: string) => {
  storage.set(KEY.AUTH_TOKEN_KEY, value);
  token = value;
};

export const startBackfilling = () => {
  backfilling = true;
};

export const stopBackfilling = () => {
  backfilling = false;
};

export const isBackfilling = () => {
  return backfilling;
};

export const saveCustomValue = (key: string, value: any) => {
  customValues[key] = value;
};

export const getCustomValue = (key: string) => {
  return customValues[key];
};

export const setIncognitoMode = (isSet: boolean) => {
  proxy[KEY.INCOGNITO] = isSet;
};

export const isIncognitoMode = () => {
  return proxy[KEY.INCOGNITO] || false;
};

export const subscribe = (key: SessionKey, callback: Callback) => {
  if (!callbacks[key]) {
    callbacks[key] = [];
  }

  callbacks[key].push(callback);
};

export const unsubscribe = (key: SessionKey, callback: Callback) => {
  if (!callbacks[key]) {
    return;
  }

  const index = callbacks[key].findIndex((cb) => cb === callback);
  callbacks[key].splice(index, 1);
};

export default {
  init,
  clearSession,
  setDetailedUserInfo,
  getUserInfo,
  getProject,
  getCurrentProject,
  setProject,
  getOrganization,
  setOrganization,
  getToken: getJWTToken,
  setToken: setJWTToken,
  setAuthToken,
  getAuthToken,
  startBackfilling,
  stopBackfilling,
  isBackfilling,
  saveCustomValue,
  getCustomValue,
};
