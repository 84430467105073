import React, { FC } from 'react';
import dayjs from 'dayjs';

import './day.scss';
import classNames from 'classnames';

interface DayProps {
  filled?: boolean;
  outlined?: boolean;
  disabled?: boolean;
  highlighted?: boolean;
  hasEvents?: boolean;
  isCurrentMonth?: boolean;
  onClick?: () => void;
  onHover?: () => void;
  value: Date;
}

const Day: FC<DayProps> = ({
  filled,
  outlined,
  disabled,
  highlighted,
  hasEvents,
  isCurrentMonth,
  onClick,
  onHover,
  value,
}) => {
  return (
    <div className="date-time-day-container">
      <div
        className={classNames('day-wrapper', {
          highlighted: !disabled && isCurrentMonth && highlighted,
          hasEvents: isCurrentMonth && hasEvents,
          outlined: !disabled && isCurrentMonth && outlined,
          filled: isCurrentMonth && filled,
        })}
        onClick={onClick}
        onMouseOver={onHover}
        data-testid={dayjs(value).format('YYYY-MM-DD')}
      >
        <button className={`day-button `} disabled={disabled || !isCurrentMonth}>
          <span
            className={classNames('day-text', {
              contrast: !disabled && filled,
              invisible: !isCurrentMonth,
              disabled: disabled,
            })}
          >
            {dayjs(value).date()}
          </span>
        </button>
      </div>
    </div>
  );
};

export default Day;
