import { ToastType } from 'components/CFToast/types';
import { AppModel, ModelId } from 'domain/model.types';
import { useToast } from 'hooks';
import useCFNavigation from 'hooks/useCFNavigation';
import { useServicesContext } from 'hooks/useServicesContext';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CFRoutes } from 'routes';

const useModel = () => {
  const [model, setModel] = useState<AppModel>();

  const params = useParams();
  const { addToast } = useToast();

  const navigate = useCFNavigation();
  const { modelService } = useServicesContext();

  const getModel = async (id: ModelId) => {
    try {
      const view = await modelService.getById(id);

      if (!view) {
        return;
      }

      setModel(view);
    } catch (e) {
      console.log(e);
      addToast(`Couldn't load model definition`, ToastType.ERROR, 5000);
      navigate(CFRoutes.model);
    }
  };

  useEffect(() => {
    const modelId = params.id;
    if (!modelId) {
      return;
    }

    getModel(modelId);
  }, [params.id]);

  return model;
};

export default useModel;
