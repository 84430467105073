import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { faDownload, faCopy } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import html2canvas from 'html2canvas';

import { useToast } from 'hooks';
import { useAnalyticsContext } from '../../context/useAnalyticsContext';

import { CFRole } from 'domain/general.types';
import { Trait } from 'domain/traits.types';
import { Series } from 'domain/stats.types';

import { getDisplayName } from 'services/traits/helpers.traits';
import { CohortID } from 'services/cohort/cohort.types';

import CFButton from 'components/buttons/CFButton';
import CFSwitch from 'components/CFSwitch';
import CFLineChart, { ScaleType } from 'components/charts/CFLineChart';
import SelectedTags from 'components/SelectedTags';
import { ToastType } from 'components/CFToast/types';

import { belongToSameGap } from 'helpers/dates';
import { chartLineColor1, CHART_COLORS, CHART_COLORS_LIGHT } from 'styles/colors';
import { createCohortTagName } from '../../helpers/cohortTagName';

import { useServicesContext } from 'hooks/useServicesContext';

import './extended-timeserie.scss';

interface Props {
  trait: Trait;
  timeseries: Record<CohortID, Series>;
}

const ExtendedTimeserie = ({ timeseries, trait }: Props) => {
  const [lightTheme, setLightTheme] = useState(false);
  const [coloredTimeseries, setColoredTimeseries] = useState(timeseries);
  const { addToast } = useToast();

  const extendedTimeserieRef = useRef<HTMLDivElement>();

  const { aggLevel, endDate, selectedCohortIDs, availableCohorts } = useAnalyticsContext();
  const { cohortService } = useServicesContext();

  const handleCopyToClipboard = () => {
    const container = extendedTimeserieRef.current;

    html2canvas(container as HTMLDivElement).then((canvas) => {
      canvas.toBlob((blob) => {
        if (blob) {
          const item = new ClipboardItem({ 'image/png': blob });
          navigator.clipboard
            .write([item])
            .then(() => {
              addToast('Image copied to clipboard', ToastType.SUCCESS);
            })
            .catch((err) => {
              console.error('Error al copiar la imagen:', err);
            });
        }
      });
    });
  };

  const handleExport = () => {
    const container = extendedTimeserieRef.current;

    html2canvas(container as HTMLDivElement, { useCORS: true }).then((canvas) => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = 'chart.png';
      link.click();
    });
  };

  const handleLightTheme = useCallback(() => {
    setLightTheme((light) => !light);
  }, []);

  const colorScheme = useMemo(() => {
    return lightTheme ? CHART_COLORS_LIGHT : CHART_COLORS;
  }, [lightTheme]);

  useEffect(() => {
    const newTimeseries: Record<CohortID, Series> = (selectedCohortIDs || []).reduce(
      (acc, curr, index) => ({ ...acc, [curr]: { ...timeseries[parseInt(curr)], color: colorScheme[index] } }),
      {}
    );

    setColoredTimeseries(newTimeseries);
  }, [selectedCohortIDs, colorScheme, timeseries]);

  return (
    <div className={classNames('expanded-timeserie', { light: lightTheme })}>
      <div className="expanded-timeserie__header">
        <div>{getDisplayName(trait)}</div>
        <div>Light Theme</div>
        <CFSwitch checked={false} onChange={handleLightTheme} />
        <CFButton
          onClick={handleExport}
          description="Export to PNG"
          value=""
          iconName={faDownload}
          role={CFRole.OnlyIcon}
        />
        <CFButton
          onClick={handleCopyToClipboard}
          description="Copy to clipboard"
          value=""
          iconName={faCopy}
          role={CFRole.OnlyIcon}
        />
      </div>

      <div ref={(el) => (extendedTimeserieRef.current = el as HTMLDivElement)}>
        <CFLineChart
          testId={`trait-chart`}
          scale={ScaleType.Linear}
          showScaleControl={false}
          yLabel={trait?.meta.display_unit}
          units={trait?.meta.display_unit ?? ''}
          color={chartLineColor1}
          data={Object.values(coloredTimeseries)}
          aggregationLevel={aggLevel}
          // Remove CFLineChart loader, because CFLazyWrapper already provides a loader
          isLoading={false}
          showLegend={false}
          step={0.01}
          highlightLast={belongToSameGap(endDate, aggLevel)}
        >
          <SelectedTags
            label=""
            values={
              availableCohorts?.length
                ? selectedCohortIDs.map((cid, idx) => ({
                    value: createCohortTagName(cohortService.getCohort(cid)),
                    color: colorScheme[idx] || chartLineColor1,
                  }))
                : []
            }
          />
        </CFLineChart>
      </div>
    </div>
  );
};

export default ExtendedTimeserie;
