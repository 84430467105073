import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { CFRole } from 'domain/general.types';

import { SamplePurpose } from 'services/cohort/cohort.types';

import { Leaf } from 'services/cohort/cohort.types.api';
import Filter from 'services/cohort/domain/Filter';
import { generateStaticSample } from 'services/cohort/cohort.repo';
import CohortService from 'services/cohort/cohort.service';
import Cohort from 'services/cohort/domain/Cohort';

import CFButton from 'components/buttons/CFButton';
import CFDownloadButton from 'components/buttons/CFDownloadButton';
import CFInput, { CFInputType } from 'components/CFInput';
import CFTitledComponent from 'components/CFTitledComponent';
import { Collapsible } from 'components/CollapsibleContent';
import { ToastType } from 'components/CFToast/types';
import CFLoadWrapper from 'components/CFLoadWrapper';
import SampleViewer from 'connected-components/sampleViewer';

import { useToast } from 'hooks';

import { useModelContext } from '../../useContext';

import './sampling.scss';
import { ModelCohortType } from '..';

interface Props {
  cohort: Cohort | null;
  cohortService: CohortService;
  extraFilter: Leaf<Filter> | undefined;
  totalSubjects: number;
  isLoading: boolean;
  type: ModelCohortType;
}

const Sampling = ({ cohort, cohortService, extraFilter, isLoading, totalSubjects, type }: Props) => {
  const { addToast } = useToast();
  const { userSampleId, itemSampleId, setUserSampleId, setItemSampleId, sampling, setSampling } = useModelContext();
  const sampleSizeInputRef = React.createRef<HTMLInputElement>();

  const [computedSampleSize, setComputedSampleSize] = useState(0);
  const [sampleSizeValid, setSampleSizeValid] = useState(false);

  const sampleId = useMemo(() => {
    return type === ModelCohortType.Item ? itemSampleId : userSampleId;
  }, [type, userSampleId, itemSampleId]);

  const sampleButtonEnable = useMemo(() => {
    const isEnable = cohort !== undefined && sampleSizeValid;

    return isEnable;
  }, [sampleSizeValid, cohort /*, selectedNudges --> only for ab testing*/]);

  const updateSampleSizeValidity = (sampleSize: string) => {
    const minSampleThreshold = 5;

    const nonEmpty = sampleSize.trim().length > 0;
    const inRange = parseInt(sampleSize) > minSampleThreshold && parseInt(sampleSize) < totalSubjects;

    setSampleSizeValid(nonEmpty && inRange);
  };

  useEffect(() => {
    if (!sampleSizeInputRef || !sampleSizeInputRef.current) {
      // may be null if allowSampling is not set
      return;
    }
    updateSampleSizeValidity((sampleSizeInputRef as any).current.value);
  }, [totalSubjects]);

  const handleSampleSizeChange = useCallback(
    (newValue: any) => {
      updateSampleSizeValidity(newValue.target.value);
    },
    [totalSubjects]
  );

  const handleFetchSamplesForDownload = useCallback(async () => {
    if (!cohort) {
      return [];
    }

    const allSamples = await cohortService.sampleAll(sampleId);
    return allSamples || [];
  }, [cohort, sampleId]);

  const handleGenerateSample = async () => {
    if (!sampleSizeInputRef.current) {
      return;
    }

    if (!cohort) {
      return;
    }

    setSampling(true);

    try {
      const { id, cnt } = await generateStaticSample(
        cohort,
        extraFilter?.filters || [],
        parseInt(sampleSizeInputRef.current.value),
        sampleId,
        SamplePurpose.Model
      );

      if (type === ModelCohortType.User) {
        setUserSampleId(id);
      } else {
        setItemSampleId(id);
      }

      setComputedSampleSize(cnt);
    } catch (err) {
      addToast('Error generating sample', ToastType.ERROR, 5000);
    } finally {
      setSampling(false);
    }
  };

  return (
    <div className="sampling">
      <CFTitledComponent title="Sample size" className="reduced">
        <CFInput
          disabled={cohort === undefined}
          type={CFInputType.Number}
          ref={sampleSizeInputRef}
          onChange={handleSampleSizeChange}
          message={cohort !== undefined ? `Must be less than ${totalSubjects}` : ''}
        />

        <CFButton
          value="Generate sample"
          isLoading={sampling}
          role={CFRole.Primary}
          disabled={!sampleButtonEnable || isLoading}
          onClick={handleGenerateSample}
        />
      </CFTitledComponent>

      <CFLoadWrapper isLoading={sampling} spinnerSize={30}>
        <Collapsible sameStyling={true}>
          <Collapsible.CollapsedHeader>
            <div className="action-header">
              <div className="subjects-title">
                {sampling ? 'Sampled subjects' : `Sampled subjects (${computedSampleSize})`}
              </div>

              <CFDownloadButton
                getData={handleFetchSamplesForDownload}
                disabled={cohort === undefined || sampleId === ''}
                name={`${cohort?.name.split(' ').join('-')}-sample.csv`}
              />
            </div>
          </Collapsible.CollapsedHeader>

          <Collapsible.Content>
            <SampleViewer sampleId={{ value: sampleId }} cohortService={cohortService} />
          </Collapsible.Content>
        </Collapsible>
      </CFLoadWrapper>
    </div>
  );
};

export default Sampling;
