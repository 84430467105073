import React, { useState, useEffect, FC } from 'react';
import ArrowDown from '../ArrowDown';
import UnitDropdown from '../UnitDropdown';
import {
  doubleChar,
  getSameInputProps,
  onEscapeOrEnterTap,
  onSideArrowTap,
} from 'components/DateTime/DatetimeRangePicker/helper';

interface Range {
  start: number;
  end: number;
}

interface InputTimeHelperProps {
  range: Range;
  value: string;
  eachInputDropdown: boolean;
  manuallyDisplayDropdown: boolean;
  setValue: (value: string) => void;
  moveNext?: () => void;
  allowDelete: boolean;
  className?: string;
  fullTimeDropdown?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  movePrev?: () => void;
  disabled?: boolean;
  [key: string]: any;
}

const InputTimeHelper: FC<InputTimeHelperProps> = (props) => {
  const {
    range,
    value,
    eachInputDropdown,
    manuallyDisplayDropdown,
    setValue,
    moveNext,
    allowDelete = false,
    ...otherProps
  } = props;
  const [changedValue, setChangedValue] = useState<string>(value);
  const [keyPressed, setKeyPressed] = useState('');
  const [firstFocus, setFirstFocus] = useState(true);
  const [changeCount, setChangeCount] = useState(0);
  const [inputFocused, setInputFocused] = useState(false);
  const propsAndState: any = { ...props, inputFocused, setInputFocused };

  const cleanNumber = (number: any) => (!isNaN(number) ? number : '').toString().replace('0', '');

  const setSafeValue = (value: string) => {
    if (parseInt(value) >= range.start && parseInt(value) <= range.end) {
      setValue(value);
    }
  };

  const onMoveNext = () => {
    if (moveNext) {
      moveNext();
      setInputFocused(false);
    }
  };

  useEffect(() => {
    if (changedValue !== value) {
      setChangedValue(value);
    }
  }, [value]);

  const canNotWriteMoreTo = (value: string) => {
    const hasTwoValidChars = cleanNumber(value).length === 2;
    const lastCharachterOfEndRange = parseInt(range.end.toString().charAt(0));
    const cannotHaveSecondChar = parseInt(cleanNumber(value)) > lastCharachterOfEndRange;
    const exceedRangeLimit = parseInt(cleanNumber(value) + keyPressed) > range.end;
    const isMultipleOf10 = value.length === 2 && value.charAt(1) === '0' && value.charAt(0) !== '0';
    return isMultipleOf10 || hasTwoValidChars || cannotHaveSecondChar || exceedRangeLimit;
  };

  useEffect(() => {
    if (changedValue === value || changedValue === '' || isNaN(parseInt(keyPressed))) {
      return;
    }

    let newHour = doubleChar(cleanNumber(value) + keyPressed);
    if (firstFocus) {
      newHour = doubleChar(keyPressed);
      setFirstFocus(false);
      setChangeCount(changeCount + 1);
      setChangedValue('');
    }
    if (parseInt(newHour.toString()) >= range.start) {
      parseInt(newHour.toString()) <= range.end && setSafeValue(newHour);
      if (canNotWriteMoreTo(newHour) || changeCount >= 1) {
        onMoveNext();
      }
    } else {
      setFirstFocus(true);
      setChangeCount(0);
    }
  }, [changedValue]);

  const onBackSpaceTap = (e: React.KeyboardEvent) => e.key === 'Backspace' && allowDelete && setValue('00');

  const onArrowDownTap = (e: React.KeyboardEvent) => {
    if (e.key !== 'ArrowDown') {
      return;
    }

    if (parseInt(value.toString()) === range.start) {
      setSafeValue(doubleChar(range.end.toString()));
    } else {
      setSafeValue(doubleChar((parseInt(value) - 1).toString()));
    }
  };

  const onArrowUpTap = (e: React.KeyboardEvent) => {
    if (e.key !== 'ArrowUp') {
      return;
    }
    if (parseInt(value.toString()) === range.end) {
      setSafeValue(doubleChar(range.start.toString()));
    } else {
      setSafeValue(doubleChar((parseInt(value) + 1).toString()));
    }
  };

  return (
    <React.Fragment>
      <div className={`inputWrapper ${manuallyDisplayDropdown ? 'manuallyDisplayDropdown' : ''}`}>
        <input
          {...getSameInputProps(propsAndState)}
          onFocus={() => {
            setFirstFocus(true);
            setChangeCount(0);
          }}
          {...otherProps}
          value={value}
          onKeyDown={(e) => {
            onEscapeOrEnterTap(e, propsAndState);
            onSideArrowTap(e, propsAndState);
            setKeyPressed(e.key);
            onBackSpaceTap(e);
            onArrowDownTap(e);
            onArrowUpTap(e);
          }}
          onChange={(e) => setChangedValue(e.target.value)}
          onClick={(e) => e.stopPropagation()}
          type="number"
          min={range.start}
          max={range.end}
        />
        {eachInputDropdown && manuallyDisplayDropdown && (
          <ArrowDown
            onClick={() => {
              setTimeout(() => setInputFocused(!inputFocused), 15);
            }}
          />
        )}
        <UnitDropdown
          shouldDisplay={eachInputDropdown}
          manuallyDisplayDropdown={manuallyDisplayDropdown}
          data={new Array(range.end + 1 - range.start).fill('')}
          {...{
            range,
            moveNext: onMoveNext,
            setValue: setSafeValue,
            dropdownVisibility: inputFocused,
            setDropdownVisibility: setInputFocused,
            value,
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default InputTimeHelper;
