import { AxiosResponse } from 'axios';
import { get as httpGet, post as httpPost } from '../../repositories/drivers/http';
import { SyncDiff } from './sync.types';
import { RepoConfig } from 'repositories/types';

const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
const path = '/v1/admin/sync';

const repoConfig = {
  token: '',
  oid: -1,
  pid: -1,
};

export const init = ({ token, oid, pid }: RepoConfig) => {
  repoConfig.token = token;
  repoConfig.oid = oid;
  repoConfig.pid = pid;
};

export const diff = async () => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };

  try {
    const { data: syncInfo } = (await httpGet(`${serverBaseUrl}${path}/diff`, config)) as AxiosResponse<SyncDiff>;

    return syncInfo;
  } catch (err) {
    console.error('error getting sync info: ', err);
    throw new Error('error-getting-sync-info');
  }
};

export const runSync = async (data: SyncDiff) => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };

  const body = data;

  try {
    await httpPost(`${serverBaseUrl}${path}/run`, body, config);
  } catch (err) {
    console.error('error running sync: ', err);
    throw new Error('error-running-sync');
  }
};
