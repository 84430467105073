import React, { Dispatch, RefObject, SetStateAction, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';

import Month from '../Month';
import CFButton from 'components/buttons/CFButton';
import TimePicker from 'components/DateTime/TimePicker';
import TimezonePicker from 'components/DateTime/TimezonePicker';

import { CFRole } from 'domain/general.types';
import { DateRange, Setter, NavigationAction, MENU_HEIGHT } from '../types';
import { MARKERS } from '..';

import './menu.scss';
interface MenuProps {
  dateRange: DateRange;
  minDate: Date;
  maxDate: Date;
  firstMonth: Date;
  secondMonth: Date;
  showTime: boolean;
  onChangeStartTime: Dispatch<SetStateAction<string>>;
  onChangeEndTime: Dispatch<SetStateAction<string>>;
  menuRef: RefObject<HTMLDivElement>;
  menuPosition: {
    top: number;
    left: number;
  };
  inputRect: {
    top: number;
    left: number;
    width: number;
    height: number;
  };
  setFirstMonth: Setter<Date>;
  setSecondMonth: Setter<Date>;
  helpers: {
    inHoverRange: (day: Date) => boolean;
  };
  handlers: {
    onDayClick: (day: Date) => void;
    onDayHover: (day: Date) => void;
    onMonthNavigate: (marker: symbol, action: NavigationAction) => void;
  };
  onApply: () => void;
  onCancel: () => void;
  onChangeTimezone: (tz: string) => void;
}

const Menu: React.FunctionComponent<MenuProps> = ({
  dateRange,
  minDate,
  maxDate,
  firstMonth,
  setFirstMonth,
  secondMonth,
  setSecondMonth,
  helpers,
  handlers,
  showTime,
  onChangeStartTime,
  onChangeEndTime,
  menuRef,
  menuPosition,
  inputRect,
  onChangeTimezone,
  onApply,
  onCancel,
}) => {
  const [adjustedPosition, setAdjustedPosition] = useState({
    left: 0,
    top: 0,
    opacity: 0,
  });

  const commonProps = useMemo(
    () => ({ dateRange, minDate, maxDate, helpers, handlers }),
    [dateRange, minDate, maxDate, helpers, handlers]
  );

  const canNavigateCloser = useMemo(
    () => dayjs(secondMonth).diff(dayjs(firstMonth), 'months') >= 2,
    [secondMonth, firstMonth]
  );

  const adjustMenuPosition = () => {
    const menuRect = menuRef.current?.getBoundingClientRect();

    if (!menuRect || !inputRect) {
      return;
    }

    const { innerWidth, innerHeight } = window;
    const buffer = 8; // add a small buffer to avoid the edges of the viewport

    const newMenuPosition = { ...menuPosition };
    // Check if the menu overflows to the right
    if (inputRect.left + menuRect.width + buffer > innerWidth) {
      newMenuPosition.left = inputRect.width - menuRect.width;
    } else {
      newMenuPosition.left = 0;
    }

    // Check if the menu overflows to the bottom
    if (
      inputRect.top + inputRect.height + menuRect.height + buffer > innerHeight &&
      inputRect.top - menuRect.height > MENU_HEIGHT
    ) {
      newMenuPosition.top = -menuRect.height - buffer;
    } else {
      newMenuPosition.top = inputRect.height + buffer;
    }

    setAdjustedPosition({ ...newMenuPosition, opacity: 1 });
  };

  useEffect(() => {
    adjustMenuPosition();
  }, [menuPosition]);

  return (
    <div ref={menuRef} className={`date-range-menu-container`} style={adjustedPosition}>
      <div className="menu-months-container">
        <Month
          {...commonProps}
          value={firstMonth}
          setValue={setFirstMonth}
          navState={[true, canNavigateCloser]}
          marker={MARKERS.FIRST_MONTH}
        />
        <Month
          {...commonProps}
          value={secondMonth}
          setValue={setSecondMonth}
          navState={[canNavigateCloser, true]}
          marker={MARKERS.SECOND_MONTH}
        />
      </div>
      {showTime && (
        <div className="menu-times-container">
          <div className="menu-time-picker-container">
            <div className="menu-time-picker">
              <span>Set a start time</span>
              <TimePicker value={dayjs(dateRange.startDate).format('HH:mm')} onChange={(t) => onChangeStartTime(t)} />
            </div>
            <div className="menu-time-picker">
              <span>Set an end time</span>
              <TimePicker value={dayjs(dateRange.endDate).format('HH:mm')} onChange={(t) => onChangeEndTime(t)} />
            </div>
          </div>
          <div className="menu-timezone-picker-container">
            <TimezonePicker onSelect={onChangeTimezone} />
          </div>
        </div>
      )}

      <div className="menu-footer">
        <CFButton value="Cancel" onClick={onCancel} />
        <CFButton value="Apply" role={CFRole.Cyan} onClick={onApply} />
      </div>
    </div>
  );
};

export default Menu;
