import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import colors from 'common.scss';
import { RGBColor } from 'domain/general.types';

import './tag.scss';

export enum TagTypes {
  System = 'system',
  Neutral = 'neutral',
  Error = 'error',
  White = 'white',
  SwitchedColors = 'swiched-colors',
  Color = 'color',
}

export enum TagSize {
  extrasmall = 'extrasmall',
  small = 'small',
  large = 'large',
}

interface Props {
  text: string;
  type: TagTypes;
  size?: TagSize;
  color?: RGBColor;
  closeIcon?: boolean;
  onClose?: React.MouseEventHandler;
  maxLength?: number;
}

export const Tag = ({ text, type, size = TagSize.large, closeIcon = false, onClose, maxLength = 0, color }: Props) => {
  let renderText = text;
  const style: React.CSSProperties = {};

  if (maxLength > 0 && renderText.length > maxLength) {
    renderText = `${renderText.slice(0, maxLength)}...`;
  }

  if (type === TagTypes.Color && color) {
    style.backgroundColor = color;
    style.color = 'black';
    style.borderColor = color;
  }

  if (type === TagTypes.SwitchedColors && color) {
    style.backgroundColor = colors.dark80;
    style.color = color;
    style.borderColor = color;
  }

  return (
    <span className={`tag ${type} ${size}`} style={style}>
      {renderText}
      {closeIcon && <FontAwesomeIcon className="tag-xmark" icon={faXmark} onClick={onClose} />}
    </span>
  );
};
