import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import Menu from './Menu';
import DefinedRanges from './DefinedRanges';

import { displayDate, isBetween } from 'helpers/dates';
import { DateRange, NavigationAction, DefinedRange } from './types';

import { useClickOutside } from 'hooks';

import './date-time-range-picker.scss';
import moment from 'moment';
import { getValidatedMonths, parseOptionalDate } from './helper';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

interface DateRangePickerProps {
  initialDateRange?: DateRange;
  definedRanges?: DefinedRange[];
  minDate?: Date | string;
  maxDate?: Date | string;
  showTime?: boolean;
  placeholder?: string;
  onChange: (dateRange: DateRange) => void;
}

type Marker = symbol;

export const MARKERS: { [key: string]: Marker } = {
  FIRST_MONTH: Symbol('firstMonth'),
  SECOND_MONTH: Symbol('secondMonth'),
};

const DateTimeRangePicker: FC<DateRangePickerProps> = ({
  onChange,
  initialDateRange,
  minDate,
  maxDate,
  definedRanges,
  placeholder,
  showTime = false,
}) => {
  const today = new Date();

  const [openCalendar, setOpenCalendar] = useState(false);
  const [openDefined, setOpenDefined] = useState(false);

  const [timezone, setTimezone] = useState(dayjs.tz.guess());

  const minDateValid = parseOptionalDate(minDate, dayjs(today).subtract(10, 'years').toDate());
  const maxDateValid = parseOptionalDate(maxDate, dayjs(today).add(10, 'years').toDate());

  const [intialFirstMonth, initialSecondMonth] = getValidatedMonths(initialDateRange || {}, minDateValid, maxDateValid);

  const [dateRange, setDateRange] = React.useState<DateRange>({
    startDate: moment.max(moment(minDateValid), moment(initialDateRange?.startDate)).toDate(),
    endDate: moment.min(moment(maxDateValid), moment(initialDateRange?.endDate)).toDate(),
  });

  const [startTime, setStartTime] = useState<string>('00:00');
  const [endTime, setEndTime] = useState<string>('23:59');

  const [formattedDateRange, setFormattedDateRange] = React.useState<string>(() => {
    if (initialDateRange?.startDate && initialDateRange?.endDate) {
      return `${dayjs(initialDateRange.startDate).format('YYYY-MM-DD')} ~ ${dayjs(initialDateRange.endDate).format(
        'YYYY-MM-DD'
      )}`;
    }
    return '';
  });

  const menuRef = useRef<HTMLDivElement>(null);
  const definedRef = useRef<HTMLDivElement>(null);

  const [hoverDay, setHoverDay] = React.useState<Date>();
  const [firstMonth, setFirstMonth] = React.useState<Date>(intialFirstMonth || today);
  const [secondMonth, setSecondMonth] = React.useState<Date>(
    initialSecondMonth || dayjs(firstMonth).add(1, 'month').toDate()
  );

  const { startDate, endDate } = dateRange;

  const [inputRect, setInputRect] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const definedRange = definedRanges?.find(
      (range) =>
        dayjs(range.startDate).isSame(dayjs(startDate), 'day') &&
        dayjs(range.endDate).isSame(dayjs(endDate).add(1, 'day'), 'day')
    );
    if (!definedRange) {
      return;
    }

    handleChangeDefined(definedRange);
  }, []);

  const setFirstMonthValidated = useCallback(
    (date: Date) => {
      if (dayjs(date).isBefore(secondMonth)) {
        setFirstMonth(date);
      }
    },
    [secondMonth]
  );

  const setSecondMonthValidated = useCallback(
    (date: Date) => {
      if (dayjs(date).isAfter(firstMonth)) {
        setSecondMonth(date);
      }
    },
    [firstMonth]
  );

  const onDayClick = useCallback(
    (day: Date) => {
      if (startDate && !endDate && !dayjs(day).isBefore(startDate)) {
        const newRange = { startDate, endDate: day };
        setDateRange(newRange);
      } else {
        setDateRange({ startDate: day, endDate: undefined });
      }
      setHoverDay(day);
    },
    [startDate, endDate, onChange]
  );

  const onMonthNavigate = useCallback(
    (marker: Marker, action: NavigationAction) => {
      if (marker === MARKERS.FIRST_MONTH) {
        const firstNew = dayjs(firstMonth).add(action, 'month').toDate();
        if (dayjs(firstNew).isBefore(secondMonth)) setFirstMonth(firstNew);
      } else {
        const secondNew = dayjs(secondMonth).add(action, 'month').toDate();
        if (dayjs(firstMonth).isBefore(secondNew)) setSecondMonth(secondNew);
      }
    },
    [firstMonth, secondMonth]
  );

  const onDayHover = useCallback(
    (date: Date) => {
      if (startDate && !endDate) {
        if (!hoverDay || !dayjs(date).isSame(hoverDay)) {
          setHoverDay(date);
        }
      }
    },
    [startDate, endDate, hoverDay]
  );

  const inHoverRange = useCallback(
    (day: Date): boolean => {
      return (
        !!startDate &&
        !endDate &&
        !!hoverDay &&
        dayjs(hoverDay).isAfter(startDate) &&
        (isBetween(day, startDate, hoverDay) || dayjs(hoverDay).isSame(day, 'day'))
      );
    },
    [startDate, endDate, hoverDay]
  );

  useClickOutside(menuRef, () => {
    if (openDefined) setOpenDefined(false);
    if (openCalendar) setOpenCalendar(false);
  });

  useClickOutside(definedRef, () => {
    if (openDefined) setOpenDefined(false);
    if (openCalendar) setOpenCalendar(false);
  });

  const handleChangeTimezone = (tz: string) => {
    setTimezone(tz);
  };

  const handleApply = () => {
    const convertedStartDate = displayDate(dateRange.startDate, startTime, showTime, false, timezone);

    const convertedEndDate = displayDate(dateRange.endDate, endTime, showTime, true, timezone);

    setFormattedDateRange(`${convertedStartDate} ~ ${convertedEndDate}`);

    const newRange = {
      startDate: dayjs(
        displayDate(
          dateRange.startDate,
          startTime,
          showTime,
          false,
          timezone,
          `YYYY-MM-DD ${showTime ? 'HH: mm: ss.SSSZ' : ''}`
        )
      )
        .local()
        .toDate(),
      endDate: dayjs(
        displayDate(
          dateRange.endDate,
          endTime,
          showTime,
          false,
          timezone,
          `YYYY-MM-DD ${showTime ? 'HH: mm: ss.SSSZ' : ''}`
        )
      )
        .local()
        .toDate(),
    };

    setDateRange(newRange);
    onChange(newRange);

    setOpenCalendar(false);
  };

  const helpers = useMemo(() => ({ inHoverRange }), [inHoverRange]);

  const handlers = useMemo(
    () => ({
      onDayClick,
      onDayHover,
      onMonthNavigate,
    }),
    [onDayClick, onDayHover, onMonthNavigate]
  );

  const handleChangeDefined = (selected: DefinedRange) => {
    if (selected.label === 'custom') {
      setOpenDefined(false);
      setOpenCalendar(true);
    } else if (selected.label === 'Today') {
      setFormattedDateRange(selected.label);

      setDateRange({
        startDate: selected.startDate,
        endDate: selected.endDate,
      });

      onChange({
        startDate: selected.startDate,
        endDate: selected.endDate,
      });

      setOpenDefined(false);
    } else {
      setFormattedDateRange(selected.label);

      setDateRange({
        startDate: moment.max(moment(selected.startDate), moment(minDateValid)).toDate(),
        endDate: moment.min(moment(selected.endDate), moment(maxDateValid)).toDate(),
      });

      onChange({
        startDate: moment.max(moment(selected.startDate), moment(minDateValid)).toDate(),
        endDate: moment.min(moment(selected.endDate), moment(maxDateValid)).toDate(),
      });

      setOpenDefined(false);
    }
  };

  return (
    <div className="date-time-range-picker">
      <input
        className="date-time-range-input"
        value={formattedDateRange}
        readOnly
        onClick={(e) => {
          const rect = e.currentTarget.getBoundingClientRect();
          setInputRect(rect);
          setMenuPosition({ left: rect.left, top: rect.bottom });
          setOpenDefined(true);
        }}
        data-testid="datetime-range-picker"
        placeholder={placeholder ?? 'YYYY-MM-DD ~ YYYY-MM-DD'}
      />
      {openDefined && (
        <DefinedRanges
          definedRef={definedRef}
          onChange={handleChangeDefined}
          menuPosition={menuPosition}
          ranges={definedRanges}
          inputRect={inputRect}
        />
      )}
      {openCalendar && (
        <Menu
          dateRange={dateRange}
          minDate={minDateValid}
          maxDate={maxDateValid}
          firstMonth={firstMonth}
          secondMonth={secondMonth}
          setFirstMonth={setFirstMonthValidated}
          setSecondMonth={setSecondMonthValidated}
          onChangeStartTime={setStartTime}
          onChangeEndTime={setEndTime}
          helpers={helpers}
          handlers={handlers}
          onApply={handleApply}
          onCancel={() => setOpenCalendar(false)}
          showTime={showTime}
          menuRef={menuRef}
          onChangeTimezone={handleChangeTimezone}
          menuPosition={menuPosition}
          inputRect={inputRect}
        />
      )}
    </div>
  );
};

export default DateTimeRangePicker;
