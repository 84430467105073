import React, { FC } from 'react';
import CFPaginatedList from 'components/CFPaginatedList';
import CFTable, { Column, TableType } from 'components/CFTable';

interface CFDataTableProps {
  title?: string;
  headers: Column[];
  total: number;
  pageSizeList?: number[];
  data: Record<string, any>[];
  emptyLabel?: string;
  multiSelect?: boolean;
  variant?: TableType;
  selected?: Record<string, any>[];
  indexCol?: string;
  onPaginated: (page: number, size: number) => void;
  onSelectRow?: (rows: Record<string, any>) => void;
}

const CFDataTable: FC<CFDataTableProps> = ({
  variant,
  title,
  headers,
  data,
  total,
  pageSizeList,
  multiSelect,
  emptyLabel,
  selected,
  indexCol,
  onPaginated,
  onSelectRow,
}) => {
  return (
    <CFPaginatedList total={total} pageSizeList={pageSizeList} onPageChange={onPaginated}>
      <CFTable
        variant={variant}
        title={title}
        data={data}
        headers={headers}
        multiSelect={multiSelect}
        selected={selected}
        emptyLabel={emptyLabel}
        onSelectRow={onSelectRow}
        indexCol={indexCol}
      />
    </CFPaginatedList>
  );
};

export default CFDataTable;
