import React, { ReactNode } from 'react';

import './box.scss';

export enum BoxAlignment {
  Center = 'center',
  Left = 'left',
  Right = 'right',
}

interface Props {
  className?: string;
  children: ReactNode;
  alignment?: BoxAlignment;
}

const Box = ({ className = '', alignment = BoxAlignment.Left, children }: Props) => {
  const generatedClassName = `box ${className}`;

  return (
    <div className={generatedClassName} style={{ justifyContent: alignment }}>
      {' '}
      {children}{' '}
    </div>
  );
};

export default Box;
