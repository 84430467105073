import React, { useEffect, useState } from 'react';

import CFBarChart from 'components/charts/CFBarChart';
import CFLineChart from 'components/charts/CFLineChart';
import CFSelectLegacy from 'components/CFSelectLegacy';
import { AggLevel, StatItem, TimeSeriesItem } from 'domain/stats.types';
import { oneWeekAgo, today, toString as dateToString } from 'helpers/dates';

import { get as getTimeSeries, getTopStats } from 'repositories/monitoring';

import DatetimeRangePicker from 'components/DateTime/DatetimeRangePicker';
import { DateRange } from 'components/DateTime/DatetimeRangePicker/types';
import CFLoadWrapper from 'components/CFLoadWrapper';
import { ToastType } from 'components/CFToast/types';
import { CFNavListWithLinks } from 'components/CFNavListWithLinks';

import { useToast } from 'hooks';
import { CFRoutes } from 'routes';

import { Tabs } from '..';

import './monitoring.scss';

//const INTEGRATION_MONITORING_REFRESH = 10000;

const Monitoring = () => {
  const { addToast } = useToast();

  const [topStats, setTopStats] = useState<StatItem[]>([]);
  const [timeSeries, setTimeSeries] = useState<TimeSeriesItem[]>([]);

  const [startDate, setStartDate] = useState<string>(dateToString(oneWeekAgo(true)));
  const [endDate, setEndDate] = useState<string>(dateToString(today()));

  const [aggLevel, setAggLevel] = useState<AggLevel>(AggLevel.Day);

  const [isBarLoading, setIsBarLoading] = useState<boolean>(true);
  const [isLineLoading, setIsLineLoading] = useState<boolean>(true);

  const loadTimeseriesData = async (startDate: string, endDate: string, aggLevel: AggLevel) =>
    getTimeSeries(startDate, endDate, 'all', aggLevel) // type as all to get all logs overview
      .then((data) => {
        setTimeSeries(data);
      })
      .catch(() => {
        addToast(`Couldn't load the time series`, ToastType.ERROR, 5000);
      })
      .finally(() => {
        setIsLineLoading(false);
      });

  const loadStatsData = async (startDate: string, endDate: string) =>
    getTopStats(startDate, endDate)
      .then((data: StatItem[]) => {
        setTopStats(data);
      })
      .catch(() => {
        addToast(`Couldn't load stats`, ToastType.ERROR, 5000);
      })
      .finally(() => {
        setIsBarLoading(false);
      });

  useEffect(() => {
    loadTimeseriesData(startDate, endDate, aggLevel);
  }, [startDate, endDate, aggLevel]);

  useEffect(() => {
    loadStatsData(startDate, endDate);
  }, [startDate, endDate]);

  /*
  // TEMPORARY DISABLED
  useEffect(() => {
    // auto refresh
    const timeseriesRefreshInterval = setInterval(() => {
      setEndDate(dateToString(today()));
    }, INTEGRATION_MONITORING_REFRESH);

    return () => clearInterval(timeseriesRefreshInterval);
  }, []);
*/
  const handleRangeChange = (dateRange: DateRange) => {
    setStartDate(dateToString(dateRange.startDate as Date));
    setEndDate(dateToString(dateRange.endDate as Date));
  };

  const handleAggLevelChange = (newLevel: AggLevel) => {
    setAggLevel(newLevel);
  };

  return (
    <div>
      <CFNavListWithLinks
        titles={[
          { title: Tabs.Monitoring, target: CFRoutes.integration },
          { title: Tabs.IngestHistory, target: CFRoutes.integration_ingest_history },
          { title: Tabs.CatalogHistory, target: CFRoutes.integration_catalog_history },
          { title: Tabs.SdkExceptions, target: CFRoutes.integration_exception_view },
          { title: Tabs.ApiKeys, target: CFRoutes.integration_keys },
        ]}
        selected={Tabs.Monitoring}
      />
      <div className="monitoring">
        <div className="controls">
          <span className="chart-level-label">Granularity</span>
          <CFSelectLegacy
            onSelected={([selected]) => handleAggLevelChange(selected.value as AggLevel)}
            defaultOption={[
              {
                value: AggLevel.Day,
                label: AggLevel.Day,
              },
            ]}
            options={[
              {
                value: AggLevel.Day,
                label: AggLevel.Day,
              },
              {
                value: AggLevel.Hour,
                label: AggLevel.Hour,
              },
              {
                value: AggLevel.Month,
                label: AggLevel.Month,
              },
            ]}
          />
          <div className="spacer"></div>
          <DatetimeRangePicker
            onChange={handleRangeChange}
            initialDateRange={{
              startDate: new Date(startDate),
              endDate: new Date(endDate),
            }}
            showTime={true}
          />
        </div>

        <CFLoadWrapper isLoading={isLineLoading}>
          <CFLineChart
            yLabel="Log count"
            title="Log count"
            data={[
              {
                name: 'Log count',
                items: timeSeries,
              },
            ]}
            aggregationLevel={aggLevel}
            isLoading={false}
          />
        </CFLoadWrapper>

        <CFLoadWrapper isLoading={isBarLoading}>
          <CFBarChart
            barWidth={15}
            data={topStats}
            xLabel="Log count"
            title="Log count per event type"
            isLoading={false}
          />
        </CFLoadWrapper>
      </div>
    </div>
  );
};

export default Monitoring;
