import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { faTrashCan } from '@fortawesome/free-solid-svg-icons';

import { Trait, TraitSubject } from 'domain/traits.types';
import { Operators } from 'domain/general.types';

import { Leaf, FilterGroupOperation } from 'services/cohort/cohort.types.api';
import { createTraitCode } from 'services/traits/helpers.traits';
import Filter from 'services/cohort/domain/Filter';

import CFTrashButton from 'components/buttons/CFTrashButton';
import CFAddButton from 'components/buttons/CFAddButton';
import CFButtonGroup, { ButtonGroupOption } from 'components/CFButtonGroup';
import CFButton from 'components/buttons/CFButton';

import FilterBuilder from './FilterBuilder';

import { useServicesContext } from 'hooks/useServicesContext';

import './leaf-builder.scss';
import { FilterViewer } from 'connected-components/FilterViewerTree';

interface Props {
  subject: TraitSubject;
  traits: Trait[];
  defaultFilters?: Leaf;
  fixedOperation?: FilterGroupOperation;
  showInitialFilter?: boolean;
  onLeafChange: () => void;
  onGroupRemoved?: () => void;
}

export interface LeafRef {
  value: () => Leaf<Filter>;
}

const LeafBuilder = forwardRef<LeafRef, Props>(function FilterGroupBuilder(
  {
    traits,
    onLeafChange: onFilterGroupChange,
    onGroupRemoved,
    fixedOperation,
    defaultFilters,
    showInitialFilter = false,
  }: Props,
  ref
) {
  const { traitSessionService: traitService } = useServicesContext();

  const [groupOperator, setGroupOperator] = useState<ButtonGroupOption>(
    fixedOperation
      ? { label: fixedOperation, value: fixedOperation }
      : { label: 'AND', value: FilterGroupOperation.And }
  );
  const filtersRef = useRef<Filter[]>([]);
  const [filterItem, setFilterItemsList] = useState<number[]>([]);

  useImperativeHandle(ref, () => {
    const isValidFilter = (filter: Filter) => {
      const trait = traitService.getTraitDefinition(filter.ptr);

      return filter.isValid(trait);
    };

    return {
      value() {
        filtersRef.current = filtersRef.current.slice(0, filterItem.length);

        return {
          filters: [...((defaultFilters?.filters as Filter[]) || []), ...filtersRef.current.filter(isValidFilter)],
          op: groupOperator.value,
        };
      },
    };
  });

  useEffect(() => {
    if (!defaultFilters) {
      return;
    }

    // this shouldn't be needed but some extra re-renders happen
    // in the parent components
    if (filterItem.length !== 0) {
      return;
    }

    setFilterItemsList((filterItemList) => [...filterItemList, ...defaultFilters.filters.map((filter, i) => i)]);
  }, [defaultFilters]);

  useEffect(() => {
    if (showInitialFilter && !defaultFilters) {
      setFilterItemsList([new Date().valueOf()]);
    }

    filtersRef.current = [];
  }, [traits]);

  const handleNewFilter = useCallback(() => {
    setFilterItemsList((filterItem) => [...filterItem, new Date().valueOf()]); // save the new position

    onFilterGroupChange();
  }, [filterItem]);

  const handleFilterChanged = useCallback(() => {
    onFilterGroupChange();
  }, []);

  const handleGroupOperatorChanged = useCallback((option: ButtonGroupOption) => {
    setGroupOperator(option);

    onFilterGroupChange();
  }, []);

  const handleDeleteFilter = useCallback(
    (index: number) => {
      filterItem.splice(index, 1);
      setFilterItemsList([...filterItem]);

      onFilterGroupChange();
    },
    [filterItem]
  );

  const handleDeleteGroup = useCallback(() => {
    onGroupRemoved && onGroupRemoved();
  }, []);

  return (
    <div className="filter-group-builder level_1">
      <div className="filters">
        <div className="filters-actions">
          {filterItem.length > 1 && (
            <CFButtonGroup
              className="filters-actions-operator"
              options={[
                {
                  value: FilterGroupOperation.And,
                  label: FilterGroupOperation.And,
                },
                {
                  value: FilterGroupOperation.OR,
                  label: FilterGroupOperation.OR,
                },
              ]}
              onSelect={handleGroupOperatorChanged}
              value={groupOperator}
            />
          )}

          {(defaultFilters?.filters || []).length === 0 && (
            <div className="filters-actions-control">
              <CFButton value={'Delete group'} iconName={faTrashCan} onClick={handleDeleteGroup} />
            </div>
          )}
        </div>

        {Array(filterItem.length)
          .fill('')
          .map((_, i) => {
            return (
              <div className="filter-group-row" key={filterItem[i]}>
                {i < (defaultFilters?.filters || []).length ? (
                  <FilterViewer
                    filter={{
                      op: (defaultFilters as Leaf).filters[i].op,
                      val: (defaultFilters as Leaf).filters[i].val,
                      ptr: (defaultFilters as Leaf).filters[i].ptr,
                    }}
                    operator={''}
                  />
                ) : (
                  <>
                    <FilterBuilder
                      traits={traits}
                      defaultTrait={traits.find(
                        (trait) => createTraitCode(trait.addr) === defaultFilters?.filters[i]?.ptr
                      )}
                      defaultOperator={defaultFilters?.filters[i]?.op as Operators}
                      defaultValue={defaultFilters?.filters[i]?.val}
                      ref={(el) => (filtersRef.current[i] = el as Filter)}
                      onFilterChanged={handleFilterChanged}
                    />

                    <CFTrashButton onClick={() => handleDeleteFilter(i)} />
                  </>
                )}
              </div>
            );
          })}
      </div>
      <CFAddButton value="Add filter" onClick={handleNewFilter} />
    </div>
  );
});

export default LeafBuilder;
