import React, { MutableRefObject, useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import { TextAreaProps } from '..';

import './cf-textarea.scss';

const KEY_ENTER = 'Enter';
const KEY_SHIFT = 'Shift';
const KEY_BACKSPACE = 'Backspace';
const KEY_DELETE = 'Delete';

const BASE_HEIGHT = 64;

const CFTextarea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(function CFTextarea(
  { className, defaultValue, placeholder, disabled = false, name, value, onChange, onSubmit },
  ref
) {
  const [shift, setShift] = useState(false);

  useEffect(() => {
    if (!ref) {
      return;
    }

    const textarea = (ref as MutableRefObject<HTMLTextAreaElement>).current;
    textarea.style.height = `${BASE_HEIGHT}px`;
  }, []);

  const autoResize = useCallback(() => {
    if (!ref) {
      return;
    }

    const textarea = (ref as MutableRefObject<HTMLTextAreaElement>).current;
    const scrollHeight = (ref as MutableRefObject<HTMLTextAreaElement>).current.scrollHeight;

    const computedHeight = BASE_HEIGHT + BASE_HEIGHT * Math.floor(scrollHeight / BASE_HEIGHT);

    textarea.style.height = `${computedHeight}px`;
    textarea.style.overflowY = 'hidden';
  }, [ref]);

  const handleKeyUp = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!ref) {
      return;
    }

    if (event.key === KEY_BACKSPACE || event.key === KEY_DELETE) {
      const textarea = (ref as MutableRefObject<HTMLTextAreaElement>).current;
      textarea.style.height = `${BASE_HEIGHT}px`;
      autoResize();
    }

    if (event.key === KEY_SHIFT) {
      setShift(false);

      return;
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === KEY_SHIFT) {
      setShift(true);

      return;
    }

    if (event.key === KEY_ENTER && !shift) {
      const textarea = (ref as MutableRefObject<HTMLTextAreaElement>).current;

      textarea.style.height = `${BASE_HEIGHT}px`;

      if (onSubmit) {
        onSubmit();
      }
    }
  };

  return (
    <textarea
      value={value}
      name={name}
      disabled={disabled}
      placeholder={placeholder}
      className={classNames('cf-textarea', className)}
      ref={ref}
      onChange={onChange}
      defaultValue={defaultValue}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      onInput={autoResize}
    />
  );
});

export default CFTextarea;
