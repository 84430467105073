import React, { useCallback, useEffect, useState } from 'react';
import { CFRoutes } from 'routes';

import { faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';

import { useToast } from 'hooks';

import { allowedTabs, interventionByTab, Tabs } from 'views/intervention';

import { CFRole } from 'domain/general.types';
import { Nudge, NudgeTag, RenderMethod } from 'services/nudging/nudge.types';

import { AuthAction, isAllowedTo } from 'services/authorization.service';

import ProtectedElement from 'connected-components/ProtectedElement';

import CFButton from 'components/buttons/CFButton';
import CFConfirmableButton from 'components/CFConfirmableButton';
import { ToastType } from 'components/CFToast/types';
import CFLoadWrapper from 'components/CFLoadWrapper';
import { Column, ColumnType } from 'components/CFTable';
import CFDataTable from 'components/CFDataTable';
import { CFNavList } from 'components/CFNavList';
import { Tag, TagTypes } from 'components/Tag';

import NudgeDetail from './NudgeDetail';
import { useServicesContext } from 'hooks/useServicesContext';

import useCFNavigation from 'hooks/useCFNavigation';

import './list-of-nudges.scss';

const ListOfNudges = () => {
  const { addToast } = useToast();
  const navigate = useCFNavigation();
  const { nudgeService } = useServicesContext();
  const [nudges, setNudges] = useState<Nudge[]>([]);
  const [totalNudges, setTotalNudges] = useState(-1);
  const [curPage, setCurPage] = useState<number>(0);
  const [curPageSize, setCurPageSize] = useState<number>(10);

  const [isLoading, setIsLoading] = useState(true);

  const downloadPage = useCallback(async () => {
    try {
      setIsLoading(true);
      const nudges = await nudgeService.list(curPage, curPageSize);
      setTotalNudges(nudges.total);
      setNudges(nudges.data);
    } catch (e) {
      addToast(`Couldn't load exception elements`, ToastType.ERROR, 5000);
    } finally {
      setIsLoading(false);
    }
  }, [curPage, curPageSize]);

  useEffect(() => {
    downloadPage();
  }, [downloadPage]);

  const handleNewPageRequest = (page: number, size: number) => {
    setCurPage(page - 1);
    setCurPageSize(size);
  };

  const handleRemoveNudge = async (nudgeId: number) => {
    await nudgeService.remove(nudgeId);
    downloadPage();
  };

  const columns: Column[] = [
    {
      title: 'ID',
      field: 'id',
      type: ColumnType.STRING,
      style: {
        minWidth: '75px',
      },
    },
    {
      title: 'Name',
      field: 'name',
      type: ColumnType.STRING,
      style: {
        minWidth: '200px',
      },
    },
    {
      title: 'Description',
      field: 'description',
      type: ColumnType.STRING,
    },
    {
      title: 'Render method',
      field: 'render_method',
      type: ColumnType.STRING,

      renderCell: (row) => {
        const renderMethodNameMap = {
          [RenderMethod.InApp]: 'In app',
          [RenderMethod.PushNotification]: 'Push notification',
        };

        return renderMethodNameMap[row.definition?.render_method as RenderMethod];
      },
    },
    {
      title: 'Tags',
      field: 'tags',
      type: ColumnType.STRING,
      style: {
        minWidth: '200px',
      },
      renderCell: (row) => {
        const nudgeTagNamesMap = {
          [NudgeTag.Incentive]: 'Incentive',
          [NudgeTag.Motivation]: 'Motivation',
          [NudgeTag.Reminder]: 'Reminder',
        };

        return (
          <div className="tags">
            {row.definition?.message?.tags?.map((tag: NudgeTag) => (
              <Tag key={tag} text={nudgeTagNamesMap[tag]} type={TagTypes.System} />
            ))}
          </div>
        );
      },
    },
    {
      title: '',
      field: '',
      type: ColumnType.OBJECT,
      expandable: true,
      style: {
        minWidth: '30px',
      },
      renderCell: (row) => (
        <div className="nudges-extended">
          <ProtectedElement authAction={AuthAction.CreateNudge}>
            <div className="nudges-extended-controls">
              <CFConfirmableButton title={'Remove nudge'} question={'Are you sure to remove this nudge?'}>
                <CFButton value="Delete nudge" iconName={faTrashCan} onClick={() => handleRemoveNudge(row.id)} />
              </CFConfirmableButton>
            </div>
          </ProtectedElement>

          <NudgeDetail {...(row as Nudge)} />
        </div>
      ),
    },
  ];

  return (
    <div className="list-of-nudges">
      <CFNavList
        titles={allowedTabs()}
        selected={Tabs.Nudges}
        onClick={(selectedTab) => navigate(interventionByTab[selectedTab])}
      />

      <CFLoadWrapper isLoading={isLoading}>
        <div className="nudge-container">
          <div className="controls">
            {isAllowedTo(AuthAction.CreateNudge) && (
              <CFButton
                value="Add Nudge"
                role={CFRole.Primary}
                iconName={faPlus}
                onClick={() => navigate(CFRoutes.intervention_nudge_new)}
              />
            )}
          </div>

          <CFDataTable
            headers={columns}
            total={totalNudges}
            data={nudges.map((nudge) => ({ ...nudge, id: nudge.id }))}
            onPaginated={handleNewPageRequest}
            indexCol={'id'}
          />
        </div>
      </CFLoadWrapper>
    </div>
  );
};

export default ListOfNudges;
