import React, { useCallback, useEffect, useState } from 'react';

import { faEye, faPause, faPlay, faPlus, faThumbtack } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import dayjs from 'dayjs';

import useCFNavigation from 'hooks/useCFNavigation';
import { useServicesContext } from 'hooks/useServicesContext';

import { CFRoutes } from 'routes';

import { AppModel, Model, ModelId, ModelStatus, ModelTag } from 'domain/model.types';
import { CFRole } from 'domain/general.types';

import { AuthAction, isAllowedTo } from 'services/authorization.service';
import { InterventionSlotStatus } from 'services/intervention/intervention.types';
import { remove as removeModel } from 'services/model/model.repo';

import ProtectedElement from 'connected-components/ProtectedElement';

import CFDataTable from 'components/CFDataTable';
import { Column, ColumnType } from 'components/CFTable';
import CFConfirmableButton from 'components/CFConfirmableButton';
import CFTrashButton from 'components/buttons/CFTrashButton';
import CFLoadWrapper from 'components/CFLoadWrapper';
import { ToastType } from 'components/CFToast/types';
import CFButton from 'components/buttons/CFButton';
import StatusTag, { StatusTagVariant } from 'components/StatusTag';
import ModelDetails from './components/ModelDetails';
import ModelTagChip from './components/ModelTagList/ModelTag';
import ModelRunsSummary from './components/ModelRunsSummary';

import { useToast } from 'hooks';

import './model.scss';

export const statusLabelMap = {
  [ModelStatus.Running]: 'Running',
  [ModelStatus.Paused]: 'Paused',
  [ModelStatus.Terminated]: 'Terminated',
  [ModelStatus.Finished]: 'Finished',
  [ModelStatus.Pending]: 'Pending',
};

export const statusVariantMap = {
  [ModelStatus.Running]: StatusTagVariant.InProgress,
  [ModelStatus.Paused]: StatusTagVariant.Paused,
  [ModelStatus.Terminated]: StatusTagVariant.Terminated,
  [ModelStatus.Finished]: StatusTagVariant.Success,
  [ModelStatus.Pending]: StatusTagVariant.Pending,
};

export const runStatusClassMap = {
  [InterventionSlotStatus.Success]: 'success',
  [InterventionSlotStatus.InProgress]: 'in-progress',
  [InterventionSlotStatus.Failed]: 'failed',
  [InterventionSlotStatus.Invalid]: 'failed',
  [InterventionSlotStatus.Timeout]: 'failed',
};

const ModelList = () => {
  const { modelService } = useServicesContext();

  const [isLoading, setIsLoading] = useState(true);

  const [totalModels, setTotalModels] = useState(0);
  const [models, setModels] = useState<Model[]>([]);
  const [curPage, setCurrentPage] = useState<number>(0);
  const [curPageSize, setCurrentPageSize] = useState<number>(10);

  const { addToast } = useToast();

  const navigate = useCFNavigation();

  const downloadPage = useCallback(async () => {
    setIsLoading(true);
    if (!modelService) {
      return;
    }
    const models = await modelService.getModels(curPage, curPageSize);
    setTotalModels(models.total);
    setModels(models.data);
    setIsLoading(false);
  }, [curPage, curPageSize]);

  useEffect(() => {
    downloadPage();
  }, [downloadPage]);

  const handlePageChange = (page: number, size: number) => {
    setCurrentPage(page - 1);
    setCurrentPageSize(size);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handlePinToLanding = async (id: ModelId, currentPinStatus: boolean) => {
    try {
      await modelService.pin(id, !currentPinStatus);

      setModels((models) => {
        const thisModel = models.find((model) => model.definition.id === id) as Model;
        thisModel.definition.pin_to_landing = !currentPinStatus;

        return [...models];
      });
    } catch (e) {
      addToast('Error pinning model to landing page', ToastType.ERROR);
    }
  };

  const handleViewDefinition = useCallback((id: ModelId) => {
    navigate(CFRoutes.model_monitoring_definition.replace(':id', `${id}`));
  }, []);

  const handlePause = useCallback(
    async (modelId: ModelId) => {
      const model = await modelService.getById(modelId);

      if (model.schedule.status === ModelStatus.Paused) {
        await modelService.resume(modelId);
      } else {
        await modelService.pause(modelId);
      }

      // for future optimizations: instead of downloading the whole current page
      // we could update just current model
      downloadPage();
    },
    [curPage, curPageSize]
  );

  const handlePublish = useCallback(async (modelId: ModelId) => {
    const model = await modelService.getById(modelId);

    try {
      if (model.published) {
        await modelService.unpublish(modelId);
      } else {
        await modelService.publish(modelId);
      }
    } catch {
      console.log('error (un)publishing');
    }

    // for future optimizations: instead of downloading the whole current page
    // we could update just current model
    downloadPage();
  }, []);

  const handleRemoveModel = useCallback(
    (id: ModelId) => {
      setIsLoading(true);
      removeModel(id)
        .then(() => {
          const filteredModels = models.filter((model) => model.definition.id !== id);
          setModels(filteredModels);
          addToast(`Model deleted`, ToastType.SUCCESS);
        })
        .catch((err) => {
          addToast(`Error deleting model`, ToastType.ERROR);
          console.log('Error deleting model: ', err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [models]
  );

  let columns: Column[] = [
    {
      title: 'ID',
      field: 'id',
      type: ColumnType.STRING,
      style: {
        maxWidth: '75px',
      },
      renderCell: (row) => {
        if ((row as AppModel).definition.pin_to_landing) {
          return (
            <div className="inline">
              {row.definition?.id}

              <FontAwesomeIcon className="button-icon" icon={faThumbtack} size="lg" />
            </div>
          );
        }
        return row.definition?.id;
      },
    },
    {
      title: 'Name',
      field: 'definition.name',
      type: ColumnType.STRING,
      style: {
        maxWidth: '300px',
      },
    },
    {
      title: 'Model Class',
      field: 'definition.algo_spec.class_name',
      type: ColumnType.STRING,
      renderCell: (row) => {
        return (
          <div className="model-class">
            <div>{row.definition.algo_spec.class_name}</div>
            <div className="model-tags">
              {row.definition.tags?.map((tag: ModelTag) => (
                <ModelTagChip key={tag} tag={tag} onClick={() => ({})} />
              ))}
            </div>
          </div>
        );
      },
    },
    {
      title: 'Created at',
      field: 'createdAt',
      type: ColumnType.OBJECT,
      renderCell: (row) => {
        return dayjs(row.definition.created_at).format('YYYY-MM-DD');
      },
    },
    {
      title: 'Algorithm',
      field: 'definition.algo_spec.algo_name',
      type: ColumnType.STRING,
    },
    {
      title: 'Created by',
      field: 'definition.created_by',
      type: ColumnType.STRING,
      renderCell: (row) => {
        return row.definition.created_by.split('@')[0];
      },
    },
    {
      title: 'Runs',
      field: 'runs',
      type: ColumnType.OBJECT,
      renderCell: (row) => <ModelRunsSummary model={row as AppModel} />,
    },

    {
      title: 'Status',
      field: 'status',
      type: ColumnType.STRING,
      renderCell: (row) => (
        <StatusTag
          label={statusLabelMap[row.schedule.status as ModelStatus]}
          variant={statusVariantMap[row.schedule.status as ModelStatus]}
        />
      ),
    },
    {
      title: '',
      field: '',
      type: ColumnType.OBJECT,
      expandable: true,
      renderCell: (row) => (
        <div className="models-extended">
          <div className="models-extended-controls">
            <CFButton value="View Details" iconName={faEye} onClick={() => handleViewDefinition(row.definition.id)} />

            <ProtectedElement authAction={AuthAction.CreateModel}>
              <>
                {row.schedule.status === ModelStatus.Paused && (
                  <CFConfirmableButton title={'Resume model'} question={'Are you sure to resume this model?'}>
                    <CFButton value="Resume" iconName={faPlay} onClick={() => handlePause(row.definition.id)} />
                  </CFConfirmableButton>
                )}

                {row.schedule.status === ModelStatus.Running && (
                  <CFConfirmableButton title={'Pause model'} question={'Are you sure to pause this model?'}>
                    <CFButton value="Pause" iconName={faPause} onClick={() => handlePause(row.definition.id)} />
                  </CFConfirmableButton>
                )}

                {row.published && (
                  <CFConfirmableButton title={'Unpublish model'} question={'Are you sure to unpublish this model?'}>
                    <CFButton value="Unpublish" iconName={faEye} onClick={() => handlePublish(row.definition.id)} />
                  </CFConfirmableButton>
                )}

                {!row.published && (
                  <CFConfirmableButton title={'Publish model'} question={'Are you sure to unpublish this model?'}>
                    <CFButton value="Publish" iconName={faEye} onClick={() => handlePublish(row.definition.id)} />
                  </CFConfirmableButton>
                )}

                {row.removable && (
                  <CFConfirmableButton title={'Remove model'} question={'Are you sure to remove this model?'}>
                    <CFTrashButton onClick={() => handleRemoveModel(row.definition.id)} />
                  </CFConfirmableButton>
                )}
              </>
            </ProtectedElement>
          </div>
          <ModelDetails model={row as AppModel} />
        </div>
      ),
    },
  ];

  if (isAllowedTo(AuthAction.CreateModel)) {
    columns = [
      ...columns.slice(0, -1),
      {
        title: 'Visibility',
        field: 'published',
        type: ColumnType.STRING,
        renderCell: (row) => (
          <StatusTag
            label={row.published ? 'Published' : 'Unpublished'}
            variant={row.published ? StatusTagVariant.Success : StatusTagVariant.Failed}
          />
        ),
      },
      ...columns.slice(-1),
    ];
  }

  return (
    <CFLoadWrapper isLoading={isLoading}>
      <div className="models">
        <div className="models-controls">
          <div className="title">Models</div>
          <ProtectedElement authAction={AuthAction.CreateModel}>
            <CFButton
              value="Add Model"
              role={CFRole.Primary}
              iconName={faPlus}
              onClick={() => navigate(CFRoutes.model_new)}
            />
          </ProtectedElement>
        </div>
        <CFDataTable
          headers={columns}
          total={totalModels}
          data={models.map((model) => ({ ...model, id: model.definition.id }))}
          onPaginated={handlePageChange}
          indexCol={'id'}
        />
      </div>
    </CFLoadWrapper>
  );
};

export default ModelList;
