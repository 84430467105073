import React, { useEffect, useState } from 'react';
import { useToast } from 'hooks';

import CFLoadWrapper from 'components/CFLoadWrapper';
import CFLineChart from 'components/charts/CFLineChart';
import { ToastType } from 'components/CFToast/types';

import { AggLevel, TimeSeriesItem } from 'domain/stats.types';
import { ColAddr, Trait } from 'domain/traits.types';
import { TimeRFC3999 } from 'domain/general.types';

import { useServicesContext } from 'hooks/useServicesContext';
import { chartLineColor1 } from 'styles/colors';

import { sortedChartsDaily, sortedChartsMonthly } from '..';

import './timeseries.scss';
import { getDisplayName } from 'services/traits/helpers.traits';

interface Props {
  traits: Trait[];
  backfillingID: number;
  startDate: TimeRFC3999;
  endDate: TimeRFC3999;
  aggLevel?: AggLevel;
}

const Timeseries = ({ traits, startDate, endDate, backfillingID, aggLevel = AggLevel.Day }: Props) => {
  const { addToast } = useToast();

  const [timeSeries, setTimeSeries] = useState<Record<string, TimeSeriesItem[]>>({});
  const { traitSessionService: traitService, landingService } = useServicesContext();
  const [allSubjectCohortId, setAllSubjectCohort] = useState<number>(-1);

  const sortedCharts = aggLevel === AggLevel.Day ? sortedChartsDaily : sortedChartsMonthly;

  const updateTimeseries = () => {
    if (!traits) {
      return;
    }

    if (allSubjectCohortId === -1) {
      return;
    }

    // do not download timeseries for all the traits, but just
    // for those we want to show in this screen
    const timeseriesPromises = Promise.all(
      sortedCharts.map((traitName) =>
        traitService.getTimeseries(
          startDate,
          endDate,
          traitService.getTraitDefinition(traitName)?.addr as ColAddr,
          `${allSubjectCohortId}`
        )
      )
    );

    timeseriesPromises.then((traitTimeseries) => {
      const newTimeseries = { ...timeSeries };

      traitTimeseries.forEach((serie, i) => {
        const name = sortedCharts[i];
        newTimeseries[name] = serie;
      });

      setTimeSeries(newTimeseries);
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const landingCohortId = await landingService.getLandingCohortId();
        setAllSubjectCohort(landingCohortId);
      } catch (err: any) {
        addToast(`Error getting analytics information: ${err.message}`, ToastType.ERROR, 5000);
      }
    })();
  }, []);

  useEffect(() => {
    updateTimeseries();
  }, [backfillingID]);

  useEffect(() => {
    updateTimeseries();
  }, [startDate, endDate, traits, allSubjectCohortId, aggLevel]);

  return (
    <div className="landing-timeseries">
      {sortedCharts.map((seriesName) => {
        const trait = traitService.getTraitDefinition(seriesName);

        return (
          <CFLoadWrapper key={seriesName} isLoading={!timeSeries[seriesName]}>
            <CFLineChart
              key={seriesName}
              yLabel={trait?.meta.display_unit}
              units={trait?.meta.display_unit ?? ''}
              title={getDisplayName(trait)}
              color={chartLineColor1}
              data={[
                {
                  name: getDisplayName(trait),
                  items: timeSeries[seriesName] || [],
                },
              ]}
              aggregationLevel={aggLevel}
              isLoading={false}
            />
          </CFLoadWrapper>
        );
      })}
    </div>
  );
};

export default Timeseries;
