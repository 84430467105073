import React, { useCallback, useEffect, useState } from 'react';

import debounce from 'debounce';

import useCFNavigation from 'hooks/useCFNavigation';

import { NewInterventionRequest } from 'services/intervention/intervention.types';

import { ToastType } from 'components/CFToast/types';
import { CFNavList } from 'components/CFNavList';
import CFButton from 'components/buttons/CFButton';
import Wizard from 'components/CFWizard';

import InterventionDefinition from './definition';
import { InterventionABMetricsPolicy } from './metricsPolicy';
import InterventionNudgePolicy from './nudgePolicy';

import { allowedTabs, interventionByTab, Tabs } from '../..';

import { CFRoutes } from 'routes';
import { CFRole } from 'domain/general.types';

import useToast from 'hooks/useToast';

import { useInterventionContext } from 'views/intervention/useContext';

import { Steps } from '.';

import pagePlusIcon from 'assets/icons/pagePlus.svg';

import InterventionParticipantsAB from './participants/participants-ab';
import InterventionSection from './interventionSection';
import SchedulingBuilder from 'connected-components/SchedulingBuilder';
import { useServicesContext } from 'hooks/useServicesContext';

import useSourceIntervention from './hooks/useSourceIntervention';

import './intervention.scss';

interface Props {
  updateReadiness: (isReady: boolean, step: Steps) => void;
}

const NewABIntervention = ({ updateReadiness }: Props) => {
  const navigate = useCFNavigation();

  const {
    cohortService,
    nudgeService,
    traitSessionService: traitService,
    modelService,
    interventionService,
  } = useServicesContext();

  const sourceIntervention = useSourceIntervention();
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const {
    sampleId,
    subject,
    definitionRef,
    participantsRef,
    nudgePolicyRef,
    metricsPolicyRef,
    schedulingRef,
    readiness,
  } = useInterventionContext();

  useEffect(() => {
    return () => interventionService.stopDraft();
  }, []);

  const buildNewInterventionObject = (): NewInterventionRequest => {
    const { name, description, algorithm } = definitionRef.current.value();

    const request: NewInterventionRequest = {
      intervention: {
        name,
        description,
        participant_policy: participantsRef.current?.value().participants,
        nudge_policy: nudgePolicyRef.current?.value(),
        metric_policy: metricsPolicyRef.current?.value(),
        algo_policy: { ...algorithm, split_ratio: participantsRef.current?.value().splitRatio },
      },
      schedule: schedulingRef.current?.value(),
    };

    return request;
  };

  const handleSaveDraft = async () => {
    const newInterventionRequest = buildNewInterventionObject();

    await interventionService.saveDraft(newInterventionRequest.intervention, newInterventionRequest.schedule);
  };

  // note: debounce returns a function
  // eslint-disable-next-line
  const debouncedSaveDraft = useCallback(debounce(handleSaveDraft, 2000), []);

  const handleCreateIntervention = async () => {
    const newInterventionRequest = buildNewInterventionObject();

    try {
      setLoading(true);
      await interventionService.create(newInterventionRequest.intervention, newInterventionRequest.schedule);

      addToast('Intervention created', ToastType.SUCCESS, 5000);

      navigate(CFRoutes.intervention);
    } catch (err) {
      addToast(`Impossible to create intervention ${(err as any).message}`, ToastType.ERROR, 5000);
      setLoading(false);

      console.log('Error creating intervention: ', err);
    }
  };

  return (
    <div className="intervention">
      <Wizard>
        <Wizard.Header>
          <CFNavList
            titles={allowedTabs()}
            selected={Tabs.Interventions}
            onClick={(selectedTab) => navigate(interventionByTab[selectedTab])}
          />
        </Wizard.Header>

        <Wizard.Step name={Steps.Definition} ready={readiness.definition}>
          <InterventionDefinition
            ref={definitionRef}
            modelService={modelService}
            defaultValue={
              sourceIntervention && {
                name: sourceIntervention.intervention.name,
                description: sourceIntervention.intervention.description,
                algorithm: sourceIntervention.intervention.algo_policy,
              }
            }
            onReady={(thisReady) => updateReadiness(thisReady, Steps.Definition)}
          />
        </Wizard.Step>

        <Wizard.Step name={Steps.Nudge} ready={readiness.nudge}>
          <InterventionNudgePolicy
            nudgeService={nudgeService}
            traitService={traitService}
            defaultValue={sourceIntervention && sourceIntervention.intervention.nudge_policy}
            ref={nudgePolicyRef}
            onReady={(thisReady) => updateReadiness(thisReady, Steps.Nudge)}
          />
        </Wizard.Step>

        <Wizard.Step name={Steps.Participants} ready={readiness.participants}>
          <InterventionParticipantsAB
            ref={participantsRef}
            traitService={traitService}
            cohortService={cohortService}
            onReady={(thisReady) => updateReadiness(thisReady, Steps.Participants)}
          />
        </Wizard.Step>

        <Wizard.Step name={Steps.Metrics} ready={readiness.metrics}>
          <InterventionABMetricsPolicy
            ref={metricsPolicyRef}
            sampleId={sampleId.value}
            subject={subject}
            cohortService={cohortService}
            traitService={traitService}
            onReady={(thisReady) => updateReadiness(thisReady, Steps.Metrics)}
          />
        </Wizard.Step>

        <Wizard.Step name={Steps.Scheduling} ready={readiness.scheduling}>
          <InterventionSection
            name={Steps.Scheduling}
            title="Scheduling"
            subtitle="Set schedules for your intervention."
          >
            <SchedulingBuilder
              defaultValue={sourceIntervention?.schedule}
              ref={schedulingRef}
              onReady={(thisReady) => updateReadiness(thisReady, Steps.Scheduling)}
            />
          </InterventionSection>
        </Wizard.Step>

        <Wizard.Footer>
          <div className="controls">
            <CFButton
              value="Create Intervention"
              role={CFRole.Primary}
              disabled={Object.values(readiness).some((ready) => !ready)}
              isLoading={loading}
              onClick={handleCreateIntervention}
              iconCustom={<img src={pagePlusIcon} />}
            />
          </div>
        </Wizard.Footer>
      </Wizard>
    </div>
  );
};

export default NewABIntervention;
