import React, { useMemo, useRef } from 'react';

import { StatItem } from 'domain/stats.types';

import { EChartsOption, LinearGradient, ReactECharts } from '..';

import colors from 'common.scss';
import { tooltip } from '../chartCommon';

import './cf-histogram.scss';

interface Props {
  data: StatItem[];
  xLabel?: string;
  title: string;
  color?: string;
  barWidth?: number;
  isLoading: boolean;
}

const CFHistogram = ({
  data,
  title,
  xLabel = '',
  color = 'rgba(148, 213, 219, 0.6)',
  barWidth = 10,
  isLoading = true,
}: Props) => {
  const chartRef = useRef<HTMLDivElement>(null);

  const chartContainerStyle = {
    width: '100%',
    height: '200px',
  };

  const options: EChartsOption = useMemo(() => {
    const seriesX = data.map((dataItem) => dataItem.count);
    const seriesY = data.map((dataItem) => dataItem.type);

    const option: EChartsOption = {
      tooltip: {
        ...tooltip(),
      },
      legend: {},
      grid: {
        left: '5%',
        right: '5%',
        bottom: '10%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: seriesY,
        boundaryGap: [0, 0.01],
        name: xLabel,
        nameLocation: 'middle',
        nameGap: 30,
        nameTextStyle: {
          color: colors.dark50,
          fontSize: 14,
          fontWeight: 600,
        },
      },
      yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: color,
          },
        },
        splitLine: {
          lineStyle: {
            color: '#333',
          },
        },
        axisLabel: {
          color: colors.dark60,
        },
      },
      series: [
        {
          type: 'bar',
          animation: false,

          data: seriesX,
          color,
          barWidth: barWidth,
          itemStyle: {
            borderRadius: [4, 4, 0, 0], // Specify the border radius
            borderColor: color,
            borderWidth: 1,
            borderType: 'solid',
            color: new LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: color,
              },
              {
                offset: 1,
                color: 'rgba(148, 213, 219, 0)',
              },
            ]),
          },
        },
      ],
    };

    return option;
  }, [data, title]);

  return (
    <div className="cf-histogram">
      {title}
      <ReactECharts ref={chartRef} option={options} style={chartContainerStyle} loading={isLoading} />
    </div>
  );
};

export default CFHistogram;
