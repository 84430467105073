import React from 'react';
import classNames from 'classnames';

interface Props extends React.PropsWithChildren {
  className?: string;
}

const Tabs: React.FC<Props> = ({ children, className }) => {
  return <div className={classNames('cf-tabs', className)}>{children}</div>;
};

export default Tabs;
