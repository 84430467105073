import React, { PropsWithChildren, createContext, useEffect, useState } from 'react';

import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Toast, ToastType } from 'components/CFToast/types';
import CFToast from 'components/CFToast';

export interface IToast {
  addToast: (message: string, state: ToastType, duration?: number) => void;
}

export const ToastContext = createContext<IToast | null>(null);

const ToastProvider = (props: PropsWithChildren) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const addToast = (message: string, state: ToastType, duration?: number) => {
    setToasts((prevToast) => [...prevToast, { id: Date.now(), message: message, state: state, duration: duration }]);
  };

  const removeToast = (id: number) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id != id));
  };

  useEffect(() => {
    if (toasts.length > 0) {
      const timer = setTimeout(() => removeToast(toasts[0].id ?? 0), toasts[0].duration ?? 3000);
      return () => clearTimeout(timer);
    }
  }, [toasts]);

  return (
    <ToastContext.Provider value={{ addToast }}>
      {props.children}
      <TransitionGroup className="cf-toast-list">
        {toasts.map((toast) => (
          <CSSTransition
            key={toast.id}
            timeout={500} // duration of transition
            classNames="toast" // class prefix for the transition stages
          >
            <CFToast
              key={toast.id}
              state={toast.state}
              message={toast.message}
              onClose={() => removeToast(toast.id ?? 0)}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
    </ToastContext.Provider>
  );
};

export default ToastProvider;
