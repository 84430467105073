import React, { FC } from 'react';

import './day.scss';
import dayjs from 'dayjs';

interface DayProps {
  filled?: boolean;
  outlined?: boolean;
  highlighted?: boolean;
  disabled?: boolean;
  isCurrentMonth?: boolean;
  startOfRange?: boolean;
  endOfRange?: boolean;
  onClick?: () => void;
  onHover?: () => void;
  value: Date;
}

const Day: FC<DayProps> = ({
  filled,
  outlined,
  highlighted,
  disabled,
  isCurrentMonth,
  startOfRange,
  endOfRange,
  onClick,
  onHover,
  value,
}) => {
  return (
    <div
      className={`
        date-time-range-day-container
        ${
          startOfRange && endOfRange
            ? 'border-radius'
            : startOfRange
            ? 'left-border-radius'
            : endOfRange
            ? 'right-border-radius'
            : ''
        }
        ${!disabled && isCurrentMonth && highlighted ? 'highlighted' : ''} 
        ${!disabled && isCurrentMonth && outlined ? 'outlined' : ''} 
        ${isCurrentMonth && filled ? 'filled' : ''}
      `}
      onClick={onClick}
      onMouseOver={onHover}
    >
      <button
        className={`day-button `}
        disabled={disabled || !isCurrentMonth}
        data-testid={dayjs(value).format('YYYY-MM-DD')}
      >
        <span
          className={`day-text ${!disabled && filled ? 'contrast' : ''} ${
            !isCurrentMonth ? 'invisible' : ''
          } ${disabled ? 'disabled' : ''}`}
        >
          {dayjs(value).date()}
        </span>
      </button>
    </div>
  );
};

export default Day;
