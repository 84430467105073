import React, { useEffect, useMemo, useRef, useState } from 'react';

import moment from 'moment';

import { AggLevel } from 'domain/stats.types';
import {
  Trait,
  TraitSubject,
  TraitTimeseriesTypesDaily,
  TraitTimeseriesTypesMonthly,
  TraitType,
} from 'domain/traits.types';

import { CFRole, TimeRFC3999 } from 'domain/general.types';

import { CohortID } from 'services/cohort/cohort.types';
import { getCurrentProject, isBackfilling as isBackfillingService } from 'services/session/session.service';
import { AuthAction } from 'services/authorization.service';

import { getEndDate, nineMonthsAgo } from 'helpers/dates';
import { toString as dateToString } from 'helpers/dates';

import DatetimeRangePicker from 'components/DateTime/DatetimeRangePicker';
import { DateRange, DefinedRange } from 'components/DateTime/DatetimeRangePicker/types';
import { ToastType } from 'components/CFToast/types';
import CFButton from 'components/buttons/CFButton';
import { defaultRanges } from 'components/DateTime/DatetimeRangePicker/default';
import { KPI } from 'components/kpis';
import CFButtonGroup from 'components/CFButtonGroup';

import BackfillModal from './Backfill';
import KPIMatrix from './KPIMatrix';
import Timeseries from './Timeseries';
import CohortInfo from './CohortInfo';

import { useClickOutside, useToast } from 'hooks';
import { useServicesContext } from 'hooks/useServicesContext';

import './landing.scss';
import ProtectedElement from 'connected-components/ProtectedElement';

export interface DashboardDataItem {
  title: string;
  kpi_list: KPI[];
}

export const sortedChartsDaily = [
  TraitTimeseriesTypesDaily.ActiveUserCount,
  TraitTimeseriesTypesDaily.ConnectionTimeMean,
  TraitTimeseriesTypesDaily.NewUserCount,
];

export const sortedChartsMonthly = [
  TraitTimeseriesTypesMonthly.ActiveUserCount,
  TraitTimeseriesTypesMonthly.ConnectionTimeMean,
];

const Analytics = () => {
  const { backfillService, traitSessionService: traitService } = useServicesContext();

  const { addToast } = useToast();

  const project = getCurrentProject();

  const [startDate, setStartDate] = useState<string>(nineMonthsAgo());
  const [endDate, setEndDate] = useState<string>(getEndDate(project?.created_at));

  // we need to implement a global state to handle the case where
  // a user switch the active tab
  const [isBackfilling, setIsBackfilling] = useState(isBackfillingService());
  const [backfillingID, setBackfillingID] = useState(0);
  const backfillButtonRef = useRef<HTMLButtonElement>(null);
  const [backfillPopupSwitcher, setBackfillPopupSwitcher] = useState<boolean>(false);
  const [backfillPopupPosition, setBackfillPopupPosition] = useState({ top: 0, left: 0 });

  const [traits, setTraits] = useState<Trait[]>([]);

  const [aggLevel, setAggLevel] = useState<AggLevel>(AggLevel.Day);

  useEffect(() => {
    if (!project) {
      return;
    }

    if (startDate < project?.created_at) {
      setStartDate(project.created_at);
    }
  }, []);

  useEffect(() => {
    const checkBackfillInterval = setInterval(() => {
      // quick and dirty solution to check backfilling state
      // need a subscribtions mechanism to detect it automatically
      // maybe global statue mng like redux, or just convert
      // this service into a class to be able to trigger events
      setIsBackfilling(isBackfillingService());
    }, 200);

    return () => clearInterval(checkBackfillInterval);
  }, []);

  useEffect(() => {
    (async () => {
      const dailyTraits = await traitService.getTraits({ subject: TraitSubject.User, aggLevel: AggLevel.Day });
      const monthlyTraits = await traitService.getTraits({ subject: TraitSubject.User, aggLevel: AggLevel.Month });

      setTraits([...dailyTraits, ...monthlyTraits]);
    })();
  }, []);

  const handleRangeChange = (dateRange: DateRange) => {
    if (dateRange.startDate && dateRange.endDate) {
      setStartDate(dateToString(dateRange.startDate));
      setEndDate(dateToString(dateRange.endDate));
    }
  };

  const defaultDateRanges: DefinedRange[] = useMemo(
    () => [
      ...defaultRanges,
      {
        label: 'Full history',
        startDate: moment(project?.created_at ?? 0).toDate(),
        endDate: new Date(),
      },
    ],
    [project]
  );

  const handleBackfill = async (
    startDate: TimeRFC3999,
    endDate: TimeRFC3999,
    aggLevel: AggLevel,
    selectedSubject: TraitSubject,
    tableType: TraitType,
    cohortIds: CohortID[],
    recomputeSubjects: boolean
  ) => {
    setIsBackfilling(true);

    setTimeout(async () => {
      try {
        await backfillService.backfill(
          startDate,
          endDate,
          aggLevel,
          selectedSubject,
          tableType,
          cohortIds,
          recomputeSubjects
        );
      } catch (e: any) {
        addToast(`Error backfilling: ${e.message}`, ToastType.ERROR, 5000);
      } finally {
        setIsBackfilling(false);
      }

      setBackfillingID(Date.now());
    }, 0);
  };

  const toggleBackfillPopup = (evt: React.MouseEvent<HTMLElement>) => {
    setBackfillPopupSwitcher((prevState) => !prevState);
    const rect = evt.currentTarget.getBoundingClientRect();
    setBackfillPopupPosition({ top: rect.top, left: rect.left });
  };

  const menuRef = useRef<HTMLDivElement>(null);

  useClickOutside(menuRef, () => {
    setBackfillPopupSwitcher(false);
  });

  return (
    <div className="analytics">
      <div className="always-visible">
        <CohortInfo sticky={true} />
      </div>
      <KPIMatrix traits={traits} />
      <div className="controls">
        <ProtectedElement authAction={AuthAction.Backfill}>
          <CFButton buttonRef={backfillButtonRef} value="Backfill" role={CFRole.Cyan} onClick={toggleBackfillPopup} />
        </ProtectedElement>

        {backfillPopupSwitcher && (
          <BackfillModal
            ref={menuRef}
            handleBackfill={handleBackfill}
            isOpen={backfillPopupSwitcher}
            position={backfillPopupPosition}
            isBackfilling={isBackfilling}
            onCancel={() => setBackfillPopupSwitcher(false)}
          />
        )}
        <CFButtonGroup
          className="agg-selector"
          options={[
            {
              label: 'Daily',
              value: AggLevel.Day,
            },
            {
              label: 'Monthly',
              value: AggLevel.Month,
            },
          ]}
          value={{ label: aggLevel, value: aggLevel }}
          onSelect={(option) => setAggLevel(option.value)}
        />
        <DatetimeRangePicker
          onChange={handleRangeChange}
          definedRanges={defaultDateRanges}
          initialDateRange={{
            startDate: new Date(startDate),
            endDate: new Date(endDate),
          }}
          maxDate={new Date(endDate)}
          showTime={true}
        />
      </div>
      <Timeseries
        traits={traits}
        startDate={startDate}
        endDate={endDate}
        backfillingID={backfillingID}
        aggLevel={aggLevel}
      />
    </div>
  );
};

export default Analytics;
