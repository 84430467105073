import React from 'react';

import useChurnRatio from '../useChurnRatio';

import './churn-info.scss';

const ChurnInfo = () => {
  const churnRatio = useChurnRatio();

  return (
    <div className="churn-info">
      <div className="churn-info__item">
        <div>Churn definition:</div>
        <div>{churnRatio?.value || '-'} days</div>
      </div>

      <div className="churn-info__item">
        <div>Monitoring window:</div>
        <div>{(churnRatio?.value || 0) * 2} days</div>
      </div>
    </div>
  );
};

export default ChurnInfo;
