export type Role = string;

export enum UserRole {
  User = 'user',
  Admin = 'admin',
}

export enum ProjectRole {
  User = 'user',
  InterventionCreator = 'intervention_creator',
  Viewer = 'viewer',
}

export interface UserAdminRolesUpdateRequest {
  username: string;
  roles: Role[];
}

export interface UserRolesInOrganization {
  oid: number;
  org_roles: UserRole[];
  proj_roles: Record<number, Role[]>;
}

export interface UserInfo {
  username: string;
  superuser: boolean;
  roles: Record<number, UserRolesInOrganization>;
  admin_roles: Role[];
  created_at: string;
}

export interface RoleList {
  admin_roles: Role[];
  roles: Role[];
}
