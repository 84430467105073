export enum Units {
  MillisIS = 'ms',
  SecondIS = 's',
  MinuteIS = 'min',
  HourIS = 'h',
  Millis = 'milliseconds',
  Second = 'second',
  Seconds = 'seconds',
  Minute = 'minute',
  Minutes = 'minutes',
  Day = 'day',
  Days = 'days',
  Kbps = 'kbps',
  Mbps = 'mbps',
  User = 'user',
  Users = 'users',
  Percentage = '%',
  Ratio = 'ratio',
}

const noop = (value: number) => value;

export const conversions: Record<string, Record<string, (value: number) => number>> = {
  [Units.Second]: {
    [Units.SecondIS]: noop,
    [Units.Second]: noop,
    [Units.MillisIS]: (value: number) => value * 1000,
    [Units.Millis]: (value: number) => value * 1000,
    [Units.MinuteIS]: (value: number) => value / 60,
    [Units.Minute]: (value: number) => value / 60,
  },
  [Units.SecondIS]: {
    [Units.SecondIS]: noop,
    [Units.Second]: noop,
    [Units.MillisIS]: (value: number) => value * 1000,
    [Units.Millis]: (value: number) => value * 1000,
    [Units.MinuteIS]: (value: number) => value / 60,
    [Units.Minute]: (value: number) => value / 60,
  },
  [Units.Day]: {
    [Units.Day]: noop,
    [Units.Days]: noop,
  },
  [Units.Kbps]: {
    [Units.Second]: noop,
    [Units.Mbps]: (value: number) => value / 1000,
  },
  [Units.User]: {
    [Units.User]: noop,
    [Units.Users]: noop,
  },
  [Units.Ratio]: {
    [Units.Percentage]: (value: number) => value * 100,
  },
  [Units.Second]: {
    [Units.Minute]: (value: number) => value / 60,
  },
};

export const convertUnitsFromTo = (value: number, from: string, to: string) => {
  const lowercaseFrom = (from || '').toLowerCase();
  const lowercaseTo = (to || '').toLowerCase();

  if (!conversions[lowercaseFrom] || !conversions[lowercaseFrom][lowercaseTo]) {
    return value;
  }

  return conversions[lowercaseFrom][lowercaseTo](value);
};
