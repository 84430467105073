import { AnalyticSummary } from 'domain/analytic.types';
import { Trait } from 'domain/traits.types';
import { CFAnalyticRepository } from 'repositories/analytic';
import { CFCohortRepository } from 'services/cohort/cohort.repo';
import { CFTraitRepository } from 'services/traits/traits.repo';
import CFService from 'services/cfservice';
import { CFSessionService } from 'services/session/session.service';
import { updateLandingCohort } from 'services/cohort/cohort.repo.landing';
import VOChurn from './domain/VOChurn';

export default class LandingService extends CFService {
  analyticsPromise: Promise<AnalyticSummary> | null = null;

  traitRepository: CFTraitRepository;
  analyticRepository: CFAnalyticRepository;
  cohortRepository: CFCohortRepository;
  sessionService: CFSessionService;
  traitDefinitions: Record<string, Trait> = {};

  constructor(
    traitRepository: CFTraitRepository,
    analyticRepository: CFAnalyticRepository,
    cohortRepository: CFCohortRepository,
    sessionService: CFSessionService
  ) {
    super();
    this.traitRepository = traitRepository;
    this.analyticRepository = analyticRepository;
    this.cohortRepository = cohortRepository;
    this.sessionService = sessionService;
  }

  async init() {
    //
  }

  async getLandingCohortId(): Promise<number> {
    if (!this.analyticsPromise) {
      this.analyticsPromise = this.analyticRepository.get();
    }

    const analytics = await this.analyticsPromise;

    return analytics.landing_cohort_id;
  }

  async setCohortForLanding(cohortId: string) {
    await updateLandingCohort(cohortId);

    this.analyticsPromise = null;
  }

  async getAnalytics(): Promise<AnalyticSummary> {
    if (!this.analyticsPromise) {
      this.analyticsPromise = this.analyticRepository.get();
    }

    const analytics = await this.analyticsPromise;
    return analytics;
  }

  async getChurnDefinition(): Promise<VOChurn> {
    const CHURN_RATIO_PREFIX = 'gdt_user_general.churn_ratio';

    if (!this.analyticsPromise) {
      this.analyticsPromise = this.analyticRepository.get();
    }

    const analytics = await this.analyticsPromise;

    const churnDefinition = Object.keys(analytics.trait_summary_results).find((key) =>
      key.startsWith(CHURN_RATIO_PREFIX)
    );

    return new VOChurn(churnDefinition);
  }
}
