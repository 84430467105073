import React, { useEffect } from 'react';
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import ContentEditable from 'react-contenteditable';

import CFInput from 'components/CFInput';
import CFTitledComponent from 'components/CFTitledComponent';
import CFSelect, { Option } from 'components/CFSelect';

import { Message, TemplateType } from 'services/nudging/nudge.types';
import { Trait } from 'domain/traits.types';

import { sanitizeHtml, templateToHtml } from 'services/nudging/templateParsing';
import { createTraitCode } from 'services/traits/helpers.traits';
import { Ptr } from 'services/cohort/cohort.types.api';

import TraitsConfiguration, { TraitsConfigurationRef } from './TraitsConfiguration';
import ItemPairConfiguration, { ItemPairConfigurationRef } from './ItemPairConfiguration';

import './nudge-message-form.scss';

interface NewNudgeFormProps {
  onMessageChange: (message: string) => void;
  onTitleChange: (title: string) => void;
  traits: Trait[];
}

interface NudgeMessage {
  message: Partial<Message>;
}

export interface NudgeMessageRef {
  value: () => NudgeMessage;
}

const NudgeMessageForm = forwardRef<NudgeMessageRef, NewNudgeFormProps>(function NewNudgeMessageForm(
  { traits, onMessageChange, onTitleChange }: NewNudgeFormProps,
  ref
) {
  const titleRef = useRef<HTMLInputElement | null>(null);
  const bodyRef = useRef<HTMLDivElement | null>(null);
  const itemPairConfig = useRef<ItemPairConfigurationRef | null>(null);
  const traitsConfig = useRef<TraitsConfigurationRef | null>(null);

  const [recommendationTypeOption, setRecommendationTypeOption] = useState<Option>({
    value: TemplateType.None,
    label: 'No recommendation',
  });

  const [content, setContent] = useState('');

  const traitCodes = traits.reduce<Record<string, Trait>>((acc, cur) => {
    acc[createTraitCode(cur)] = cur;

    return acc;
  }, {});

  useImperativeHandle(ref, () => ({
    value() {
      const nudgeMessage: NudgeMessage = {
        message: {
          title: titleRef.current?.value || '',
          body: bodyRef.current?.textContent || '',
        },
      };

      if (recommendationTypeOption.value === TemplateType.ItemPairTemplate) {
        nudgeMessage.message.tmpl_cfg = itemPairConfig.current?.value();
      }

      if (recommendationTypeOption.value === TemplateType.TraitsTemplate) {
        nudgeMessage.message.tmpl_cfg = traitsConfig.current?.value();
      }

      return nudgeMessage;
    },
  }));

  const handleRecommendationSelected = async (option: Option) => {
    setRecommendationTypeOption(option);

    if (option.value === TemplateType.ItemPairTemplate || option.value === TemplateType.RandomItemPairTemplate) {
      // ask the service to add this info

      setContent(templateToHtml(' {{ primary }} {{ secondary }} ', ['primary', 'secondary']));
    } else {
      setContent('');
    }
  };

  /*  const traitsFromTemplate = (text: string) => {
    const matches = text.match(/{{ ([^{}]*) }}/g) || [];
    const vars = matches.map((v) => v.replace(/{{ ([^{}]*) }}/g, '$1'));

    const traits = Array.from(new Set(vars))
      .filter((v) => traitCodes[v])
      .map((v) => traitCodes[v]);

    return traits;
  };*/

  const handleMessageChange = useCallback(() => {
    const keywords =
      recommendationTypeOption.value === TemplateType.ItemPairTemplate
        ? ['primary', 'secondary']
        : Object.keys(traitCodes);

    const input = sanitizeHtml(bodyRef.current?.textContent || '');
    const html = templateToHtml(input, keywords);

    setContent(html);
    onMessageChange(bodyRef.current?.textContent || '');
  }, [traitCodes]);

  const handleTitleChange = useCallback(() => {
    onTitleChange(titleRef.current?.value || '');
  }, []);

  const handleTraitSelected = useCallback(
    async (ptr: Ptr) => {
      setContent((content) => `${content} ${templateToHtml(`{{ ${ptr} }}`, Object.keys(traitCodes))}`);
    },
    [content, traitCodes]
  );

  useEffect(() => {
    onMessageChange(bodyRef.current?.textContent || '');
  }, [content]);

  return (
    <div className={`new-nudge-form`}>
      <CFTitledComponent title="Title">
        <CFInput ref={(el) => (titleRef.current = el)} onChange={handleTitleChange} />
      </CFTitledComponent>

      <CFTitledComponent title="Recommendation">
        <CFSelect
          testId="nudge-message-form-template-type-select"
          onSelected={handleRecommendationSelected}
          options={[
            { label: 'No recommendation', value: TemplateType.None },
            { label: 'Trait', value: TemplateType.TraitsTemplate },
            { label: 'Item pair', value: TemplateType.ItemPairTemplate },
          ]}
          value={recommendationTypeOption}
        />
      </CFTitledComponent>

      {recommendationTypeOption.value === TemplateType.TraitsTemplate && (
        <TraitsConfiguration ref={traitsConfig} traits={traits} onTraitSelected={handleTraitSelected} />
      )}

      {recommendationTypeOption.value === TemplateType.ItemPairTemplate && (
        <ItemPairConfiguration ref={itemPairConfig} />
      )}

      <CFTitledComponent title="Custom message">
        <ContentEditable
          role="textbox"
          innerRef={bodyRef}
          html={content}
          onChange={handleMessageChange}
          className="editable-area"
        />
      </CFTitledComponent>
    </div>
  );
});

export default NudgeMessageForm;
