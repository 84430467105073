import Tab from './Tab';
import TabContext from './TabContext';
import TabPanel from './TabPanel';
import Tabs from './Tabs';

import './cf-tabs.scss';

export { HideType } from './TabContext';

export default {
  Tab,
  TabContext,
  TabPanel,
  Tabs,
};
