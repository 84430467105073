import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Trait } from 'domain/traits.types';

import CFTrashButton from 'components/buttons/CFTrashButton';
import CFTraitItem from 'connected-components/traits/CFTraitItem';
import SampleVSCohortComparator from '../SampleVSCohortComparator';

import { useInterventionContext } from 'views/intervention/useContext';

import CohortService from 'services/cohort/cohort.service';
import Cohort from 'services/cohort/domain/Cohort';

import './traits-list-item.scss';
import VOSampleId from 'services/intervention/domain/VOSampleId';

interface Props {
  trait: Trait;
  sampleId: VOSampleId;
  cohortService: CohortService;
  onDelete: (trait: Trait) => void;
}

const TraitListItem = ({ trait, sampleId, cohortService, onDelete }: Props): JSX.Element => {
  const [expanded, setExpanded] = useState(false);
  const { cohort } = useInterventionContext();

  const handleExpandClick = () => setExpanded((prev) => !prev);

  return (
    <>
      <div className="trait-list-item">
        <div className="trait-list-item-element">
          <CFTrashButton onClick={() => onDelete(trait)} />
        </div>
        <div className="trait-list-item-element">
          <CFTraitItem addr={trait.addr} />
        </div>
        <div className="trait-list-item-element">{trait.addr.dtype}</div>
        <div className="trait-list-item-element">{trait.meta.description}</div>
        <div className="trait-list-item-expandable" onClick={handleExpandClick}>
          {expanded ? (
            <FontAwesomeIcon icon={icon({ name: 'angle-up', style: 'solid' })} />
          ) : (
            <FontAwesomeIcon icon={icon({ name: 'angle-down', style: 'solid' })} />
          )}
        </div>
      </div>
      {expanded && (
        <SampleVSCohortComparator
          addr={trait.addr}
          cohort={cohort as Cohort}
          sampleId={sampleId}
          cohortService={cohortService}
        />
      )}
    </>
  );
};

export default TraitListItem;
