import React, { useState } from 'react';
import dayjs from 'dayjs';

import { Column, ColumnType } from 'components/CFTable';
import CFDataTable from 'components/CFDataTable';

import { Model } from 'domain/model.types';

import './runs.scss';

interface Props {
  model: Model;
}

const ModelRuns = ({ model }: Props) => {
  const [curPage, setCurPage] = useState(0);
  const [curPageSize, setCurPageSize] = useState(5);

  const runs = Object.entries(model.schedule.slots || {});

  const handlePageChange = (page: number, size: number) => {
    setCurPage(page - 1);
    setCurPageSize(size);
  };

  const columns: Column[] = [
    {
      title: 'Run time',
      field: 'id',
      type: ColumnType.STRING,
      renderCell: (row) => {
        return dayjs(row[0]).tz().format('YYYY-MM-DD HH:mm z');
      },
    },
    {
      title: 'Status',
      field: 'status',
      type: ColumnType.STRING,
      renderCell: (row) => {
        return row[1].status;
      },
    },
    {
      title: 'Details',
      field: 'details',
      type: ColumnType.STRING,
      renderCell: (row) => {
        return row[1].history?.[0]?.detail;
      },
    },
  ];

  return (
    <div className="model-definition-runs">
      <CFDataTable
        headers={columns}
        total={runs.length}
        data={runs?.slice(curPage * curPageSize, (curPage + 1) * curPageSize)}
        pageSizeList={[5]}
        onPaginated={handlePageChange}
      />
    </div>
  );
};

export default ModelRuns;
