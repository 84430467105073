import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { createSearchParams, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube, faPlus } from '@fortawesome/free-solid-svg-icons';

import useCFNavigation, { sessionQueryInfo } from 'hooks/useCFNavigation';

import { CFRoutes } from 'routes';
import { ReactComponent as UsersLogo } from 'users.svg';

import ProtectedElement from 'connected-components/ProtectedElement';

import CFButton from 'components/buttons/CFButton';
import { CFNavList } from 'components/CFNavList';
import CFSelectLegacy, { SelectableItem } from 'components/CFSelectLegacy';
import CFDataTable from 'components/CFDataTable';
import { Column, ColumnType } from 'components/CFTable';
import { Tag, TagTypes } from 'components/Tag';
import CFLoadWrapper from 'components/CFLoadWrapper';

import CohortDetail from 'views/segmentation/cohorts/ListOfCohorts/CohortDetail';

import { getOrganization, getProject, getUserInfo } from 'services/session/session.service';
import { AuthAction } from 'services/authorization.service';

import { TraitSubject } from 'domain/traits.types';
import { CFRole } from 'domain/general.types';
import { CohortType } from 'services/cohort/cohort.types';

import { useServicesContext } from 'hooks/useServicesContext';

import { segmentationByTab, Tabs } from '../..';

import { useCohortsContext } from './context.cohort';
import Cohort from 'services/cohort/domain/Cohort';

import './list-of-cohorts.scss';

const typeOptions = [
  {
    label: 'Auto',
    value: CohortType.Auto,
  },
  {
    label: 'Manual',
    value: CohortType.Manual,
  },
  {
    label: 'All',
    value: CohortType.All,
  },
];

const ListOfCohorts = () => {
  const { cohortService } = useServicesContext();
  const { landingCohort } = useCohortsContext();

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [cohorts, setCohorts] = useState<Cohort[]>([]);
  const [totalCohorts, setTotalCohorts] = useState(-1);
  const [curPage, setCurPage] = useState<number>(0);
  const [curPageSize, setCurPageSize] = useState<number>(10);
  const [subjects, setSubjects] = useState<string[]>([]);

  const [selectedSubject, setSelectedSubject] = useState<TraitSubject>(
    (params.get('subject') as TraitSubject) || TraitSubject.User
  );
  const [selectedType, setSelectedType] = useState(CohortType.Auto);
  const [isLoading, setIsloading] = useState(true);

  const navigate = useCFNavigation();

  const downloadPage = useCallback(
    async (page: number) => {
      try {
        setIsloading(true);

        const cohorts = await cohortService.getListOfCohorts(page, curPageSize, selectedSubject, selectedType);

        setTotalCohorts(cohorts.total);
        setCohorts(cohorts.data);
      } finally {
        setIsloading(false);
      }
    },
    [curPageSize, selectedSubject, selectedType]
  );

  useEffect(() => {
    downloadPage(curPage);
  }, [curPage]);

  useEffect(() => {
    downloadPage(0);
  }, [selectedSubject, selectedType]);

  useEffect(() => {
    const userInfo = getUserInfo();

    const currentOrg = userInfo.available_orgprojs.find((orgproj) => orgproj.org.id.toString() === getOrganization());
    const currentProj = currentOrg?.projs.find((proj) => proj.id.toString() === getProject());

    setSubjects(currentProj?.subjects || []);
  }, []);

  const handleNewPageRequest = (page: number, size: number) => {
    setCurPage(page - 1);
    setCurPageSize(size);
  };

  const handleCohortRemoved = useCallback(
    (cohortId: number) => {
      setCohorts(cohorts.filter((cohort) => cohort.id !== cohortId));
    },
    [cohorts]
  );

  const handleSelectedSubject = ([item]: SelectableItem[]) => {
    setSelectedSubject(item.value as TraitSubject);
  };

  const handleSelectedType = ([item]: SelectableItem[]) => {
    setSelectedType(item.value as CohortType);
  };

  const columns: Column[] = useMemo(
    () =>
      [
        {
          title: 'ID',
          field: 'id',
          type: ColumnType.STRING,
          style: {
            width: '10px',
          },
        },
        {
          title: 'Tags',
          field: 'tags',
          type: ColumnType.STRING,
          renderCell: (row) => {
            const tags = row.created_by === 'system' ? ['auto'] : [];

            if ((row as Cohort).id === landingCohort) {
              tags.push('landing');
            }

            return (
              <div className="tags">
                {tags.map((tag) => (
                  <Tag key={`tag-${tag}`} text={tag} type={TagTypes.System} />
                ))}
              </div>
            );
          },
          style: {
            width: '25px',
          },
        },
        {
          title: 'Name',
          field: 'name',
          type: ColumnType.STRING,
          style: {
            width: '1000px',
          },
        },
        {
          title: 'Subjects count',
          field: 'subjects_count',
          type: ColumnType.STRING,
          renderCell: (row) => {
            const itemLabel = row.subject_type === TraitSubject.User ? 'users' : 'items';
            const counter = row.size;

            return (
              <div className="subjects-count">
                <div className="counter-icon">
                  {itemLabel === 'users' && <UsersLogo />}
                  {itemLabel !== 'users' && <FontAwesomeIcon className="button-icon" icon={faCube} size="lg" />}
                </div>

                <div className="counter">
                  {counter === -1 ? '?' : counter} <span className="counter-tag"> {itemLabel} </span>
                </div>
              </div>
            );
          },
          style: {
            width: '200px',
          },
        },
        {
          title: 'Latest date',
          field: 'subjects_count',
          type: ColumnType.STRING,
          renderCell: (row) => {
            if (!row.latestDate || row.latestDate === '0001-01-01T00:00:00Z') {
              return 'Not available';
            }

            return dayjs(row.latestDate).tz().format('DD-MM-YYYY');
          },
          style: {
            width: '225px',
          },
        },
        {
          title: '',
          field: 'exapnded',
          expandable: true,
          type: ColumnType.STRING,
          renderCell: (row) => {
            return (
              <CohortDetail
                cohort={row as Cohort}
                onRemove={handleCohortRemoved}
                usedInLanding={(row as Cohort).id === landingCohort}
                removable={(row.created_by === 'system' && getUserInfo().user.superuser) || row.created_by !== 'system'}
              />
            );
          },
          style: {
            width: '15px',
          },
        },
      ] as Column[],
    [handleCohortRemoved, landingCohort]
  );

  const tabs = Object.values(Tabs);

  return (
    <div className="list-of-cohorts">
      <CFNavList
        titles={tabs}
        selected={Tabs.Cohorts}
        onClick={(selectedTab) => navigate(segmentationByTab[selectedTab])}
      />
      <CFLoadWrapper isLoading={isLoading}>
        <div className="cohort-container">
          <div className="controls">
            <div className="filters">
              {subjects.length !== 0 && (
                <div className="inline">
                  <span>Subject:</span>
                  <CFSelectLegacy
                    className="subject-selector"
                    options={subjects.map((subject) => ({
                      value: subject,
                      label: subject,
                    }))}
                    defaultOption={[{ value: selectedSubject, label: selectedSubject }]}
                    role={CFRole.Secondary}
                    onSelected={handleSelectedSubject}
                  />
                </div>
              )}
              <div className="inline">
                <span>Type:</span>
                <CFSelectLegacy
                  className="type-selector"
                  options={typeOptions}
                  defaultOption={[typeOptions[0]]}
                  role={CFRole.Secondary}
                  onSelected={handleSelectedType}
                />
              </div>
            </div>
            <ProtectedElement authAction={AuthAction.CreateCohort}>
              <CFButton
                value="Add Cohort"
                role={CFRole.Primary}
                iconName={faPlus}
                onClick={() =>
                  navigate({
                    pathname: CFRoutes.segmentation_cohort_new,
                    search: `?${createSearchParams({ subject: selectedSubject, ...sessionQueryInfo() })}`,
                  })
                }
              />
            </ProtectedElement>
          </div>

          {totalCohorts !== 0 && totalCohorts !== -1 && (
            <CFDataTable headers={columns} total={totalCohorts} data={cohorts} onPaginated={handleNewPageRequest} />
          )}
          {totalCohorts === 0 && <div className="no-data">No cohorts available</div>}
        </div>
      </CFLoadWrapper>
    </div>
  );
};

export default ListOfCohorts;
