import React from 'react';

import { InterventionViewExtended } from 'services/intervention/intervention.types';
import TraitService from 'services/traits/traitSession.service';
import { getIdentifier } from 'services/traits/helpers.traits';

import MonitoringSection from '../monitoringSection';

import CFTraitItem from 'connected-components/traits/CFTraitItem';

import './metrics.scss';

interface Props {
  intervention: InterventionViewExtended;
  traitService: TraitService;
  className?: string;
}

const ABMetricsViewer = ({ intervention, traitService, className = '' }: Props) => {
  return (
    <MonitoringSection title="Metrics" className={`metrics-definition metrics ${className}`}>
      <div className="metrics-section">
        {intervention.intervention.metric_policy.ab?.metrics
          ?.map((addr) => traitService.getTraitFromAddr(addr))
          .map((trait) => (
            <div key={getIdentifier(trait)} className="trait-list-item">
              <div className="trait-list-item-element">
                <CFTraitItem addr={trait.addr} />
              </div>
              <div className="trait-list-item-element">{trait.addr.dtype}</div>
              <div className="trait-list-item-element">{trait.meta.description}</div>
            </div>
          ))}
      </div>
    </MonitoringSection>
  );
};

export default ABMetricsViewer;
