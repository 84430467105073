import React from 'react';
import CFTable, { Column, ColumnType, TableType } from 'components/CFTable';

import './expandedView.scss';

interface ExceptionHistoryItem {
  u_id: string;
  app_version: string;
  device_id: string;
  date: string;
  time: string;
}

const columns: Column[] = [
  {
    title: 'Used ID',
    field: 'u_id',
    type: ColumnType.STRING,
    style: {
      zIndex: 0,
    },
  },
  {
    title: 'App Version',
    field: 'app_version',
    type: ColumnType.STRING,
    style: {
      zIndex: 0,
    },
  },
  {
    title: 'Device ID',
    field: 'device_id',
    type: ColumnType.STRING,
    style: {
      zIndex: 0,
    },
  },
  {
    title: 'Date',
    field: 'date',
    type: ColumnType.STRING,
    style: {
      zIndex: 0,
    },
  },
  {
    title: 'Time',
    field: 'time',
    type: ColumnType.STRING,
    style: {
      zIndex: 0,
    },
  },
];

interface Props {
  exceptionHistory: ExceptionHistoryItem[];
  stackTrace: string;
}

const ExpandedView: React.FC<Props> = ({ exceptionHistory, stackTrace }) => {
  return (
    <div className="expanded-view">
      <div className="expanded-view-title">Exception History</div>
      <div className="expanded-view-table">
        <CFTable headers={columns} data={exceptionHistory} variant={TableType.Secondary} />
      </div>
      <div className="expanded-view-title">Stack Trace</div>
      <div className="expanded-view-stacktrace">
        <pre>{stackTrace}</pre>
      </div>
    </div>
  );
};

export default ExpandedView;
