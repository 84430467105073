import React, { forwardRef } from 'react';
import classNames from 'classnames';

import CFCheckbox from 'components/CFCheckbox';

import { Option } from '..';

import './item.scss';

export interface Props {
  option: Option;
  active: boolean;
  focused?: boolean;
  isMulti?: boolean;
  disabled?: boolean;
  onClick?: (option: Option) => void;
}

const Item = forwardRef<HTMLDivElement, Props>(function Item(
  { option, active, onClick, isMulti = false, focused = false }: Props,
  ref
) {
  return (
    <div
      ref={ref}
      role="option"
      key={option.value}
      className={classNames('cf-select-item', { active, focused })}
      onClick={() => onClick?.(option)}
    >
      {isMulti && <CFCheckbox variant="secondary" checked={active} onChange={() => onClick?.(option)} tabIndex={-1} />}
      {option.label}
    </div>
  );
});

export default Item;
