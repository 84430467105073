import React, { FC } from 'react';

import { Trait } from 'domain/traits.types';

import CFTrashButton from 'components/buttons/CFTrashButton';
import CFTraitItem from 'connected-components/traits/CFTraitItem';

import './traits-list-item.scss';

interface ItraitListItem {
  trait: Trait;
  onDelete: (trait: Trait) => void;
}

const TraitListItem: FC<ItraitListItem> = ({ trait, onDelete }) => {
  const { dtype } = trait.addr;
  const { description } = trait.meta;

  return (
    <div className="trait-list-item">
      <div className="trait-list-item-element">
        <CFTrashButton onClick={() => onDelete(trait)} />
      </div>
      <div className="trait-list-item-element">
        <CFTraitItem addr={trait.addr} />
      </div>
      <div className="trait-list-item-element">{dtype}</div>
      <div className="trait-list-item-element">{description}</div>
    </div>
  );
};

export default TraitListItem;
