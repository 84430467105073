import React, { useCallback } from 'react';

import { ModelTag } from 'domain/model.types';
import classNames from 'classnames';

import './model-tag-chip.scss';

interface Props {
  tag: ModelTag;
  ghost?: boolean;
  editable?: boolean;
  onRemove?: () => void;
  onClick: () => void;
}

const ModelTagLabels = {
  [ModelTag.Intervention]: 'inv',
  [ModelTag.Landing]: 'landing',
  [ModelTag.ML]: 'ml',
};

const ModelTagChip = ({ onClick, onRemove, tag, editable = false, ghost = false }: Props) => {
  const handleOnRemove = useCallback(() => {
    if (!onRemove) {
      return;
    }

    onRemove();
  }, []);

  return (
    <div key={tag} className={classNames('model-tag-chip', { ghost })} onClick={onClick}>
      <span>{ModelTagLabels[tag]}</span>

      {editable && !ghost && tag !== ModelTag.Intervention && (
        <span className="delete-action" onClick={handleOnRemove}>
          x
        </span>
      )}
    </div>
  );
};

export default ModelTagChip;
