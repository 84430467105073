import React, { FC } from 'react';
import classNames from 'classnames';

import CFSpinner from 'components/CFSpinner';
import colors from 'common.scss';
import './cf-load-wrapper.scss';

interface Props {
  isLoading: boolean;
  children: JSX.Element | JSX.Element[];
  spinnerSize?: number;
  background?: 'fade' | 'solid';
}

const CFLoadWrapper: FC<Props> = ({ isLoading, children, spinnerSize = 70, background = 'fade' }: Props) => (
  <div className="load-wrapper">
    {isLoading && (
      <div className={classNames('load-backdrop', background)}>
        <CFSpinner size={spinnerSize} color={colors.cfCyan} stroke={4} />
      </div>
    )}
    {children}
  </div>
);

export default CFLoadWrapper;
