import React, { useCallback, useEffect, useState } from 'react';

import CFPaginatedList from 'components/CFPaginatedList';
import { SampleItem } from 'services/cohort/cohort.types';

import CohortService from 'services/cohort/cohort.service';

import './sample-viewer.scss';
import { useToast } from 'hooks';
import { ToastType } from 'components/CFToast/types';

export enum SampleViewerType {
  Cohort,
  Model,
}

interface Props {
  sampleId: { value: string };
  cohortService: CohortService;
  type?: SampleViewerType;
}

const SampleViewer = ({ cohortService, sampleId }: Props) => {
  const [totalSampledSubjects, setTotalSampledSubjects] = useState(-1);
  const [samples, setSamples] = useState<SampleItem[]>([]);
  const [curPage, setCurPage] = useState<number>(0);
  const [curPageSize, setCurPageSize] = useState<number>(15);

  const { addToast } = useToast();
  const handleNewPageRequest = (page: number, size: number) => {
    setCurPage(page - 1);
    setCurPageSize(size);
  };

  useEffect(() => {
    (async () => {
      if (!sampleId || !sampleId.value) {
        return;
      }

      const { total, data } = await cohortService.sample(sampleId.value, curPage, curPageSize);

      setTotalSampledSubjects(total);
      setSamples(data);
    })();
  }, [sampleId, curPage, curPageSize]);

  const handleClickOnSubject = useCallback((id: string) => {
    navigator.clipboard
      .writeText(id)
      .then(() => {
        addToast(`${id} copied to clipboard`, ToastType.INFO);
      })
      .catch((err) => {
        console.error('Error copying to clipboard: ', err);
      });
  }, []);

  return (
    <CFPaginatedList total={totalSampledSubjects} pageSizeList={[15, 30, 90]} onPageChange={handleNewPageRequest}>
      {!samples.length ? (
        <span>No subjects</span>
      ) : (
        <div className="sample-viewer">
          {samples.map((sample) => (
            <div
              key={sample.subject_id}
              className="sample-item"
              onClick={() => handleClickOnSubject(sample.subject_id)}
            >
              {sample.subject_id}
            </div>
          ))}
        </div>
      )}
    </CFPaginatedList>
  );
};

export default SampleViewer;
