import React from 'react';

import { useServicesContext } from 'hooks/useServicesContext';

import { Model, SplitRatio } from 'domain/model.types';

import { FilterAPI, FilterGroupOperation } from 'services/cohort/cohort.types.api';
import FilterSet from 'services/cohort/domain/FilterSet';
import { isConfiguredWithSamples } from 'services/model/helper.model';
import Cohort from 'services/cohort/domain/Cohort';

import KeyValue from 'views/intervention/components/KeyValue';

import SampleViewer from 'connected-components/sampleViewer';
import TreeViewer from 'connected-components/TreeViewer';

import CFSplitRatios from 'components/CFSplitRatios';

import './cohort.scss';

interface Props {
  model: Model;
  cohort: Cohort | undefined;
  extraFilters?: FilterAPI[];
  splitRatio?: SplitRatio;
}

const ModelCohort = ({ cohort, model, splitRatio }: Props) => {
  const { cohortService } = useServicesContext();

  return (
    <div className="model-definition-cohort">
      {cohort && model.cohort.id !== 0 && (
        <div>
          <KeyValue name="Cohort name" value={cohort.name} />
          <TreeViewer value={cohort.tree} />
          {(model.definition?.population_policy?.cohort?.extra_filters || []).length > 0 && (
            <>
              <div className="key"> Extra filters: </div>
              <TreeViewer
                value={
                  new FilterSet({
                    nodes: [
                      {
                        leaf: {
                          filters: model.definition.population_policy.cohort.extra_filters,
                          op: FilterGroupOperation.And,
                        },
                      },
                    ],
                    op: FilterGroupOperation.And,
                  })
                }
              />
            </>
          )}
        </div>
      )}
      <div>
        {splitRatio && (
          <CFSplitRatios
            defaultValue={{
              train: splitRatio.train || 0,
              validate: splitRatio.validate || 0,
              test: splitRatio.test || 0,
            }}
            disabled={true}
          />
        )}
      </div>

      {isConfiguredWithSamples(model) && (
        <SampleViewer
          cohortService={cohortService}
          sampleId={{ value: model.definition.population_policy.sample_id as string }}
        />
      )}
    </div>
  );
};

export default ModelCohort;
