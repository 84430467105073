import React from 'react';
import { RGBColor } from 'domain/general.types';

import './header-cell.scss';

interface Props {
  name: string;
  color: RGBColor;
}

const HeaderCell = ({ name, color }: Props) => {
  return (
    <div className="header-cell">
      <div className="legend" style={{ backgroundColor: color }}>
        {' '}
        &nbsp;
      </div>
      <div className="name"> {name}</div>
    </div>
  );
};

export default HeaderCell;
