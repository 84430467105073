import { useServicesContext } from 'hooks/useServicesContext';
import { useEffect, useState } from 'react';
import { useInterventionContext } from 'views/intervention/useContext';
import { SamplingType } from '../participants/types';

const useSampleSize = (type = SamplingType.Normal) => {
  const [sampleSize, setSampleSize] = useState(0);
  const { cohortService } = useServicesContext();
  const { sampleId, pureSampleId } = useInterventionContext();

  // sample size might change even is sampleId is the same
  // this happens when the user presses the button many times (which is allowed)
  useEffect(() => {
    (async () => {
      if (type === SamplingType.Normal && (!sampleId || !sampleId.valid())) {
        setSampleSize(0);
        return;
      }

      if (type === SamplingType.PureControl && (!pureSampleId || !pureSampleId.valid())) {
        return;
      }

      const size = await cohortService.getSampleSize(
        type === SamplingType.Normal ? sampleId.value : pureSampleId.value
      );

      setSampleSize(size);
    })();
  }, [sampleId, pureSampleId]);

  return sampleSize;
};

export default useSampleSize;
