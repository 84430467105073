import { SubjectId } from 'domain/general.types';
import { ModelId } from 'domain/model.types';
import { TimeSeriesItem } from 'domain/stats.types';
import { Trait, TraitName } from 'domain/traits.types';
import { getTraitName } from 'services/traits/helpers.traits';
import TraitService from 'services/traits/traitSession.service';

type TimeSeriesStore = Record<TraitName, Record<SubjectId, TimeSeriesItem[]>>;

let lastEndDate = '';
let lastStartDate = '';
let timeSeries: TimeSeriesStore = {};

const mergeTimeseries = (
  traits: Trait[],
  subjects: SubjectId[],
  ts1: TimeSeriesStore,
  ts2: TimeSeriesStore
): TimeSeriesStore => {
  const mergedTimeSeries: Record<TraitName, Record<SubjectId, TimeSeriesItem[]>> = {};

  for (const trait of traits) {
    const traitName = getTraitName(trait);
    if (!mergedTimeSeries[traitName]) {
      mergedTimeSeries[traitName] = {};
    }

    subjects.forEach((subject) => {
      if (ts1[traitName]?.[subject]) {
        mergedTimeSeries[traitName][subject] = ts1[traitName][subject];
      } else if (ts2[traitName]?.[subject]) {
        mergedTimeSeries[traitName][subject] = ts2[traitName][subject];
      }
    });
  }

  return mergedTimeSeries;
};

export const getMLTTimeseries = async (
  traitService: TraitService,
  start: string,
  end: string,
  modelId: ModelId,
  traits: Trait[],
  subjects: SubjectId[]
) => {
  let newSubjects = [];
  let optimized = false;

  if (lastEndDate === end && lastStartDate === start && Object.keys(timeSeries).length !== 0) {
    newSubjects = subjects.filter((subject) => timeSeries?.[getTraitName(traits[0])][subject] === undefined);
    optimized = true;
  } else {
    newSubjects = subjects;
  }

  lastStartDate = start;
  lastEndDate = end;

  const partialTimeSeries = await traitService.getMLTTimeseries(start, end, modelId, traits, newSubjects);

  if (optimized) {
    timeSeries = mergeTimeseries(traits, subjects, partialTimeSeries, timeSeries);
  } else {
    timeSeries = partialTimeSeries;
  }

  return timeSeries;
};
