import { AxiosResponse } from 'axios';
import { post as httpPost } from '../../repositories/drivers/http';
import { GraphPerGroup, GroupName, MarkovProcessId, NodeUpdate, ThresholdTuple } from './markov.types';

import {
  AdvantageResponse,
  AdvantageScoresReq,
  EdgeEdit,
  GroupPreviewResponse,
  MarkovProcessAPI,
  MarkovReq,
  MarkovSearchReq,
  PreviewGroupReq,
  RestlessAdvantageReq,
  RestlessAdvantageResponse,
} from './markov.types.api';
import { mapTupleToStateFilter } from './mappers';
import { SubjectId, TimeRFC3999 } from 'domain/general.types';
import { domainUpdatesToAPIEdits } from './helpers.markov';
import { RepoConfig } from 'repositories/types';
import { AlgorithmName, InterventionId } from 'services/intervention/intervention.types';
import { ColAddr } from 'domain/traits.types';

const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
const path = '/v1/inv';

const repoConfig = {
  token: '',
  oid: -1,
  pid: -1,
};

export const init = ({ token, oid, pid }: RepoConfig) => {
  repoConfig.token = token;
  repoConfig.oid = oid;
  repoConfig.pid = pid;
};

export const getScores = async (
  tuples: ThresholdTuple[],
  budget: number,
  algoName: AlgorithmName,
  markovId: string,
  treatmentGroup: GroupName,
  edits: EdgeEdit[],
  addrs: ColAddr[],
  offset: number,
  gap: number
) => {
  const config = {};

  const body: AdvantageScoresReq = {
    filters: tuples.map((tuple) => mapTupleToStateFilter(tuple)),
    offset,
    budget,
    algo_name: algoName,
    markov_id: markovId,
    edits: { [treatmentGroup]: edits },
    group_filters: addrs,
    gap,
  };

  try {
    const data = (await httpPost(
      `${serverBaseUrl}${path}/markov/advantagescores?oid=${repoConfig.oid}&pid=${repoConfig.pid}`,
      body,
      config
    )) as AxiosResponse<AdvantageResponse>;

    return data.data;
  } catch (err) {
    console.error('error getting advantage scores: ', err);
    throw new Error('error-getting-advantage-scores');
  }
};

export const generate = async (
  sampleId: string,
  actions: string[],
  tuples: ThresholdTuple[],
  populationBlend: number,
  offset: number,
  gap: number
): Promise<MarkovProcessAPI> => {
  const config = {};

  const body: MarkovReq = {
    sample_id: sampleId,
    filters: tuples.map((tuple) => mapTupleToStateFilter(tuple)),
    actions,
    population_blend: populationBlend,
    offset,
    gap,
  };

  try {
    const data = (await httpPost(
      `${serverBaseUrl}${path}/markov/gen?oid=${repoConfig.oid}&pid=${repoConfig.pid}`,
      body,
      config
    )) as AxiosResponse<MarkovProcessAPI>;

    return data.data;
  } catch (err) {
    console.error('error generating markov graph: ', err);
    throw new Error('error-generating-markov-graph');
  }
};

export const get = async (
  markov_id: MarkovProcessId,
  subjectId: SubjectId,
  groupName: string,
  updates: NodeUpdate[]
) => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };

  const edits = domainUpdatesToAPIEdits(updates);

  const body: MarkovSearchReq = {
    markov_id,
    subject_id: subjectId,
    edits: { [groupName]: edits },
  };

  try {
    const data = (await httpPost(
      `${serverBaseUrl}${path}/markov/search`,
      body,
      config
    )) as AxiosResponse<GraphPerGroup>;

    return data.data;
  } catch (err) {
    console.error('error listing interventions: ', err);
    throw new Error('error-listing-interventions');
  }
};

export const getAdvantage = async (interventionId: InterventionId, addr: ColAddr, time: TimeRFC3999) => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };

  const body: RestlessAdvantageReq = {
    inv_id: interventionId,
    trait: addr,
    time,
  };

  try {
    const data = (await httpPost(
      `${serverBaseUrl}${path}/restless/advantage-scatter-plot`,
      body,
      config
    )) as AxiosResponse<RestlessAdvantageResponse>;

    return {
      x: data.data.x || [],
      y: data.data.y || [],
    };
  } catch (err) {
    console.error('error listing interventions: ', err);
    throw new Error('error-listing-interventions');
  }
};

export const previewGroup = async (sampleId: string, addrs: ColAddr[]): Promise<GroupPreviewResponse> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };

  const body: PreviewGroupReq = {
    sample_id: sampleId,
    group_filters: addrs,
  };

  try {
    const data = (await httpPost(
      `${serverBaseUrl}${path}/restless/preview-group`,
      body,
      config
    )) as AxiosResponse<GroupPreviewResponse>;

    return data.data;
  } catch (err) {
    console.error('error previewing group: ', err);
    throw new Error('error-preview-group');
  }
};
