import { CFRole } from 'domain/general.types';
import React, { useCallback, useState } from 'react';
import CFButton from '../CFButton';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

interface Props {
  disabled?: boolean;
  name: string;
  value?: string;
  getData: () => Promise<string[]> | string[];
}

const CFDownloadButton = ({ getData, name, disabled = false, value = 'Download' }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleDownload = useCallback(
    async (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      evt.stopPropagation();

      setLoading(true);
      const data = await getData();
      setLoading(false);

      const downloadLink = document.createElement('a');

      downloadLink.href = URL.createObjectURL(new Blob(data.map((item) => `${item}\n`)));
      downloadLink.download = name;
      downloadLink.click();
    },
    [getData]
  );

  return (
    <CFButton
      role={CFRole.Secondary}
      value={value}
      onClick={handleDownload}
      isLoading={loading}
      disabled={disabled}
      iconName={faDownload}
    />
  );
};

export default CFDownloadButton;
