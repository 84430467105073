import React from 'react';

import ListOfCohorts from './listOfCohorts';

import CohortsContext from './context.cohort';

const ListOfCohortsContainer = () => {
  return (
    <CohortsContext>
      <ListOfCohorts />
    </CohortsContext>
  );
};

export default ListOfCohortsContainer;
