import { SeriesItem, SeriesRange } from 'components/charts/CFScatterPlotChart';
import { ScatterPlot, TTEGroup, TTEGroupRange } from 'domain/analytic.types';

export const buildTTEAreas = (tteGroupRange: TTEGroupRange): [SeriesItem[], number] => {
  const series: SeriesItem[] = [];
  const tteColors = {
    [TTEGroup.Short]: '#E17F6F', // red  400
    [TTEGroup.Average]: '#FDBA8C', // orange 300
    [TTEGroup.Long]: '#91B956', // olive 400,
    [TTEGroup.Loyal]: '#7EBCED', // no name
  };

  Object.keys(tteGroupRange).forEach((groupName) => {
    // skip groups where range edges are switched
    if (tteGroupRange[groupName as TTEGroup][1] < tteGroupRange[groupName as TTEGroup][0]) {
      return;
    }

    series.push({
      name: groupName,
      area: {
        min: tteGroupRange[groupName as TTEGroup][0],
        max: tteGroupRange[groupName as TTEGroup][1],
        label: groupName,
      },
      color: tteColors[groupName as TTEGroup],
      values: [],
    });
  });

  // by convention, loyal values are negative. So we are changing there the edges
  // of that group to be higher than the higher one. Later, we will change how to
  // render xAXis to draw the infinite symbol on the right
  const maxTTE = Math.max(...series.map((group) => (group.area as SeriesRange).max));
  const maxLoyal = maxTTE + 5;

  const loyalSerie = series.find((serie) => serie.name === TTEGroup.Loyal);

  if (loyalSerie !== undefined) {
    (loyalSerie?.area as SeriesRange).min = maxTTE;
    (loyalSerie?.area as SeriesRange).max = maxLoyal;
  }

  return [series, maxLoyal];
};

export const fillTTEAreas = (predictionData: ScatterPlot, series: SeriesItem[], maxLoyal: number) => {
  const nonChurners = [];

  const loyalSerie = series.find((serie) => serie.name === TTEGroup.Loyal);

  for (let i = 0; i < predictionData.x.length; i++) {
    const xValue = predictionData.x[i];
    const yValue = predictionData.y[i];

    for (let areaIndex = 0; areaIndex < series.length; areaIndex++) {
      if (xValue < 0) {
        nonChurners.push({
          x: maxLoyal + (Math.random() / 5) * (Math.random() < 0.5 ? -1 : 1),
          y: 0.05 + (Math.random() / 20) * (Math.random() < 0.5 ? -1 : 1),
          id: '',
        });

        continue;
      }

      if (xValue < (series[areaIndex].area as SeriesRange).max) {
        series[areaIndex].values.push({
          x: xValue,
          y: yValue,
          id: '',
        });
      }
    }
  }

  nonChurners.forEach((nonChurner) => loyalSerie?.values.push(nonChurner));
};
