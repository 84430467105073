import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { HintMessage } from 'services/assistant/assistant.types';
import { useMessagesContext } from '../context/useMessagesContext';
import { useServicesContext } from 'hooks/useServicesContext';
import MessageHint from '../MessageHint';

import CFNavigationArrows from 'components/CFNavigationArrows';

import './prompt-hints.scss';

const MAX_EMBEDDED_PROMPTS = 2;
const MAX_EXPANDED_PROMPTS = 3;

const PromptHints = () => {
  const { setCurrentMessage, waitingResponse, messages, embedded } = useMessagesContext();
  const { assistantService } = useServicesContext();

  const [hintMessages, setHintMessages] = useState<HintMessage[]>([]);
  const [manuallyExpanded, setManuallyExpanded] = useState(false);

  const [index, setIndex] = useState(0);

  useEffect(() => {
    (async () => {
      const hintMessages = await assistantService.getHintMessages();

      setHintMessages(hintMessages);
    })();
  }, []);

  const showPrompts = useMemo(() => {
    return manuallyExpanded || messages.length === 0;
  }, [manuallyExpanded, messages]);

  const maxVisiblePrompts = useMemo(() => {
    if (embedded) {
      return MAX_EMBEDDED_PROMPTS;
    } else {
      return MAX_EXPANDED_PROMPTS;
    }
  }, [embedded]);

  const handleSwitchVisibility = useCallback(() => {
    setManuallyExpanded((expanded) => !expanded);
  }, []);

  const handleHintMessageClick = useCallback(async (message: HintMessage) => {
    setCurrentMessage(message.text);
  }, []);

  const handleNextPrompt = useCallback(() => {
    setIndex((index) => index + 1);
  }, []);

  const handlePreviousPrompt = useCallback(() => {
    setIndex((index) => index - 1);
  }, []);

  const titleIcon = useMemo(() => {
    if (!messages.length) {
      return '';
    }

    if (showPrompts) {
      return <FontAwesomeIcon icon={faAngleDown} style={{ cursor: 'pointer' }} />;
    } else {
      return <FontAwesomeIcon icon={faAngleUp} style={{ cursor: 'pointer' }} />;
    }
  }, [showPrompts, messages]);

  return (
    <div className="prompt_hints">
      <div className="prompt_hints__header" onClick={handleSwitchVisibility}>
        <div className="prompt_hints__header__switcher">
          <span> Prompts list </span>

          {titleIcon}
        </div>

        {hintMessages.length > maxVisiblePrompts && showPrompts && (
          <CFNavigationArrows
            index={index}
            windowSize={maxVisiblePrompts}
            total={hintMessages.length}
            onNext={handleNextPrompt}
            onPrevious={handlePreviousPrompt}
          />
        )}
      </div>

      {showPrompts && (
        <div className="prompt_hints__prompts">
          {hintMessages.slice(index, index + maxVisiblePrompts).map((message) => (
            <MessageHint
              key={message.title}
              message={message}
              onClick={() => handleHintMessageClick(message)}
              disabled={waitingResponse !== undefined}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default PromptHints;
