import { Module } from 'domain/general.types';
import { AggLevel } from 'domain/stats.types';
import { ColAddr, TraitCode, TraitSubject } from 'domain/traits.types';
import { CFUsersRepository } from 'services/admin/users/users.repo';
import { Ptr } from 'services/cohort/cohort.types.api';
import { createTraitCode, getAggLevel } from 'services/traits/helpers.traits';
import TraitService from 'services/traits/traitSession.service';
import { moveElementDown, moveElementUp } from '../helpers/arrays';

type GdtSettingStore = Partial<Record<AggLevel, ColAddr[]>>;

class TraitConfigManager {
  private gdtSettings: GdtSettingStore = {};
  private usersRepo: CFUsersRepository;
  private traitService: TraitService;
  private callbacks: Array<(traitCodes: TraitCode[]) => void> = [];

  constructor(usersRepo: CFUsersRepository, traitService: TraitService) {
    this.usersRepo = usersRepo;
    this.traitService = traitService;
  }

  updateSettingsByAggLevels(addrs: ColAddr[]) {
    this.gdtSettings = addrs.reduce((acc, cur) => {
      const aggLevel = getAggLevel(cur);

      if (!acc[aggLevel]) {
        acc[aggLevel] = [];
      }

      acc[aggLevel]?.push(cur);
      return acc;
    }, {} as GdtSettingStore);
  }

  async syncSettings(subject: TraitSubject, module: Module) {
    const addrs = Object.keys(this.gdtSettings).map((key) => this.gdtSettings[key as AggLevel]);

    await this.usersRepo.updateGDTSettings(subject, module, addrs.flat() as ColAddr[]);
  }

  async updateSettings(subject: TraitSubject, module: Module, colAddrs: ColAddr[]) {
    if (!colAddrs.length) {
      return;
    }

    const aggLevel = getAggLevel(colAddrs[0]);
    this.gdtSettings[aggLevel] = colAddrs;

    const addrs = Object.keys(this.gdtSettings).map((key) => this.gdtSettings[key as AggLevel]);

    await this.usersRepo.updateGDTSettings(subject, module, addrs.flat() as ColAddr[]);
  }

  moveChartUp(traitCode: TraitCode, subject: TraitSubject, module: Module): TraitCode[] {
    const trait = this.traitService.getTraitDefinition(traitCode);
    const aggLevel = getAggLevel(trait.addr);

    const position = this.gdtSettings[aggLevel]
      ?.map((addr) => createTraitCode(addr))
      .findIndex((code) => code === traitCode);

    if (position === undefined) {
      return (this.gdtSettings[aggLevel] || []).map((addr) => createTraitCode(addr));
    }

    this.gdtSettings[aggLevel] = moveElementUp(this.gdtSettings[aggLevel] || [], position);

    this.syncSettings(subject, module);

    const traitCodes = (this.gdtSettings[aggLevel] || []).map((addr) => createTraitCode(addr));

    this.notify(traitCodes);
    return traitCodes;
  }

  moveChartDown(traitCode: TraitCode, subject: TraitSubject, module: Module): TraitCode[] {
    const trait = this.traitService.getTraitDefinition(traitCode);
    const aggLevel = getAggLevel(trait.addr);

    const position = this.gdtSettings[aggLevel]
      ?.map((addr) => createTraitCode(addr))
      .findIndex((code) => code === traitCode);

    if (position === undefined) {
      return (this.gdtSettings[aggLevel] || []).map((addr) => createTraitCode(addr));
    }

    this.gdtSettings[aggLevel] = moveElementDown(this.gdtSettings[aggLevel] || [], position);

    this.syncSettings(subject, module);

    const traitCodes = (this.gdtSettings[aggLevel] || []).map((addr) => createTraitCode(addr));
    this.notify(traitCodes);
    return traitCodes;
  }

  async resetTraits(subject: TraitSubject, module: Module): Promise<TraitCode[]> {
    await this.usersRepo.updateGDTSettings(subject, module, []);
    let traitCodes = await this.getConfiguredTraits(subject, module);

    // it may happen that a configured trait has been removed from backend,
    // so at this point we filter it
    traitCodes = traitCodes.filter((traitCode) => {
      return this.traitService.getTraitDefinition(traitCode) !== undefined;
    });

    this.notify(traitCodes);

    return traitCodes;
  }

  async getConfiguredTraits(subject: TraitSubject, module: Module): Promise<Ptr[]> {
    const availableTraits = (await this.usersRepo.getGDTSettings(subject, module)).filter(
      (coldAddr) => this.traitService.getTraitFromAddr(coldAddr) !== undefined
    );

    const ptrs = availableTraits.map((colAddr: ColAddr) => colAddr.ptr);

    this.traitService.getTraitDefinition;

    this.updateSettingsByAggLevels(availableTraits);

    return ptrs;
  }

  async removeChart(traitCode: TraitCode, subject: TraitSubject, module: Module): Promise<TraitCode[]> {
    const trait = this.traitService.getTraitDefinition(traitCode);

    const aggLevel = getAggLevel(trait.addr);

    this.gdtSettings[aggLevel] = this.gdtSettings[aggLevel]?.filter((addr) => createTraitCode(addr) !== traitCode);

    this.syncSettings(subject, module);

    const traitCodes = (this.gdtSettings[aggLevel] || []).map((addr) => createTraitCode(addr));
    this.notify(traitCodes);

    return traitCodes;
  }

  async addChart(chartName: string, subject: TraitSubject, module: Module): Promise<TraitCode[]> {
    const trait = this.traitService.getTraitDefinition(chartName);

    const aggLevel = getAggLevel(trait.addr);

    this.gdtSettings[aggLevel] = [trait.addr, ...(this.gdtSettings[aggLevel] || [])];

    this.syncSettings(subject, module);

    const traitCodes = (this.gdtSettings[aggLevel] || []).map((addr) => createTraitCode(addr));
    this.notify(traitCodes);

    return traitCodes; // backward compatibility
  }

  subscribe(callback: (codes: TraitCode[]) => void) {
    this.callbacks.push(callback);
  }

  unsubscribe(callback: (codes: TraitCode[]) => void) {
    const index = this.callbacks.findIndex((cb) => cb === callback);

    if (index === -1) {
      return;
    }

    this.callbacks.splice(index, 1);
  }

  notify(traitCodes: TraitCode[]) {
    this.callbacks.forEach((cb) => cb(traitCodes));
  }
}
export default TraitConfigManager;
