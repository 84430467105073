import React from 'react';

import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CameraImage from './images/camera.png';

import './ios-nudge-preview.scss';

interface Props {
  text: string;
  title: string;
}

const IOSNudgePreview = ({ text, title }: Props) => {
  return (
    <div className="ios-nudge-preview">
      <div className="title"> iOS Devices</div>

      <div className="phone">
        <div className="screen">
          <div className="header">
            <div className="content">
              <div className="speaker">&nbsp;</div>
              <img src={CameraImage} alt={'Camera icon'} />
            </div>

            <div className="post-header"></div>
          </div>
          <div className="notification">
            <div className="header">
              <FontAwesomeIcon className="alert-icon" icon={icon({ name: 'square', style: 'solid' })} />
              Global Health App - 10m
            </div>
            <div className="title"> {title}</div>
            <div> {text} </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IOSNudgePreview;
