import React, { CSSProperties, FC } from 'react';
import dayjs from 'dayjs';

import './day.scss';
import classNames from 'classnames';

interface DayProps {
  filled?: boolean;
  outlined?: boolean;
  disabled?: boolean;
  highlighted?: boolean;
  hasEvents?: boolean;
  isCurrentMonth?: boolean;
  color?: string;
  backgroundColor?: string;
  onClick?: () => void;
  onHover?: () => void;
  value: Date;
}

const Day: FC<DayProps> = ({
  filled,
  outlined,
  disabled,
  highlighted,
  hasEvents,
  isCurrentMonth,
  color,
  backgroundColor,
  onClick,
  onHover,
  value,
}) => {
  const styles = {
    ...(color && { '--day-color': color }),
    ...(backgroundColor && { '--day-background-color': backgroundColor }),
  } as CSSProperties;

  return (
    <div className="date-time-day-container2">
      <div
        className={classNames('day-wrapper', {
          highlighted: !disabled && isCurrentMonth && highlighted,
          hasEvents: isCurrentMonth && hasEvents,
          outlined: isCurrentMonth && outlined,
          filled: isCurrentMonth && filled,
        })}
        style={styles}
        onClick={onClick}
        onMouseOver={onHover}
        data-testid={dayjs(value).format('YYYY-MM-DD')}
      >
        <button className={`day-button `} disabled={disabled || !isCurrentMonth}>
          <span
            className={classNames('day-text', {
              contrast: !disabled && filled,
              invisible: !isCurrentMonth,
              disabled: disabled,
            })}
          >
            {dayjs(value).date()}
          </span>
        </button>
      </div>
    </div>
  );
};

export default Day;
