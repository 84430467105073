import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { BanditDataSpec, CensoringTarget, PopulationPolicy, RecommenderDataSpec } from 'domain/model.types';
import { ColAddr, TraitSubject } from 'domain/traits.types';
import { AlgorithmClass, AlgorithmName } from 'services/intervention/intervention.types';
import { Ptr } from 'services/cohort/cohort.types.api';

export enum Steps {
  Definition = 'definition',
  Cohort = 'cohort',
  Parameters = 'parameters',
  Features = 'features',
  Target = 'target',
  Schedule = 'scheduling',
}

interface ModelDefinition {
  name: string;
  class: AlgorithmClass;
  algorithm: AlgorithmName;
}

interface ModelParameters {
  [key: string]: string;
}

interface ModelCohort extends PopulationPolicy {}

interface ModelFeatures {
  dynamic: ColAddr[];
  static: ColAddr[];
}

interface ModelTargetBandit extends BanditDataSpec {}

interface ModelTargetRecommender extends RecommenderDataSpec {}

type ModelTarget = CensoringTarget | ModelTargetBandit | ModelTargetRecommender;

interface ModelContextValue {
  definition: ModelDefinition | null;
  setDefinition: React.Dispatch<React.SetStateAction<ModelDefinition | null>>;

  parameters: ModelParameters | null;
  setParameters: React.Dispatch<React.SetStateAction<ModelParameters | null>>;

  userCohort: ModelCohort | null;
  setUserCohort: React.Dispatch<React.SetStateAction<ModelCohort | null>>;

  itemCohort: ModelCohort | null;
  setItemCohort: React.Dispatch<React.SetStateAction<ModelCohort | null>>;

  features: ModelFeatures | null;
  setFeatures: React.Dispatch<React.SetStateAction<ModelFeatures | null>>;

  target: ModelTarget | null;
  setTarget: React.Dispatch<React.SetStateAction<ModelTarget | null>>;

  readiness: Record<Steps, boolean>;
  updateReadiness: (isReady: boolean, step: Steps) => void;

  aggDuration: number;
  setAggDuration: React.Dispatch<React.SetStateAction<number>>;

  userSampleId: string;
  setUserSampleId: React.Dispatch<React.SetStateAction<string>>;

  itemSampleId: string;
  setItemSampleId: React.Dispatch<React.SetStateAction<string>>;

  sampling: boolean;
  setSampling: React.Dispatch<React.SetStateAction<boolean>>;

  fullDuration: number;
  setFullDuration: React.Dispatch<React.SetStateAction<number>>;

  testDuration: number;
  setTestDuration: React.Dispatch<React.SetStateAction<number>>;

  selectedSubjectType: TraitSubject;
  setSelectedSubjectType: React.Dispatch<React.SetStateAction<TraitSubject>>;

  selectedItemSubjectType: TraitSubject;
  setSelectedItemSubjectType: React.Dispatch<React.SetStateAction<TraitSubject>>;

  windows: Record<Ptr, number[]>;
  setWindows: React.Dispatch<React.SetStateAction<Record<Ptr, number[]>>>;
}

const ModelContext = createContext<ModelContextValue | undefined>(undefined);

interface Props extends React.PropsWithChildren {}

const ContextProvider: React.FC<Props> = ({ children }) => {
  const [definition, setDefinition] = useState<ModelDefinition | null>(null);
  const [parameters, setParameters] = useState<ModelParameters | null>(null);

  const [userCohort, setUserCohort] = useState<ModelCohort | null>(null);
  const [itemCohort, setItemCohort] = useState<ModelCohort | null>(null);

  const [fullDuration, setFullDuration] = useState<number>(0);
  const [testDuration, setTestDuration] = useState<number>(0);

  const [features, setFeatures] = useState<ModelFeatures | null>(null);
  const [target, setTarget] = useState<ModelTarget | null>(null);
  const [aggDuration, setAggDuration] = useState<number>(1 * 24 * 3600 * 10 ** 9);
  const [userSampleId, setUserSampleId] = useState<string>('');
  const [itemSampleId, setItemSampleId] = useState<string>('');

  const [sampling, setSampling] = useState(false);

  const [windows, setWindows] = useState<Record<Ptr, number[]>>({});

  const [selectedSubjectType, setSelectedSubjectType] = useState(TraitSubject.User);
  const [selectedItemSubjectType, setSelectedItemSubjectType] = useState(TraitSubject.Drug);

  const [readiness, setReadiness] = useState({
    [Steps.Definition]: false,
    [Steps.Cohort]: false,
    [Steps.Parameters]: true,
    [Steps.Features]: false,
    [Steps.Target]: false,
    [Steps.Schedule]: false,
  });

  const updateReadiness = useCallback(
    (isReady: boolean, step: Steps) => {
      setReadiness((readiness) => ({ ...readiness, [step]: isReady }));
    },
    [readiness]
  );

  useEffect(() => {
    if (definition?.class === AlgorithmClass.Recommender && definition.algorithm === AlgorithmName.Item2Item) {
      setReadiness((readiness) => ({ ...readiness, [Steps.Features]: true }));
    }

    if (definition?.class === AlgorithmClass.Recommender) {
      setReadiness((readiness) => ({ ...readiness, [Steps.Target]: true }));
    }
  }, [definition]);

  return (
    <ModelContext.Provider
      value={{
        definition,
        setDefinition,
        parameters,
        setParameters,

        userCohort,
        setUserCohort,

        itemCohort,
        setItemCohort,

        testDuration,
        setTestDuration,

        fullDuration,
        setFullDuration,

        selectedSubjectType,
        setSelectedSubjectType,

        selectedItemSubjectType,
        setSelectedItemSubjectType,

        userSampleId,
        setUserSampleId,

        itemSampleId,
        setItemSampleId,

        features,
        setFeatures,

        target,
        setTarget,

        readiness,
        updateReadiness,

        aggDuration,
        setAggDuration,

        sampling,
        setSampling,

        windows,
        setWindows,
      }}
    >
      {children}
    </ModelContext.Provider>
  );
};

export const useModelContext = (): ModelContextValue => {
  const context = useContext(ModelContext);
  if (!context) {
    throw new Error('useModelContext must be used within a ModelContextProvider');
  }
  return context;
};

export default ContextProvider;
