import React, { useEffect, useState, useRef, FC } from 'react';
import dayjs from 'dayjs';

import InputTimeHelper from './InputTimeHelper';
import ArrowDown from './ArrowDown';
import UnitDropdown from './UnitDropdown';

import './time-picker.scss';
import { doubleChar, getDatePartsByProps, getTimeString } from '../DatetimeRangePicker/helper';

interface TimeInputProps {
  value?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  onChangeEveryFormat?: (value: string) => void;
  disabled?: boolean;
  allowDelete?: boolean;
  eachInputDropdown?: boolean;
  manuallyDisplayDropdown?: boolean;
  fullTimeDropdown?: boolean;
}

const TimePicker: FC<TimeInputProps> = (props) => {
  const {
    value,
    defaultValue,
    onChange,
    onChangeEveryFormat,
    disabled = false,
    allowDelete = true,
    eachInputDropdown = false,
    manuallyDisplayDropdown = false,
    fullTimeDropdown = true,
  } = props;

  const dateParts = getDatePartsByProps();
  const [hour, setHour] = useState<string>(dateParts.hour);
  const [minute, setMinutes] = useState<string>(dateParts.minute);
  const [fullTimeDropdownVisibility, setFullTimeDropdownVisibility] = useState(false);
  const hourRef = useRef<HTMLInputElement>(null);
  const minuteRef = useRef<HTMLInputElement>(null);

  const hourRange = { start: 0, end: 23 };

  const focusElementByRef = (ref: any) => {
    ref.current && ref.current.focus();
  };

  const blurElementByRef = (ref: React.RefObject<HTMLInputElement>) => {
    ref.current && ref.current.blur();
  };

  const setTimeHourString = (value: string) => {
    const dateParts = getDatePartsByProps(value.replace(/ /g, ''));
    setHour(dateParts.hour);
    setMinutes(dateParts.minute);
  };

  useEffect(() => {
    const dateString = getTimeString(hour, minute);
    onChangeEveryFormat && onChangeEveryFormat(dateString);
    if (hour !== '' && minute !== '') {
      onChange && onChange(dateString);
    }
  }, [hour, minute]);

  useEffect(() => {
    setTimeHourString(value || dayjs().format('HH:mm'));
  }, [value]);

  useEffect(() => {
    setTimeHourString(defaultValue || dayjs().format('HH:mm'));
  }, []);

  const hideDropdown = () => {
    setFullTimeDropdownVisibility(false);
  };

  useEffect(() => {
    window.addEventListener('click', hideDropdown);
    document.querySelector('body')?.addEventListener('click', hideDropdown);
    return () => {
      window.removeEventListener('click', hideDropdown);
      document.querySelector('body')?.removeEventListener('click', hideDropdown);
    };
  }, []);

  const fullTimeDropdownData = () => {
    const format24Data = new Array(24)
      .fill('')
      .map((_, index) => [`${doubleChar(index.toString())} : 00`, [`${doubleChar(index.toString())} : 30`]])
      .flat(2);
    return format24Data;
  };

  const amPmInputProps = {
    disabled,
    eachInputDropdown: eachInputDropdown && !fullTimeDropdown,
    manuallyDisplayDropdown: manuallyDisplayDropdown && !fullTimeDropdown,
    fullTimeDropdown,
  };

  const sameInputProps = { ...amPmInputProps, allowDelete, placeholder: '- -' };

  return (
    <div className="react-time-input-picker-wrapper">
      <div className={`react-time-input-picker ${disabled ? 'is-disabled' : ''}`}>
        <React.Fragment>
          <InputTimeHelper
            inputRef={hourRef}
            value={hour}
            setValue={setHour}
            {...sameInputProps}
            moveNext={() => focusElementByRef(minuteRef)}
            range={hourRange}
          />
          <InputTimeHelper
            inputRef={minuteRef}
            value={minute}
            {...sameInputProps}
            setValue={setMinutes}
            moveNext={() => blurElementByRef(minuteRef)}
            movePrev={() => focusElementByRef(hourRef)}
            range={{ start: 0, end: 59 }}
          />
          {fullTimeDropdown && (
            <div>
              <ArrowDown
                onClick={(e: any) => {
                  e.stopPropagation();
                  setFullTimeDropdownVisibility(!fullTimeDropdownVisibility);
                }}
              />
            </div>
          )}
          <div className="fullTime__wrapper">
            <UnitDropdown
              fullTimeDropdownVisibility
              data={fullTimeDropdownData()}
              shouldDisplay={fullTimeDropdown && fullTimeDropdownVisibility}
              manuallyDisplayDropdown={manuallyDisplayDropdown}
              type="notRange"
              className="fullTime"
              {...{
                value: getTimeString(hour, minute),
                setValue: setTimeHourString,
                dropdownVisibility: fullTimeDropdownVisibility,
                setDropdownVisibility: setFullTimeDropdownVisibility,
              }}
            />
          </div>
        </React.Fragment>
      </div>
    </div>
  );
};

export default React.memo(TimePicker);
