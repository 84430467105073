import React, { useMemo, useRef, useState } from 'react';
import useCFNavigation from 'hooks/useCFNavigation';

import { CFRoutes } from 'routes';
import { useToast } from 'hooks';

import {
  BanditDataSpec,
  CensoringDataSpec,
  CensoringTarget,
  NewModelRequest,
  PopulationPolicy,
  RecommenderDataSpec,
} from 'domain/model.types';
import { TraitSubject } from 'domain/traits.types';

import { CFRole } from 'domain/general.types';

import { AlgorithmClass } from 'services/intervention/intervention.types';
import { create as createModel } from 'services/model/model.repo';
import { getCurrentProject } from 'services/session/session.service';

import InterventionSection from 'views/intervention/interventions/NewIntervention/interventionSection';

import SchedulingBuilder, { InterventionSchedulingRef } from 'connected-components/SchedulingBuilder';

import Wizard from 'components/CFWizard';
import ModelDefinition from './definition';
import CFButton from 'components/buttons/CFButton';
import { ToastType } from 'components/CFToast/types';
import { SplitRatio } from 'components/CFSplitRatios';

import Item2VectFeatures from './features/item2VecFeatures';
import ModelFeatures from './features/genericFeatures';
import CensoringModelFeatures, { CensoringModelFeaturesRef } from './features/censoringFeatures';

import ModelCohortPicker, { ModelCohortType } from './cohort';
import ModelTarget from './target';

import { useServicesContext } from 'hooks/useServicesContext';

import ModelContextProvider from './useContext';
import { Steps, useModelContext } from './useContext';

import pagePlusIcon from 'assets/icons/pagePlus.svg';

import { cohortSelectorDescription } from './constants';

import './new-model.scss';

const ModelNew = () => {
  const { modelService } = useServicesContext();

  const [loading, setLoading] = useState(false);

  const {
    definition,
    itemCohort,
    // userCohort: cohort,
    readiness,
    features,
    userCohort,
    target,
    aggDuration,
    fullDuration,
    testDuration,
    updateReadiness,
  } = useModelContext();

  const navigate = useCFNavigation();
  const { addToast } = useToast();

  const schedulingRef = useRef<InterventionSchedulingRef>() as React.MutableRefObject<InterventionSchedulingRef>;
  const censoringFeaturesRef = useRef<CensoringModelFeaturesRef>() as React.MutableRefObject<CensoringModelFeaturesRef>;

  const handleCreateModel = async () => {
    if (!userCohort || !definition || (includeFeatures && !features)) {
      return;
    }

    if (definition.class !== AlgorithmClass.Recommender) {
      if (!target) {
        return;
      }
    }

    if (definition.class === AlgorithmClass.Recommender) {
      if (!userCohort) {
        return;
      }
    }

    const bandit = target as BanditDataSpec;
    let censoring: CensoringDataSpec | undefined;
    let recommender: RecommenderDataSpec | undefined;

    if (definition.class === AlgorithmClass.Recommender) {
      recommender = {
        user_cohort_id: (userCohort as PopulationPolicy).cohort.cohort_id,
        user_extra_filters: (userCohort as PopulationPolicy).cohort.extra_filters,
        split_ratio: (userCohort as PopulationPolicy).split_ratio as SplitRatio,
        embedding_traits: features?.static || [],
        full_dur: fullDuration,
        test_dur: testDuration,
      };
    }

    if (definition.class === AlgorithmClass.Censoring) {
      censoring = {
        event: (target as CensoringTarget).event,
        validation_delta: (target as CensoringTarget).validation_delta,
        dynamic_features: censoringFeaturesRef.current.value().dynamic_features,
      };
    }

    const request: NewModelRequest = {
      schedule: schedulingRef.current.value(),
      definition: {
        name: definition.name,
        algo_spec: {
          class_name: definition.class,
          algo_name: definition.algorithm,
        },
        population_policy: (definition.class === AlgorithmClass.Recommender
          ? itemCohort
          : userCohort) as unknown as PopulationPolicy,
        data_defn: {
          ...(includeFeatures && { dynamic_features: features?.dynamic }),
          ...(includeFeatures && { static_features: features?.static }),
          ...(definition.class === AlgorithmClass.Recommender && { recommender }),
          ...(definition.class === AlgorithmClass.Bandit && { bandit }),
          ...(definition.class === AlgorithmClass.Censoring && { censoring }),
          ...(definition.class === AlgorithmClass.Bandit && { agg_dur: aggDuration }),
        },
      },
    };

    try {
      setLoading(true);
      await createModel(request);

      addToast('Model created', ToastType.SUCCESS, 5000);

      navigate(CFRoutes.model);
    } catch (err) {
      addToast(`Impossible to create model ${(err as any).message}`, ToastType.ERROR, 5000);
      setLoading(false);

      console.log('Error creating model: ', err);
    }
  };

  const includeFeatures = useMemo(() => {
    return !(definition?.class === AlgorithmClass.Recommender || definition?.class === AlgorithmClass.Censoring);
  }, [definition]);

  return (
    <div className="new-model">
      <Wizard>
        <Wizard.Step name={Steps.Definition} ready={readiness.definition}>
          <ModelDefinition modelService={modelService} />
        </Wizard.Step>

        {definition?.class === AlgorithmClass.Recommender && (
          <Wizard.Step name={Steps.Cohort} ready={readiness.cohort} displayName="Item cohort">
            <ModelCohortPicker
              title="Item cohort"
              subtitle="Select the items for your recommender model"
              type={ModelCohortType.Item}
              subjectTypes={getCurrentProject()?.subjects || []}
            />
          </Wizard.Step>
        )}

        <Wizard.Step name={Steps.Cohort} ready={readiness.cohort} displayName="User cohort">
          <ModelCohortPicker
            showSplitRatio={false}
            type={ModelCohortType.User}
            title="User cohort"
            subtitle={definition ? cohortSelectorDescription[definition.class] : ''}
            subjectTypes={[TraitSubject.User]}
          />
        </Wizard.Step>

        {includeFeatures && (
          <Wizard.Step name={Steps.Features} ready={readiness.features}>
            <ModelFeatures />
          </Wizard.Step>
        )}

        {definition?.class === AlgorithmClass.Censoring && (
          <Wizard.Step name={Steps.Features} ready={readiness.features}>
            <CensoringModelFeatures ref={censoringFeaturesRef} />
          </Wizard.Step>
        )}

        {definition?.class === AlgorithmClass.Recommender && (
          <Wizard.Step name={Steps.Features} ready={readiness.features}>
            <Item2VectFeatures />
          </Wizard.Step>
        )}

        {definition?.class !== AlgorithmClass.Recommender && (
          <Wizard.Step name={Steps.Target} ready={readiness.target}>
            <ModelTarget />
          </Wizard.Step>
        )}

        <Wizard.Step name={Steps.Schedule} ready={readiness.scheduling}>
          <InterventionSection name={Steps.Schedule} title="Scheduling" subtitle="Set schedules for your model.">
            <SchedulingBuilder ref={schedulingRef} onReady={(ready) => updateReadiness(ready, Steps.Schedule)} />
          </InterventionSection>
        </Wizard.Step>

        <Wizard.Footer>
          <div className="controls">
            <CFButton
              value="Create Model"
              role={CFRole.Primary}
              disabled={Object.values(readiness).some((ready) => !ready)}
              isLoading={loading}
              onClick={handleCreateModel}
              iconCustom={<img src={pagePlusIcon} />}
            />
          </div>
        </Wizard.Footer>
      </Wizard>
    </div>
  );
};

export default function ModelForm() {
  return (
    <ModelContextProvider>
      <ModelNew />
    </ModelContextProvider>
  );
}
