import React, { useCallback, useEffect, useState } from 'react';

import CFPaginatedList from 'components/CFPaginatedList';

import { useServicesContext } from 'hooks/useServicesContext';

import './sample-viewer.scss';
import classNames from 'classnames';

export enum SampleViewerType {
  Cohort,
  Model,
}

interface Props {
  sampleId?: string;
  type?: SampleViewerType;
  selectedItems?: string[];
  handleClick?: (item: string) => void;
  handleError?: () => void;
}

const ListViewer = ({ sampleId, selectedItems = [], handleClick, handleError }: Props) => {
  const [totalSampledSubjects, setTotalSampledSubjects] = useState(-1);
  const [samples, setSamples] = useState<string[]>([]);
  const [curPage, setCurPage] = useState<number>(0);
  const [curPageSize, setCurPageSize] = useState<number>(15);
  const { modelService } = useServicesContext();

  const handleNewPageRequest = (page: number, size: number) => {
    setCurPage(page - 1);
    setCurPageSize(size);
  };

  const downloadPage = useCallback(async () => {
    if (!sampleId) {
      return;
    }

    try {
      const { total, data } = await modelService.getUsers(sampleId, curPage, curPageSize);

      setTotalSampledSubjects(total);

      setSamples(data);
    } catch (err) {
      handleError?.();
    }
  }, [sampleId, curPage, curPageSize]);

  useEffect(() => {
    downloadPage();
  }, [downloadPage]);

  return (
    <CFPaginatedList
      total={totalSampledSubjects}
      className={'sample-viewer-container'}
      pageSizeList={[15, 30, 90]}
      onPageChange={handleNewPageRequest}
    >
      {!samples.length ? (
        <span>No subjects</span>
      ) : (
        <div className="sample-viewer">
          {samples.map((sample) => {
            const selected = selectedItems.includes(sample);

            return (
              <div
                key={sample}
                className={classNames('sample-item', {
                  clickable: handleClick,
                  selected,
                })}
                onClick={() => handleClick?.(sample)}
              >
                {sample}
              </div>
            );
          })}
        </div>
      )}
    </CFPaginatedList>
  );
};

export default ListViewer;
