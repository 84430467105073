import React, { forwardRef, useImperativeHandle } from 'react';

// import LoadCsv from 'components/LoadCsv';

import CFTitledComponent from 'components/CFTitledComponent';
import CFTextarea from 'components/textarea/CFTextarea';

import './group-participants.scss';

interface Props {
  groupName: string;
}

export interface GroupParticipantsRef {
  value: () => string[];
}

const GroupParticipants = forwardRef<GroupParticipantsRef, Props>(function GroupParticipants(
  { groupName }: Props,
  ref
) {
  const textAreaRef = React.createRef<HTMLTextAreaElement>();

  useImperativeHandle(ref, () => ({
    value() {
      // TODO: move this to helper to test it easily
      const parsedSubjects = textAreaRef.current?.value
        .replace(/\r\n/g, '\n') // make sure line break is always the same
        .split('\n')
        .join(',') // join lines by commas
        .split(',')
        .map((subject) => subject.trim())
        .filter((subject) => subject.length !== 0);

      return parsedSubjects || [];
    },
  }));

  return (
    <div className="group-participants">
      <div className="groupname">Group: {groupName}</div>

      <CFTitledComponent title="Subject IDs">
        <CFTextarea ref={textAreaRef} placeholder="enter subject IDs, separated by a comma" />
      </CFTitledComponent>
    </div>
  );
});

export default GroupParticipants;

/*

  const handleCSVUpload = useCallback((data: any) => {
    setSubjects(data.filter((line: string) => line[0].trim().length !== 0));
  }, []);


  const handleNewPageRequest = useCallback((page: number, size: number) => {
    setCurrentPage(page - 1);
    setCurrentPageSize(size);
  }, []);
        <LoadCsv text="Upload File" onLoad={handleCSVUpload} />

*/
