import React, { forwardRef, useEffect, useState } from 'react';
import classNames from 'classnames';

import UsersLogo from './images/users.svg';
import { useServicesContext } from 'hooks/useServicesContext';
import Cohort from 'services/cohort/domain/Cohort';

import CFTooltip, { CFTooltipPositions } from 'components/CFTooltip';
import CohortExtendedInfo from './CohortExtendedInfo';

import './cohort-info.scss';

interface Props {
  sticky?: boolean;
}

const CohortInfo = forwardRef<HTMLDivElement, Props>(function CohortInfo({ sticky }, ref) {
  const { cohortService, landingService } = useServicesContext();
  const [cohort, setCohort] = useState<Cohort | undefined>();

  useEffect(() => {
    (async () => {
      const landingCohortId = await landingService.getLandingCohortId();
      const landingCohort = await cohortService.getRemoteCohort(landingCohortId);

      setCohort(landingCohort);
    })();
  }, []);

  return (
    <div ref={ref} className={classNames('cohort-info', { sticky })}>
      {cohort && (
        <CFTooltip
          position={CFTooltipPositions.Left}
          anchor=".cohort-info"
          description={<CohortExtendedInfo cohort={cohort} />}
        >
          <div className="content">
            <img src={UsersLogo} alt="Logo" />
            {cohort.name}
          </div>
        </CFTooltip>
      )}
    </div>
  );
});

export default CohortInfo;
