import { AlgorithmClass } from 'services/intervention/intervention.types';
import { Model } from 'domain/model.types';
import { CFRoutes } from 'routes';
import { AuthAction, isAllowedTo } from 'services/authorization.service';

export enum Tabs {
  Definition = 'definition',
  Metrics = 'metrics',
  MlTraits = 'traits',
}

export const allowedTabs = (model: Model) => {
  const tabs = [Tabs.Definition, Tabs.Metrics];

  if (
    isAllowedTo(AuthAction.ModelInspectMLTraits) &&
    model.definition.algo_spec.class_name === AlgorithmClass.Censoring
  ) {
    tabs.push(Tabs.MlTraits);
  }

  return tabs;
};

export const monitoringByTab: Record<string, CFRoutes> = {
  [Tabs.Definition]: CFRoutes.model_monitoring_definition,
  [Tabs.Metrics]: CFRoutes.model_monitoring_metrics,
  [Tabs.MlTraits]: CFRoutes.model_mltraits,
};
