import React from 'react';

import { RecommenderMetrics } from 'domain/model.types';
import { AggLevel } from 'domain/stats.types';

import CFLineChart from 'components/charts/CFLineChart';

import { CHART_COLORS } from 'styles/colors';

import './recommender-metrics.scss';

interface Props {
  metrics: RecommenderMetrics;
}

const RecommenderMetricsChart = ({ metrics }: Props) => {
  const seriesAccuracy = Object.keys(metrics.accuracy).map((item, i) => ({
    name: item,
    items: metrics.accuracy[item].map((valueItem) => ({ time: valueItem.t, value: valueItem.v })),
    color: CHART_COLORS[i],
  }));

  const seriesPrecission = Object.keys(metrics.precision_k).map((item, i) => ({
    name: item,
    items: metrics.precision_k[item].map((valueItem) => ({ time: valueItem.t, value: valueItem.v })),
    color: CHART_COLORS[i],
  }));

  const seriesRecall = Object.keys(metrics.recall_k).map((item, i) => ({
    name: item,
    items: metrics.precision_k[item].map((valueItem) => ({ time: valueItem.t, value: valueItem.v })),
    color: CHART_COLORS[i],
  }));

  return (
    <div className="recommender-metrics">
      <CFLineChart data={seriesAccuracy} aggregationLevel={AggLevel.Day} isLoading={false} title="Accuracy" />

      <CFLineChart
        data={seriesPrecission}
        aggregationLevel={AggLevel.Day}
        isLoading={false}
        title="Precision (at k = 8)"
      />

      <CFLineChart data={seriesRecall} aggregationLevel={AggLevel.Day} isLoading={false} title="Recall (at k = 8)" />
    </div>
  );
};

export default RecommenderMetricsChart;
