export enum Sections {
  Analytics = 'analytics',
  Assistant = 'assistant',
  Integration = 'integration',
  Segmentation = 'segmentation',
  Intervention = 'intervention',
  Model = 'model',
}

export enum CFRoutes {
  assistant = '/assistant',
  intervention = '/intervention',
  intervention_new = '/intervention/new',
  intervention_monitoring_definition = '/intervention/:id/definition',
  intervention_monitoring_metrics = '/intervention/:id/metrics',
  intervention_nudge_list = '/intervention/nudges',
  intervention_nudge_new = '/intervention/nudges/new',
  intervention_nudge_adoption_new = '/intervention/nudge/adoption/new',
  intervention_nudge_adoption = '/intervention/nudge/adoption',
  analytics = '/analytics',
  analytics_explore = '/analytics/explore',
  analytics_engagement_per_role = '/analytics/engagement/per_role',
  analytics_engagement_percentage = '/analytics/engagement/percentage',
  analytics_engagement_patient_encounters = '/analytics/engagement/patient_encounters',
  integration = '/integration/monitoring',
  integration_ingest_history = '/integration/ingesthistory',
  integration_catalog_history = '/integration/cataloghistory',
  integration_exception_view = '/integration/exceptionview',
  integration_keys = '/integration/keys',
  integration_keys_new = '/integration/keys/new',
  segmentation_cohort = '/segmentation',
  segmentation_cohort_new = '/segmentation/new',
  segmentation_trait = '/segmentation/traits',
  traits = '/traits',
  traits_definition = '/traits/definition',
  traits_backfill_status = '/traits/backfill',
  admin = '/admin',
  admin_project = '/admin/project',
  admin_org = '/admin/org',
  admin_users = '/admin/users',
  admin_internal = '/admin/internal',
  project_new = '/admin/projects/new',
  project_edit = '/admin/project/:project/edit/',
  new_org = '/admin/orgs/new',
  invite_user = '/admin/users/invite',
  user_detail = '/admin/users/:username',
  model = '/model',
  model_new = '/model/new',
  model_monitoring_definition = '/model/:id/definition',
  model_monitoring_metrics = '/model/:id/metrics',
  model_mltraits = '/model/:id/mltraits',
}

export const getCurrentSection = (): Sections => {
  return document.location.pathname.slice(1).split('/')[0] as Sections;
};
