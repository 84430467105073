import React, { Children, createContext, ReactElement, ReactNode, useContext, useEffect, useReducer } from 'react';
import CFWizardSteps, { StepStatus } from './CFWizardSteps';

import './wizard.scss';

interface WizardStepProps {
  children: ReactElement;
  ready: boolean;
  name: string;
  displayName?: string;
}

enum ActionType {
  Focus,
  Ready,
}

interface StepState {
  ready: boolean;
  focus: boolean;
  status: StepStatus;
}

interface ReadinessAction {
  type: ActionType;
  ready?: boolean;
  step: StepName;
}

type StepName = string;

interface WizardContextValue {
  readiness: Record<StepName, StepState>;
  setReadiness: any;
}

const WizardContext = createContext<WizardContextValue | null>(null);

const readinessReducer = (state: Record<StepName, StepState>, action: ReadinessAction): Record<StepName, StepState> => {
  const newState = { ...state };

  if (!newState[action.step]) {
    newState[action.step] = {
      ready: false,
      focus: false,
      status: StepStatus.Pending,
    };
  }

  switch (action.type) {
    case ActionType.Focus:
      Object.keys(newState).forEach((key) => {
        newState[key as StepName].focus = false;

        if (newState[action.step].status !== StepStatus.Ready) {
          newState[action.step].status == StepStatus.Pending;
        }
      });
      newState[action.step].focus = true;

      if (newState[action.step].status !== StepStatus.Ready) {
        newState[action.step].status = StepStatus.Ongoing;
      }

      return newState;

    case ActionType.Ready:
      newState[action.step].ready = action.ready as boolean;

      if (action.ready) {
        newState[action.step].status = StepStatus.Ready;
      } else if (newState[action.step].focus) {
        newState[action.step].status = StepStatus.Ongoing;
      } else {
        newState[action.step].status = StepStatus.Pending;
      }
      return newState;

    default:
      return state;
  }
};

const WizardStep = ({ name, ready, children }: WizardStepProps) => {
  const context = useContext(WizardContext);

  useEffect(() => {
    context?.setReadiness({
      type: ActionType.Ready,
      step: name,
      ready,
    });
  }, [ready]);

  const handleFocus = async (sectionName: StepName) => {
    context?.setReadiness({
      type: ActionType.Focus,
      step: sectionName,
    });
  };

  return <div onFocus={() => handleFocus(name)}>{children}</div>;
};

const WizardHeader = (props: any) => {
  return props.children;
};

const WizardFooter = (props: any) => {
  return props.children;
};

interface Props {
  children: ReactNode;
}

const Wizard = ({ children }: Props) => {
  const arrayChildren = Children.toArray(children);
  const steps = arrayChildren.filter((child) => (child as any).type.name === WizardStep.name);

  const initialReducerState = steps.reduce(
    (acc, curr) => ({
      ...acc,
      [(curr as any).props.name]: {
        ready: false,
        focus: false,
        status: StepStatus.Pending,
      },
    }),
    {}
  );

  const [readiness, setReadiness] = useReducer(readinessReducer, initialReducerState);

  const header = arrayChildren.find((child) => (child as any).type.name === WizardHeader.name);
  const footer = arrayChildren.find((child) => (child as any).type.name === WizardFooter.name);

  return (
    <WizardContext.Provider value={{ readiness, setReadiness }}>
      <div className="cf-wizard">
        {header}
        <div className="steps">{steps}</div>

        <CFWizardSteps
          className="cf-wizard-container"
          steps={steps.map((step) => ({
            name: (step as any).props.displayName || (step as any).props.name,
            targetSection: (step as any).props.name,
            status: readiness[(step as any).props.name]?.status || StepStatus.Pending,
          }))}
        />
        {footer}
      </div>
    </WizardContext.Provider>
  );
};

Wizard.Step = WizardStep;
Wizard.Header = WizardHeader;
Wizard.Footer = WizardFooter;

export default Wizard;
