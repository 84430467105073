import React, { useEffect, useState } from 'react';

import CFTraitItem from 'connected-components/traits/CFTraitItem';
import CFTitledComponent from 'components/CFTitledComponent';
import Box from 'components/Box';

import { AlgorithmClass } from 'services/intervention/intervention.types';
import { Model } from 'domain/model.types';
import { Trait } from 'domain/traits.types';

import { useServicesContext } from 'hooks/useServicesContext';

import './target.scss';

interface Props {
  model: Model;
}

const ModelTarget = ({ model }: Props) => {
  const { traitSessionService: traitService } = useServicesContext();
  const [rewardTrait, setRewardTrait] = useState<Trait>();
  const [eventTrait, setEventTrait] = useState<Trait>();

  const isBandit = model.definition?.algo_spec.class_name === AlgorithmClass.Bandit;
  const isCensoring = model.definition?.algo_spec.class_name === AlgorithmClass.Censoring;
  const isRecommender = model.definition?.algo_spec.class_name === AlgorithmClass.Recommender;

  useEffect(() => {
    if (isBandit && model.definition.data_defn.bandit?.reward) {
      setRewardTrait(traitService.getTraitFromAddr(model.definition.data_defn.bandit?.reward));
    }

    if (isCensoring && model.definition.data_defn.censoring) {
      if (model.definition.data_defn.censoring.event) {
        setEventTrait(traitService.getTraitFromAddr(model.definition.data_defn.censoring.event));
      }
    }
  }, [traitService.traitDefinitions]);

  return (
    <div className="model-target">
      {isCensoring && (
        <>
          {eventTrait && (
            <CFTitledComponent title={'Event'}>
              <Box className="field">
                <CFTraitItem addr={eventTrait?.addr} />
              </Box>
            </CFTitledComponent>
          )}

          {model.definition.data_defn.censoring?.validation_delta !== undefined && (
            <CFTitledComponent title={'Validation delta'}>
              <Box className="field">{model.definition.data_defn.censoring?.validation_delta}</Box>
            </CFTitledComponent>
          )}
        </>
      )}
      {isBandit && rewardTrait && (
        <CFTitledComponent title={'Reward'}>
          <Box className="field">
            <CFTraitItem addr={rewardTrait?.addr} />
          </Box>
        </CFTitledComponent>
      )}

      {isRecommender && (
        <CFTitledComponent title={'Subject'}>
          <Box className="field">
            <span>{model.cohort.subject_type} </span>
          </Box>
        </CFTitledComponent>
      )}
    </div>
  );
};

export default ModelTarget;
