import { AggLevel, TimeSeriesItem } from 'domain/stats.types';
import {
  ColAddr,
  Trait,
  TraitCategory,
  TraitCode,
  TraitDataType,
  TraitDefinition,
  TraitSubject,
  TraitUsage,
} from 'domain/traits.types';
import { capitalizeFirst } from 'helpers/misc';
import { CFTraitRepository, TraitsSearchParams } from 'services/traits/traits.repo';
import CFService from '../cfservice';

import { conversions as conversor } from 'helpers/unitconversion';

import { CFSessionService } from '../session/session.service';
import {
  createTraitCode,
  getDisplayName,
  getTraitCategory,
  getTraitName,
  getTraitNameFromAddr,
  isAggLevel,
} from './helpers.traits';
import { GDTPoint, Modules, SubjectId, TimeRFC3999 } from 'domain/general.types';
import { ModelId } from 'domain/model.types';

import { CohortID } from 'services/cohort/cohort.types';
import FilterSet from 'services/cohort/domain/FilterSet';
import { Ptr } from 'services/cohort/cohort.types.api';

export default class TraitService extends CFService {
  //initialized = false;
  initPromise: Promise<Trait[][]> | null;
  traitRepository: CFTraitRepository;
  sessionService: CFSessionService;
  traitDefinitions: Record<string, Trait> = {};
  allSubjectCohortId: number | null = null;
  indexedTraitDefinitions: Record<TraitCategory, string[]> = {
    [TraitCategory.Catalogue]: [],
    [TraitCategory.Static]: [],
    [TraitCategory.Dynamic]: [],
    [TraitCategory.Grouped]: [],
    [TraitCategory.MLT]: [],
  };
  traitModelByCohort: Record<CohortID, Record<TraitCode, ModelId[]>> = {};
  lastCohortForModel: CohortID | undefined = undefined;

  _name = 'traitService';

  constructor(traitRepository: CFTraitRepository, sessionService: CFSessionService) {
    super();
    this.traitRepository = traitRepository;
    this.sessionService = sessionService;
    this.initPromise = null;
  }

  async init() {
    // machine learning traits
    //    const mltraits = await this.fetchTraits({ subject: TraitSubject.User, category: TraitCategory.MLT });

    // initialize all the traits to make then available when needed
    const traitFetchPromises = (this.sessionService.getCurrentProject()?.subjects as TraitSubject[]).map((subject) =>
      this.fetchTraits({
        subject,
      })
    );

    this.initPromise = Promise.all(traitFetchPromises);

    await this.initPromise;
  }

  indexTrait(trait: Trait) {
    const traitCode = createTraitCode(trait);
    this.traitDefinitions[traitCode] = trait;

    const category = getTraitCategory(trait.addr.ptr);
    if (
      category === TraitCategory.Catalogue ||
      category === TraitCategory.Static ||
      category === TraitCategory.Dynamic
    ) {
      this.indexedTraitDefinitions[category].push(traitCode);
    }
  }

  async getAvailableTraits() {
    await this.initPromise;

    return [
      ...this.indexedTraitDefinitions[TraitCategory.Catalogue].map((traitCode) => this.traitDefinitions[traitCode]),
      ...this.indexedTraitDefinitions[TraitCategory.Dynamic].map((traitCode) => this.traitDefinitions[traitCode]),
      ...this.indexedTraitDefinitions[TraitCategory.Static].map((traitCode) => this.traitDefinitions[traitCode]),
    ];
  }

  getTraitFromAddr(addr: ColAddr) {
    const traitCode = createTraitCode(addr);

    return this.getTraitDefinition(traitCode);
  }

  getTraitDefinition(ptr: Ptr) {
    return this.traitDefinitions[ptr];
  }

  async getAllContextStaticTraits(subject: TraitSubject): Promise<Trait[]> {
    const traits = await Promise.all([
      this.getTraits({ subject, category: TraitCategory.Catalogue }),
      this.getTraits({ subject, category: TraitCategory.Static }),
    ]);

    return traits.flat().filter((trait) => getTraitNameFromAddr(trait.addr) !== 'id');
  }

  async getBanditContextStaticTraits(subject: TraitSubject): Promise<Trait[]> {
    const traits = await Promise.all([
      this.getTraits({ subject, category: TraitCategory.Catalogue, usage: TraitUsage.BanditContext }),
      this.getTraits({ subject, category: TraitCategory.Static, usage: TraitUsage.BanditContext }),
    ]);

    return traits.flat().filter((trait) => getTraitNameFromAddr(trait.addr) !== 'id');
  }

  async getContextDynamicTraits(subject: TraitSubject, usage: TraitUsage): Promise<Trait[]> {
    const traits = await this.getTraits({ subject, category: TraitCategory.Dynamic, usage });

    return traits.filter((trait) => getTraitNameFromAddr(trait.addr) !== 'id');
  }

  getTraitsSubjects(): TraitSubject[] {
    const userInfo = this.sessionService.getUserInfo();

    const orgproj = userInfo.available_orgprojs.find(
      (orgproj) => `${orgproj.org.id}` === this.sessionService.getOrganization()
    );
    const project = orgproj?.projs.find((project) => `${project.id}` === this.sessionService.getProject());

    return project?.subjects as TraitSubject[];
  }

  async getUniqueValuesForTrait(traitAddr: ColAddr): Promise<Array<any>> {
    if (traitAddr.dtype === TraitDataType.Boolean || traitAddr.dtype === TraitDataType.Bool) {
      return [true, false];
    }

    if (([TraitDataType.Float4, TraitDataType.Int4, TraitDataType.Number] as string[]).includes(traitAddr.dtype)) {
      return [];
    }

    if (traitAddr.dtype === TraitDataType.Varchar || traitAddr.dtype.slice(1) === TraitDataType.Varchar) {
      const uniqueValues = await this.traitRepository.getUniqueValuesForTrait(traitAddr);

      return uniqueValues;
    }

    return [];
  }

  async getTraits({ subject, category, usage, aggLevel, module, visibility }: TraitsSearchParams) {
    await this.initPromise;

    const allTraits = Object.values(this.traitDefinitions);

    let traits: Trait[] = [...allTraits];

    const filterByCategory = (traits: Trait[]) =>
      traits.filter((trait) => getTraitCategory(trait.addr.ptr) === category);

    const filterBySubject = (traits: Trait[]) => traits.filter((trait) => trait.meta.subject === subject);

    const filterByUsage = (traits: Trait[]) =>
      traits.filter((trait) => trait.meta.usage?.includes(usage as TraitUsage));

    const filterByAggLevel = (traits: Trait[]) =>
      traits.filter((trait) => isAggLevel(trait.addr, aggLevel as AggLevel));

    const filterByModule = (traits: Trait[]) =>
      traits.filter((trait) => trait.meta.modules?.includes(module as Modules));

    const filterByVisibility = (traits: Trait[]) => traits.filter((trait) => trait.meta.Show || trait.meta.show);

    if (category) {
      traits = filterByCategory(traits);
    }

    if (subject) {
      traits = filterBySubject(traits);
    }

    if (usage) {
      traits = filterByUsage(traits);
    }

    if (aggLevel) {
      traits = filterByAggLevel(traits);
    }

    if (module) {
      traits = filterByModule(traits);
    }

    if (visibility) {
      traits = filterByVisibility(traits);
    }

    return traits;
  }

  async fetchTraits({ subject, category, usage, aggLevel, module }: TraitsSearchParams) {
    await this.initPromise;
    const subjectsToFetch = [subject];

    const traits = await Promise.all(
      subjectsToFetch.map((subject) => this.traitRepository.search({ subject, usage, category, aggLevel, module }))
    );
    // checks if the modules in the trait are exactly equal
    // to the modules in the project
    const traitModulesMatchProject = (trait: Trait) => {
      // mlt traits do not have modules
      if (getTraitCategory(trait.addr.ptr) === TraitCategory.MLT) {
        return true;
      }

      for (const traitModule of trait.meta.modules || []) {
        if (this.sessionService.getCurrentProject()?.modules.find((trait) => trait === traitModule) !== undefined) {
          return true;
        }
      }

      return false;
    };
    const filteredTraits = traits.flat().filter(traitModulesMatchProject);

    filteredTraits.forEach((trait) => {
      trait.meta.description = capitalizeFirst(trait.meta.description);
      this.indexTrait(trait);
    });

    return filteredTraits;
  }

  async getTraitDefinitions(subject: TraitSubject, category: TraitCategory): Promise<TraitDefinition[]> {
    const traits = await this.traitRepository.search({ subject, category });

    return (traits || []).map((trait) => ({
      name: getTraitName(trait),
      ptr: trait.addr.ptr,
      display_name: getDisplayName(trait),
      description: trait.meta.description,
      unit: trait.meta.unit,
      category,
    }));
  }

  async getTimeseries(start: string, end: string, traitAddr: ColAddr, cohortId: string): Promise<TimeSeriesItem[]> {
    const trait = this.getTraitFromAddr(traitAddr);

    const timeSeries = await this.traitRepository.getTimeseries(start, end, traitAddr, cohortId);

    if (!trait.meta.unit || conversor[trait.meta.unit] === undefined) {
      return timeSeries;
    }

    if (!conversor[trait.meta.unit][trait.meta.display_unit]) {
      return timeSeries;
    }

    return timeSeries.map((item) => ({
      ...item,
      value: conversor[trait.meta.unit][trait.meta.display_unit](item.value),
    }));
  }

  async getMLTTimeseries(start: string, end: string, modelId: ModelId, traits: Trait[], subjects: SubjectId[]) {
    if (!subjects.length) {
      return {};
    }

    const addrs = traits.map((trait) => trait.addr);
    const timeSeries = await this.traitRepository.getMLTTimeseries(start, end, addrs, subjects, modelId);

    return timeSeries;
  }

  async initModelsForCohort(cohortId: CohortID) {
    const modelIDsByTraitCode = await this.traitRepository.getModelTraits(cohortId);

    this.lastCohortForModel = cohortId;
    this.traitModelByCohort[cohortId] = modelIDsByTraitCode;
  }

  getModels(traitCode: TraitCode): ModelId[] {
    if (!this.lastCohortForModel) {
      return [];
    }

    // TODO: wait for promise of initModelsForCohort?
    return this.traitModelByCohort[this.lastCohortForModel]?.[traitCode] || [];
  }

  async getGDTPoint(ptr: Ptr, filterSet: FilterSet, start: TimeRFC3999, end: TimeRFC3999): Promise<GDTPoint> {
    const tree = filterSet.getLegacy();

    const gdtPoint = await this.traitRepository.getGDTCustom(ptr, tree, start, end);

    return gdtPoint;
  }
}
