import { AxiosResponse } from 'axios';
import { get as httpGet, post as httpPost } from './drivers/http';

import { OrgDefinition } from '../types';
import { RepoConfig } from './types';

const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
const path = '/v1/orgproj/org';

const repoConfig = {
  token: '',
  oid: -1,
  pid: -1,
};

export const init = ({ token, oid, pid }: RepoConfig) => {
  repoConfig.token = token;
  repoConfig.oid = oid;
  repoConfig.pid = pid;
};

export const get = async (): Promise<OrgDefinition[]> => {
  const config = {};

  const orgs = (await httpGet(
    `${serverBaseUrl}${path}?oid=${repoConfig.oid}&pid=${repoConfig.pid}`,
    config
  )) as AxiosResponse;

  return Object.values(orgs.data).map((orgproj: any) => orgproj.org);
};

export const create = async (name: string, description: string): Promise<number> => {
  const config = {};

  const body = {
    name,
    description,
    oid: repoConfig.oid,
    pid: repoConfig.pid,
  };

  try {
    const newOrgResponse = await httpPost(
      `${serverBaseUrl}${path}?oid=${repoConfig.oid}&pid=${repoConfig.pid}`,
      body,
      config
    );
    return newOrgResponse?.data;
  } catch (err) {
    console.error('error creating org: ', err);
    throw new Error('error-creating-project');
  }
};
