import { CHART_COLORS } from 'styles/colors';
import colors from 'common.scss';

enum Groups {
  All = 'all_groups',
  Control = 'control',
  Treatment = 'treatment',
}

const colorsByGroup: Record<Groups, string> = {
  [Groups.All]: 'white',
  [Groups.Control]: colors.cfCyan,
  [Groups.Treatment]: colors.cfYellow,
};

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getColorForGroup = (groupName: string, index: number, isControl = false) => {
  if (isControl) {
    return colors.cfCyan;
  }

  let color = colorsByGroup[groupName.toLowerCase() as Groups];

  if (!color) {
    color = CHART_COLORS[index + 1]; // +1 to skip cyan, just in case
    colorsByGroup[groupName.toLowerCase() as Groups] = color;
  }

  if (!color) {
    color = getRandomColor();
    colorsByGroup[groupName.toLowerCase() as Groups] = color;
  }

  return color;
};
