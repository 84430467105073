export interface DateRange {
  startDate?: Date;
  endDate?: Date | null | undefined;
}

export type Setter<T> =
  | React.Dispatch<React.SetStateAction<T>>
  | ((value: T) => void);

export enum NavigationAction {
  Previous = -1,
  Next = 1,
}

export type DefinedRange = {
  startDate: Date;
  endDate: Date;
  label: string;
};

export const MENU_HEIGHT = 78