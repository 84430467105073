import React from 'react';

import CFButton from 'components/buttons/CFButton';
import CFTitledComponent from 'components/CFTitledComponent';
import CFTitledSection from 'components/CFTitledSection';
import CFMessage from 'components/CFMessage';
import CFText from 'components/CFText';

import './api-key-viewer.scss';

interface Props {
  apiKey: string;
}

const warningMessageText = `This is your new API key. Please copy it and save it in a safe place, 
    we never will show this key again.  
    Only share this API key with people you trust.`;

const ApiKeyViewer = ({ apiKey }: Props) => {
  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(apiKey)
      .then(() => {
        console.log('API key copied correctly to clipboard');
      })
      .catch(() => {
        console.warn('Error copying key to clipboard');
      });
  };

  return (
    <CFTitledSection title={'Integration   >   API Keys   >   New'}>
      <div className="api-key-viewer">
        <CFMessage text={warningMessageText} type={'neutral'} />

        <CFTitledComponent title="API key">
          <CFText text={apiKey} />
        </CFTitledComponent>

        <CFButton value={'Copy to clipboard'} onClick={handleCopyToClipboard} />
      </div>
    </CFTitledSection>
  );
};

export default ApiKeyViewer;
