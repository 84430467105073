import React from 'react';

import { Trait, TraitSubject, TraitUsage } from 'domain/traits.types';
import { useEffect, useState } from 'react';

import { CFNavList } from 'components/CFNavList';

import { segmentationByTab, Tabs } from '..';
import CFSelectLegacy, { SelectableItem } from 'components/CFSelectLegacy';
import { postgresqlTypeToHuman } from 'helpers/misc';

import { CFRole } from 'domain/general.types';
import { getOrganization, getProject, getUserInfo } from 'services/session/session.service';

import CFTraitItem from 'connected-components/traits/CFTraitItem';
import CFTable, { Column, ColumnType } from 'components/CFTable';
import { useServicesContext } from 'hooks/useServicesContext';

import useCFNavigation from 'hooks/useCFNavigation';

import './traits.scss';
import { getDisplayName } from 'services/traits/helpers.traits';

const Traits = () => {
  const { traitSessionService: traitService } = useServicesContext();
  const [visibleTraits, setVisibleTraits] = useState<Trait[]>([]);
  const [subjects, setSubjects] = useState<string[]>([]);
  const navigate = useCFNavigation();

  const downloadTraits = async (subject: TraitSubject) => {
    const usage = TraitUsage.Cohort;

    const traits = await traitService.getTraits({ subject, usage });

    const formattedTraits = traits.map((trait) => ({
      ...trait,
      dtype: postgresqlTypeToHuman(trait.addr.dtype),
    }));

    setVisibleTraits(formattedTraits);
  };

  useEffect(() => {
    downloadTraits(TraitSubject.User);
  }, []);

  useEffect(() => {
    const userInfo = getUserInfo();

    const currentOrg = userInfo.available_orgprojs.find((orgproj) => orgproj.org.id.toString() === getOrganization());
    const currentProj = currentOrg?.projs.find((proj) => proj.id.toString() === getProject());

    setSubjects(currentProj?.subjects || []);
  }, []);

  const handleSelectedSubject = ([item]: SelectableItem[]) => {
    downloadTraits(item.value as TraitSubject);
  };

  const headers: Column[] = [
    {
      title: '',
      type: ColumnType.NUMBER,
      field: '',
      renderCell: (row) => <CFTraitItem showAggLevel={true} addr={row.addr} omitDisplayName={true} />,
    },
    {
      title: 'Trait',
      type: ColumnType.NUMBER,
      field: '',
      renderCell: (row) => getDisplayName(row as Trait),
    },
    {
      title: 'Description',
      type: ColumnType.STRING,
      field: 'description',
    },
  ];

  const tabs = Object.values(Tabs);

  return (
    <div className="traits">
      <CFNavList
        titles={tabs}
        selected={Tabs.Traits}
        onClick={(selectedTab) => navigate(segmentationByTab[selectedTab])}
      />

      <div className="traits-content">
        <div className="filters">
          {subjects.length !== 0 && (
            <CFSelectLegacy
              className="subject-selector"
              options={subjects.map((subject) => ({
                value: subject,
                label: subject,
              }))}
              defaultOption={[{ value: TraitSubject.User, label: TraitSubject.User }]}
              role={CFRole.Secondary}
              onSelected={handleSelectedSubject}
            />
          )}
        </div>

        <CFTable
          headers={headers}
          data={visibleTraits.map((trait) => ({ ...trait, description: trait.meta.description }))}
          emptyLabel="No Selected Traits"
        />
      </div>
    </div>
  );
};

export default Traits;
