import React, { useCallback, useEffect, useState } from 'react';

import { Leaf } from 'services/cohort/cohort.types.api';
import Cohort from 'services/cohort/domain/Cohort';
import Filter from 'services/cohort/domain/Filter';
import FilterSet from 'services/cohort/domain/FilterSet';

import { ToastType } from 'components/CFToast/types';

import { useServicesContext } from 'hooks/useServicesContext';

import { useToast } from 'hooks';
import CFPaginatedList from 'components/CFPaginatedList';
import CFLoadWrapper from 'components/CFLoadWrapper';

interface Props {
  cohort: Cohort | undefined;
  extraFilter: Leaf<Filter> | undefined;
}

const CohortViewer = ({ cohort, extraFilter }: Props) => {
  const { cohortService } = useServicesContext();
  const [totalSubjects, setTotalSubjects] = useState(-1);
  const [subjects, setSubjects] = useState<string[]>([]);

  const [curPage, setCurPage] = useState<number>(0);
  const [curPageSize, setCurPageSize] = useState<number>(15);
  const [isLoading, setIsloading] = useState(false);

  const { addToast } = useToast();

  useEffect(() => {
    (async () => {
      if (!cohort) {
        return;
      }

      setIsloading(true);

      const cohortFilterWithExtraFilters = buildFilterDataObject();

      try {
        const { total, data } = await cohortService.preview(cohortFilterWithExtraFilters, curPage, curPageSize);

        setTotalSubjects(total);
        setSubjects(data);
        setIsloading(false);
      } catch (err) {
        addToast('Error previewing sample', ToastType.ERROR, 5000);
      } finally {
        setIsloading(false);
      }
    })();
  }, [curPage, curPageSize, cohort, extraFilter]);

  const handleNewPageRequest = useCallback(async (page: number, size: number) => {
    setCurPage(page - 1);
    setCurPageSize(size);
  }, []);

  const buildFilterDataObject = (): FilterSet => {
    const filterSet = new FilterSet((cohort as Cohort).tree.getLegacy());

    if (extraFilter) {
      filterSet.addExtraFilter(extraFilter);
    }

    return filterSet;
  };
  return (
    <CFLoadWrapper isLoading={isLoading} spinnerSize={30}>
      <CFPaginatedList total={totalSubjects} pageSizeList={[15, 30, 90]} onPageChange={handleNewPageRequest}>
        {!subjects.length ? (
          <span> Select a cohort first</span>
        ) : (
          <div className="subject-view">
            {subjects.map((subject) => (
              <div key={subject} className="subject-item">
                {subject}
              </div>
            ))}
          </div>
        )}
      </CFPaginatedList>
    </CFLoadWrapper>
  );
};

export default CohortViewer;
