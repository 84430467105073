import React, { useEffect } from 'react';
import { ScatterRangeProps } from '.';
import CFScatterRangeChart from './components/Chart';
import ScatterLegend from './components/Legend';
import VisualMap from './components/VisualMap';

import { useScatterRangeContext } from './useScatterRange';

const CFScatterRangeCore = (props: ScatterRangeProps) => {
  const { selectedItems } = useScatterRangeContext();

  useEffect(() => {
    if (!props.onSelectedChanged) {
      return;
    }

    props.onSelectedChanged([...selectedItems]);
  }, [selectedItems]);

  return (
    <div className="cf-scatter-range-container">
      <CFScatterRangeChart {...props} className="cf-scatter-range-container__plot" />
      {props.showGradient && <VisualMap className="cf-scatter-range-container__map" />}
      {props.legend && <ScatterLegend className="cf-scatter-range-container__legend" items={props.legend} />}
    </div>
  );
};

export default CFScatterRangeCore;
