import React, { ReactNode } from 'react';

import './monitoring-section.scss';

interface Props {
  title: string;
  subTitle?: string;
  children: ReactNode;
  className?: string;
}

const MonitoringSection = ({ className, title, subTitle, children }: Props) => {
  return (
    <div className={`${className || ''} monitoring-section`.trim()}>
      <div className="title">{title}</div>
      {subTitle && <div className="sub-title">{subTitle}</div>}
      <div className="content">{children}</div>
    </div>
  );
};

export default MonitoringSection;
