import Cohort from 'services/cohort/domain/Cohort';
import { CohortAPI } from '../cohort/cohort.types';
import { TimeRFC3999 } from '../../domain/general.types';
import { FixedNudgeSchedule, FixedNudgeScheduleRequest, TsPoint } from '../intervention/intervention.types';
import { ModelId } from '../../domain/model.types';
import { TraitSubject } from '../../domain/traits.types';
import { PaginatedElement } from 'types';
import { RepoConfig } from 'repositories/types';

export interface CFNudgeRepository {
  init: ({ token, oid, pid }: RepoConfig) => void;
  create: (nudge: CreatingNudge) => void;
  get: (page: number, per_page: number) => Promise<PaginatedElement<Nudge>>;
  getById: (id: NudgeID) => Promise<Nudge>;
  remove: (nudgeId: number) => void;
}

export type NudgeID = number;

export enum NudgeType {
  Message = 'message',
  Recommender = 'recommend',
}

export enum RenderMethod {
  InApp = 'in_app_message',
  PushNotification = 'push_notification',
}

export enum NudgeTag {
  Incentive = 'incentive',
  Motivation = 'motivation',
  Reminder = 'reminder',
}

export enum TemplateType {
  ItemPairTemplate = 'item_pair',
  RandomItemPairTemplate = 'random_item_pair',
  TraitsTemplate = 'traits',
  None = 'none',
}

export enum PairRankType {
  PurchaseCount = 'purchase_count',
  PurchaseAmount = 'purchase_amount',
  PurchaseQuantity = 'purchase_quantity',
  Random = 'random',
}

export interface ItemPairMsgCfg {
  item_type: TraitSubject;
  pair_rank_type?: PairRankType;
}

export interface MsgTmplCfg {
  tmpl_type: TemplateType;
  item_pair_cfg: ItemPairMsgCfg;
  traits: string[];
}

export interface Message {
  title: string;
  body: string;
  tags: NudgeTag[];
  tmpl_cfg?: MsgTmplCfg;
}

export interface NudgeMessageDefinition {
  type: NudgeType.Message;
  message: Message;
  render_method: RenderMethod;
  cta: CallToAction;
  render_page: RenderPage;
  noop: boolean;
}

export interface NudgeRecommendDefinition {
  type: NudgeType.Recommender;
  recommend: {
    model_id: ModelId;
    subject_type: TraitSubject;
  };
}

export type NudgeDefinition = NudgeMessageDefinition | NudgeRecommendDefinition;

export interface CreatingNudge {
  name: string;
  description: string;
  definition: NudgeDefinition;
}

export interface Nudge extends CreatingNudge {
  id: NudgeID;
  pid: number;
  use: number;
  created_by: string;
  created_at: number;
}

export enum NudgeState {
  Block = 'block',
  Discard = 'discard',
  Open = 'open',
  Error = 'error',
  Shown = 'shown',
  Unknown = 'unknown',
}

export type NudgeStat = Record<NudgeState, TsPoint[]>;

export interface AdoptedNudgeCreateRequest {
  nudge_id: number;
  cohort_id: number;
  schedule: FixedNudgeScheduleRequest;
  expire_dur?: number;
}

export interface AdoptedNudgeAPI {
  cohort: CohortAPI;
  def: {
    id: number;
    pid: number;
    nudge_id: number;
    schedule_id: number;
    cohort_id: number;
    expire_dur: number;
    created_by: string;
    created_at: TimeRFC3999;
  };
  nudge: Nudge;
  schedule: FixedNudgeSchedule;
}

export interface AppAdoptedNudge extends Omit<AdoptedNudgeAPI, 'cohort'> {
  cohort: Cohort;
}

export enum CallToAction {
  Redirect = 'redirect',
  AddToCart = 'add_to_cart',
}

export enum RenderPage {
  Cart = 'cart',
  Home = 'home',
  Search = 'search',
  Product = 'product',
  Checkout = 'checkout',
  Reminder = 'reminder',
  Favorite = 'favorite',
  Other = 'other',
  None = '',
}
