import dayjs from 'dayjs';
import { DateRange } from './types';

type Falsy = false | null | undefined | 0 | '';

export const onEscapeOrEnterTap = (
  e: React.KeyboardEvent<HTMLInputElement>,
  props: {
    inputFocused: boolean;
    eachInputDropdown: boolean;
    movePrev?: () => void;
    setInputFocused: (focused: boolean) => void;
    inputRef: React.RefObject<HTMLInputElement>;
    moveNext?: () => void;
  }
) => {
  const { inputFocused, eachInputDropdown, movePrev, setInputFocused, inputRef, moveNext } = props;
  if (e.key === 'Escape' && inputFocused && eachInputDropdown) {
    setInputFocused(false);
    if (movePrev) {
      movePrev();
    } else {
      inputRef.current?.blur();
    }
  }

  if (e.key === 'Enter' && inputFocused && eachInputDropdown) {
    setInputFocused(false);
    if (moveNext) {
      moveNext();
    } else {
      inputRef.current?.blur();
    }
  }
};

export const onSideArrowTap = (
  e: React.KeyboardEvent<HTMLInputElement>,
  props: {
    moveNext?: () => void;
    movePrev?: () => void;
  }
) => {
  const { moveNext, movePrev } = props;
  e.key === 'ArrowRight' && moveNext && moveNext();
  e.key === 'ArrowLeft' && movePrev && movePrev();
};

export const getSameInputProps = (props: {
  setInputFocused: (focused: boolean) => void;
  inputFocused: boolean;
  disabled: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  manuallyDisplayDropdown: boolean;
}) => {
  const { setInputFocused, inputFocused, disabled, inputRef, manuallyDisplayDropdown } = props;
  return {
    onFocusCapture: () => !manuallyDisplayDropdown && setInputFocused(true),
    disabled,
    onBlurCapture: () => {
      !inputFocused &&
        setTimeout(() => {
          setInputFocused(false);
        }, 10);
    },
    ref: inputRef,
  };
};

export const doubleChar = (value: string) => (value.length >= 2 ? value : '0' + value).slice(-2);

export const getDatePartsByProps = (stringTimeValue?: string) => {
  const hourByProp: string = (stringTimeValue ?? dayjs().format('HH:mm')).toString().trim().substring(0, 2);
  const minuteByProp: string = (stringTimeValue ?? dayjs().format('HH:mm')).toString().trim().substring(3, 5);
  return {
    hour: doubleChar(hourByProp),
    minute: doubleChar(minuteByProp),
  };
};

export const getTimeString = (hour: string, minute: string) => {
  const hour24Format = doubleChar(hour);
  const dateString24 = doubleChar(hour24Format.toString()) + ':' + minute;
  return dateString24;
};

export const parseOptionalDate = (date: Date | string | Falsy, defaultValue: Date) => {
  if (date) {
    const parsed = dayjs(date);

    if (parsed.isValid()) {
      return parsed.toDate();
    }
  }

  return defaultValue;
};

export const getValidatedMonths = (range: DateRange, minDate: Date, maxDate: Date) => {
  const { startDate, endDate } = range;

  if (startDate && endDate) {
    const newStart = dayjs.max(dayjs(startDate), dayjs(minDate))?.toDate();
    const newEnd = dayjs.min(dayjs(endDate), dayjs(maxDate))?.toDate();

    return [newStart, dayjs(newStart).isSame(newEnd, 'month') ? dayjs(newStart).add(1, 'month').toDate() : newEnd];
  } else {
    return [startDate, endDate];
  }
};
