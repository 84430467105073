import { ToastType } from 'components/CFToast/types';
import { useToast } from 'hooks';
import { useServicesContext } from 'hooks/useServicesContext';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { InterventionViewExtended } from 'services/intervention/intervention.types';
import { useInterventionContext } from 'views/intervention/useContext';

const useSourceIntervention = () => {
  const [sourceIntervention, setSourceIntervention] = useState<InterventionViewExtended>();
  const { addToast } = useToast();
  const [searchParams] = useSearchParams();
  const { interventionService } = useServicesContext();

  const { setSkipClonning } = useInterventionContext();

  useEffect(() => {
    (async () => {
      const clonedId = searchParams.get('from');

      if (!clonedId) {
        return;
      }

      try {
        const interventionView = await interventionService.getView(parseInt(clonedId));
        setSourceIntervention(interventionView);
      } catch (err) {
        setSkipClonning(true);
        addToast('Impossible to clone unknown intervention', ToastType.ERROR);
        console.log('Impossible to clone unknown intervention');
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const draftId = searchParams.get('draft');

      if (!draftId) {
        return;
      }

      interventionService.startDraft(parseInt(draftId));

      const draftInterventionView = await interventionService.getDraft(parseInt(draftId));

      setSourceIntervention(draftInterventionView as InterventionViewExtended);
    })();
  }, []);

  return sourceIntervention;
};

export default useSourceIntervention;
