import React, { ReactNode } from 'react';

import { Trait } from 'domain/traits.types';
import { createTraitCode, getDisplayName } from 'services/traits/helpers.traits';

enum ParsingStatus {
  Out,
  In,
  Opening,
  Closing,
}

export const sanitize = (template: string) => {
  const regex = /{{\s*(\w*)\s*}}/g;

  return template.replaceAll(regex, '{{ $1 }}');
};

export const templateToJSX = (template: string): ReactNode[] => {
  const openSymbol = '{';
  const closeSymbol = '}';

  let status = ParsingStatus.Out;

  let previousBlockStart = 0;
  let currentBlockStart = 0;

  const actions = {
    [ParsingStatus.Out]: (c: string, i: number): ReactNode => {
      if (c === openSymbol) {
        status = ParsingStatus.Opening;
        previousBlockStart = currentBlockStart;
        currentBlockStart = i;
      }

      return;
    },
    [ParsingStatus.In]: (c: string): ReactNode => {
      if (c === closeSymbol) {
        status = ParsingStatus.Closing;
      }
      return '';
    },
    [ParsingStatus.Opening]: (c: string, i: number): ReactNode => {
      if (c === openSymbol) {
        status = ParsingStatus.In;

        if (i > 1) {
          // copy text before curly brackets
          const blockText = template.slice(previousBlockStart, i - 2);

          let node = <span> {blockText} </span>;

          // force a whitespace between blocks
          if (!blockText.trim().length) {
            node = <span>&nbsp;</span>;
          }
          return node;
        }

        currentBlockStart = i - 1; // because there are two curly brackets
        previousBlockStart = i - 1;
      } else {
        // just found an individual openSymbol
        status = ParsingStatus.Out;
        currentBlockStart = previousBlockStart;
      }
    },
    [ParsingStatus.Closing]: (c: string, i: number): ReactNode => {
      if (c === closeSymbol) {
        status = ParsingStatus.Out;

        const blockText = template.slice(currentBlockStart, i + 1);
        const node = <span className="template-highlight"> {blockText} </span>;

        currentBlockStart = i + 1;
        previousBlockStart = i + 1;

        return node;
      }
    },
  };

  const output: ReactNode[] = [];

  for (let i = 0; i < template.length; i++) {
    output.push(actions[status](template[i], i));
  }

  output.push(<span> {template.slice(currentBlockStart, template.length)} </span>);

  return output.filter((item) => item);
};

export const templateToHtml = (input: string, keywords: string[]): string => {
  const templateRegexp = new RegExp(`({{ (${keywords.join('|')}) }})`, 'g');

  return input.replaceAll(templateRegexp, '<span class="template-highlight">$1</span>');
};

export const replaceTraitCodesWithDisplayName = (text: string, traits: Trait[]) => {
  const traitCodes = traits.reduce<Record<string, Trait>>((acc, cur) => {
    acc[createTraitCode(cur)] = cur;

    return acc;
  }, {});

  const templateRegexp = new RegExp(`({{ (${Object.keys(traitCodes).join('|')}) }})`, 'g');
  const matches = text.match(templateRegexp) || [];
  const vars = matches.map((v) => v.replace(/{{ ([^{}]*) }}/g, '$1'));
  const displayNames = vars.map((traitCode) => getDisplayName(traitCodes[traitCode]));

  return Array.from(matches).reduce<string>((cur, match, i) => cur.replaceAll(match, displayNames[i]), text);
};

export const sanitizeHtml = (input: string): string => {
  return input
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;');
};
