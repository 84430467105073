import React from 'react';
import dayjs from 'dayjs';

import StatusTag from 'components/StatusTag';

import { AppModel, ModelStatus } from 'domain/model.types';
import { AlgorithmClass } from 'services/intervention/intervention.types';
import { ColAddr } from 'domain/traits.types';

import KeyValue from 'views/intervention/components/KeyValue';
import { statusLabelMap, statusVariantMap } from 'views/model';

import ModelTagList from '../ModelTagList';

import CFTraitItem from 'connected-components/traits/CFTraitItem';

import './model-details.scss';
import ModelRunsSummary from '../ModelRunsSummary';

interface Props {
  model: AppModel;
}

const ModelDetails = ({ model }: Props) => {
  return (
    <div className="model-details">
      <div className="content">
        <KeyValue name="ID" value={model.definition?.id || ''} />
        <KeyValue name="Name" value={model.definition?.name || ''} />
        <KeyValue name="Trains on" value={model.cohort?.name || ''} />
        <KeyValue name="Created at" value={dayjs(model.definition.created_at).format('YYYY-MM-DD')} />
        <KeyValue name="Created by" value={model.definition.created_by || ''} />
        <KeyValue
          name="Status"
          value={
            <StatusTag
              label={statusLabelMap[model.schedule.status as ModelStatus]}
              variant={statusVariantMap[model.schedule.status as ModelStatus]}
            />
          }
        />
        <KeyValue name="Tags" value={<ModelTagList model={model} />} />

        <KeyValue name="Algorithm" value={model.definition.algo_spec.algo_name} />
        <KeyValue name="Model Class" value={model.definition.algo_spec.class_name} />
        {model.definition.algo_spec.class_name === AlgorithmClass.Censoring && (
          <>
            <KeyValue
              name="Behavioral Event"
              value={<CFTraitItem addr={model.definition.data_defn.censoring?.event as ColAddr} />}
            />
          </>
        )}
        {model.definition.algo_spec.class_name === AlgorithmClass.Bandit && (
          <KeyValue name="Reward" value={<CFTraitItem addr={model.definition.data_defn.bandit?.reward as ColAddr} />} />
        )}
        <KeyValue name="Runs" value={<ModelRunsSummary model={model} />} />
      </div>
    </div>
  );
};

export default ModelDetails;
