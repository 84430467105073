import { CFRoutes } from 'routes';
import { AuthAction, isAllowedTo } from 'services/authorization.service';

export enum Tabs {
  Interventions = 'intervention library',
  Nudges = 'nudge library',
  Adoption = 'nudge adoption',
}

export const allowedTabs = () => {
  const tabs = [Tabs.Interventions, Tabs.Nudges];

  if (isAllowedTo(AuthAction.AdoptNudge)) {
    tabs.push(Tabs.Adoption);
  }

  return tabs;
};

export const tabNameToCode = (tabName: string) => tabName.split(' ').join('-');

export const interventionByTab: Record<string, CFRoutes> = {
  [tabNameToCode(Tabs.Interventions)]: CFRoutes.intervention,
  [tabNameToCode(Tabs.Nudges)]: CFRoutes.intervention_nudge_list,
  [tabNameToCode(Tabs.Adoption)]: CFRoutes.intervention_nudge_adoption,
};
