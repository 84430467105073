import React, { useEffect, useMemo, useState } from 'react';
import { OrgDefinition } from 'types';

import { get as getOrgs } from 'repositories/organizations';
import CFTable, { Column, ColumnType } from 'components/CFTable';
import AdminLayout from 'views/admin/layout';
import CFButton from 'components/buttons/CFButton';
import { CFRole } from 'domain/general.types';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import useCFNavigation from 'hooks/useCFNavigation';
import { CFRoutes } from 'routes';
import { Tabs } from 'views/admin/tabs';

const ListOfOrgs = () => {
  const navigate = useCFNavigation();

  const [organizations, setOrganizations] = useState<OrgDefinition[]>([]);

  const updateListOfOrg = async () => {
    const listOfOrgs = await getOrgs();

    setOrganizations(listOfOrgs);
  };

  useEffect(() => {
    updateListOfOrg();
  }, []);

  const columns: Column[] = useMemo(
    () => [
      {
        title: 'Id',
        field: 'id',
        type: ColumnType.STRING,
      },
      {
        title: 'Name',
        field: 'name',
        type: ColumnType.STRING,
      },
      {
        title: 'Description',
        field: 'description',
        type: ColumnType.STRING,
      },
      {
        title: 'Created at',
        field: 'created_at',
        type: ColumnType.DATE,
      },
    ],
    []
  );

  return (
    <AdminLayout tab={Tabs.Organizations}>
      <div className="actions">
        <CFButton
          role={CFRole.Primary}
          value="Add organization"
          iconName={faPlus}
          onClick={() => navigate(CFRoutes.new_org)}
        />
      </div>

      <CFTable headers={columns} data={organizations} />
    </AdminLayout>
  );
};

export default ListOfOrgs;
