import { useMemo } from 'react';

import { TraitSubject } from 'domain/traits.types';

import { getCurrentProject } from 'services/session/session.service';

const useAvailableSubjects = () => {
  const availableSubjects = useMemo(() => {
    const project = getCurrentProject();
    const supportedSubjects = [TraitSubject.Drug];

    return project?.subjects.filter((subject) => supportedSubjects.includes(subject));
  }, []);

  return availableSubjects;
};

export default useAvailableSubjects;
