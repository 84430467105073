import React from 'react';

import './cf-card-picker.scss';

interface Props {
  title: string;
  children: React.ReactNode;
}

const CFCardPicker = ({ title, children }: Props) => {
  return (
    <div className="cf-card-picker">
      <div className="title">{title}</div>

      <div className="cards">{children}</div>
    </div>
  );
};

export default CFCardPicker;
