import { RGBColor } from 'domain/general.types';
import { ColAddr, TraitCategory } from 'domain/traits.types';
import colors from 'common.scss';
import { Ptr } from 'services/cohort/cohort.types.api';

export interface TraitItemProps {
  addr: ColAddr | Ptr;
  showAggLevel?: boolean;
  omitDisplayName?: boolean;
  disabled?: boolean;
}

export const tableColors: Record<string, RGBColor> = {
  [TraitCategory.Dynamic]: colors.cfCyan,
  [TraitCategory.Catalogue]: colors.cfGreen,
  [TraitCategory.Static]: colors.cfRed,
  [TraitCategory.Grouped]: colors.cfCyan20,
  [TraitCategory.MLT]: colors.cfYellow,
};
