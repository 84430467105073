import React, { ReactNode } from 'react';
import useCFNavigation from 'hooks/useCFNavigation';

import { useParams } from 'react-router-dom';
import classnames from 'classnames';

import { CFNavList } from 'components/CFNavList';

import { allowedTabs, monitoringByTab, Tabs } from '../tabs';
import useModel from '../hooks/useModel';

interface Props {
  children: ReactNode | ReactNode[];
  className?: string;
  tab: Tabs;
}

const ModelDetailLayout = ({ children, className, tab }: Props) => {
  const navigate = useCFNavigation();
  const params = useParams();

  const model = useModel();

  if (!model) {
    return <div></div>;
  }

  return (
    <div className={classnames(className)}>
      <CFNavList
        titles={allowedTabs(model)}
        selected={tab}
        onClick={(selectedTab) => navigate(monitoringByTab[selectedTab].replace(':id', params.id as string))}
      />
      {children}
    </div>
  );
};

export default ModelDetailLayout;
