import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useServicesContext } from 'hooks/useServicesContext';

import { InterventionId, RestlessCounts } from 'services/intervention/intervention.types';

import CFStackedChart from 'components/charts/CFStackedChart';
import CFSelect, { Option } from 'components/CFSelect';
import CFTitledComponent from 'components/CFTitledComponent';

import './restless-group-proportions.scss';

interface Props {
  counts: RestlessCounts;
  interventionId: InterventionId;
}

const stateIndexToOption = (state: number, i: number) => ({ label: `s${state}`, value: `s${i}` });

const RestlessGroupProportions = ({ counts, interventionId }: Props) => {
  const { interventionService } = useServicesContext();
  const [selectedStates, setSelectedStates] = useState<string[]>([]);

  const handleSelectState = useCallback((clickedState: Option) => {
    setSelectedStates((selected) => {
      const index = selected.indexOf(clickedState.value);

      if (index === -1) {
        return [...selected, clickedState.value];
      } else {
        const newSelected = [...selected];

        newSelected.splice(index, 1);

        return newSelected;
      }
    });
  }, []);

  const xTics = useMemo(() => {
    return Object.keys(counts);
  }, [counts]);

  const totals = xTics.map((tic) =>
    counts[tic]
      .filter((count) => selectedStates.indexOf(`s${count.state}`) !== -1)
      .reduce((acc, cur) => acc + cur.count, 0)
  );

  const series = interventionService.getGroupNames(interventionId).map((groupName) => ({
    name: groupName,
    values: xTics.map((tic, i) => {
      const normalizedFactor = totals[i] ? totals[i] : 1;

      return {
        time: tic,
        value:
          counts[tic]
            .filter((count) => count.group === groupName)
            .filter((count) => selectedStates.indexOf(`s${count.state}`) !== -1)
            .reduce((acc, cur) => acc + cur.count, 0) / normalizedFactor,
      };
    }),
  }));

  const states = useMemo(() => {
    return Array(interventionService.getMarkovStatesCount(interventionId))
      .fill('')
      .map((_, i) => i);
  }, []);

  useEffect(() => {
    setSelectedStates(states.map((state) => `s${state}`));
  }, [states]);

  return (
    <div className="restless-group-proportions">
      <div className="controls">
        <CFTitledComponent title="States">
          <CFSelect
            options={states.map(stateIndexToOption)}
            isMulti={true}
            value={selectedStates.map((state) => ({ value: state, label: state }))}
            onSelected={handleSelectState}
          />
        </CFTitledComponent>
      </div>

      <CFStackedChart
        key="restless-group-proportions"
        title=""
        xLabel="Decision point"
        xAxis={xTics}
        series={series}
        isLoading={false}
      />
    </div>
  );
};

export default RestlessGroupProportions;
