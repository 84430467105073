import { NodeUpdate } from './markov.types';
import { EdgeEdit } from './markov.types.api';

export const indexToNodeName = (index: number) => `s${index}`;

export const domainUpdatesToAPIEdits = (updates: NodeUpdate[]) => {
  return (updates || []).map((update) => ({
    scale: update.value,
    src_des: [parseInt(update.source.slice(1)), parseInt(update.target.slice(1))],
  })) as EdgeEdit[];
};

export const mapEditToUpdate = (edit: EdgeEdit): NodeUpdate => {
  return {
    source: indexToNodeName(edit.src_des[0]),
    target: indexToNodeName(edit.src_des[1]),
    value: edit.scale,
  };
};
