import React, { FC, useEffect, useRef } from 'react';

import { doubleChar, getTimeString } from 'components/DateTime/DatetimeRangePicker/helper';

interface UnitDropdownProps {
  data: string[];
  dropdownVisibility: boolean;
  manuallyDisplayDropdown?: boolean;
  shouldDisplay: boolean;
  setDropdownVisibility: (visibility: boolean) => void;
  type?: 'notRange' | string;
  range?: { start: number; end?: number };
  moveNext?: () => void;
  setValue: (value: string) => void;
  value: string;
  className?: string;
  fullTimeDropdownVisibility?: boolean;
}

const UnitDropdown: FC<UnitDropdownProps> = ({
  data,
  dropdownVisibility,
  manuallyDisplayDropdown,
  shouldDisplay,
  setDropdownVisibility,
  type,
  range,
  moveNext,
  setValue,
  value,
  className,
  fullTimeDropdownVisibility,
}) => {
  const dropdownRef = useRef(null);

  const hideDropdown = () => {
    setDropdownVisibility(false);
  };

  useEffect(() => {
    window.addEventListener('click', hideDropdown);
    document.querySelector('body')?.addEventListener('click', hideDropdown);
    return () => {
      window.removeEventListener('click', hideDropdown);
      document.querySelector('body')?.removeEventListener('click', hideDropdown);
    };
  }, []);

  const getStyleWithoutPx = (element: any, styleProp: any): number => {
    const styleValue = window.getComputedStyle(element)[styleProp];
    return parseInt(styleValue.replace('px', ''), 10);
  };

  const scrollToActiveUnit = (currentUnit: string, index: number, ref: React.RefObject<HTMLDivElement>) => {
    const activeUnit = document.querySelector(`[data-key="${currentUnit}"]`);
    const scrollContainer = ref.current;

    if (!scrollContainer || !activeUnit) {
      return;
    }

    const additionalHeightProp = [
      'borderTopWidth',
      'borderBottomWidth',
      'paddingTop',
      'paddingBottom',
      'marginBottom',
      'marginTop',
    ];
    const scrollerAdditionalHeight = additionalHeightProp
      .map((prop) => getStyleWithoutPx(scrollContainer, prop))
      .reduce((a, b) => a + b, 0);
    const activeUnitAdditionalHeight = additionalHeightProp
      .map((prop) => getStyleWithoutPx(activeUnit, prop))
      .reduce((a, b) => a + b, 0);
    const activeUnitHeight = activeUnit.getBoundingClientRect().height + activeUnitAdditionalHeight;
    const scrollContainerHeight = scrollContainer.getBoundingClientRect().height + scrollerAdditionalHeight;
    scrollContainer.scrollTo({
      top: activeUnitHeight * index - scrollContainerHeight / 2,
      behavior: 'smooth',
    });
  };

  return (
    <React.Fragment>
      {dropdownVisibility && shouldDisplay && (
        <div ref={dropdownRef} className={`inputWrapper__dropdown ${className || ''}`}>
          {data.map((unit, index) => {
            const unitLabel = type === 'notRange' ? unit : doubleChar(((range?.start || 0) + index).toString());
            let currentUnit = unitLabel;
            if (fullTimeDropdownVisibility) {
              currentUnit = unitLabel.replace(/ /g, '');
              const hour = parseInt(currentUnit.split(':')[0]);
              const dateParts = {
                hour: hour,
                minute: currentUnit.split(':')[1].replace('AM', '').replace('PM', ''),
              };
              currentUnit = getTimeString(dateParts.hour.toString(), dateParts.minute);
            }
            if (currentUnit === value) {
              scrollToActiveUnit(currentUnit, index + 1, dropdownRef);
              setTimeout(() => {
                scrollToActiveUnit(currentUnit, index + 1, dropdownRef);
              }, 250);
            }
            return (
              <span
                data-key={currentUnit}
                key={currentUnit}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setValue(currentUnit);
                  !manuallyDisplayDropdown && moveNext && moveNext();
                  setDropdownVisibility(false);
                }}
                className={currentUnit === value ? 'is-active' : ''}
              >
                <div>{unitLabel.replace('AM', '').replace('PM', '')}</div>
              </span>
            );
          })}
        </div>
      )}
    </React.Fragment>
  );
};
export default UnitDropdown;
