import React, { useEffect, useMemo, forwardRef, useImperativeHandle, useState, useCallback } from 'react';
import classNames from 'classnames';

import CFInput from 'components/CFInput';
import { SelectableItem } from 'components/CFSelectLegacy';
import CFSelect, { Option } from 'components/CFSelect';
import TagInputContainer from 'components/CFSelect/common/TagInputContainer';
import TraitItem from 'components/CFSelect/common/TraitItem';
import TraitInputContainer from 'components/CFSelect/common/TraitInputContainer';

import { FilterAPI } from 'services/cohort/cohort.types.api';
import { Trait, TraitCategory, TraitDataType } from 'domain/traits.types';
import { DataType, Operators } from 'domain/general.types';

import { toString as dateToString, toString } from 'helpers/dates';

import {
  areEqual,
  createTraitCode,
  getDisplayName,
  getIdentifier,
  getTraitCategory,
  getTraitName,
  isNumeric,
  isTimestamp as traitIsTimestamp,
} from 'services/traits/helpers.traits';

import { getOperators } from './operators';

import { getUniqueValuesForTrait } from 'services/traits/trait.service';

import { useServicesContext } from 'hooks/useServicesContext';

import { AppModel } from 'domain/model.types';

import debounce from 'debounce';

import './filter-builder.scss';
import Filter from 'services/cohort/domain/Filter';
import DatetimePicker from 'components/DateTime/DatetimePicker';

interface TraitWithModels extends Trait {
  selectable: boolean;
}

interface Props {
  traits: Trait[];
  defaultTrait?: Trait;
  defaultOperator?: Operators;
  defaultValue?: string;
  onFilterChanged: () => void;
  disabled?: boolean;
}

const FilterBuilder = forwardRef<Filter, Props>(function FilterBuilder(
  {
    traits,
    defaultTrait = undefined,
    defaultOperator = Operators.Equal,
    defaultValue = undefined,
    onFilterChanged,
    disabled = false,
  }: Props,
  ref
) {
  const [selectedTrait, setSelectedTrait] = useState<Trait>(defaultTrait || traits[0]);
  const [traitWithModels, setTraitWithModels] = useState<TraitWithModels[]>([]);
  const [uniqueValues, setUniqueValues] = useState<string[]>([]);
  const [availableModels, setAvailableModels] = useState<AppModel[]>([]);
  const [model, setModel] = useState<AppModel>();
  const { traitSessionService: traitService, modelService } = useServicesContext();

  const toSelectableItem = (value: string) => ({ label: value, value });

  let currentListOfOperators: SelectableItem[];
  if (selectedTrait) {
    currentListOfOperators = getOperators(selectedTrait?.addr.dtype as DataType).map(toSelectableItem);
  } else {
    currentListOfOperators = [];
  }

  const [operator, setOperator] = useState<Operators>(defaultOperator);

  const [value, setValue] = useState<string>(`${defaultValue === undefined ? '' : defaultValue}`);

  const [traitSearch, setTraitSearch] = useState('');
  const [valueSearch, setValueSearch] = useState('');

  useImperativeHandle(ref, () => {
    let formattedValue;
    // TODO: encapsulate component to multiple IDs. Here just ask each components about its values
    if (allowMultipleSelection) {
      formattedValue = (value.trim() || '').split(',').map((item) => item.trim());
    } else {
      formattedValue = isNumericType ? parseFloat(value) : value;
    }

    const filter = new Filter({} as FilterAPI);
    filter.ptr = selectedTrait.addr.ptr;
    filter.op = operator;
    filter.val = formattedValue;

    return filter;
  });

  const debouncedOnFilterChanged = useCallback(debounce(onFilterChanged, 500), []);

  const isNumericType = useMemo(() => {
    return isNumeric(selectedTrait);
  }, [selectedTrait]);

  const isTimestamp = useMemo(() => {
    return traitIsTimestamp(selectedTrait);
  }, [selectedTrait]);

  const isRangeUnknown = useMemo(() => {
    if (!selectedTrait) {
      return true;
    }

    return (
      isNumericType ||
      (getTraitCategory(selectedTrait.addr) === TraitCategory.Dynamic &&
        selectedTrait?.addr.dtype === TraitDataType.Varchar)
    );
  }, [isNumericType, selectedTrait]);

  const allowMultipleSelection = useMemo(() => {
    return operator === Operators.IncludedIn || operator === Operators.NotIncludedIn;
  }, [operator]);

  const isFreeInputText = useMemo(() => {
    return (
      getTraitName(selectedTrait) === 'id' || isRangeUnknown || (uniqueValues.length > 10 && allowMultipleSelection)
    );
  }, [allowMultipleSelection, selectedTrait, isRangeUnknown, uniqueValues, operator]);

  const isDropdownInput = useMemo(() => {
    return !isFreeInputText && !isTimestamp && !allowMultipleSelection;
  }, [isFreeInputText, isTimestamp, allowMultipleSelection]);

  const isDropdownMultipleInput = useMemo(() => {
    return !isFreeInputText && !isTimestamp && allowMultipleSelection;
  }, [isFreeInputText, isTimestamp, allowMultipleSelection]);

  useEffect(() => {
    if (!selectedTrait) {
      return;
    }

    if (defaultTrait && areEqual(selectedTrait, defaultTrait)) {
      setValue(`${defaultValue}`);
      setOperator(defaultOperator);
    } else {
      setValue('');
      setOperator(currentListOfOperators[0].value as Operators);
    }
  }, [selectedTrait]);

  useEffect(() => {
    if (!isTimestamp) {
      return;
    }

    // when the just selected trait is a timestamp
    // initialize to current date
    setValue(dateToString(new Date()));
  }, [isTimestamp]);

  useEffect(() => {
    if (!selectedTrait) {
      return;
    }

    // emulate the user introduces a trait
    handleTraitChange({
      label: getDisplayName(selectedTrait),
      value: createTraitCode(selectedTrait),
    });

    setTimeout(() => debouncedOnFilterChanged(), 0);
  }, [selectedTrait]);

  useEffect(() => {
    if (!selectedTrait) {
      return;
    }

    const modelIDs = traitService.getModels(createTraitCode(selectedTrait));

    Promise.all(modelIDs.map(async (id) => modelService.getById(id))).then((models) => {
      setAvailableModels(models);

      if (modelIDs.length) {
        setModel(models[0]);
      }
    });
  }, [selectedTrait]);

  useEffect(() => {
    debouncedOnFilterChanged();
  }, [model, operator, value, selectedTrait]);

  useEffect(() => {
    const traitsWithModels = traits.map((trait) => {
      const models = traitService.getModels(createTraitCode(trait));
      const category = getTraitCategory(trait.addr);

      return {
        ...trait,
        selectable: category !== TraitCategory.MLT || (category === TraitCategory.MLT && models.length !== 0),
      };
    });

    setTraitWithModels(traitsWithModels);
  }, [traits]);

  const handleTraitChange = async (item: Option) => {
    const selectedTrait = traits.find((trait) => createTraitCode(trait) === item.value);

    if (!selectedTrait) {
      return;
    }

    setSelectedTrait(selectedTrait);

    // set trait before this request, to avoid blocking the dropdown
    const uniqueValues = await getUniqueValuesForTrait(selectedTrait.addr);

    setUniqueValues(uniqueValues.map((value) => String(value)));
  };

  const handleSelectedModel = useCallback(
    (option: Option) => {
      const model = availableModels.find((model) => model.definition.id === option.value);

      if (!model) {
        return;
      }

      setModel(model);
    },
    [availableModels]
  );

  const handleOperatorChange = (item: Option) => {
    setOperator(item.value as Operators);
  };

  const handleValueChange = (item: Option) => {
    if (!allowMultipleSelection) {
      setValue(item.value);

      return;
    }

    const values = value.split(',').filter((_value) => !!_value);
    const newValues = values.filter((_value) => _value !== item.value);

    if (newValues.length === values.length) {
      newValues.push(item.value);
    }

    setValue(newValues.join(','));
  };

  const handleTextValueChange = (input: any) => {
    setValue(input.target.value);
  };

  const handleNewDateValueChange = (date: Date) => {
    setValue(toString(date));
  };

  if (!selectedTrait) {
    return <div></div>;
  }

  return (
    <div className={classNames('filter-builder', { extended: availableModels.length !== 0 })}>
      <CFSelect
        disabled={disabled}
        value={{
          label: getDisplayName(selectedTrait),
          value: createTraitCode(selectedTrait),
          meta: { trait: selectedTrait },
        }}
        onSelected={handleTraitChange}
        options={traitWithModels
          .map((trait) => ({
            label: getDisplayName(trait),
            value: createTraitCode(trait),
            disabled: !trait.selectable,
            meta: { trait },
          }))
          .filter((option) => {
            return !traitSearch.trim() || option.label.toLowerCase().includes(traitSearch.toLowerCase());
          })}
        searchable
        onSearch={setTraitSearch}
        Item={TraitItem}
        InputContainer={TraitInputContainer}
      />

      {availableModels.length !== 0 && (
        <CFSelect
          options={availableModels.map((model) => ({
            label: model.definition.name || model.definition.id,
            value: model.definition.id,
          }))}
          isMulti={false}
          value={{
            label: model?.definition.name || availableModels[0].definition.name,
            value: model?.definition.id || availableModels[0].definition.id,
          }}
          onSelected={handleSelectedModel}
        />
      )}

      <CFSelect
        disabled={disabled}
        key={`operator-${getIdentifier(selectedTrait)}`}
        value={{ label: operator, value: operator }}
        options={currentListOfOperators}
        onSelected={handleOperatorChange}
      />

      {isFreeInputText && <CFInput placeholder="value" onChange={handleTextValueChange} defaultValue={value} />}

      {isTimestamp && (
        <>
          <DatetimePicker
            initialDate={defaultValue ? new Date(defaultValue) : undefined}
            onChange={handleNewDateValueChange}
          />
        </>
      )}

      {isDropdownInput && (
        <CFSelect
          disabled={disabled}
          key={`value-${getIdentifier(selectedTrait)}`}
          value={{ label: value, value }}
          options={uniqueValues.map(toSelectableItem).filter((option) => {
            return !valueSearch.trim() || option.label.toLowerCase().includes(valueSearch.toLowerCase());
          })}
          onSelected={handleValueChange}
          searchable
          onSearch={setValueSearch}
        />
      )}

      {isDropdownMultipleInput && (
        <CFSelect
          disabled={disabled}
          key={`value-${getIdentifier(selectedTrait)}`}
          value={value
            .split(',')
            .filter((value) => !!value)
            .map((value) => ({ label: value, value }))}
          options={uniqueValues.map(toSelectableItem).filter((option) => {
            return !valueSearch.trim() || option.label.toLowerCase().includes(valueSearch.toLowerCase());
          })}
          isMulti
          onSelected={handleValueChange}
          searchable
          onSearch={setValueSearch}
          InputContainer={TagInputContainer}
        />
      )}
    </div>
  );
});

export default FilterBuilder;
