import React, { useEffect, useState, useCallback, useRef } from 'react';
import moment from 'moment';

import { CHART_COLORS, chartLineColor1 } from 'styles/colors';

import DatetimeRangePicker from 'components/DateTime/DatetimeRangePicker';
import { DateRange, DefinedRange } from 'components/DateTime/DatetimeRangePicker/types';
import { defaultRanges } from 'components/DateTime/DatetimeRangePicker/default';
import CFButton from 'components/buttons/CFButton';
import CFConfirmableButton from 'components/CFConfirmableButton';
import CFSelect, { Option } from 'components/CFSelect';
import TimeSerie from './components/Timeserie';
import FilterControls from './components/FilterControls';
import SelectedTags, { TagValue } from '../../../components/SelectedTags';

import { AggLevel } from 'domain/stats.types';

import { getCurrentProject } from 'services/session/session.service';
import { removeCohortByName } from './services/cohort';

import { daysAgo, isSameDayAsToday, toString as dateToString } from 'helpers/dates';
import { createCohortTagName } from './helpers/cohortTagName';

import { useServicesContext } from 'hooks/useServicesContext';

import AnalyticsLayout from '../layout';
import { Tabs } from '../tabs';

import { useAnalyticsContext } from './context/useAnalyticsContext';

import './analytics.scss';
import { NormalizationType } from './types';

const normalizationOptions = [
  { label: 'Normalization: total subjects', value: NormalizationType.CohortSubjects },
  { label: 'Normalization: active subjects', value: NormalizationType.ActiveUsers },
];
const AnalyticsInternal = () => {
  const { cohortService, traitSessionService: traitService } = useServicesContext();
  const {
    traitConfigManager,
    selectedCharts,
    subject,
    module,
    aggLevel,
    setAggLevel,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    selectedCohortIDs,
    setSelectedCohortIDs,
    availableCohorts,
    normalizationType,
    setNormalizationType,
  } = useAnalyticsContext();

  const project = getCurrentProject();
  const [showStickyLegend, setShowStickyLegend] = useState(true);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleRangeChange = useCallback((dateRange: DateRange) => {
    if (dateRange.startDate && dateRange.endDate) {
      setStartDate(dateToString(dateRange.startDate));

      if (aggLevel === AggLevel.Day && isSameDayAsToday(dateRange.endDate)) {
        setEndDate(daysAgo(1));
      } else {
        setEndDate(dateToString(dateRange.endDate));
      }
    }
  }, []);

  const handleRemoveCohortFromTag = useCallback(
    (value: TagValue) => {
      const filteredIds = removeCohortByName(
        cohortService,
        value.value,
        selectedCohortIDs.map((i) => parseInt(i)),
        availableCohorts ?? []
      );

      setSelectedCohortIDs(filteredIds.map((i) => i.toString()));
    },
    [availableCohorts, selectedCohortIDs, cohortService]
  );

  const handleResetTraits = useCallback(() => {
    traitConfigManager?.resetTraits(subject, module);
  }, [traitConfigManager, subject, module, aggLevel]);

  const handleAggLevelChange = useCallback((value: Option) => {
    setAggLevel(value.value as AggLevel);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(async (entry) => {
        setShowStickyLegend(!entry.isIntersecting);
      });
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const defaultDateRanges: DefinedRange[] = [
    ...defaultRanges,
    {
      label: 'Full history',
      startDate: moment(project?.created_at ?? 0).toDate(),
      endDate: new Date(),
    },
  ];

  return (
    <AnalyticsLayout className="analytics" tab={Tabs.Explore}>
      <FilterControls />
      <div className="secondary-controls">
        <SelectedTags
          ref={containerRef}
          label="Selected cohorts"
          values={
            availableCohorts?.length
              ? selectedCohortIDs.map((cid, idx) => ({
                  value: createCohortTagName(cohortService.getCohort(cid)),
                  color: CHART_COLORS[idx] || chartLineColor1,
                }))
              : []
          }
          onRemove={handleRemoveCohortFromTag}
        />

        <CFSelect
          options={normalizationOptions}
          isMulti={false}
          value={normalizationOptions.find((option) => option.value === normalizationType) as Option}
          onSelected={(option: Option) => setNormalizationType(option.value as NormalizationType)}
        />

        <CFSelect
          options={[AggLevel.Day, AggLevel.Week, AggLevel.Month].map((key) => ({
            label: key,
            value: key.toLowerCase(),
          }))}
          isMulti={false}
          value={{ label: aggLevel, value: aggLevel }}
          onSelected={handleAggLevelChange}
        />

        <DatetimeRangePicker
          onChange={handleRangeChange}
          definedRanges={defaultDateRanges}
          initialDateRange={{
            startDate: new Date(startDate),
            endDate: new Date(endDate),
          }}
          maxDate={new Date()}
          showTime={false}
        />
      </div>

      {showStickyLegend && (
        <SelectedTags
          label="Selected cohorts"
          values={selectedCohortIDs.map((cid, idx) => ({
            value: createCohortTagName(cohortService.getCohort(cid)),
            color: CHART_COLORS[idx] || chartLineColor1,
          }))}
          onRemove={handleRemoveCohortFromTag}
          sticky
        />
      )}
      <div className="main">
        {selectedCharts.map((seriesName, i) => {
          const trait = traitService.getTraitDefinition(seriesName);

          return (
            <TimeSerie key={`${subject}-${seriesName}-${startDate}-${endDate}-${module}`} trait={trait} index={i} />
          );
        })}

        {selectedCharts.length === 0 && selectedCharts.length > 0 && <div className="no-data">No data available</div>}
        {selectedCharts.length === 0 && <div className="no-data">No traits available</div>}
      </div>

      <CFConfirmableButton
        question={`Are you sure to reset traits? This action will undo your change and will show the default traits`}
        title={'Reset traits'}
      >
        <CFButton value={'Reset traits'} onClick={handleResetTraits} />
      </CFConfirmableButton>
    </AnalyticsLayout>
  );
};

export default AnalyticsInternal;

/*

 <CFLazyWrapper
              // Combined key is a hack to force of re mounting LazyWrapper
              // to retrigger onLoad that will fetch data with new params
              key={`${subject}-${seriesName}-${startDate}-${endDate}-${module}`}
              onLoad={loadChartData(seriesName)}
            >
              <TimeSerie trait={trait} index={i} />
            </CFLazyWrapper>
*/
