import { useEffect, useState } from 'react';

import { KPI } from 'components/kpis';
import { useServicesContext } from 'hooks/useServicesContext';

const useEngagementData = () => {
  const { userEngagementService } = useServicesContext();
  const [kpis, setKpis] = useState<KPI[]>([]);

  const resetKPIs = async () => {
    const roles = await userEngagementService.getRoles();
    const initialKPIs = roles.map((role) => ({ value: '-', unit: '', label: role, variation: 0 }));

    setKpis(initialKPIs);
  };

  const updateKPIs = async () => {
    const roles = await userEngagementService.getRoles();

    roles.forEach((role) => {
      userEngagementService.getKPIForRole(role).then((gdtPoint) => {
        setKpis((kpis: KPI[]) => {
          const kpi = kpis.find((kpi) => kpi.label === role);

          if (!kpi) {
            const newKpi = {
              value: gdtPoint.cur,
              variation: gdtPoint.prev ? ((gdtPoint.cur - gdtPoint.prev) / gdtPoint.prev) * 100 : 0,
              unit: '',
              label: role,
            };

            return [newKpi, ...kpis];
          }

          kpi.value = gdtPoint.cur;
          kpi.variation = gdtPoint.prev ? ((gdtPoint.cur - gdtPoint.prev) / gdtPoint.prev) * 100 : 0;

          return [...kpis];
        });
      });
    });
  };

  useEffect(() => {
    userEngagementService.subscribe(() => {
      resetKPIs();
      updateKPIs();
    });
  }, []);

  useEffect(() => {
    resetKPIs();

    updateKPIs();
  }, []);

  return kpis;
};

export default useEngagementData;
