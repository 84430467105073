import React, { useCallback, useMemo } from 'react';
import { useEffect, useState } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { CFRole } from 'domain/general.types';

import { getCurrentProject, getOrganization } from 'services/session/session.service';
import { get as getUsers, removeFromPlatform } from 'services/admin/users/users.repo';
import { AuthAction, isAllowedTo } from 'services/authorization.service';

import AdminLayout from 'views/admin/layout';
import { Tabs } from 'views/admin/tabs';

import CFButton from 'components/buttons/CFButton';
import CFTable, { Column, ColumnType } from 'components/CFTable';
import CFConfirmableButton from 'components/CFConfirmableButton';
import CFTrashButton from 'components/buttons/CFTrashButton';

import { UserDefinition } from 'types';

import useCFNavigation from 'hooks/useCFNavigation';

import { CFRoutes } from 'routes';
import { useToast } from 'hooks';
import { ToastType } from 'components/CFToast/types';

const ListOfUsers = () => {
  const [users, setUsers] = useState<UserDefinition[]>([]);
  const { addToast } = useToast();
  const navigate = useCFNavigation();

  const updateListOfUsers = async () => {
    const listOfUsers = await getUsers();
    setUsers(listOfUsers);
  };

  useEffect(() => {
    updateListOfUsers();
  }, []);

  const handleRemoveUserFromOrg = useCallback(
    async (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>, username: string) => {
      evt.stopPropagation();
      try {
        await removeFromPlatform(username);

        const newUsers = users.filter((user) => user.username !== username);
        setUsers(newUsers);

        addToast('User removed from platform', ToastType.SUCCESS);
      } catch {
        addToast('Impossible to remove user', ToastType.ERROR);
      }
    },
    [users]
  );

  const columns: Column[] = [
    {
      title: 'Email',
      field: 'username',
      type: ColumnType.STRING,
    },
    {
      title: 'Created at',
      field: 'created_at',
      type: ColumnType.DATE,
    },
    {
      title: 'Org roles',
      field: 'org_roles',
      type: ColumnType.OBJECT,
    },
    {
      title: 'Project roles',
      field: 'project_roles',
      type: ColumnType.OBJECT,
    },
  ];

  if (isAllowedTo(AuthAction.DeleteUser)) {
    columns.push({
      title: '',
      field: '',
      type: ColumnType.OBJECT,
      renderCell: (row) => {
        return (
          <CFConfirmableButton
            question="Are you sure you want to completely remove the user from the platform?"
            title="Remove user from platform"
          >
            <CFTrashButton
              onClick={(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                handleRemoveUserFromOrg(evt, (row as UserDefinition).username)
              }
            />
          </CFConfirmableButton>
        );
      },
    });
  }

  const formattedUsers = useMemo(() => {
    const orgId = getOrganization();
    const project = getCurrentProject();

    if (!project) {
      return [];
    }

    if (!orgId) {
      return [];
    }

    return users.map((user) => ({
      username: user.username,
      created_at: user.created_at,
      org_roles: user.roles[orgId].org_roles,
      project_roles: user.roles[orgId].proj_roles[project?.id] || [],
    }));
  }, [users]);

  const handleSelectUser = (row: Record<string, any>) => {
    navigate(`/admin/users/${row.username}`);
  };

  return (
    <AdminLayout className="analytics" tab={Tabs.Users}>
      <div className="actions">
        <CFButton
          role={CFRole.Primary}
          value="Add user"
          iconName={faPlus}
          onClick={() => navigate(CFRoutes.invite_user)}
        />
      </div>
      <CFTable indexCol="username" headers={columns} data={formattedUsers} onSelectRow={handleSelectUser} />
    </AdminLayout>
  );
};

export default ListOfUsers;
