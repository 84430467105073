import React, { RefObject, useEffect, useState } from 'react';
import { DefinedRange, MENU_HEIGHT } from '../types';
import { defaultRanges } from '../default';

import './cf-defined-ranges.scss';

type DefinedRangesProps = {
  ranges?: DefinedRange[];
  onChange: (selected: DefinedRange) => void;
  definedRef: RefObject<HTMLDivElement>;
  menuPosition: {
    top: number;
    left: number;
  };
  inputRect: {
    top: number;
    left: number;
    width: number;
    height: number;
  };
};

const DefinedRanges: React.FunctionComponent<DefinedRangesProps> = ({
  ranges = defaultRanges,
  definedRef,
  menuPosition,
  inputRect,
  onChange,
}) => {
  const [adjustedPosition, setAdjustedPosition] = useState({
    left: 0,
    top: 0,
    opacity: 0,
  });
  const [selected, setSelected] = useState<DefinedRange>();

  useEffect(() => {
    if (selected) onChange(selected);
  }, [selected]);

  const adjustMenuPosition = () => {
    const definedRect = definedRef.current?.getBoundingClientRect();

    if (!definedRect || !inputRect) {
      return;
    }

    const { innerWidth, innerHeight } = window;
    const buffer = 8; // add a small buffer to avoid the edges of the viewport

    const newMenuPosition = { ...menuPosition };
    // Check if the menu overflows to the right
    if (inputRect.left + definedRect.width + buffer > innerWidth) {
      newMenuPosition.left = inputRect.width - definedRect.width;
    } else {
      newMenuPosition.left = 0;
    }

    newMenuPosition.top = inputRect.height + buffer;
    // Check if the menu overflows to the bottom
    if (
      inputRect.top + inputRect.height + definedRect.height + buffer > innerHeight &&
      inputRect.top - definedRect.height > MENU_HEIGHT
    ) {
      newMenuPosition.top = -definedRect.height - buffer;
    } else {
      newMenuPosition.top = inputRect.height + buffer;
    }

    setAdjustedPosition({ ...newMenuPosition, opacity: 1 });
  };

  useEffect(() => {
    adjustMenuPosition();
  }, [menuPosition]);

  return (
    <div className="cf-predefined-range" ref={definedRef} style={adjustedPosition}>
      {ranges.map((range, idx) => (
        <div
          className="cf-predefined-item"
          key={idx}
          onClick={() => setSelected(range)}
          data-testid={`test-defined-range-${idx}`}
        >
          <span>{range.label}</span>
        </div>
      ))}
      <div
        className="cf-predefined-item"
        onClick={() =>
          setSelected({
            startDate: new Date(),
            endDate: new Date(),
            label: 'custom',
          })
        }
        data-testid="test-defined-range-custom"
      >
        <span>Custom Range</span>
      </div>
    </div>
  );
};

export default DefinedRanges;
