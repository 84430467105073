import { RepoConfig } from './types';
import { IngestHistoryLogs } from '../domain/ingesthistory.types';
import { get as httpGet } from './drivers/http';
import { AxiosResponse } from 'axios';

const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
const path = '/v1/ingest';

const repoConfig = {
  token: '',
  oid: -1,
  pid: -1,
};

export const init = ({ token, oid, pid }: RepoConfig) => {
  repoConfig.token = token;
  repoConfig.oid = oid;
  repoConfig.pid = pid;
};

export const getIngestHistoryLogs = async (page: number, page_limit: number): Promise<IngestHistoryLogs> => {
  const config = {};

  const logHistory = (await httpGet(
    `${serverBaseUrl}${path}/log/history?oid=${repoConfig.oid}&pid=${repoConfig.pid}&page=${page}&per_page=${page_limit}`,
    config
  )) as AxiosResponse;

  return logHistory.data || [];
};
