import React from 'react';

import './key-value.scss';
import classNames from 'classnames';

interface KeyValueProps {
  name: string;
  value: string | number | JSX.Element;
  forceBreakline?: boolean;
}

const KeyValue = ({ name, value, forceBreakline = false }: KeyValueProps) => {
  return (
    <div className={classNames('key-value-pair', { 'force-breakline': forceBreakline })}>
      <div className="key">{name}:</div>
      <div className="value">{value}</div>
    </div>
  );
};

export default KeyValue;
