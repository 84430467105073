import React, { createContext, useState, useContext } from 'react';

export enum HideType {
  Hidden,
  Removed,
}

interface TabContextValue {
  currentTab: string;
  hideType?: HideType;
  handleChangeTab: (newValue: string) => void;
}

const TabContext = createContext<TabContextValue | undefined>(undefined);

interface Props extends React.PropsWithChildren {
  value: string;
  hideType?: HideType;
}

const TabContextProvider: React.FC<Props> = ({ children, value, hideType = HideType.Removed }) => {
  const [currentTab, setCurrentTab] = useState<string>(value);

  const handleChangeTab = (newValue: string) => {
    setCurrentTab(newValue);
  };

  return <TabContext.Provider value={{ currentTab, hideType, handleChangeTab }}>{children}</TabContext.Provider>;
};

export const useTabContext = (): TabContextValue => {
  const context = useContext(TabContext);
  if (!context) {
    throw new Error('useTabContext must be used within a TabContextProvider');
  }
  return context;
};

export default TabContextProvider;
