import Cohort from 'services/cohort/domain/Cohort';

export const createCohortTagName = (cohort: Cohort) => {
  if (!cohort) {
    return 'unknown';
  }

  return `${cohort.name} (${cohort?.size})` || 'unknown';
};

export const getCohortNameFromTag = (tagName: string) => {
  return tagName.split(' ').slice(0, -1).join(' ');
};
