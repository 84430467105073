import React, { FC } from 'react';
import dayjs from 'dayjs';

import Header from '../Header';
import Day from '../Day';

import { chunks } from 'helpers/misc';
import { getDaysInMonth, inDateRange, isBetween, isEndOfRange, isRangeSameDay, isStartOfRange } from 'helpers/dates';
import { DateRange, NavigationAction } from '../types';

import './month.scss';

const WEEK_DAYS: string[] = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sat'];

interface MonthProps {
  value: Date;
  marker: symbol;
  dateRange: DateRange;
  minDate: Date;
  maxDate: Date;
  navState: [boolean, boolean];
  setValue: (date: Date) => void;
  helpers: {
    inHoverRange: (day: Date) => boolean;
  };
  handlers: {
    onDayClick: (day: Date) => void;
    onDayHover: (day: Date) => void;
    onMonthNavigate: (marker: symbol, action: NavigationAction) => void;
  };
}

const Month: FC<MonthProps> = ({
  helpers,
  handlers,
  value: date,
  dateRange,
  marker,
  setValue: setDate,
  minDate,
  maxDate,
  navState,
}) => {
  const [back, forward] = navState;

  return (
    <div className="date-range-month-root">
      <Header
        date={date}
        setDate={setDate}
        nextDisabled={!forward}
        prevDisabled={!back}
        onClickPrevious={() => handlers.onMonthNavigate(marker, NavigationAction.Previous)}
        onClickNext={() => handlers.onMonthNavigate(marker, NavigationAction.Next)}
      />

      <div className="month-week-days-container">
        {WEEK_DAYS.map((day) => (
          <span key={day} className="month-week-day">
            {day}
          </span>
        ))}
      </div>

      <div className="month-days-container">
        {chunks(getDaysInMonth(date), 7).map((week, index) => (
          <div key={index} className="month-week">
            {week.map((day) => {
              const isStart = isStartOfRange(dateRange, day);
              const isEnd = isEndOfRange(dateRange, day);
              const isRangeOneDay = isRangeSameDay(dateRange);
              const highlighted = inDateRange(dateRange, day) || helpers.inHoverRange(day);

              return (
                <Day
                  key={dayjs(day).format('YYYY-MM-DD')}
                  filled={isStart || isEnd}
                  outlined={!highlighted && dayjs().isSame(day, 'day')}
                  highlighted={highlighted && !isRangeOneDay}
                  isCurrentMonth={dayjs(day).isSame(date, 'month')}
                  disabled={!isBetween(day, minDate, maxDate)}
                  startOfRange={isStart}
                  endOfRange={isEnd}
                  onClick={() => handlers.onDayClick(day)}
                  onHover={() => handlers.onDayHover(day)}
                  value={day}
                />
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Month;
