import { BasicTraitStats, CohortID, CohortType, InternalCohort, SampleItem } from 'services/cohort/cohort.types';

import { ColAddr, TraitCode, TraitSubject } from 'domain/traits.types';
import { downloadAll } from 'helpers/network';
import { CFCohortRepository } from 'services/cohort/cohort.repo';

import { PaginatedElement } from 'types';
import CFService from '../cfservice';
import { getCurrentProject } from '../session/session.service';
import { createTraitCode } from '../traits/helpers.traits';
import TraitService from '../traits/traitSession.service';
import Cohort from './domain/Cohort';
import FilterSet from './domain/FilterSet';

export default class CohortService extends CFService {
  traitStats: Record<TraitCode, BasicTraitStats> = {};
  traitService: TraitService;
  cohortRepository: CFCohortRepository;
  indexedCohorts: Record<string, Cohort> = {};
  knownSamples: Record<string, number> = {};

  _name = 'cohortService';

  constructor(cohortRepository: CFCohortRepository, traitService: TraitService) {
    super();
    this.traitService = traitService;
    this.cohortRepository = cohortRepository;
  }

  async init() {
    //
  }

  async getTraitBasicStats(sampleId: string, addr: ColAddr, group?: string): Promise<BasicTraitStats> {
    const traitCode = createTraitCode(addr);
    const key = `${sampleId}-${traitCode}`;
    let basicTraitStats = this.traitStats[key];

    if (!basicTraitStats) {
      basicTraitStats = await this.cohortRepository.getTraitBasicStats(sampleId, addr, group);
      this.traitStats[key] = basicTraitStats;
    }

    return basicTraitStats;
  }

  async getTraitBasicStatsForCohort(cohortId: string, addr: ColAddr): Promise<BasicTraitStats> {
    const traitCode = createTraitCode(addr);
    const key = `${cohortId}-${traitCode}`;
    let basicTraitStats = this.traitStats[key];

    if (!basicTraitStats) {
      basicTraitStats = await this.cohortRepository.getTraitBasicStatsForCohort(cohortId, addr);
      this.traitStats[key] = basicTraitStats;
    }

    return basicTraitStats;
  }

  async getListOfCohorts(
    page: number,
    per_page: number,
    subject_type: TraitSubject,
    cohort_type?: CohortType
  ): Promise<PaginatedElement<Cohort>> {
    const modules = getCurrentProject()?.modules || [];

    const cohorts = await this.cohortRepository.getCohortListBySubject(
      page,
      per_page,
      subject_type,
      modules,
      cohort_type
    );

    this.indexedCohorts = cohorts.data.reduce((acc, cohort) => ({ ...acc, [cohort.id]: cohort }), this.indexedCohorts);

    return cohorts as PaginatedElement<Cohort>;
  }

  async getRemoteCohort(id: CohortID): Promise<Cohort | undefined> {
    const cachedCohort = this.indexedCohorts[id];

    if (cachedCohort) {
      return cachedCohort;
    }

    try {
      const cohort = await this.cohortRepository.getById(id);
      this.indexedCohorts[id] = cohort;
      return cohort;
    } catch (e) {
      return undefined;
    }
  }

  getCohort(id: string) {
    // TODO: to remove, keep here for backward compatibility
    return this.indexedCohorts[id];
  }

  async previewAll(filterSet: FilterSet) {
    const getSubjectPage = (page: number, page_size: number) => this.preview(filterSet, page, page_size);
    const subjects = await downloadAll(getSubjectPage);

    return subjects;
  }

  async preview(filterSet: FilterSet, page: number, per_page: number): Promise<PaginatedElement<string>> {
    return this.cohortRepository.preview(filterSet, page, per_page);
  }

  async sampleAll(sampleId: string) {
    const getSamplePage = (page: number, page_size: number) => this.sample(sampleId, page, page_size);
    const samples = await downloadAll(getSamplePage);

    return samples.map((item) => item.subject_id);
  }

  async getSampleSize(sampleId: string) {
    // if (!this.knownSamples[sampleId]) {
    const data = await this.cohortRepository.sample(sampleId, 0, 10);

    this.knownSamples[sampleId] = data.total;
    // }

    return this.knownSamples[sampleId];
  }

  async sample(sampleId: string, page: number, per_page: number): Promise<PaginatedElement<SampleItem>> {
    return this.cohortRepository.sample(sampleId, page, per_page);
  }

  async create(cohort: InternalCohort) {
    await this.cohortRepository.create(cohort);
  }
}
