import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import './search-field.scss';

export interface Props {
  autoFocus?: boolean;
  onChange?: (value: string) => void;
}

const SearchField = ({ onChange, autoFocus = true }: Props) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.value);
    },
    [onChange]
  );

  return (
    <div className="cf-select-searchField">
      <FontAwesomeIcon className="cf-select-searchIcon" icon={faSearch} size="sm" />
      <input
        type="text"
        className="cf-select-searchInput"
        onChange={handleChange}
        data-testid="cf-select-search"
        autoFocus={autoFocus}
      />
    </div>
  );
};

export default SearchField;
