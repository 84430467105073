import { AggLevelPtr } from 'domain/stats.types';
import { Ptr } from 'services/cohort/cohort.types.api';

class VOChurn {
  private _value = 0;
  private _aggLevel: AggLevelPtr = AggLevelPtr.Day;

  constructor(ptr: Ptr | undefined) {
    if (!ptr) {
      return;
    }

    const aggDefinition = ptr.split('#')[1];

    this._aggLevel = aggDefinition.slice(-1) as AggLevelPtr;
    this._value = parseInt(aggDefinition.slice(0, -1));
  }

  get value() {
    return this._value;
  }

  get aggLevel() {
    return this._aggLevel;
  }

  toString() {
    return `${this._value}${this._aggLevel}`;
  }
}

export default VOChurn;
