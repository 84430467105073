import React from 'react';

import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AssistantMessage } from 'services/assistant/assistant.types';

interface Props {
  message: AssistantMessage;
  removable?: boolean;
  onDiscard: () => void;
}

import '../message.scss';
import './follow-up-message.scss';

const FollowUpMessage = ({ message, removable = false, onDiscard }: Props) => {
  return (
    <div className="assistant-message question">
      {removable && (
        <FontAwesomeIcon icon={faClose} style={{ cursor: 'pointer' }} className="close-button" onClick={onDiscard} />
      )}
      {message.text}
    </div>
  );
};

export default FollowUpMessage;
