import React from 'react';

import CFButton from '../CFButton';

import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { CFRole } from 'domain/general.types';

interface Props {
  disabled?: boolean;
  value: string;
  onClick: () => void;
  testid?: string;
}

const CFAddButton = ({ onClick, value, disabled = false, testid }: Props) => {
  return (
    <CFButton
      value={value}
      role={CFRole.Borderless}
      iconName={faPlus}
      disabled={disabled}
      onClick={onClick}
      testId={testid}
    />
  );
};

export default CFAddButton;
