import React, { createContext, useContext, useEffect, useState } from 'react';

import { useServicesContext } from 'hooks/useServicesContext';
import { useToast } from 'hooks';
import { ToastType } from 'components/CFToast/types';
import Cohort from 'services/cohort/domain/Cohort';

interface CohortsContextValue {
  landingCohort: number;
  setCohortForLanding: (cohort: Cohort) => Promise<void>;
}

const CohortsContext = createContext<CohortsContextValue | undefined>(undefined);

interface Props extends React.PropsWithChildren {}

const ContextProvider: React.FC<Props> = ({ children }) => {
  const [landingCohort, setLandingCohort] = useState(-1);
  const { landingService } = useServicesContext();
  const { addToast } = useToast();

  useEffect(() => {
    (async () => {
      const cohordId = await landingService.getLandingCohortId();
      setLandingCohort(cohordId);
    })();
  }, []);

  const setCohortForLanding = async (cohort: Cohort) => {
    try {
      await landingService.setCohortForLanding(cohort.id.toString());
    } catch {
      addToast('Impossible to set landing cohort', ToastType.ERROR);
      return;
    }

    setLandingCohort(cohort.id);
  };

  return (
    <CohortsContext.Provider
      value={{
        landingCohort,
        setCohortForLanding,
      }}
    >
      {children}
    </CohortsContext.Provider>
  );
};

export const useCohortsContext = (): CohortsContextValue => {
  const context = useContext(CohortsContext);
  if (!context) {
    throw new Error('useCohortsContext must be used within an CohortsContextProvider');
  }
  return context;
};

export default ContextProvider;
