import colors from 'common.scss';

export const enum ToastType {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export interface Toast {
  id?: number;
  state: ToastType;
  message: string;
  duration?: number;
}

export interface ToastList {
  toasts: Toast[];
}

export interface ToastProps {
  state: ToastType;
  message: string;
  onClose: () => void;
}

export const ToastColor: Record<ToastType, string> = {
  [ToastType.SUCCESS]: colors.light,
  [ToastType.INFO]: colors.dark70,
  [ToastType.WARNING]: colors.dark70,
  [ToastType.ERROR]: colors.light,
};
