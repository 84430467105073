import dayjs from 'dayjs';
import { ColAddr, TraitMetricsNames } from 'domain/traits.types';
import React, { useEffect, useMemo, useState } from 'react';

import { toString as dateToString, getEndDate } from 'helpers/dates';

import TraitService from 'services/traits/traitSession.service';
import CFLineChart from 'components/charts/CFLineChart';
import { chartLineColor1 } from 'styles/colors';
import { AggLevel, TimeSeriesItem } from 'domain/stats.types';

import CFTitledComponent from 'components/CFTitledComponent';

import colors from 'common.scss';

import './detailed-app-performance.scss';
import { getDisplayName } from 'services/traits/helpers.traits';

const performanceTraits = [
  TraitMetricsNames.AppRenderingTime,
  TraitMetricsNames.AppStartTime,
  TraitMetricsNames.SpeedDown,
  TraitMetricsNames.SpeedUp,
];

interface Props {
  traitService: TraitService;
  allSubjectCohortId: number;
}

const DetailedAppPerformance = ({ traitService, allSubjectCohortId }: Props) => {
  const [timeSeries, setTimeSeries] = useState<Record<string, TimeSeriesItem[]>>({});

  const startDate = useMemo(() => {
    return dateToString(dayjs().subtract(1, 'month').toDate());
  }, []);

  const endDate = useMemo(() => {
    return getEndDate(startDate);
  }, []);

  useEffect(() => {
    const timeseriesPromises = Promise.all(
      performanceTraits.map((traitName) =>
        traitService.getTimeseries(
          startDate,
          endDate,
          traitService.getTraitDefinition(traitName)?.addr as ColAddr,
          `${allSubjectCohortId}`
        )
      )
    );

    timeseriesPromises.then((traitTimeseries) => {
      const newTimeseries = { ...timeSeries };

      traitTimeseries.forEach((serie, i) => {
        const name = performanceTraits[i];
        newTimeseries[name] = serie;
      });

      setTimeSeries(newTimeseries);
    });
  }, []);

  return (
    <CFTitledComponent title={'App performance details'} className="detailed-app-performance">
      <CFLineChart
        yLabel={'ms'}
        units={'ms'}
        title={getDisplayName(traitService.getTraitDefinition(TraitMetricsNames.AppStartTime))}
        color={chartLineColor1}
        data={[
          {
            name: getDisplayName(traitService.getTraitDefinition(TraitMetricsNames.AppStartTime)),
            items: timeSeries[TraitMetricsNames.AppStartTime] || [],
          },
        ]}
        aggregationLevel={AggLevel.Day}
        isLoading={false}
      />

      <CFLineChart
        yLabel={'ms'}
        units={'ms'}
        title={getDisplayName(traitService.getTraitDefinition(TraitMetricsNames.AppRenderingTime))}
        color={chartLineColor1}
        data={[
          {
            name: getDisplayName(traitService.getTraitDefinition(TraitMetricsNames.AppRenderingTime)),
            items: timeSeries[TraitMetricsNames.AppRenderingTime] || [],
          },
        ]}
        aggregationLevel={AggLevel.Day}
        isLoading={false}
      />

      <CFLineChart
        yLabel={'mbps'}
        units={'mbps'}
        title={getDisplayName(traitService.getTraitDefinition(TraitMetricsNames.SpeedDown))}
        color={chartLineColor1}
        data={[
          {
            name: getDisplayName(traitService.getTraitDefinition(TraitMetricsNames.SpeedDown)),
            items:
              timeSeries[TraitMetricsNames.SpeedDown]?.map((item) => ({ ...item, value: item.value / 1000 })) || [],
            color: colors.cfCyan,
          },
          {
            name: getDisplayName(traitService.getTraitDefinition(TraitMetricsNames.SpeedUp)),
            items: timeSeries[TraitMetricsNames.SpeedUp]?.map((item) => ({ ...item, value: item.value / 1000 })) || [],
            color: colors.cfYellow,
          },
        ]}
        aggregationLevel={AggLevel.Day}
        isLoading={false}
      />
    </CFTitledComponent>
  );
};

export default DetailedAppPerformance;
