import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import ToastProvider from 'context/ToastProvider';
import ServicesContext from './hooks/useServicesContext';

import '@fontsource/open-sans'; // Defaults to weight 400.

import './index.scss';
import './assets/fonts/tomato-grotesk/woff2/TomatoGroteskRegular.woff2';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BrowserRouter>
    <ToastProvider>
      <ServicesContext>
        <App />
      </ServicesContext>
    </ToastProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
