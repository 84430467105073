import { AxiosResponse } from 'axios';
import { get as httpGet } from './drivers/http';

import { AggLevel, InputStatItem, InputTimeSeriesItem, StatItem, TimeSeriesItem } from 'domain/stats.types';
import { RepoConfig } from './types';

const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
const path = '/v1/ingest';

const repoConfig = {
  token: '',
  oid: -1,
  pid: -1,
};

export const init = ({ token, oid, pid }: RepoConfig) => {
  repoConfig.token = token;
  repoConfig.oid = oid;
  repoConfig.pid = pid;
};

export const getTopStats = async (start: string, end: string): Promise<StatItem[]> => {
  const config = {};

  const stats = (await httpGet(
    `${serverBaseUrl}${path}/type-count?oid=${repoConfig.oid}&pid=${repoConfig.pid}&start=${start}&end=${end}`,
    config
  )) as AxiosResponse;

  return (stats.data || []).map((item: InputStatItem) => ({
    count: item.cnt,
    type: item.log_type,
  }));
};

export const get = async (start: string, end: string, type: string, aggLevel: AggLevel): Promise<TimeSeriesItem[]> => {
  const config = {
    params: {
      oid: repoConfig.oid,
      pid: repoConfig.pid,
      start,
      end,
      log_type: type,
      agg_level: aggLevel,
    },
  };

  const stats = (await httpGet(`${serverBaseUrl}${path}/count-ts`, config)) as AxiosResponse;

  const sortedData = stats.data.sort(function (a: InputTimeSeriesItem, b: InputTimeSeriesItem) {
    return a['t'].localeCompare(b['t']);
  });

  return (sortedData || []).map((item: InputTimeSeriesItem) => ({
    value: item.v,
    time: item.t,
  }));
};
