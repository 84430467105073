import React from 'react';

import { ColAddr } from 'domain/traits.types';

import CohortService from 'services/cohort/cohort.service';
import Cohort from 'services/cohort/domain/Cohort';
import BoxplotLoader, { BoxplotType } from '../BoxplotLoader';

import './sample-cohort-comparator.scss';
import VOSampleId from 'services/intervention/domain/VOSampleId';

interface Props {
  cohortService: CohortService;
  cohort: Cohort;
  sampleId: VOSampleId;
  groups?: string[];
  addr: ColAddr;
}

const SampleVSCohortComparator = ({ cohortService, cohort, sampleId, addr, groups = [] }: Props) => {
  return (
    <div className="sample-cohort-comparator">
      <div className="boxplot-data">
        <div className="stats-title">All Subjects within selected cohort </div>
        <BoxplotLoader
          cohortService={cohortService}
          groupId={cohort ? `${cohort.id}` : '-1'}
          addr={addr}
          type={BoxplotType.Cohort}
        />
      </div>
      {groups.length === 0 ? (
        <>
          <div className="stats-title">Sample subjects</div>
          <BoxplotLoader cohortService={cohortService} groupId={sampleId.value} addr={addr} type={BoxplotType.Sample} />
        </>
      ) : (
        groups.map((group) => (
          <div className="boxplot-data" key={group}>
            <div className="stats-title"> {group}</div>
            <BoxplotLoader
              cohortService={cohortService}
              groupId={sampleId.value}
              group={group}
              addr={addr}
              type={BoxplotType.Sample}
            />
          </div>
        ))
      )}
    </div>
  );
};

export default SampleVSCohortComparator;
