import React, { ReactNode } from 'react';

import './monitoring-section.scss';

interface Props {
  title: string;
  subTitle?: string;
  actionSection?: JSX.Element;
  children: ReactNode;
  className?: string;
}

const MonitoringSection = ({ className, title, subTitle, actionSection, children }: Props) => {
  return (
    <div className={`${className || ''} monitoring-section`.trim()}>
      <div className="header">
        <div className="heading">
          <div className="title">{title}</div>
          {subTitle && <div className="sub-title">{subTitle}</div>}
        </div>
        {actionSection}
      </div>

      <div className="content">{children}</div>
    </div>
  );
};

export default MonitoringSection;
