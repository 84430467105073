import React, { useMemo, useState } from 'react';

import CFPagination from '../CFPagination';
import CFSelectLegacy, { SelectableItem } from 'components/CFSelectLegacy';

import './cf-paginated-list.scss';
import classNames from 'classnames';

interface Props {
  total: number;
  pageSizeList?: number[];
  children: JSX.Element[] | JSX.Element;
  className?: string;
  onPageChange: (page: number, size: number) => void;
}

const defaultPageSizeList = [10, 20, 50];

const CFPaginatedList = ({ children, className, total, pageSizeList = defaultPageSizeList, onPageChange }: Props) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentSize, setCurrentSize] = useState<SelectableItem>({
    label: pageSizeList[0].toString(),
    value: pageSizeList[0].toString(),
  });

  const pageSizeOption: SelectableItem[] = useMemo(
    () =>
      pageSizeList.map((pageSize) => ({
        label: pageSize.toString(),
        value: pageSize.toString(),
      })),
    pageSizeList
  );

  const handlePageChange = async (e: React.MouseEvent<Element, MouseEvent>, page: number) => {
    await onPageChange(page, parseInt(currentSize.value));
    setCurrentPage(page);
  };

  const handlePageSizeChange = async ([item]: SelectableItem[]) => {
    const newPage = Math.ceil((parseInt(currentSize.value) * (currentPage - 1) + 1) / parseInt(item.value));
    setCurrentPage(newPage);
    await onPageChange(newPage, parseInt(item.value));
    setCurrentSize(item);
  };

  return (
    <div className={classNames('paginated-list', className)}>
      <div className="paginated-content">{children}</div>
      <div>
        <div className="paginated-actions">
          <div className="paginated-size">
            <label>Row for Page:</label>
            <CFSelectLegacy options={pageSizeOption} defaultOption={[currentSize]} onSelected={handlePageSizeChange} />
          </div>
          {total !== 0 && total > parseInt(currentSize.value) && (
            <CFPagination
              pageCount={Math.ceil(total / parseInt(currentSize.value))}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CFPaginatedList;
