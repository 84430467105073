import { CFRoutes } from 'routes';
import { AuthAction, isAllowedTo } from 'services/authorization.service';

export enum Tabs {
  Organizations = 'organizations',
  Projects = 'projects',
  Users = 'users',
  Internal = 'internal',
}

export const allowedTabs = () => {
  let tabs = [Tabs.Projects, Tabs.Users];

  if (isAllowedTo(AuthAction.CreateOrganization)) {
    tabs = [Tabs.Organizations, ...tabs];
  }

  if (isAllowedTo(AuthAction.SeeInternalAdminTab)) {
    tabs.push(Tabs.Internal);
  }

  return tabs;
};

export const tabNameToCode = (tabName: string) => tabName.split(' ').join('-');

export const monitoringByTab: Record<string, CFRoutes> = {
  [tabNameToCode(Tabs.Projects)]: CFRoutes.admin_project,
  [tabNameToCode(Tabs.Users)]: CFRoutes.admin_users,
  [tabNameToCode(Tabs.Organizations)]: CFRoutes.admin_org,
  [tabNameToCode(Tabs.Internal)]: CFRoutes.admin_internal,
};
