import React from 'react';
import Cohort from 'services/cohort/domain/Cohort';

import './cohort-extended-info.scss';

interface Props {
  cohort: Cohort;
}

const CohortExtendedInfo = ({ cohort }: Props) => {
  return (
    <div className="cohort-extended-info">
      <div className="counter">{cohort.size} users</div>
      <div> {cohort.description} </div>
    </div>
  );
};

export default CohortExtendedInfo;
