import { ThresholdTuple } from './markov.types';
import { StateFilterAPI } from './markov.types.api';

export const mapTupleToStateFilter = (tuple: ThresholdTuple): StateFilterAPI => {
  return {
    ptr: tuple.trait,
    //partitions: tuple.bounds,
    reward_weight: tuple.weight,
  };
};
