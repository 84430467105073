import React, { FC } from 'react';

import './cf-skeleton-block.scss';
import classNames from 'classnames';

interface Props {
  width: number;
  height: number;
  className?: string;
}

const CFSkeletonBlock: FC<Props> = ({ width, height, className }: Props) => (
  <div
    className={classNames('skeleton', className)}
    style={{
      width,
      height,
    }}
  ></div>
);

export default CFSkeletonBlock;
