import React, { useCallback, useRef, useState } from 'react';

import useCFNavigation from 'hooks/useCFNavigation';

import { CFNavList } from 'components/CFNavList';
import Wizard from 'components/CFWizard';
import CFButton from 'components/buttons/CFButton';
import { ToastType } from 'components/CFToast/types';

import { allowedTabs, interventionByTab, Tabs } from 'views/intervention';
import InterventionNudgePolicy, {
  InterventionNudgePolicyRef,
} from 'views/intervention/interventions/NewIntervention/nudgePolicy';

import InterventionSection from 'views/intervention/interventions/NewIntervention/interventionSection';

import CohortService from 'services/cohort/cohort.service';
import NudgeService from 'services/nudging/nudge.service';

import InterventionContext, { useInterventionContext } from '../../useContext';

import { CFRole } from 'domain/general.types';
import { AdoptedNudgeCreateRequest } from 'services/nudging/nudge.types';
import { TraitSubject } from 'domain/traits.types';
import { FixedNudgeScheduleRequest } from 'services/intervention/intervention.types';

import { CFRoutes } from 'routes';
import { useToast } from 'hooks';

import { create as createAdoptedNudge } from 'repositories/adopted_nudges';

import TraitService from 'services/traits/traitSession.service';

import CohortSelector from 'connected-components/CohortSelector';

import { FilterGroupOperation } from 'services/cohort/cohort.types.api';

import SchedulingBuilder, { InterventionSchedulingRef } from 'connected-components/SchedulingBuilder';

import pagePlusIcon from 'assets/icons/pagePlus.svg';

import './nudge-adoption.scss';
import Cohort from 'services/cohort/domain/Cohort';

interface Props {
  cohortService: CohortService;
  nudgeService: NudgeService;
  traitService: TraitService;
}

export enum Steps {
  Participants = 'participants',
  Nudge = 'nudge',
  Scheduling = 'scheduling',
}

const NudgeAdoptionCreator = ({ cohortService, nudgeService, traitService }: Props) => {
  const navigate = useCFNavigation();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const nudgePolicyRef = useRef<InterventionNudgePolicyRef>() as React.MutableRefObject<InterventionNudgePolicyRef>;
  const schedulingRef = useRef<InterventionSchedulingRef>() as React.MutableRefObject<InterventionSchedulingRef>;

  const { setCohort, setSubject, cohort } = useInterventionContext();

  const [readiness, setReadiness] = useState({
    [Steps.Participants]: false,
    [Steps.Nudge]: false,
    [Steps.Scheduling]: false,
  });

  const updateReadiness = useCallback(
    (isReady: boolean, step: Steps) => {
      setReadiness((readiness) => ({ ...readiness, [step]: isReady }));
    },
    [readiness]
  );

  const handleAdoptNudge = useCallback(async () => {
    const request: AdoptedNudgeCreateRequest = {
      cohort_id: (cohort as Cohort).id,
      nudge_id: Object.values(nudgePolicyRef.current.value().group_allocation)[0],
      expire_dur: nudgePolicyRef.current.value().expire_dur,
      schedule: schedulingRef.current.value() as FixedNudgeScheduleRequest, // "recurring" is always there
    };

    try {
      setLoading(true);
      await createAdoptedNudge(request);

      addToast('Nudge adopted', ToastType.SUCCESS, 5000);

      navigate(CFRoutes.intervention_nudge_adoption);
    } catch (err) {
      addToast(`Impossible to create intervention ${(err as any).message}`, ToastType.ERROR, 5000);
      setLoading(false);

      console.log('Error creating intervention: ', err);
    }
  }, [cohort]);

  const handleOnCohortSelected = useCallback((cohort: Cohort) => {
    setCohort(cohort);
    setSubject(cohort.subject_type as TraitSubject);
    updateReadiness(true, Steps.Participants);
  }, []);

  return (
    <div className="nudge-adoption">
      <InterventionContext>
        <Wizard>
          <Wizard.Header>
            <CFNavList
              titles={allowedTabs()}
              selected={Tabs.Adoption}
              onClick={(selectedTab) => navigate(interventionByTab[selectedTab])}
            />
          </Wizard.Header>

          <Wizard.Step name={Steps.Participants} ready={readiness.participants}>
            <InterventionSection name={Steps.Participants} title={'Participants'}>
              <CohortSelector
                cohortService={cohortService}
                traitService={traitService}
                onCohortSelected={handleOnCohortSelected}
                onExtraFilterUpdated={() => ({})}
                defaultFilters={{
                  filters: [],
                  op: FilterGroupOperation.And,
                }}
              />
            </InterventionSection>
          </Wizard.Step>

          <Wizard.Step name={Steps.Nudge} ready={readiness.nudge}>
            <InterventionNudgePolicy
              ref={nudgePolicyRef}
              nudgeService={nudgeService}
              traitService={traitService}
              minNumberOfNudges={1}
              maxNumberOfNudges={1}
              includeDefaultNudge={false}
              onReady={(thisReady) => updateReadiness(thisReady, Steps.Nudge)}
            />
          </Wizard.Step>

          <Wizard.Step name={Steps.Scheduling} ready={readiness.scheduling}>
            <InterventionSection
              name={Steps.Scheduling}
              title="Scheduling"
              subtitle="Set schedules for your nudge adoption."
            >
              <SchedulingBuilder
                ref={schedulingRef}
                onReady={(thisReady) => updateReadiness(thisReady, Steps.Scheduling)}
              />
            </InterventionSection>
          </Wizard.Step>

          <Wizard.Footer>
            <div className="control">
              <CFButton
                value="Create Nudge Adoption"
                role={CFRole.Primary}
                disabled={Object.values(readiness).some((ready) => !ready)}
                isLoading={loading}
                onClick={handleAdoptNudge}
                iconCustom={<img src={pagePlusIcon} />}
              />
            </div>
          </Wizard.Footer>
        </Wizard>
      </InterventionContext>
    </div>
  );
};

export default NudgeAdoptionCreator;
