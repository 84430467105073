import { DataType } from 'domain/general.types';
import { isNumber } from 'lodash';

const typeTranslator: Record<DataType, string> = {
  [DataType.Boolean]: 'boolean',
  [DataType.Bool]: 'boolean',
  [DataType.Float4]: 'number',
  [DataType.Int4]: 'number',
  [DataType.Int2]: 'number',
  [DataType.Number]: 'number',
  [DataType.Varchar]: 'string',
  [DataType.Timestamp]: 'timestamp',
  [DataType.VarcharArray]: 'varchar[]',
};

export const capitalizeFirst = (str: string) => {
  if (!str) {
    return '';
  }

  if (str.length === 0) {
    return str;
  }

  return `${str[0].toUpperCase()}${str.slice(1)}`;
};

export const capitalize = (str: string) => {
  const nonCapitalizable = ['btw'];

  if (!str) {
    return '';
  }

  if (str.length === 0) {
    return str;
  }

  const wordParts = str.split('_').join(' ').split(' ');

  if (wordParts.length === 1 && wordParts[0].length <= 4) {
    return wordParts[0].toUpperCase();
  }

  const capitalizedStr = wordParts
    .map((str, index) => {
      const lowerCaseWord = str.toLowerCase();

      if (index !== 0 && nonCapitalizable.includes(lowerCaseWord)) {
        return lowerCaseWord;
      }

      return capitalizeFirst(lowerCaseWord);
    })
    .join(' ');

  return capitalizedStr;
};

export const postgresqlTypeToHuman = (type: string) => {
  let psType = type;

  if (type.startsWith('_')) {
    psType = type.slice(1);
  }

  const humanType = typeTranslator[psType as DataType] || type;

  if (type.startsWith('_')) {
    return `${humanType} list`;
  } else {
    return humanType;
  }
};

export const chunks = <T>(array: ReadonlyArray<T>, size: number): T[][] => {
  return Array.from({ length: Math.ceil(array.length / size) }, (v, i) => array.slice(i * size, i * size + size));
};

export const timeWindowToHuman = (durationNanos: number): [number, string] => {
  let duration;
  let durationUnit;

  const durationInHours = durationNanos / 10 ** 9 / 3600;
  if (durationInHours <= 24) {
    durationUnit = 'hours';
    duration = durationInHours;
  } else {
    durationUnit = 'days';
    duration = durationNanos / 10 ** 9 / 3600 / 24;
  }

  return [duration, durationUnit];
};

export const round = (value: number, decimals = 0): string => {
  const ERROR_RETURN_VALUE = '-';

  if (value === undefined || value === null) {
    return ERROR_RETURN_VALUE;
  }

  if (!isNumber(value)) {
    return value;
  }

  const roundedValue = value.toFixed(decimals);

  if (!roundedValue) {
    return ERROR_RETURN_VALUE;
  }

  if (roundedValue === '-0') {
    return '0';
  }

  return roundedValue;
};

export const getRandomInt = (min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getValueByPath = (obj: any, path: string) => {
  const [first, ...pathParts] = path.split('.');
  let content = obj[first];

  for (const part of pathParts) {
    if (!content) {
      return content;
    }

    content = content[part];
  }

  return content;
};
