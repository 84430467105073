import React from 'react';

import CFButton from 'components/buttons/CFButton';
import CFInput from 'components/CFInput';
import CFTitledComponent from 'components/CFTitledComponent';
import CFTextarea from 'components/textarea/CFTextarea';
import CFSelectLegacy, { SelectableItem } from 'components/CFSelectLegacy';

import { create as createProject } from 'repositories/projects';

import { TraitSubject } from 'domain/traits.types';
import { CFRole, Modules } from 'domain/general.types';

import { getOrganization } from 'services/session/session.service'; // TODO: inject this instead of import
import useSubjectTypes from 'services/traits/hooks/useSubjectTypes';

import { CFRoutes } from 'routes';

import pagePlusIcon from 'assets/icons/pagePlus.svg';

import useCFNavigation from 'hooks/useCFNavigation';

import AdminLayout from 'views/admin/layout';
import { Tabs } from 'views/admin/tabs';

import './new-project.scss';

const NewProject = () => {
  const navigate = useCFNavigation();
  const subjectTypes = useSubjectTypes();

  const nameInputRef = React.createRef<HTMLInputElement>();
  const descriptionInputRef = React.createRef<HTMLTextAreaElement>();
  const subjectsRef = React.createRef<any>();
  const modulesRef = React.createRef<any>();

  const handleCancelProject = async () => {
    navigate(CFRoutes.admin_project);
  };

  const handleNewProject = async () => {
    try {
      await createProject(
        nameInputRef.current?.value || '',
        descriptionInputRef.current?.value || '',
        parseInt(getOrganization() || ''),
        (subjectsRef.current as any).getValue().map((item: SelectableItem) => item.value.toLowerCase()),
        (modulesRef.current as any).getValue().map((item: SelectableItem) => item.value.toLowerCase())
      );
    } catch (err) {
      console.log('error creating project: ', err);
    }

    navigate(CFRoutes.admin_project);
  };

  return (
    <AdminLayout tab={Tabs.Projects}>
      <div className="new-project-container">
        <CFTitledComponent title={'Name'} className="name">
          <CFInput ref={nameInputRef} />
        </CFTitledComponent>

        <CFTitledComponent title={'Subjects'} className="subjects">
          <CFSelectLegacy
            ref={subjectsRef}
            options={subjectTypes.map((subject) => ({
              value: subject,
              label: subject,
            }))}
            mandatoryValueOptions={[TraitSubject.User]}
            isMulti={true}
            defaultOption={[{ value: 'user', label: 'user' }]}
          />
        </CFTitledComponent>

        <CFTitledComponent title={'Modules'} className="">
          <CFSelectLegacy
            ref={modulesRef}
            options={Object.values(Modules).map((module) => ({
              value: module,
              label: module,
            }))}
            mandatoryValueOptions={[Modules.Core]}
            isMulti={true}
            defaultOption={[{ value: Modules.Core, label: Modules.Core }]}
          />
        </CFTitledComponent>

        <CFTitledComponent title={'Description'} className="description">
          <CFTextarea ref={descriptionInputRef} />
        </CFTitledComponent>

        <div className="controls">
          <CFButton value={'Cancel'} onClick={handleCancelProject} />
          <CFButton
            value={'Create Project'}
            onClick={handleNewProject}
            role={CFRole.Primary}
            iconCustom={<img src={pagePlusIcon} />}
          />
        </div>
      </div>
    </AdminLayout>
  );
};

export default NewProject;
