import { AxiosResponse } from 'axios';
import { RepoConfig } from 'repositories/types';
import { HTTPMethod, request } from '../../repositories/drivers/http';

type JSONValue = string | number | boolean | JSONObject | JSONArray;

interface JSONObject {
  [key: string]: JSONValue;
}
interface JSONArray extends Array<JSONValue> {}

const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
const pathVersion = '/v1';

const repoConfig = {
  token: '',
  oid: -1,
  pid: -1,
};

export const init = ({ token, oid, pid }: RepoConfig) => {
  repoConfig.token = token;
  repoConfig.oid = oid;
  repoConfig.pid = pid;
};

export const makeGenericRequest = async (method: HTTPMethod, url: string, body: JSONObject): Promise<JSONObject> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };

  try {
    const data = (await request(
      method,
      `${serverBaseUrl}${pathVersion}${url}`,
      body,
      config
    )) as unknown as AxiosResponse<JSONObject>;

    return data.data;
  } catch (err) {
    console.log('error making reqeust! === ', err);
    throw new Error(err as any);
  }
};
