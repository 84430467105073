import React, { useEffect, useMemo, useState } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';

import { ProjectDefinition } from 'types';
import { CFRoutes } from 'routes';

import useCFNavigation from 'hooks/useCFNavigation';

import { get as getProjects, remove as removeProject } from 'repositories/projects';

import AdminLayout from 'views/admin/layout';
import { Tabs } from 'views/admin/tabs';

import { CFRole } from 'domain/general.types';

import CFConfirmableButton from 'components/CFConfirmableButton';
import { Tag, TagTypes } from 'components/Tag';
import CFButton from 'components/buttons/CFButton';
import CFTable, { Column, ColumnType } from 'components/CFTable';

import './list-of-projects.scss';

const ListOfProjects = () => {
  const [selectedProject, setSelectedProject] = useState(-1);
  const [projects, setProjects] = useState<ProjectDefinition[]>([]);
  const navigate = useCFNavigation();

  const updateListOfProjects = async () => {
    const listOfProjects = await getProjects();

    setProjects(listOfProjects);
  };

  useEffect(() => {
    updateListOfProjects();
  }, []);

  const handleRowSelected = (id: number) => {
    setSelectedProject(id);
  };

  const handleDeleteProject = async (id: number) => {
    try {
      await removeProject(id);
    } catch (err) {
      console.log(err);
    }
    await updateListOfProjects();
  };

  const handleEditProject = () => {
    navigate(CFRoutes.project_edit.replace(':project', `${selectedProject}`));
  };

  const project = projects.find((project) => project.id === selectedProject);

  const columns: Column[] = useMemo(
    () => [
      {
        title: 'Id',
        field: 'id',
        type: ColumnType.STRING,
      },
      {
        title: 'Name',
        field: 'name',
        type: ColumnType.STRING,
      },
      {
        title: 'Description',
        field: 'description',
        type: ColumnType.STRING,
      },
      {
        title: 'Created by',
        field: 'created_by',
        type: ColumnType.STRING,
      },
    ],
    []
  );

  return (
    <AdminLayout className="analytics" tab={Tabs.Projects}>
      <div className="actions">
        <CFButton
          role={CFRole.Primary}
          value="Add project"
          iconName={faPlus}
          onClick={() => navigate(CFRoutes.project_new)}
        />
      </div>

      <div className="list-of-projects">
        <CFTable headers={columns} data={projects} onSelectRow={(row) => handleRowSelected(row.id)} />

        {project && (
          <div>
            <div className="project-detail">
              <div className="section">
                <div className="blog">
                  <div className="category">
                    Project Name: <span>{project.name}</span>
                  </div>
                  <div className="category">
                    Created At: <span>{dayjs(project.created_at).format('DD/MM/YYYY')}</span>
                  </div>
                  <div className="category">
                    Created By: <span>{project.created_by}</span>
                  </div>
                  <div className="category">
                    Churn definition: <span>{project.churn_definition}</span>
                  </div>
                  <div className="category">
                    Telegram bot token: <span>{project.telegram_bot_token}</span>
                  </div>
                </div>
                <div className="blog">
                  <div className="category">Description</div>
                  <div className="category">
                    <span>{project.description}</span>
                  </div>
                </div>
              </div>
              <div className="category">Subject</div>
              <div className="section">
                {project.subjects.map((subject) => (
                  <Tag key={subject} text={subject} type={TagTypes.System} />
                ))}
              </div>

              <div className="category">Module</div>

              <div className="section">
                {project.modules.map((module) => (
                  <Tag key={module} text={module} type={TagTypes.System} />
                ))}
              </div>

              <div className="category"> Assistant prompt </div>
              <div className="section"> {project.assistant_prompt} </div>
            </div>

            <div className="controls">
              <CFConfirmableButton
                title="Delete project"
                question="Are you sure you want to delete this project? This action can not be undone"
              >
                <CFButton value="Delete" onClick={() => handleDeleteProject(selectedProject)} />
              </CFConfirmableButton>

              <CFButton value="Edit" onClick={handleEditProject} role={CFRole.Primary} />
            </div>
          </div>
        )}
      </div>
    </AdminLayout>
  );
};

export default ListOfProjects;
