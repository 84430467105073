import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';

import { ColAddr, Trait, TraitCategory, TraitSubject } from 'domain/traits.types';
import InterventionSection from '../interventionSection';
import { Steps } from '..';
import TraitsTable from 'connected-components/TraitsTable';
import { useServicesContext } from 'hooks/useServicesContext';
import { getTraitName } from 'services/traits/helpers.traits';
import CFButton from 'components/buttons/CFButton';
import { CFRole } from 'domain/general.types';
import { previewGroup } from 'services/markov/markov.repo';
import { useInterventionContext } from 'views/intervention/useContext';
//import { GroupName } from 'services/markov/markov.types';
import { useToast } from 'hooks';
import { ToastType } from 'components/CFToast/types';
import GroupPreview from './Preview';
import { GroupPreviewResponse } from 'services/markov/markov.types.api';

export interface GroupTraitsRef {
  value: () => ColAddr[];
}

interface Props {
  onReady: (ready: boolean) => void;
}

const WhittleIndex = 'WhittleIndex';

const GroupTraits = forwardRef<GroupTraitsRef, Props>(function GroupTraits({ onReady }: Props, ref) {
  const { traitSessionService: traitService } = useServicesContext();
  const { sampleId, algorithmName } = useInterventionContext();
  const [selected, setSelected] = useState<Trait[]>([]);
  const [catalogTraits, setCatalogTraits] = useState<Trait[]>([]);
  const [groupAssignment, setGroupAssignments] = useState<GroupPreviewResponse>();

  const { addToast } = useToast();

  useImperativeHandle(ref, () => ({
    value() {
      return selected.map((trait) => trait.addr);
    },
  }));

  useEffect(() => {
    if (algorithmName === WhittleIndex) {
      onReady(true);
    } else {
      onReady(selected.length !== 0);
    }
  }, [algorithmName]);

  useEffect(() => {
    if (algorithmName === WhittleIndex) {
      onReady(true);
      return;
    }

    onReady(selected.length !== 0);
  }, [selected]);

  useEffect(() => {
    (async () => {
      const traits = await traitService.getTraits({ category: TraitCategory.Catalogue, subject: TraitSubject.User });

      setCatalogTraits(traits.filter((trait) => getTraitName(trait) !== 'id'));
    })();
  }, []);

  const handleSelectTrait = useCallback((row: Record<string, any>) => {
    const currentTrait = row as Trait;
    setSelected((prevSelected) => {
      const isExist = prevSelected.some((trait) => trait.addr.ptr === currentTrait.addr.ptr);

      if (isExist) {
        return prevSelected.filter((trait) => trait.addr.ptr !== currentTrait.addr.ptr);
      } else {
        return [...prevSelected, currentTrait];
      }
    });
    setGroupAssignments(undefined);
  }, []);

  const handlePreview = useCallback(async () => {
    const addrs = selected.map((trait) => trait.addr);
    try {
      const groupPreview = await previewGroup(sampleId.value, addrs);
      setGroupAssignments(groupPreview);
    } catch {
      addToast('Impossible to preview groups', ToastType.ERROR);
    }
  }, [selected, sampleId]);

  return (
    <InterventionSection name={Steps.GroupTraits} title={'Social Group Filters'} className="group-traits">
      <TraitsTable selected={selected} handleSelectTrait={(row) => handleSelectTrait(row)} traits={catalogTraits} />

      <CFButton value={'Preview'} onClick={handlePreview} role={CFRole.Primary} />

      {groupAssignment ? <GroupPreview groupView={groupAssignment} traits={selected} /> : <span></span>}
    </InterventionSection>
  );
});

export default GroupTraits;
