import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';

import { CFRoutes } from 'routes';
import { useToast } from 'hooks';

import { useServicesContext } from 'hooks/useServicesContext';

import { CFNavList } from 'components/CFNavList';
import CFButton from 'components/buttons/CFButton';
import TreeViewer from 'connected-components/TreeViewer';
import CFConfirmableButton from 'components/CFConfirmableButton';
import { ToastType } from 'components/CFToast/types';
import CFLoadWrapper from 'components/CFLoadWrapper';
import { Column, ColumnType } from 'components/CFTable';
import CFDataTable from 'components/CFDataTable';

import { allowedTabs, interventionByTab, Tabs } from 'views/intervention';
import Schedule from 'views/intervention/interventions/monitoring/definition/schedule';

import { CFRole } from 'domain/general.types';
import { AppAdoptedNudge, NudgeType, RenderMethod } from 'services/nudging/nudge.types';

import { remove as removeAdoptedNudge } from 'repositories/adopted_nudges';

import useCFNavigation from 'hooks/useCFNavigation';

import './list-adopted-nudges.scss';
import FilterSet from 'services/cohort/domain/FilterSet';

const ListAdoptedNudges = () => {
  const { nudgeService } = useServicesContext();
  const { addToast } = useToast();
  const navigate = useCFNavigation();
  const [adoptedNudges, setAdoptedNudges] = useState<AppAdoptedNudge[]>([]);
  const [totalAdoptedNudges, setTotalAdoptedNudges] = useState(0);
  const [curPage, setCurPage] = useState(0);
  const [curPageSize, setCurPageSize] = useState(10);

  const [isLoading, setIsLoading] = useState(true);

  const downloadPage = useCallback(async () => {
    setIsLoading(true);
    const adoptedNudgesPage = await nudgeService.listAdopted(curPage, curPageSize);

    setAdoptedNudges(adoptedNudgesPage.data);
    setTotalAdoptedNudges(adoptedNudgesPage.total);
    setIsLoading(false);
  }, [nudgeService, curPage, curPageSize]);

  useEffect(() => {
    downloadPage();
  }, [downloadPage]);

  const handleRemove = useCallback(
    (id: number) => async () => {
      try {
        setIsLoading(true);
        await removeAdoptedNudge(id);

        const newAdoptedNudges = adoptedNudges.filter((adoptedNudge) => adoptedNudge.def.id !== id);
        setAdoptedNudges(newAdoptedNudges);

        addToast('Successefully removed nudge', ToastType.SUCCESS);
      } catch {
        addToast(`Could not remove nudge`, ToastType.ERROR);
      } finally {
        setIsLoading(false);
      }
    },
    [adoptedNudges]
  );

  const handleNewPageRequest = (page: number, size: number) => {
    setCurPage(page - 1);
    setCurPageSize(size);
  };

  const columns: Column[] = useMemo(
    () =>
      [
        {
          title: 'ID',
          field: 'def.id',
          type: ColumnType.STRING,
          style: {
            width: '75px',
          },
        },
        {
          title: 'Nudge name',
          field: 'nudge.name',
          type: ColumnType.STRING,
          style: {
            minWidth: '260px',
          },
        },
        {
          title: 'Cohort name',
          field: 'cohort.name',
          type: ColumnType.STRING,
          style: {
            minWidth: '260px',
          },
        },
        {
          title: 'Render method',
          field: 'render_method',
          type: ColumnType.STRING,
          style: {
            minWidth: '260px',
          },
          renderCell: (row) => {
            const renderMethodNameMap = {
              [RenderMethod.InApp]: 'In app',
              [RenderMethod.PushNotification]: 'Push notification',
            };

            const adoptedNudge = row as AppAdoptedNudge;

            if (adoptedNudge.nudge.definition.type === NudgeType.Message) {
              return renderMethodNameMap[adoptedNudge.nudge.definition.render_method as RenderMethod];
            } else {
              return '';
            }
          },
        },
        {
          title: '',
          field: 'expandable',
          type: ColumnType.STRING,
          expandable: true,
          style: {
            width: '10px',
          },
          renderCell: (row) => {
            return (
              <div className="adopted-nudge-details">
                <div className="remove">
                  <div className="title">Cohort</div>
                  <CFConfirmableButton title="Remove nudge" question="Are you sure you want to remove this nudge?">
                    <CFButton value="Remove" iconName={faTrashCan} onClick={handleRemove(row.def.id)}></CFButton>
                  </CFConfirmableButton>
                </div>

                <TreeViewer value={new FilterSet(row.cohort.tree)} />

                <Schedule fixedSchedulePolicy={row.schedule} />
              </div>
            );
          },
        },
      ] as Column[],
    [adoptedNudges]
  );

  return (
    <div className="list-of-adopted-nudges">
      <CFNavList
        titles={allowedTabs()}
        selected={Tabs.Adoption}
        onClick={(selectedTab) => navigate(interventionByTab[selectedTab])}
      />
      <CFLoadWrapper isLoading={isLoading}>
        <div className="adopted-nudges-container">
          <div className="controls">
            <CFButton
              value="Adopt nudge"
              role={CFRole.Primary}
              iconName={faPlus}
              onClick={() => navigate(CFRoutes.intervention_nudge_adoption_new)}
            />
          </div>

          <CFDataTable
            headers={columns}
            total={totalAdoptedNudges}
            data={adoptedNudges.map((adoptedNudge) => ({ ...adoptedNudge, id: adoptedNudge.def.id }))}
            onPaginated={handleNewPageRequest}
          />
        </div>
      </CFLoadWrapper>
    </div>
  );
};

export default ListAdoptedNudges;
