import React from 'react';

import { Trait } from 'domain/traits.types';

import CFTraitItem from 'connected-components/traits/CFTraitItem';

import './markov-state.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTrendDown, faArrowTrendUp } from '@fortawesome/free-solid-svg-icons';

interface Props {
  isLow: boolean;
  trait: Trait;
}

const MarkovState = ({ isLow, trait }: Props) => {
  const arrow = isLow ? faArrowTrendDown : faArrowTrendUp;

  return (
    <div className="markov-state">
      <CFTraitItem addr={trait.addr} key={trait.addr.ptr} />
      <FontAwesomeIcon icon={arrow} size="lg" />
    </div>
  );
};

export default MarkovState;
