import React, { CSSProperties, forwardRef } from 'react';
import classNames from 'classnames';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './selected-tags.scss';

export interface TagValue {
  value: string;
  color: string;
}

interface Props {
  label: string;
  values: TagValue[];
  sticky?: boolean;
  onRemove?: (value: TagValue) => void;
}

const SelectedTags = forwardRef<HTMLDivElement, Props>(function SelectedTags(
  { label, values, sticky = false, onRemove },
  ref
) {
  return (
    <div ref={ref} className={classNames('selected-tags', { sticky })}>
      <div className="selected-tags-label">{label}</div>
      {values.map(({ value, color }) => (
        <div key={value} className="selected-tags-value" style={{ '--tag-color': color } as CSSProperties}>
          {value}
          {onRemove && (
            <FontAwesomeIcon
              className="selected-tags-cross"
              onClick={() => onRemove({ value, color })}
              icon={icon({ name: 'xmark', style: 'solid' })}
            />
          )}
        </div>
      ))}
    </div>
  );
});

export default SelectedTags;
