import React, { useCallback } from 'react';

import { capitalize, round } from 'helpers/misc';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

import CFSkeletonBlock from 'components/CFSkeletonBlock';
import { KPI } from 'components/kpis';

import classNames from 'classnames';

import './data-info-box.scss';

interface Props {
  title?: string;
  units?: string;
  isLoading?: boolean;
  kpis: KPI[];
  onShowExtendedInfo?: () => void;
  onClickedKPI?: (label: string) => void;
}

const DECIMALS_FOR_PERCENTAGE = 2;
const DECIMALS_FOR_ANY = 0;

const DataInfoBox = ({ kpis, title, units, onShowExtendedInfo, onClickedKPI, isLoading = false }: Props) => {
  if (!kpis) {
    return <div></div>;
  }

  const handleClickOnValue = useCallback((label: string) => {
    if (!onClickedKPI) {
      return;
    }

    onClickedKPI(label);
  }, []);

  return (
    <div className="data-info-box">
      {onShowExtendedInfo && (
        <div className="extended" onClick={onShowExtendedInfo}>
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ cursor: 'pointer' }} />
        </div>
      )}
      {isLoading && (
        <>
          <CFSkeletonBlock width={96} height={16} />
          <CFSkeletonBlock width={62} height={30} />
          <CFSkeletonBlock width={96} height={16} />
        </>
      )}
      {!isLoading && (
        <>
          <div className="title">
            <div>
              <span> {title || ''} </span>
              <span className="unit-title">{units || ''}</span>
            </div>
          </div>

          <div className="values">
            {kpis.map((kpi, i) => {
              return (
                <div key={i}>
                  <div
                    className={classNames('value', { clickable: onClickedKPI !== undefined })}
                    onClick={() => handleClickOnValue(kpi.label)}
                  >
                    {typeof kpi.value === 'number'
                      ? round(kpi.value, kpi.unit === '%' ? DECIMALS_FOR_PERCENTAGE : DECIMALS_FOR_ANY)
                      : kpi.value}

                    <span className="unit"> {kpi.unit} </span>
                  </div>

                  <span className="label"> {capitalize(kpi.label)} </span>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default DataInfoBox;
