import React, { useCallback, useMemo, useState } from 'react';

import { FixedNudgeSchedule } from 'services/intervention/intervention.types';
import dayjs from 'dayjs';
import MonitoringSection from '../monitoringSection';
import WeekViewer from 'components/DateTime/WeekViewer';
import Month from 'components/DateTime/DatetimePicker/Month';
import moment from 'moment';
import { NavigationAction } from 'components/DateTime/DatetimeRangePicker/types';
import { getEdgeDateRanges, getRecurringDatesForMonth } from 'helpers/dates';

interface Props {
  fixedSchedulePolicy: FixedNudgeSchedule;
  className?: string;
}

const Schedule = ({ fixedSchedulePolicy, className }: Props) => {
  const [week, setWeek] = useState(dayjs().week());

  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());

  const year = useMemo(() => dayjs().year(), []);

  const handleMonthNavigate = (action: NavigationAction) => {
    if (action === NavigationAction.Next) {
      const nextMonth = dayjs(currentDate).add(1, 'month');

      setCurrentDate(nextMonth.toDate());
    } else {
      const prevMonth = dayjs(currentDate).subtract(1, 'month');

      setCurrentDate(prevMonth.toDate());
    }
  };

  const handleClickOnDay = useCallback((day: Date) => {
    setWeek(dayjs(day).week());
    setSelectedDate(day);
  }, []);

  const schedulingSubTitle = useMemo(() => {
    const { startDate, endDate } = getEdgeDateRanges(fixedSchedulePolicy);

    return `Schedules for this intervention run from ${startDate.format('YYYY-MM-DD')} through ${endDate.format(
      'YYYY-MM-DD'
    )}, on ${fixedSchedulePolicy?.definition?.tz || endDate.format('Z')} time zone.`;
  }, [fixedSchedulePolicy]);

  const timestamps = useMemo(() => {
    if (fixedSchedulePolicy.definition.time_points?.pts) {
      return fixedSchedulePolicy.definition.time_points?.pts;
    } else if (fixedSchedulePolicy.definition.recurring?.pairs) {
      return Array.from(
        new Set(
          getRecurringDatesForMonth(fixedSchedulePolicy.definition.recurring.pairs, currentDate)
            .flat()
            .concat(
              getRecurringDatesForMonth(
                fixedSchedulePolicy.definition.recurring.pairs,
                dayjs(currentDate).subtract(1, 'month').toDate()
              ).flat()
            )
            .concat(
              getRecurringDatesForMonth(
                fixedSchedulePolicy.definition.recurring.pairs,
                dayjs(currentDate).add(1, 'month').toDate()
              ).flat()
            )
            .map((date) =>
              dayjs(date.toISOString()).utcOffset(fixedSchedulePolicy.definition.tz).format('YYYY-MM-DDTHH:mm:ss.SSS')
            )
        )
      ).sort();
    }
  }, [fixedSchedulePolicy, currentDate]);

  return (
    <MonitoringSection title="Scheduling" subTitle={schedulingSubTitle} className={`${className} schedule-definition`}>
      <div className="calendar-with-week">
        <Month
          value={currentDate}
          date={selectedDate}
          minDate={moment(0).toDate()}
          maxDate={dayjs().add(1, 'year').toDate()}
          setValue={() => ({})}
          timestamps={timestamps}
          helpers={{
            isHover: () => false,
          }}
          handlers={{
            onDayClick: handleClickOnDay,
            onDayHover: () => ({}),
            onMonthNavigate: handleMonthNavigate,
          }}
        />
        <WeekViewer week={week} year={year} timestamps={timestamps} />
      </div>
    </MonitoringSection>
  );
};

export default Schedule;
