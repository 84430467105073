import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import './cf-checkbox.scss';

interface Props {
  onChange?: (selected: boolean) => void;
  defaultCheck?: boolean;
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  variant?: 'primary' | 'secondary';
  tabIndex?: number;
  name?: string;
}

const CFCheckbox = React.forwardRef<HTMLInputElement, Props>(function CFCheckbox(
  { onChange, label, checked = false, disabled = false, variant = 'primary', tabIndex = 0, name, defaultCheck = false },
  ref
) {
  const [check, setCheck] = useState<boolean>(checked);

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (!onChange) {
      return;
    }
    e.stopPropagation();
    onChange(e.currentTarget.checked);
  };

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    setCheck(!check);

    e.stopPropagation();
  };

  useEffect(() => {
    setCheck(checked);
  }, [checked]);

  useEffect(() => {
    if (!defaultCheck) {
      return;
    }

    setCheck(true);
  }, [defaultCheck]);

  return (
    <div className={classNames('cf-checkbox', { secondary: variant === 'secondary' })}>
      <input
        data-testid={name}
        name={name}
        tabIndex={tabIndex}
        disabled={disabled}
        checked={check}
        ref={ref}
        type="checkbox"
        defaultChecked={defaultCheck}
        onChange={handleOnChange}
        onClick={handleClick}
      />
      {label && <span>{label}</span>}
    </div>
  );
});

export default CFCheckbox;
