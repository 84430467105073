import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCheck, faPen } from '@fortawesome/free-solid-svg-icons';

import { AppModel, ModelTag } from 'domain/model.types';

import { useServicesContext } from 'hooks/useServicesContext';

import { useToast } from 'hooks';

import { ToastType } from 'components/CFToast/types';

import ModelTagChip from './ModelTag';

import './model-tag-list.scss';

interface Props {
  model: AppModel;
}

const ModelTagList = ({ model }: Props) => {
  const { modelService } = useServicesContext();
  const [availableTags, setAvailableTags] = useState<ModelTag[]>([]);

  const [editMode, setEditMode] = useState(false);
  const [selectedTags, setSelectedTags] = useState(model.definition.tags || []);
  const { addToast } = useToast();

  useEffect(() => {
    (async () => {
      const tags = await modelService.getAvailableTags(model.definition.id);

      setAvailableTags(tags);
    })();
  }, []);

  const ghostTags = useMemo(() => {
    if (!editMode) {
      return [];
    }

    const tagsCounter: Partial<Record<ModelTag, boolean>> = {};
    availableTags.forEach((tag: ModelTag) => (tagsCounter[tag] = false));

    selectedTags.forEach((tag) => (tagsCounter[tag] = true));

    return availableTags.filter((tag) => !tagsCounter[tag]);
  }, [selectedTags, availableTags, editMode]);

  const handleAddTag = useCallback((tag: ModelTag) => {
    setSelectedTags((tags) => [...tags, tag]);
    modelService
      .addTag(model.definition.id, tag)
      .then()
      .catch((err: any) => {
        const newTags = selectedTags.filter((currentTag) => currentTag !== tag);
        setSelectedTags(newTags);

        addToast(`Error adding tag: ${err.message}`, ToastType.ERROR);
      });
  }, []);

  const handleRemoveTag = useCallback(async (tag: ModelTag) => {
    const newTags = selectedTags.filter((currentTag) => currentTag !== tag);
    setSelectedTags(newTags);

    modelService
      .removeTag(model.definition.id, tag)
      .then()
      .catch((err: any) => {
        addToast(`Error removing tag: ${err.message}`, ToastType.ERROR);
      });
  }, []);

  const actionIcon = useMemo(() => {
    if (!editMode) {
      // if the available tags is only INV, do not show edit button, since
      // the user is not allow to remove / add it
      if (availableTags.length === 1 && availableTags[0] === ModelTag.Intervention) {
        return undefined;
      }

      return faPen;
    }

    if (editMode) {
      return faCheck;
    }

    return undefined;
  }, [editMode, ghostTags]);

  return (
    <div className="model-tag-list">
      <div className="tag-list">
        {selectedTags.map((tag: ModelTag) => (
          <ModelTagChip
            key={tag}
            editable={editMode}
            tag={tag}
            onClick={() => ({})}
            onRemove={() => handleRemoveTag(tag)}
          />
        ))}

        {ghostTags
          .filter((tag) => tag !== ModelTag.Intervention)
          .map((tag) => (
            <ModelTagChip key={tag} ghost editable={editMode} tag={tag} onClick={() => handleAddTag(tag)} />
          ))}

        {actionIcon && (
          <FontAwesomeIcon
            className="tag-list__action"
            icon={actionIcon}
            size="sm"
            onClick={() => setEditMode(!editMode)}
          />
        )}
      </div>
    </div>
  );
};

export default ModelTagList;
