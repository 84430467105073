import React, { ReactNode, useCallback, useState } from 'react';

import { Payload } from 'services/assistant/assistant.types';
import { FilterAPI } from 'services/cohort/cohort.types.api';

import CFNavigationArrows from 'components/CFNavigationArrows';

import CFPayloadErrorBoundary from './payloadErrorBoundary';

import './payload-viewer.scss';
import classNames from 'classnames';

interface Props {
  payloads: Payload[];
}

const PADDING = 10;

// eslint-disable-next-line
const prettyPrintPayloadLegacy = (payload: Record<string, any> | string, level = 0): ReactNode => {
  if (!payload) {
    return <div>{payload}</div>;
  }

  if (typeof payload === 'string') {
    return <div>{payload}</div>;
  }

  if (Array.isArray(payload)) {
    return payload.toString();
  }

  // filters are printed first
  const keys = Object.keys(payload).sort((a, b) => (b === 'filters' ? 1 : -1));

  return keys.map((key: string) => {
    if (key === 'filters') {
      return (
        <div key={`${key}-${level}`} style={{ paddingLeft: level * PADDING }}>
          <div className="args__title">Filters</div>
          {(payload[key] as FilterAPI[])?.map((filter) => (
            <div key={filter.ptr || filter.identifier} className="args__value">
              {filter.ptr || filter.identifier} {filter.op} {filter.val}
            </div>
          ))}
        </div>
      );
    }

    if (Array.isArray(payload[key])) {
      return (
        <div key={`${key}-${level}`} style={{ paddingLeft: level * PADDING }}>
          <div className="args__title">{key}</div>
          <div className="args__value"> {payload[key].toString()} </div>
        </div>
      );
    } else if (typeof payload[key] === 'object') {
      return [
        <div key={`object-key-${key}`} className="args__title">
          {key}
        </div>,
        prettyPrintPayloadLegacy(payload[key], level + 1),
      ];
    } else {
      return (
        <div key={`${key}-${level}`} style={{ paddingLeft: level * PADDING }}>
          <div className="args__title">{key}</div>
          <div className="args__value"> {payload[key].toString()} </div>
        </div>
      );
    }
  });
};

interface FieldRendererProps {
  name: keyof Payload;
  payload: Payload;
}

const FieldRenderer = ({ name, payload }: FieldRendererProps) => {
  let value;

  const formattedName = name.split('_').join(' ');

  if (payload[name] === undefined || payload[name] === null) {
    value = '';
  } else if ((payload[name] as any).join !== undefined) {
    value = (payload[name] as string[]).join(', ');
  } else if (typeof payload[name] === 'boolean') {
    value = `${payload[name]}`;
  } else {
    value = ((payload[name] || '') as string).split('\n').map((item) => <div key={Math.random()}>{item}</div>);
  }

  return (
    <div>
      <span className="content__name">{formattedName}: </span>
      {value}
    </div>
  );
};

const PayloadViewer = ({ payloads }: Props) => {
  const [index, setIndex] = useState(0);
  const handleNextPayload = useCallback(() => {
    setIndex((index) => index + 1);
  }, []);

  const handlePreviousPayload = useCallback(() => {
    setIndex((index) => index - 1);
  }, []);

  if (!payloads.length) {
    return <div> malformed payload</div>;
  }

  return (
    <div className={classNames('payload-viewer', { single: payloads.length > 0 })}>
      {payloads.length > 1 && (
        <div className="counter">
          {index + 1}/{payloads.length}
        </div>
      )}

      <CFPayloadErrorBoundary sourceData={payloads[index]}>
        <div className="content">
          {payloads[index].query && (
            <>
              <pre> {payloads[index].query} </pre>
            </>
          )}

          {Object.keys(payloads[index])
            .filter((item) => item !== 'query')
            .map((field) => (
              <FieldRenderer key={field} name={field as keyof Payload} payload={payloads[index]} />
            ))}
        </div>
      </CFPayloadErrorBoundary>

      {payloads.length > 1 && (
        <CFNavigationArrows
          index={index}
          windowSize={1}
          total={payloads.length}
          onNext={handleNextPayload}
          onPrevious={handlePreviousPayload}
        />
      )}
    </div>
  );
};

export default PayloadViewer;

/*
          {payloads[index].error && (
            <>
              <span className="content__name">Error: </span>
              {payloads[index].error}
            </>
          )}

          {payloads[index].subjects && (
            <div>
              <span className="content__name">Subjects: </span>
              {(payloads[index].subjects || []).join(', ')}
            </div>
          )}

          {payloads[index].modules && (
            <div>
              <span className="content__name">Modules: </span>
              {(payloads[index].modules || []).join(', ')}
            </div>
          )}

          {payloads[index].summarized_question && (
            <div>
              <span className="content__name">Summarized question: </span>
              {payloads[index].summarized_question.split('\n').map((item) => (
                <div key={Math.random()}>{item}</div>
              ))}
            </div>
          )}

          {payloads[index].full_question && (
            <div>
              <span className="content__name">Full question: </span>
              {payloads[index].full_question.split('\n').map((item) => (
                <div key={Math.random()}>{item}</div>
              ))}
            </div>
          )}

*/
