import React from 'react';
import { useLocation } from 'react-router-dom';

import { CFNavList } from 'components/CFNavList';
import { segmentationByTab, Tabs } from '../..';
import { useServicesContext } from 'hooks/useServicesContext';

import NewCohortContent from './new-cohort';
import { TraitSubject } from 'domain/traits.types';

import useCFNavigation from 'hooks/useCFNavigation';

import './new-cohort.scss';

const NewCohort = () => {
  const { traitSessionService: traitService } = useServicesContext();
  const navigate = useCFNavigation();
  const tabs = Object.values(Tabs);

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  return (
    <div className="new-cohort">
      <CFNavList
        titles={tabs}
        selected={Tabs.Cohorts}
        onClick={(selectedTab) => navigate(segmentationByTab[selectedTab])}
      />

      <NewCohortContent traitService={traitService} defaultSubject={params.get('subject') as TraitSubject} />
    </div>
  );
};

export default NewCohort;
