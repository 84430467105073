import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { get as getProjects, edit as editProject } from 'repositories/projects';

import { ProjectDefinition } from 'types';
import { CFRoutes } from 'routes';

import useToast from 'hooks/useToast';
import useCFNavigation from 'hooks/useCFNavigation';

import { CFRole, Modules } from 'domain/general.types';
import { TraitSubject } from 'domain/traits.types';

import useSubjectTypes from 'services/traits/hooks/useSubjectTypes';

import AdminLayout from 'views/admin/layout';
import { Tabs } from 'views/admin/tabs';

import CFTextarea from 'components/textarea/CFTextarea';
import CFButton from 'components/buttons/CFButton';
import CFInput from 'components/CFInput';
import CFSelectLegacy, { SelectableItem } from 'components/CFSelectLegacy';
import CFTitledComponent from 'components/CFTitledComponent';
import { ToastType } from 'components/CFToast/types';

import './edit-project.scss';

const EditProject = () => {
  const navigate = useCFNavigation();
  const subjectTypes = useSubjectTypes();

  const params = useParams();
  const { addToast } = useToast();
  const descriptionInputRef = React.createRef<HTMLInputElement>();
  const subjectsRef = React.createRef<any>();
  const modulesRef = React.createRef<any>();
  const promptRef = React.createRef<HTMLTextAreaElement>();
  const telegramRef = React.createRef<HTMLInputElement>();
  const churnRef = React.createRef<HTMLInputElement>();

  const [project, setProject] = useState<ProjectDefinition>();

  useEffect(() => {
    (async () => {
      try {
        const projects = await getProjects();

        const project = projects.find((project) => `${project.id}` === params.project);
        setProject(project);
      } catch (err) {
        console.log('error getting projects to edit');
      }
    })();
  }, []);

  const handleEditProject = async () => {
    try {
      await editProject(
        parseInt(params.project as string),
        descriptionInputRef.current?.value || '',
        (subjectsRef.current as any).getValue().map((item: SelectableItem) => item.value.toLowerCase()),
        (modulesRef.current as any).getValue().map((item: SelectableItem) => item.value.toLowerCase()),
        promptRef.current?.value || '',
        telegramRef.current?.value || '',
        Number(churnRef.current?.value) || 0
      );
    } catch (err) {
      addToast((err as any).message, ToastType.ERROR, 5000);
      return;
    }

    navigate(CFRoutes.admin_project);
  };

  const handleCancelUpdate = async () => {
    navigate(CFRoutes.admin_project);
  };

  if (!project?.subjects) {
    return <div></div>;
  }

  return (
    <AdminLayout tab={Tabs.Organizations}>
      <div className="edit-project-container">
        <div className="input">
          <CFTitledComponent title={'Description'}>
            <CFInput ref={descriptionInputRef} defaultValue={project?.description} />
          </CFTitledComponent>

          <CFTitledComponent title={'Subjects'}>
            <CFSelectLegacy
              ref={subjectsRef}
              options={subjectTypes.map((subject) => ({
                value: subject,
                label: subject,
                selected: project?.subjects?.find((projectSubject) => projectSubject === subject) !== undefined,
              }))}
              mandatoryValueOptions={[TraitSubject.User]}
              isMulti={true}
              defaultOption={project?.subjects?.map((subject) => ({
                value: subject,
                label: subject,
              }))}
            />
          </CFTitledComponent>
        </div>

        <CFTitledComponent title={'Modules'}>
          <CFSelectLegacy
            ref={modulesRef}
            options={Object.values(Modules).map((module) => ({
              value: module,
              label: module,
              selected: project?.modules?.find((projectModule) => projectModule === module) !== undefined,
            }))}
            mandatoryValueOptions={[Modules.Core]}
            isMulti={true}
            defaultOption={project?.modules?.map((subject) => ({
              value: subject,
              label: subject,
            }))}
          />
        </CFTitledComponent>

        <CFTitledComponent title="Churn definition">
          <CFInput ref={churnRef} defaultValue={project.churn_definition} />
        </CFTitledComponent>

        <CFTitledComponent title="Telegram Bot Token">
          <CFInput ref={telegramRef} defaultValue={project.telegram_bot_token} />
        </CFTitledComponent>

        <CFTitledComponent title="Assistant prompt">
          <CFTextarea ref={promptRef} defaultValue={project.assistant_prompt} />
        </CFTitledComponent>

        <div className="controls">
          <CFButton value={'Cancel'} onClick={handleCancelUpdate} />
          <CFButton value={'Update'} onClick={handleEditProject} role={CFRole.Primary} />
        </div>
      </div>
    </AdminLayout>
  );
};

export default EditProject;
