import React, { useMemo } from 'react';

import classNames from 'classnames';
import { getUserInfo } from 'services/session/session.service';
import { ReactComponent as LlmIcon } from 'views/assistant/images/coloredchat.svg';

import './profile-icon.scss';

export enum IconType {
  System = 'system',
  User = 'user',
}

export enum IconMode {
  Dark = 'dark',
  Light = 'light',
}

interface Props {
  className?: string;
  type: IconType;
  mode?: IconMode;
}

const ProfileIcon = ({ className, mode, type }: Props) => {
  const userName = useMemo(() => {
    const userInfo = getUserInfo();

    return userInfo.name[0];
  }, []);

  return type === IconType.System ? (
    <div className={classNames('profile-icon', IconType.System, className, mode)}>
      <LlmIcon className="profile-icon_image" />
    </div>
  ) : (
    <div className={`profile-icon ${IconType.User}`}>{userName}</div>
  );
};

export default ProfileIcon;
