import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';

import { SubjectId } from 'domain/general.types';

import { useServicesContext } from 'hooks/useServicesContext';

import { InterventionId } from 'services/intervention/intervention.types';
import { IndividualMetricAPI } from 'services/intervention/types.api';

import CFTraitItem from 'connected-components/traits/CFTraitItem';

import CFTable, { Column, ColumnType } from 'components/CFTable';
import CFNavigationArrows from 'components/CFNavigationArrows';
import { Tag, TagTypes } from 'components/Tag';

import CFButtonGroup, { ButtonGroupOption } from 'components/CFButtonGroup';

import './trait-info-per-subject.scss';
import useDecisionsPoints from 'views/intervention/interventions/monitoring/hooks/useDecisionsPoints';

interface Props {
  interventionId: InterventionId;
  subjectId: SubjectId;
}

enum MetricTypes {
  Contextual = 'bandit_ctx',
  Metrics = 'metrics',
}

const metricOptions = [
  { label: 'Metrics', value: MetricTypes.Metrics },
  { label: 'Context', value: MetricTypes.Contextual },
];

const TraitInfoPerSubject = ({ interventionId, subjectId }: Props) => {
  const { interventionService } = useServicesContext();

  const [metrics, setMetrics] = useState<IndividualMetricAPI>();
  const [offset, setOffset] = useState(-1);

  const [metricType, setMetricType] = useState(MetricTypes.Contextual);
  const [loading, setLoading] = useState(true);
  const decisionPoints = useDecisionsPoints(interventionId);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const metrics = await interventionService.getMetricsForSubject(interventionId, [subjectId], offset);

        setLoading(false);
        if (metrics && metrics.length) {
          setMetrics(metrics[0]);
        }
      } catch {
        setMetrics(undefined);
      }
    })();
  }, [offset]);

  const banditData = useMemo(() => {
    if (!metrics) {
      return [];
    }

    return Object.keys(metrics[metricType]).map((ptr) => ({
      ptr,
      value: metrics[metricType][ptr],
    }));
  }, [metrics, metrics?.bandit_ctx, metricType, loading]);

  const headers: Column[] = [
    {
      title: 'Trait',
      type: ColumnType.NUMBER,
      field: '',
      renderCell: (row) => <CFTraitItem addr={row.ptr} />,
    },
    {
      title: 'Value',
      type: ColumnType.NUMBER,
      field: 'value',
      renderCell: (row) => (loading ? '-' : row.value.toFixed(4)),
    },
  ];

  const handleNextPoint = useCallback(() => {
    setOffset((offset) => offset + 1);
  }, []);

  const handlePreviousPoint = useCallback(() => {
    setOffset((offset) => offset - 1);
  }, []);

  const currentDecisionPoint = useMemo(() => {
    return decisionPoints?.slice(offset - 1, offset !== 0 ? offset : undefined)[0];
  }, [decisionPoints, offset]);

  const reward = useMemo(() => {
    if (!metrics || metrics.bandit_reward === undefined || metrics.bandit_reward === null) {
      return '-';
    }

    return parseFloat(metrics.bandit_reward.toFixed(3));
  }, [metrics]);

  const weight = useMemo(() => {
    if (!metrics || metrics.bandit_weight === undefined || metrics.bandit_weight === null) {
      return '-';
    }

    return parseFloat(metrics.bandit_weight.toFixed(3));
  }, [metrics]);

  const selectedMetricOption = useMemo(() => {
    return metricOptions.find((option) => option.value === metricType);
  }, [metricType]);

  const handleSelectTypeOfMetrics = useCallback((option: ButtonGroupOption) => {
    setMetricType(option.value);
  }, []);

  return (
    <div className="trait-info-per-subject">
      <div className="trait-info-per-subject__header">
        <span className="title">{dayjs(currentDecisionPoint).format('DD-MM-YYYY HH:mm:ss')}</span>
        <Tag text={`Reward: ${reward}`} type={TagTypes.Neutral} />
        <Tag text={`Weight: ${weight}`} type={TagTypes.Neutral} />
      </div>
      <div className="trait-value-list">
        {metrics ? (
          <CFTable headers={headers} data={banditData} emptyLabel="No Selected Traits" />
        ) : (
          <div className="warning-message"> No data for current decision point </div>
        )}
      </div>

      <div className="trait-info-per-subject__footer">
        <CFButtonGroup
          options={metricOptions}
          value={selectedMetricOption as ButtonGroupOption}
          onSelect={handleSelectTypeOfMetrics}
        />
        <CFNavigationArrows
          index={(decisionPoints?.length || 0) + offset - 1}
          windowSize={1}
          total={decisionPoints?.length || 0}
          onNext={handleNextPoint}
          onPrevious={handlePreviousPoint}
        />
      </div>
    </div>
  );
};

export default TraitInfoPerSubject;
