import React, { useCallback, useRef, useState } from 'react';

import CFInput from 'components/CFInput';
import CFButton from 'components/buttons/CFButton';

import { CFRole } from 'domain/general.types';

import CFSelect, { Option } from 'components/CFSelect';
import CFTitledComponent from 'components/CFTitledComponent';

import { NodeName, SubGraph } from 'services/markov/markov.types';

import './node-editor.scss';

export interface EdgeUpdate {
  from: string;
  to: string;
  factor: number;
}

interface Props {
  name: NodeName;
  values: SubGraph;
  onChange: (update: EdgeUpdate) => void;
}

const NodeEditor = ({ name, values, onChange }: Props) => {
  const scaleRef = useRef<HTMLInputElement>();

  const [selectedNode, setSelectedNode] = useState(Object.keys(values)[0]);
  const [nodeAlreadySelected, setNodeAlreadySelected] = useState(false);

  const handleOnClick = useCallback(() => {
    if (!scaleRef || !scaleRef.current) {
      return;
    }

    onChange({
      from: name,
      to: selectedNode,
      factor: parseFloat(scaleRef.current?.value),
    });
  }, [selectedNode, scaleRef, scaleRef.current]);

  const handleSelectNode = useCallback((nodeOption: Option) => {
    setSelectedNode(nodeOption.value);
    setNodeAlreadySelected(true);
  }, []);

  const nodes = Object.keys(values);

  return (
    <div className="node-editor">
      <div className="node-editor__header"> Editing node: {name}</div>

      <div className="node-editor__controls">
        <CFTitledComponent title="Edge">
          <CFSelect
            options={nodes.map((node) => ({ label: node, value: node, disabled: nodeAlreadySelected }))}
            value={{ value: selectedNode, label: selectedNode }}
            onSelected={handleSelectNode}
          />
        </CFTitledComponent>

        <CFTitledComponent title="Scale by">
          <CFInput ref={(el) => (scaleRef.current = el as HTMLInputElement)} />
        </CFTitledComponent>
      </div>

      <div className="node-editor__footer">
        <CFButton onClick={handleOnClick} value="Accept" role={CFRole.Primary} />
      </div>
    </div>
  );
};

export default NodeEditor;
