import { FilterAPI, FilterGroupOperation } from 'services/cohort/cohort.types.api';

import { Operators, TimeRFC3999 } from 'domain/general.types';
import { oneWeekAgo, pruneHours, today } from 'helpers/dates';
import CFService from 'services/cfservice';
import TraitService from 'services/traits/traitSession.service';
import FilterSet from 'services/cohort/domain/FilterSet';
import Filter from 'services/cohort/domain/Filter';

type RoleCode = string; // role_<filters>_start_end
type RoleValue = {
  cur: number;
  prev: number;
};

class UserEngagementService extends CFService {
  private cachedRoleData: Record<RoleCode, RoleValue> = {};

  private initPromise: Promise<void> | null = null;

  private traitService: TraitService;
  private countryFullName = 'ct_user.country';
  private organizationNameFullName = 'ct_user.organization_name';
  private stateFullName = 'ct_user.region_state';
  private roleFullName = 'ct_user_chw.role';
  private dauFullName = 'gdt_user_general.active_user_count#1d';

  private cities: string[] = [];
  private countries: string[] = [];
  private states: string[] = [];
  private roles: string[] = [];

  private _country = '';
  private _state = '';
  private _organizationName = '';

  private _startDate = pruneHours(oneWeekAgo());
  private _endDate = pruneHours(today());

  private callbacks: Array<() => void> = [];

  constructor(traitService: TraitService) {
    super();

    this.traitService = traitService;
  }

  init() {
    if (this.initPromise) {
      return this.initPromise;
    }

    this.initPromise = new Promise<void>((resolve) => {
      this.initializeFilters()
        .then(() => {
          this.initializeData(); // wait???
          resolve();
        })
        .catch(() => {
          console.log('Impossible to initialize filters');
        });
    });

    return this.initPromise;
  }

  private async initializeFilters() {
    const valuePromises = [this.countryFullName, this.organizationNameFullName, this.stateFullName, this.roleFullName]
      .map((traitFullName) => this.traitService.getTraitDefinition(traitFullName))
      .map((trait) => this.traitService.getUniqueValuesForTrait(trait.addr));

    const [countryValues, orgNameValues, stateValues, roleValues] = await Promise.all(valuePromises);

    this.cities = orgNameValues;
    this.countries = countryValues;
    this.states = stateValues;
    this.roles = roleValues;

    this._country = this.countries[0] || '';
    this._organizationName = this._organizationName[0] || '';
    this._state = this.states[0] || '';
  }

  private async initializeData() {
    await this.initPromise;

    const defaultKPIRolesPromises = this.roles.map((role) => this.getKPIForRole(role));

    const defaultKPIRoles = await Promise.all(defaultKPIRolesPromises);

    defaultKPIRoles.forEach((kpi, i) => (this.cachedRoleData[this.cachedCodeForRole(this.roles[i])] = kpi));

    const globalKPI = await this.getGlobalKPI();

    this.cachedRoleData[this.cachedCodeForRole('global')] = globalKPI;
  }

  private async getLeaf() {
    const items = [
      { name: this.countryFullName, value: this._country },
      { name: this.organizationNameFullName, value: this._organizationName },
      { name: this.stateFullName, value: this._state },
    ];

    const leaf = {
      op: FilterGroupOperation.And,
      filters: items
        .filter((item) => item.value !== '')
        .map((item) => {
          const filter = new Filter({} as FilterAPI);

          filter.op = Operators.Equal;
          filter.val = item.value;
          filter.ptr = item.name;

          return filter;
        }),
    };

    return leaf;
  }

  private cachedCodeForRole(role: string) {
    return `${role}-${this._country}##${this._organizationName}##${this._state}##${this._startDate}##${this._endDate}`;
  }

  async getCountries(): Promise<string[]> {
    await this.initPromise;
    return this.countries;
  }

  async getCities(): Promise<string[]> {
    await this.initPromise;

    return this.cities;
  }

  async getStates(): Promise<string[]> {
    await this.initPromise;

    return this.states;
  }

  async getRoles(): Promise<string[]> {
    await this.initPromise;

    return this.roles;
  }

  public set country(country: string) {
    this._country = country;
    this.notify();
  }

  public set organizationName(org: string) {
    this._organizationName = org;
    this.notify();
  }

  public set state(state: string) {
    this._state = state;
    this.notify();
  }

  public set startDate(date: TimeRFC3999) {
    this._startDate = date;
    this.notify();
  }

  public set endDate(date: TimeRFC3999) {
    this._endDate = date;
    this.notify();
  }

  async getGlobalKPI() {
    await this.initPromise;

    const cachedCode = this.cachedCodeForRole('global');
    if (this.cachedRoleData[cachedCode]) {
      return this.cachedRoleData[cachedCode];
    }
    const leaf = await this.getLeaf();
    const filterSet = new FilterSet({ op: FilterGroupOperation.And, nodes: [{ leaf: leaf }] });

    try {
      const globalGDT = await this.traitService.getGDTPoint(
        this.dauFullName,
        filterSet,
        this._startDate,
        this._endDate
      );
      this.cachedRoleData[cachedCode] = globalGDT;
      return globalGDT;
    } catch {
      return { cur: 0, prev: 0 };
    }
  }

  async getKPIForRole(role: string) {
    await this.initPromise;

    const cachedCode = this.cachedCodeForRole(role);

    if (this.cachedRoleData[cachedCode]) {
      return this.cachedRoleData[cachedCode];
    }

    const roleTrait = this.traitService.getTraitDefinition(this.roleFullName);
    const currentLeaf = await this.getLeaf();

    const filter = new Filter({} as FilterAPI);
    filter.op = Operators.Equal;
    filter.ptr = roleTrait.addr.ptr;
    filter.val = role;

    const tree = new FilterSet({
      op: FilterGroupOperation.And,
      nodes: [
        {
          leaf: {
            op: FilterGroupOperation.And,
            filters: [...currentLeaf.filters, filter],
          },
        },
      ],
    });

    try {
      const gdtPoint = await this.traitService.getGDTPoint(this.dauFullName, tree, this._startDate, this._endDate);
      this.cachedRoleData[cachedCode] = gdtPoint;
      return gdtPoint;
    } catch {
      return { cur: 0, prev: 0 };
    }
  }

  subscribe(callback: () => void) {
    this.callbacks.push(callback);
  }

  notify() {
    this.callbacks.forEach((cb) => cb());
  }
}

export default UserEngagementService;
