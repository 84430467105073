import React, { FC } from 'react';
import { ToastColor, ToastProps, ToastType } from './types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faExclamationCircle, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import './cf-toast.scss';

const CFToast: FC<ToastProps> = ({ state = ToastType.SUCCESS, message, onClose }) => {
  return (
    <div className={classNames('cf-toast', state)}>
      <div className="cf-toast-icon">
        <FontAwesomeIcon
          icon={state === ToastType.SUCCESS ? faSquareCheck : faExclamationCircle}
          size="xl"
          color={ToastColor[state]}
        />
      </div>
      <div className="cf-toast-content">{message}</div>
      <div className="cf-toast-close">
        <FontAwesomeIcon icon={faClose} size="xl" onClick={onClose} style={{ cursor: 'pointer' }} />
      </div>
    </div>
  );
};
export default CFToast;
