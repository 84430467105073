import { EventRiskGroup, ScatterPlot } from 'domain/analytic.types';
import colors from 'common.scss';
import { SplitConfig } from 'components/charts/CFScatterPlotChart';

const eventRiskColors = {
  [EventRiskGroup.High]: {
    labelColor: colors.cfCyan,
    color: colors.cfCyan20,
  },
  [EventRiskGroup.Medium]: {
    labelColor: colors.cfCyan20,
    color: colors.cfCyan40,
  },
  [EventRiskGroup.Low]: {
    labelColor: colors.cfCyan40,
    color: colors.cfCyan60,
  },
};

export const buildRiskConfig = (predictionData: ScatterPlot): SplitConfig[] => {
  const splitsConfig = [EventRiskGroup.Low, EventRiskGroup.Medium, EventRiskGroup.High].map((groupName) => ({
    label: groupName,
    starts: predictionData.event_risk_group_range[groupName as EventRiskGroup][0],
    color: eventRiskColors[groupName as EventRiskGroup].color,
    labelColor: eventRiskColors[groupName as EventRiskGroup].labelColor,
  }));

  return splitsConfig;
};
