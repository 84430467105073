import React, { FC, useEffect, useMemo, useRef, useState } from 'react';

import colors from 'common.scss';
import {
  DataItem,
  DataSeries,
  LineStyle,
  tooltip,
  tooltipWithConfidenceIntervalFormatter,
  X_AXIS_DATE_FORMAT,
} from '../chartCommon';
import { AggLevel } from 'domain/stats.types';
import dayjs from 'dayjs';

import { EChartsOption, ReactECharts } from '..';

import './cf-line-chart-with-confidence-interval.scss';
import { getInstanceByDom } from 'echarts/core';

interface Props {
  xLabel?: string;
  yLabel?: string;
  title?: string;
  data: DataSeries[];
  isLoading: boolean;
}

const CFLineChartWithConfidenceInterval: FC<Props> = ({ xLabel, yLabel, title, data, isLoading }) => {
  const [selectedSeries, setSelectedSeries] = useState<Record<string, boolean>>({});

  const chartRef = useRef<HTMLDivElement>(null);

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const options = useMemo(() => {
    const series: echarts.SeriesOption | echarts.SeriesOption[] | undefined = [];

    // in a previous version this calculation had a specific useEffect
    // but we found a deadlock problem. The value was not always updated and this
    // useEffect received Infinity
    const base = data.reduce((minValue: number, series: DataSeries) => {
      const minLowerInSeries = series.data.reduce((minValueInner: number, item: DataItem) => {
        if (item.value === null) {
          return minValueInner;
        }
        return Math.floor(Math.min(minValueInner, item.lower ?? item.value));
      }, minValue);
      return Math.min(minValue, minLowerInSeries);
    }, Infinity);

    data.forEach((seriesItem, index) => {
      const color = getRandomColor();
      series.push(
        {
          name: `${seriesItem.name} Lower`,
          type: 'line',
          data: seriesItem.data.map((item) => [
            item.time,
            item.value !== undefined && item.value !== null
              ? (item.lower ?? item.value) - base
              : (null as unknown as number),
          ]),
          lineStyle: { opacity: 0 },
          stack: `confidence-band-${index + 1}`,
          symbol: 'none',
          animation: false,
        },
        {
          name: `${seriesItem.name} Upper-Lower`,
          type: 'line',
          data: seriesItem.data.map((item) => [
            item.time,
            item.upper && item.lower ? item.upper - item.lower : (null as unknown as number),
          ]),
          lineStyle: { opacity: 0 },
          areaStyle: { color: seriesItem.color ?? color, opacity: 0.1 },
          stack: `confidence-band-${index + 1}`,
          symbol: 'none',
          animation: false,
        },
        {
          name: seriesItem.name,
          type: 'line',
          data: seriesItem.data.map((item) => [
            item.time,
            item.value !== undefined && item.value !== null ? item.value - base : (null as unknown as number),
          ]),
          color: seriesItem.color ?? color,
          lineStyle: { type: seriesItem.lineStyle ?? LineStyle.SOLID },
          //symbol: Array.from(new Set(seriesItem.data.map((item) => item.time))).length < 2 ? 'circle' : 'none',
          symbolSize: 5,
          animation: false,
          showSymbol: true,
          symbol: 'circle',
        }
      );
    });

    const option = {
      dataZoom: {
        filterMode: 'none',
        type: 'inside',
        xAxisIndex: [0],
        start: 0,
        end: 100,
      },
      tooltip: {
        ...tooltip(),
        formatter: (params: any) => tooltipWithConfidenceIntervalFormatter(params, data),
      },
      grid: {
        top: series.length < 4 * 3 ? '15%' : '30%', // 1 series -> 3 elements in the array due confidence intervals
      },
      xAxis: {
        type: 'time',
        name: xLabel,
        nameLocation: 'middle',
        nameGap: 30,
        nameTextStyle: {
          color: colors.dark50,
          fontSize: 14,
          fontWeight: 600,
        },
        axisLine: {
          lineStyle: {
            color: colors.dark50,
          },
        },
        axisLabel: {
          formatter: function (value: any) {
            return dayjs(value).format(X_AXIS_DATE_FORMAT[AggLevel.Hour]);
          },
        },
      },
      legend: {
        data: data.flatMap((item) => ({ name: item.name, icon: 'circle' })),
        right: '150px',
        selected: data.reduce((acc, item) => {
          return {
            ...acc,
            // comparison with undefined to take into account initial state (when selectedSeries object is empty)
            [`${item.name}`]: selectedSeries[item.name] === undefined || selectedSeries[item.name],
            [`${item.name} Upper-Lower`]: selectedSeries[item.name] === undefined || selectedSeries[item.name],
            [`${item.name} Lower`]: true,
          };
        }, {}),
        textStyle: {
          color: '#fff',
          fontSize: 14,
        },
        inactiveColor: '#777',
      },
      yAxis: {
        type: 'value',
        name: yLabel,
        splitLine: {
          lineStyle: {
            color: colors.dark50,
          },
        },
        axisLabel: {
          color: colors.dark50,
          formatter: (val: number) => {
            return parseFloat((val + base).toFixed(2));
          },
        },
        offset: 20,
        nameLocation: 'middle',
        nameGap: 70,
        nameTextStyle: {
          color: colors.dark50,
          fontWeight: 600,
          fontSize: 14,
        },
      },
      series: series,
      visualMap: [
        {
          show: false,
          type: 'continuous',
          seriesIndex: series.map((_, index) => index),
          dimension: 0,
          min: 0,
          max: data.length - 1,
          inRange: { colorAlpha: [1, 1] },
        },
      ],
    };

    return option;
  }, [data, xLabel, yLabel, title, selectedSeries]);

  useEffect(() => {
    // Update chart
    if ((chartRef as any).current !== null) {
      const chart = getInstanceByDom((chartRef as any).current);
      chart?.on('legendselectchanged', (data: any) => {
        setSelectedSeries(data.selected);
      });
    }
  }, [chartRef, options]); // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function

  function getTitle() {
    if (!title) {
      return '';
    }

    return `${title[0].toUpperCase()}${title.slice(1)}`;
  }

  return (
    <div className="cf-line-chart-confidence-interval">
      <span className="chart-title"> {getTitle()} </span>

      <ReactECharts
        ref={chartRef}
        option={options as EChartsOption}
        style={{ width: `100%`, height: '330px' }}
        loading={isLoading}
      />
    </div>
  );
};

export default CFLineChartWithConfidenceInterval;
