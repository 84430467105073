import React from 'react';

import CFTitledSection from 'components/CFTitledSection';
import { titleToSectionId } from '../helper';

import './intervention-section.scss';

interface Props {
  children: JSX.Element | JSX.Element[];
  name: string;
  title: string;
  subtitle?: string;
  className?: string;
}

const InterventionSection = ({ children, name, title, subtitle, className }: Props) => {
  return (
    <div data-type="intervention-section">
      <CFTitledSection
        title={title}
        id={titleToSectionId(name)}
        subtitle={subtitle}
        className={`${className} intervention-section`}
      >
        {children}
      </CFTitledSection>
    </div>
  );
};

export default InterventionSection;
