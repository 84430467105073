import React, { CSSProperties } from 'react';
import classNames from 'classnames';

import './status-tag.scss';

export enum StatusTagVariant {
  Success,
  InProgress,
  Paused,
  Failed,
  Pending,
  Terminated,
}

const colorVairantMap = {
  [StatusTagVariant.InProgress]: {
    textColor: '#b0f9e8',
    backgroundColor: '#304d42',
    dotColor: '#13c692',
  },
  [StatusTagVariant.Pending]: {
    textColor: '#333333',
    backgroundColor: '#FEDE6E',
    dotColor: '#FBBC05',
  },

  [StatusTagVariant.Failed]: {
    textColor: '#f1e6e5',
    backgroundColor: '#791929',
    dotColor: '#d53f47',
  },

  [StatusTagVariant.Paused]: {
    textColor: '#d0d0d1',
    backgroundColor: '#39393f',
    dotColor: '#6f6f74',
  },

  [StatusTagVariant.Success]: {
    textColor: '#d0d0d1',
    backgroundColor: '#4441F4',
    dotColor: '#1611F1',
  },
  [StatusTagVariant.Terminated]: {
    textColor: '#d0d0d1',
    backgroundColor: '#8431A8',
    dotColor: '#B300FF',
  },
};

interface Props {
  label: string;
  variant: StatusTagVariant;
  className?: string;
}

const StatusTag = ({ label, variant, className }: Props): JSX.Element => {
  const styles = {
    '--text-color': colorVairantMap[variant]?.textColor || colorVairantMap[StatusTagVariant.Failed].textColor,
    '--background-color':
      colorVairantMap[variant]?.backgroundColor || colorVairantMap[StatusTagVariant.Failed].backgroundColor,
    '--dot-color': colorVairantMap[variant]?.dotColor || colorVairantMap[StatusTagVariant.Failed].dotColor,
  } as CSSProperties;

  return (
    <div className={classNames('status-tag', className)} style={styles}>
      {label || 'unknown'}
    </div>
  );
};

export default StatusTag;
