import React, { FC } from 'react';

import dayjs from 'dayjs';

import './week-picker.scss';

interface WeekPickerProps {
  date: Date;
  nextDisabled: boolean;
  prevDisabled: boolean;
  onClickNext: () => void;
  onClickPrevious: () => void;
}

const WeekPicker: FC<WeekPickerProps> = ({ date, nextDisabled, prevDisabled, onClickNext, onClickPrevious }) => {
  const startOfWeek = dayjs(date).startOf('week');
  const endOfWeek = dayjs(date).endOf('week');
  const today = dayjs(Date.now());
  const isCurrentWeek = today.isAfter(startOfWeek) && today.isBefore(endOfWeek);

  return (
    <div className="week-picker-container">
      <button
        className="week-picker-icon prev"
        disabled={prevDisabled}
        onClick={onClickPrevious}
        data-testid="week-picker-prev"
      />
      <span className="week-picker-text">
        {isCurrentWeek ? (
          <span>This week</span>
        ) : (
          <>
            <span>{startOfWeek.format('DD MMMM')}</span>
            <span>-</span>
            <span>{endOfWeek.format('DD MMMM')}</span>
          </>
        )}
      </span>
      <button
        className="week-picker-icon next"
        disabled={nextDisabled}
        onClick={onClickNext}
        data-testid="week-picker-next"
      />
    </div>
  );
};

export default WeekPicker;
