import React from 'react';

import classNames from 'classnames';
import { ReactComponent as LlmIcon } from 'views/assistant/images/coloredchat.svg';

import './assistant-splash.scss';

interface Props {
  embedded?: boolean;
}

const AssistantSplash = ({ embedded = false }: Props) => {
  return (
    <div className={'assistant-splash'}>
      <LlmIcon />
      <div className={classNames('title', { embedded: embedded })}>
        Unlock valuable insights through predictive analysis
      </div>
      <div className={classNames('subtitle', { embedded: embedded })}>
        Begin by posing a question, and our assistant will provide you with accurate predictions based on the available
        data
      </div>
    </div>
  );
};

export default AssistantSplash;
