export const findMaxCeiledValue = (maxValue: number) => {
  for (let i = 0; i < 10; i++) {
    if (10 ** i * maxValue > 1) {
      const max = Math.ceil(maxValue * 10 ** i) / 10 ** i;

      return max;
    }
  }

  return maxValue;
};

export const findMaxRoundedValue = (maxValue: number, decimals = 2) => {
  if (maxValue > 1) {
    return parseFloat(maxValue.toFixed(decimals));
  } else {
    return parseFloat(maxValue.toPrecision(decimals));
  }
};
