import React, { useEffect, useState } from 'react';

import CFTable, { Column, ColumnType } from 'components/CFTable';
import { useServicesContext } from 'hooks/useServicesContext';
import { BackfillStatusItem } from 'services/traits/backfill.types.api';

import TraitsLayout from '../layout';
import { Tabs } from '../tabs';
import ReferenceDetail from './components/ReferenceDetail';

const BackfillStatus = () => {
  const { backfillService } = useServicesContext();
  const [backfills, setBackfills] = useState<BackfillStatusItem[]>([]);

  useEffect(() => {
    (async () => {
      const backfills = await backfillService.list();

      setBackfills(backfills);
    })();
  }, []);

  const headers: Column[] = [
    {
      title: 'Type',
      type: ColumnType.STRING,
      field: '',
      renderCell: (row) => row.run_ref.split('-')[0],
    },
    {
      title: 'Date',
      type: ColumnType.NUMBER,
      field: '',
      renderCell: (row) => row.run_ref.split('-')[1],
    },
    {
      title: 'Start',
      type: ColumnType.STRING,
      field: 'req.start',
    },
    {
      title: 'End',
      type: ColumnType.STRING,
      field: 'req.end',
    },
    {
      title: '',
      type: ColumnType.STRING,
      field: 'expanded',
      expandable: true,
      renderCell: (row) => {
        return <ReferenceDetail backfillRef={row.run_ref} />;
      },
      style: {
        width: '15px',
      },
    },
  ];
  return (
    <TraitsLayout className="trait-definitions" tab={Tabs.BackfillStatus}>
      <CFTable headers={headers} data={backfills} indexCol="run_ref" />
    </TraitsLayout>
  );
};

export default BackfillStatus;
