import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getAuthToken, setAuthToken } from 'services/session/session.service';

const STATUS_CODE_AUTH_ERROR = 401;

const TIMEOUT = 300 * 1000;

export const CF_AUTH_TOKEN_NAME = 'cftoken';

let authErrorHandler: () => void;
let timeout = TIMEOUT;

export enum HTTPMethod {
  PUT = 'PUT',
  PATCH = 'PATCH',
  POST = 'POST',
  GET = 'GET',
  DELETE = 'DELETE',
}

export const init = (authErrorHandlerInit: () => void, _timeout = TIMEOUT) => {
  authErrorHandler = authErrorHandlerInit;
  timeout = _timeout;
};

const checkRefreshToken = (response: AxiosResponse) => {
  if (response.headers[CF_AUTH_TOKEN_NAME]) {
    setAuthToken(response.headers[CF_AUTH_TOKEN_NAME]);
  }
};

const handleError = (err: any) => {
  if (axios.isAxiosError(err)) {
    if (err.response?.status === STATUS_CODE_AUTH_ERROR) {
      authErrorHandler();
    } else {
      throw err;
    }
  } else {
    throw err;
  }
};

function newAbortSignal(timeoutMs: number) {
  const abortController = new AbortController();
  setTimeout(() => abortController.abort(), timeoutMs || 0);

  return abortController.signal;
}

export const get = async (
  url: string,
  config: AxiosRequestConfig<any> | undefined
): Promise<AxiosResponse<any, any> | undefined> => {
  try {
    const extraConfig = { ...config, headers: { [CF_AUTH_TOKEN_NAME]: getAuthToken() } };
    const response = await axios.get(url, { ...extraConfig, signal: newAbortSignal(timeout) });

    checkRefreshToken(response);
    return response;
  } catch (err: any) {
    handleError(err);
  }
};

export const post = async (url: string, body: any, config: AxiosRequestConfig<any> | undefined) => {
  try {
    const extraConfig = { ...config, headers: { [CF_AUTH_TOKEN_NAME]: getAuthToken() } };
    const response = await axios.post(url, body, { ...extraConfig, signal: newAbortSignal(timeout) });
    checkRefreshToken(response);
    return response;
  } catch (err: any) {
    handleError(err);
  }
};

export const streamPost = async (url: string, body: any, onProgress: (text: string) => void) => {
  fetch(url, {
    method: 'POST',
    headers: {
      cftoken: getAuthToken() as string,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (!response.body) {
        throw new Error('No se recibió ningún stream');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');

      const readStream = () => {
        reader
          .read()
          .then(({ done, value }) => {
            if (done) {
              return;
            }

            const chunk = decoder.decode(value, { stream: true });
            onProgress(chunk);

            readStream();
          })
          .catch((error) => {
            console.error('Error al leer el stream:', error);
          });
      };

      readStream();
    })
    .catch((error) => {
      console.error('Error en la petición:', error);
    });
};

export const remove = async (url: string, config: AxiosRequestConfig<any> | undefined) => {
  try {
    const extraConfig = { ...config, headers: { [CF_AUTH_TOKEN_NAME]: getAuthToken() } };
    const response = await axios.delete(url, { ...extraConfig, signal: newAbortSignal(timeout) });
    checkRefreshToken(response);
    return response;
  } catch (err: any) {
    handleError(err);
  }
};

export const put = async (url: string, body: any, config: AxiosRequestConfig<any> | undefined) => {
  try {
    const extraConfig = { ...config, headers: { [CF_AUTH_TOKEN_NAME]: getAuthToken() } };
    const response = await axios.put(url, body, { ...extraConfig, signal: newAbortSignal(timeout) });
    checkRefreshToken(response);
    return response;
  } catch (err: any) {
    handleError(err);
  }
};

export const request = async (
  method: HTTPMethod,
  url: string,
  data: any,
  config: AxiosRequestConfig<any> | undefined
) => {
  const headers = {
    'Content-Type': 'application/json',
    [CF_AUTH_TOKEN_NAME]: getAuthToken(),
  };
  const extraConfig = { ...config, headers, signal: newAbortSignal(timeout) };

  const response = await axios.request({
    method: method,
    url: url, // URL de la solicitud
    ...extraConfig,
    data: data,
  });

  return response;
};
