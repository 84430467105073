import { ApiKey, Scopes } from '../domain/apikey.types';
import { RepoConfig } from './types';

import { AxiosResponse } from 'axios';
import { get as httpGet, post as httpPost, remove as httpRemove } from './drivers/http';

const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
const path = '/v1/apikey';

const repoConfig = {
  token: '',
  oid: -1,
  pid: -1,
};

export const init = ({ token, oid, pid }: RepoConfig) => {
  repoConfig.token = token;
  repoConfig.oid = oid;
  repoConfig.pid = pid;
};

export const create = async (scopes: Scopes[]) => {
  const config = {};

  const body = {
    scopes: scopes,
  };

  try {
    const keyResponse = (await httpPost(
      `${serverBaseUrl}${path}/generate?oid=${repoConfig.oid}&pid=${repoConfig.pid}`,
      body,
      config
    )) as AxiosResponse;
    return keyResponse.data;
  } catch (err) {
    console.error('error creating project: ', err);
    throw new Error('error-creating-apikey');
  }
};

export const remove = async (key: string) => {
  const config = {};

  try {
    await httpRemove(`${serverBaseUrl}${path}/revoke?key=${key}&oid=${repoConfig.oid}&pid=${repoConfig.pid}`, config);
  } catch (err) {
    console.error('error removing key: ');
    throw new Error('error-removing-apikey');
  }
};

export const getMine = async (): Promise<ApiKey[]> => {
  const config = {};

  try {
    const apikeysResponse = (await httpGet(
      `${serverBaseUrl}${path}/list/mine?oid=${repoConfig.oid}&pid=${repoConfig.pid}`,
      config
    )) as AxiosResponse;
    return apikeysResponse.data || [];
  } catch (err) {
    console.error('error creating project: ', err);
    throw new Error('error-getting-apikeys');
  }
};
