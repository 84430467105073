import React, { FC } from 'react';

import dayjs from 'dayjs';

import './header.scss';

interface HeaderProps {
  date: Date;
  setDate: (date: Date) => void;
  nextDisabled: boolean;
  prevDisabled: boolean;
  onClickNext: () => void;
  onClickPrevious: () => void;
}

const Header: FC<HeaderProps> = ({ date, nextDisabled, prevDisabled, onClickNext, onClickPrevious }) => {
  return (
    <div className="header-container">
      <button className="header-icon prev" disabled={prevDisabled} onClick={onClickPrevious} />
      <span className="header-text">
        {dayjs(date).format('MMMM')} {date.getFullYear()}
      </span>
      <button className="header-icon next" disabled={nextDisabled} onClick={onClickNext} />
    </div>
  );
};

export default Header;
