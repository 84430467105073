import React from 'react';

import dayjs from 'dayjs';
import { AssistantMessage } from 'services/assistant/assistant.types';

interface Props {
  message: AssistantMessage;
}

const Timestamp = ({ message }: Props) => {
  const messageDate = dayjs(message.timestamp);
  const daysAgo = dayjs(new Date()).diff(messageDate, 'day');

  if (isNaN(daysAgo)) {
    return <span></span>;
  }

  if (daysAgo < 0) {
    return <span>{messageDate.format('YYYY-MM-DD HH:mm')}</span>;
  }

  if (daysAgo > 3) {
    return <span>{daysAgo} days ago</span>;
  } else if (daysAgo >= 1) {
    return <span>{messageDate.format('YYYY-MM-DD HH:mm')}</span>;
  } else {
    return <span>{messageDate.format('HH:mm')}</span>;
  }
};

export default Timestamp;
