import React, { FC, useCallback } from 'react';

import CFPortal from 'components/CFPortal';
import CFCheckbox from 'components/CFCheckbox';

import CFLink from 'connected-components/CFLink';

import { setIncognitoMode } from 'services/session/session.service';
import { AuthAction } from 'services/authorization.service';

import useIncognito from '../hooks/useIncognito';

import './user-menu.scss';
import ProtectedElement from 'connected-components/ProtectedElement';

interface Option {
  to: string;
  label: string;
}

interface Props {
  options: Option[];
  user: {
    fullname: string;
    username: string;
  };
  top: number;
  left: number;
  logout: () => void;
  cancel: () => void;
}

const UserMenu: FC<Props> = ({ options, user, top, left, logout, cancel }: Props) => {
  const incognito = useIncognito();

  const handleIncognito = useCallback((incognito: boolean) => {
    setIncognitoMode(incognito);
  }, []);

  return (
    <CFPortal onClickOutside={cancel} backdrop={false}>
      <div className="user-menu" style={{ left: left - 175, top: top + 70 }}>
        <div className="menu-header">
          <div className="user-avatar">{user.fullname.charAt(0)}</div>
          <div>
            <div className="user-fullname">{user.fullname}</div>
            <div className="user-username">{user.username}</div>
          </div>
        </div>

        {options.map(({ to, label }) => (
          <CFLink onClick={cancel} className="menu-item" key={to} to={to}>
            <div>{label}</div>
          </CFLink>
        ))}

        <ProtectedElement authAction={AuthAction.SetIncognitoMode}>
          <div className="menu-item">
            <CFCheckbox label="Incognito" onChange={handleIncognito} checked={incognito} />
          </div>
        </ProtectedElement>

        <div onClick={logout} className="menu-item">
          Logout
        </div>
      </div>
    </CFPortal>
  );
};

export default UserMenu;
