import React, { FC, useMemo } from 'react';
import dayjs from 'dayjs';

import Day from '../Day';

import { NavigationAction } from 'components/DateTime/DatetimeRangePicker/types';
import Header from 'components/DateTime/DatetimeRangePicker/Header';

import { chunks } from 'helpers/misc';
import { getDaysInMonth, isBetween } from 'helpers/dates';

import { TimeRFC3999 } from 'domain/general.types';

import './month.scss';

const WEEK_DAYS: string[] = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sat'];

interface MonthProps {
  value: Date;
  date: Date;
  minDate: Date;
  maxDate: Date;
  timestamps?: TimeRFC3999[];
  setValue: (date: Date) => void;
  helpers: {
    isHover: (day: Date) => boolean;
  };
  handlers: {
    onDayClick: (day: Date) => void;
    onDayHover: (day: Date) => void;
    onMonthNavigate: (action: NavigationAction) => void;
  };
}

const Month: FC<MonthProps> = ({ helpers, handlers, value, date, setValue, minDate, maxDate, timestamps = [] }) => {
  const timestampsByDay: Record<string, string[]> = useMemo(
    () =>
      timestamps.reduce((acc, curr) => {
        const [date, hour] = curr.split('T');

        if (!acc[date]) {
          acc[date] = [];
        }

        acc[date].push(hour.slice(0, -4)); // 11:22:33Z

        return acc;
      }, {} as Record<string, string[]>),
    [timestamps]
  );

  return (
    <div className="date-picker-month-root">
      <Header
        date={value}
        setDate={setValue}
        nextDisabled={false}
        prevDisabled={false}
        onClickPrevious={() => handlers.onMonthNavigate(NavigationAction.Previous)}
        onClickNext={() => handlers.onMonthNavigate(NavigationAction.Next)}
      />

      <div className="month-week-days-container">
        {WEEK_DAYS.map((day) => (
          <span key={day} className="month-week-day">
            {day}
          </span>
        ))}
      </div>

      <div className="month-days-container">
        {chunks(getDaysInMonth(value), 7).map((week, index) => (
          <div key={index} className="month-week">
            {week.map((day) => {
              const isSelected = dayjs(date).isSame(day, 'day');
              const highlighted = helpers.isHover(day);
              const hasEvents = timestampsByDay[dayjs(day).format('YYYY-MM-DD')] !== undefined;

              return (
                <Day
                  key={dayjs(day).format('YYYY-MM-DD')}
                  filled={isSelected}
                  outlined={!highlighted && dayjs().isSame(day, 'day')}
                  highlighted={highlighted}
                  hasEvents={hasEvents}
                  isCurrentMonth={dayjs(day).isSame(value, 'month')}
                  disabled={!isBetween(day, minDate, maxDate)}
                  onClick={() => handlers.onDayClick(day)}
                  onHover={() => handlers.onDayHover(day)}
                  value={day}
                />
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Month;
