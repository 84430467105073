import classNames from 'classnames';
import React, { useCallback } from 'react';
import { HintMessage } from 'services/assistant/assistant.types';

import './message-hint.scss';

interface Props {
  message: HintMessage;
  disabled: boolean;
  onClick: () => void;
}

const MessageHint = ({ disabled, message, onClick }: Props) => {
  const handleClick = useCallback(() => {
    if (disabled) {
      return;
    }

    onClick();
  }, [disabled]);

  return (
    <div className={classNames('message-hint', { disabled: disabled })} onClick={handleClick}>
      <div className="message-hint__title">{message.title}</div>
      <div className="message-hint__text">{message.text}</div>
    </div>
  );
};

export default MessageHint;
