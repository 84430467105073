import { CFRoutes } from 'routes';

export enum Tabs {
  Traits = 'traits',
  Cohorts = 'cohorts',
}

export const segmentationByTab: Record<string, CFRoutes> = {
  [Tabs.Traits]: CFRoutes.segmentation_trait,
  [Tabs.Cohorts]: CFRoutes.segmentation_cohort,
};
