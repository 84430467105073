import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import CFButton from 'components/buttons/CFButton';
import CFPortal, { ContentPosition } from 'components/CFPortal';

import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CFRole } from 'domain/general.types';

import './cf-confirmable-button.scss';

interface Props {
  children: ReactElement;
  question?: string;
  title?: string;
}

const CFConfirmableButton = ({ children, question, title }: Props) => {
  const [confirming, setConfirming] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const cancelButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (confirming && cancelButtonRef.current) {
      cancelButtonRef.current.focus();
    }
  }, [confirming]);

  const handleClickOnButton = useCallback((evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation();
    setConfirming(true);
  }, []);

  const handleCancelConfirmation = useCallback((evt?: React.MouseEvent<HTMLButtonElement>) => {
    evt?.stopPropagation();
    setConfirming(false);
  }, []);

  const handleAcceptConfirmation = useCallback(
    async (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setIsLoading(true);
      await children.props?.onClick(evt);
      setIsLoading(false);
      setConfirming(false);
    },
    [children.props?.onClick]
  );

  const childButton = useMemo(() => {
    return React.cloneElement(children, {
      onClick: handleClickOnButton,
    });
  }, [children, handleClickOnButton]);

  return (
    <React.Fragment>
      {childButton}

      {confirming && (
        <CFPortal onClickOutside={handleCancelConfirmation} mode={ContentPosition.Center}>
          <div className="confirmation-panel">
            <div className="logo">
              <div className="logo-wrapper">
                <FontAwesomeIcon
                  icon={icon({
                    name: 'exclamation',
                    style: 'solid',
                  })}
                  size="xl"
                />
              </div>
            </div>

            <div className="main">
              <div className="title"> {title ? title : 'Please Confirm'} </div>
              <div className="question">{question ? question : 'Are you sure?'}</div>
            </div>

            <div className="controls">
              <CFButton buttonRef={cancelButtonRef} value="Cancel" onClick={handleCancelConfirmation} />

              <CFButton
                isLoading={isLoading}
                role={CFRole.Primary}
                value="Confirm"
                onClick={handleAcceptConfirmation}
              />
            </div>
          </div>
        </CFPortal>
      )}
    </React.Fragment>
  );
};

export default CFConfirmableButton;
