import React, { useCallback, useEffect, useRef, useState } from 'react';

import CFButton from 'components/buttons/CFButton';
import { ToastType } from 'components/CFToast/types';
import { CFRole } from 'domain/general.types';
import { GroupName } from 'services/intervention/intervention.types';
import { useToast } from 'hooks';
import { checkSample, saveDeterminedSample } from 'services/intervention/intervention.repo';
import { useInterventionContext } from 'views/intervention/useContext';
import GroupParticipants, { GroupParticipantsRef } from './GroupParticipants';
import CFSelect, { Option } from 'components/CFSelect';
import CFTitledComponent from 'components/CFTitledComponent';
import { TraitSubject } from 'domain/traits.types';

import './participants-manual-picker.scss';
import VOSampleId from 'services/intervention/domain/VOSampleId';

interface Props {
  groupNames: string[];
}

const ParticipantsManualPicker = ({ groupNames }: Props) => {
  const groupSubjectsRefs = useRef<Record<GroupName, GroupParticipantsRef>>({});
  const { sampleId, setSampleId, subject, setSubject, setCohort } = useInterventionContext();

  const [unknownSubjects, setUnknownSubjects] = useState<string[]>([]);
  const [usedSubjects, setUsedSubjects] = useState<string[]>([]);
  const [subjectOption, setSubjectOption] = useState<Option>({
    value: subject || TraitSubject.User,
    label: subject || TraitSubject.User,
  });

  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  useEffect(() => {
    setSampleId(new VOSampleId(''));
  }, []);

  const handleSubjectChange = (option: Option) => {
    setSubject(option.value as TraitSubject);
    setSubjectOption(option);
  };

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    const groupSubjects = groupNames.reduce((acc, cur) => {
      acc[cur] = groupSubjectsRefs.current[cur].value();
      return acc;
    }, {} as Record<string, string[]>);

    try {
      const checkResult = await checkSample(groupSubjects, sampleId.value);

      setUnknownSubjects(checkResult.not_exist || []);
      setUsedSubjects(checkResult.used || []);

      if (checkResult.not_exist.length || checkResult.used.length) {
        return;
      }

      const newSampleId = await saveDeterminedSample(groupSubjects, sampleId.value);
      setSampleId(newSampleId);
      setCohort(undefined);

      addToast('Sample created', ToastType.SUCCESS);
    } catch (err: any) {
      console.log('err: ', err);
      addToast('Check the participants', ToastType.ERROR);
    }

    setLoading(false);
  }, [groupNames]);

  return (
    <div className="participants-manual-picker">
      <CFTitledComponent title={'Subject type'} className="subject-type">
        <CFSelect
          value={subjectOption}
          options={Object.values(TraitSubject).map((value) => ({ value, label: value }))}
          onSelected={handleSubjectChange}
        />
      </CFTitledComponent>
      {groupNames.map((groupName) => (
        <GroupParticipants
          ref={(el) => (groupSubjectsRefs.current[groupName] = el as GroupParticipantsRef)}
          key={groupName}
          groupName={groupName}
        />
      ))}{' '}
      {unknownSubjects.length ? (
        <div className="bad-users">
          Some users do not exist:{' '}
          {unknownSubjects.map((subject) => (
            <span key={subject} className="highlight">
              {subject}
            </span>
          ))}
        </div>
      ) : (
        ''
      )}
      {usedSubjects.length ? (
        <div className="bad-users">
          Some users are already being used:{' '}
          {usedSubjects.map((subject) => (
            <span key={subject} className="highlight">
              {subject}
            </span>
          ))}
        </div>
      ) : (
        ''
      )}
      <div className="control">
        <CFButton role={CFRole.Primary} value={'Submit'} onClick={handleSubmit} isLoading={loading} />
      </div>
    </div>
  );
};

export default ParticipantsManualPicker;
