import { AxiosResponse } from 'axios';
import { get as httpGet, post as httpPost, remove } from '../../repositories/drivers/http';

import {
  ColAddr,
  Trait,
  TraitSubject,
  TraitTimeseries,
  TraitUsage,
  TraitType,
  TraitCategory,
  TraitCode,
} from 'domain/traits.types';

import { RepoConfig } from '../../repositories/types';
import { AggLevel, TimeSeriesItem } from 'domain/stats.types';
import { Modules, SubjectId, TimeRFC3999 } from 'domain/general.types';
import { Ptr, Tree } from 'services/cohort/cohort.types.api';
import { CohortID } from 'services/cohort/cohort.types';

import { ModelId } from 'domain/model.types';
import { BackfillReference, BackfillStatusItem, ReferenceDetailItem } from './backfill.types.api';

const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;

const backfillPath = '/v1/backfill';
const path = '/v1/trait';

const repoConfig = {
  token: '',
  oid: -1,
  pid: -1,
};

export interface TraitsSearchParams {
  subject: TraitSubject;
  category?: TraitCategory;
  usage?: TraitUsage;
  aggLevel?: AggLevel;
  module?: Modules;
  visibility?: boolean;
}

export interface CFTraitRepository {
  init: (repoConfig: RepoConfig) => void;
  getAvailableSubjectTypes: () => Promise<string[]>;
  getGDTCustom: (ptr: Ptr, tree: Tree, start: TimeRFC3999, end: TimeRFC3999) => any;
  getTimeseries: (start: string, end: string, traitAddr: ColAddr, cohortId: string) => Promise<TimeSeriesItem[]>;
  getMLTTimeseries: (
    start: string,
    end: string,
    addrs: ColAddr[],
    subjects: SubjectId[],
    modelId: ModelId
  ) => Promise<Record<string, Record<string, TimeSeriesItem[]>>>;
  getUniqueValuesForTrait: (traitAddr: ColAddr) => Promise<any[]>;
  listCurrentBackfills: () => Promise<BackfillStatusItem[]>;
  backfillTrait: (
    start: TimeRFC3999 | undefined,
    end: TimeRFC3999 | undefined,
    cohortIDs: CohortID[],
    recomputeSubject: boolean,
    colAddr: TraitCode
  ) => Promise<void>;
  backfill: (
    start: string,
    end: string,
    aggLevel: AggLevel,
    subjectType: TraitSubject,
    tableType: TraitType,
    cohortIDs: CohortID[],
    recomputeSubject: boolean
  ) => void;
  checkBackfillingState: (ptr: Ptr) => Promise<boolean | null>;
  checkBackfillingReference: (ref: BackfillReference) => Promise<ReferenceDetailItem[]>;
  cancelBackfill: (ref: BackfillReference) => Promise<void>;
  search: ({ subject, category, usage, aggLevel, module }: TraitsSearchParams) => Promise<Trait[]>;
  getModelTraits: (cohortId: CohortID) => Promise<Record<TraitCode, ModelId[]>>;
}

export const init = ({ token, oid, pid }: RepoConfig) => {
  repoConfig.token = token;
  repoConfig.oid = oid;
  repoConfig.pid = pid;
};

export const getTimeseries = async (
  start: string,
  end: string,
  traitAddr: ColAddr,
  cohortId: string
): Promise<TimeSeriesItem[]> => {
  const config = {
    params: {
      oid: repoConfig.oid,
      pid: repoConfig.pid,
    },
  };

  const body = {
    ids: [cohortId],
    cols: [traitAddr],
    range: {
      start,
      end,
    },
  };

  const stats = (await httpPost(`${serverBaseUrl}${path}/value/ts-range`, body, config)) as AxiosResponse;

  return (stats.data as TraitTimeseries).rows.map((traitItem) => ({
    time: traitItem[1],
    value: traitItem[2],
  }));
};

export const getMLTTimeseries = async (
  start: string,
  end: string,
  addrs: ColAddr[],
  subjects: SubjectId[],
  modelId: ModelId
): Promise<Record<string, Record<string, TimeSeriesItem[]>>> => {
  const config = {
    params: {
      oid: repoConfig.oid,
      pid: repoConfig.pid,
    },
  };

  const body = {
    ids: subjects,
    cols: addrs,
    range: {
      start,
      end,
    },
    model_id: modelId,
  };

  const stats = (await httpPost(`${serverBaseUrl}${path}/mlt/ts-range`, body, config)) as AxiosResponse;

  const traitsIndexes: Record<string, number> = {};
  const result: Record<string, any> = {};

  (stats.data as TraitTimeseries).header.slice(2).forEach((traitName: string, index: number) => {
    traitsIndexes[traitName] = index + 2; // 0 is userId; 1 is timestamp
    result[traitName] = {};
  });

  (stats.data as TraitTimeseries).rows.forEach((item) => {
    Object.keys(traitsIndexes).forEach((traitName) => {
      if (!result[traitName][item[0]]) {
        result[traitName][item[0]] = [];
      }

      result[traitName][item[0]].push({ value: item[traitsIndexes[traitName]], time: item[1] });
    });
  });

  return result;
};

export const getUniqueValuesForTrait = async (traitAddr: ColAddr): Promise<any[]> => {
  const page = 0;
  const per_page = 300;
  const config = {
    params: {
      oid: repoConfig.oid,
      pid: repoConfig.pid,
      page,
      per_page,
    },
  };

  const body = {
    addr: traitAddr,
  };

  const traits = (await httpPost(`${serverBaseUrl}${path}/unique-vals`, body, config)) as AxiosResponse;

  return traits.data.data || [];
};

export const backfill = async (
  start: string,
  end: string,
  aggLevel: AggLevel,
  subjectType: TraitSubject,
  tableType: TraitType,
  cohortIDs: CohortID[],
  recomputeSubject: boolean
) => {
  const config = {
    params: {
      oid: repoConfig.oid,
      pid: repoConfig.pid,
    },
  };

  const body = {
    subject_type: subjectType,
    agg_level: aggLevel,
    type: tableType,
    cohort_ids: cohortIDs,
    recompute_subject: recomputeSubject,
    start,
    end,
  };

  (await httpPost(`${serverBaseUrl}${backfillPath}/run`, body, config)) as AxiosResponse;
};

export const listCurrentBackfills = async (): Promise<BackfillStatusItem[]> => {
  const config = {
    params: {
      oid: repoConfig.oid,
      pid: repoConfig.pid,
    },
  };

  const traits = (await httpGet(`${serverBaseUrl}${backfillPath}/ref/list/running`, config)) as AxiosResponse<
    BackfillStatusItem[]
  >;

  return traits.data;
};

export const checkBackfillingState = async (ptr: Ptr) => {
  const config = {
    params: {
      oid: repoConfig.oid,
      pid: repoConfig.pid,
    },
  };

  const traits = (await httpGet(`${serverBaseUrl}${backfillPath}/ref/run/exist?ptr=${ptr}`, config)) as AxiosResponse;

  return traits.data;
};

export const checkBackfillingReference = async (ref: BackfillReference): Promise<ReferenceDetailItem[]> => {
  const config = {
    params: {
      oid: repoConfig.oid,
      pid: repoConfig.pid,
    },
  };

  const traits = (await httpGet(`${serverBaseUrl}${backfillPath}/ref/check/${ref}`, config)) as AxiosResponse<
    ReferenceDetailItem[]
  >;

  return traits.data;
};

export const cancelBackfill = async (ref: BackfillReference) => {
  const config = {
    params: {
      oid: repoConfig.oid,
      pid: repoConfig.pid,
    },
  };

  await remove(`${serverBaseUrl}${backfillPath}/ref/cancel/${ref}`, config);
};

export const backfillTrait = async (
  start: TimeRFC3999 | undefined,
  end: TimeRFC3999 | undefined,
  cohortIDs: CohortID[],
  recomputeSubject: boolean,
  colAddr: TraitCode
) => {
  const config = {
    params: {
      oid: repoConfig.oid,
      pid: repoConfig.pid,
    },
  };

  const body = {
    cohort_ids: cohortIDs,
    recompute_subject: recomputeSubject,
    ptrs: [colAddr],
    start,
    end,
  };

  (await httpPost(`${serverBaseUrl}${backfillPath}/run-selected`, body, config)) as AxiosResponse;
};

export const search = async ({ subject, category, usage, aggLevel, module }: TraitsSearchParams): Promise<Trait[]> => {
  const config = {
    // withCredentials: true,
    params: {
      oid: repoConfig.oid,
      pid: repoConfig.pid,
      sub: subject,
      category,
      usage,
      agg_level: aggLevel,
      module,
    },
  };

  const traits = (await httpGet(`${serverBaseUrl}${path}/def/search`, config)) as AxiosResponse;

  return traits.data || [];
};

export const getModelTraits = async (cohortId: CohortID): Promise<Record<TraitCode, ModelId[]>> => {
  const config = {
    params: {
      oid: repoConfig.oid,
      pid: repoConfig.pid,
    },
  };

  const traits = (await httpGet(`${serverBaseUrl}${path}/mlt/${cohortId}`, config)) as AxiosResponse;

  return traits.data;
};

export const getGDTCustom = async (ptr: Ptr, tree: Tree, start: TimeRFC3999, end: TimeRFC3999) => {
  const config = {
    params: {
      oid: repoConfig.oid,
      pid: repoConfig.pid,
    },
  };

  const body = {
    ptr,
    tree,
    start,
    end,
    print_query: true,
    compare_prev: true,
  };

  const traits = (await httpPost(`${serverBaseUrl}${path}/value/gdt-point-custom`, body, config)) as AxiosResponse;

  return traits.data;
};

export const getAvailableSubjectTypes = async () => {
  const config = {
    params: {
      oid: repoConfig.oid,
      pid: repoConfig.pid,
    },
  };

  const traits = (await httpGet(`${serverBaseUrl}${path}/subject-type`, config)) as AxiosResponse;

  return traits.data;
};

export default {
  getAvailableSubjectTypes,
  getGDTCustom,
  getModelTraits,
  getUniqueValuesForTrait,
  getTimeseries,
  getMLTTimeseries,
  backfill,
  backfillTrait,
  listCurrentBackfills,
  checkBackfillingState,
  checkBackfillingReference,
  cancelBackfill,
  init,
  search,
};
