import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { NudgeRecommendDefinition, NudgeType } from 'services/nudging/nudge.types';
import { Model, ModelId } from 'domain/model.types';
import { TraitSubject } from 'domain/traits.types';
import CFSelect, { Option } from 'components/CFSelect';
import { useServicesContext } from 'hooks/useServicesContext';
import CFTitledComponent from 'components/CFTitledComponent';
import { AlgorithmClass } from 'services/intervention/intervention.types';
import ModelOption from './ModelOption';

//import './nudge-recommend-form.scss';

interface Props {}

export interface NudgeRecommentRef {
  value: () => NudgeRecommendDefinition;
}

const NudgeRecommendForm = forwardRef<NudgeRecommentRef, Props>(function NewNudgeRecommendForm({}: Props, ref) {
  const { modelService } = useServicesContext();

  const [model, setModel] = useState<Model>();
  const [models, setModels] = useState<Model[]>([]);

  useEffect(() => {
    (async () => {
      const models = await modelService.getModels(0, 100, AlgorithmClass.Recommender);

      setModels(models.data);
    })();
  }, []);

  const handleModelChange = (option: Option) => {
    const model = models.find((model) => model.definition.id === option.value);

    setModel(model);
  };

  useImperativeHandle(ref, () => ({
    value() {
      return {
        type: NudgeType.Recommender,
        recommend: {
          model_id: model?.definition.id as ModelId,
          subject_type: model?.cohort.subject_type as TraitSubject,
        },
      };
    },
  }));

  return (
    <div className={`new-nudge-form`}>
      <CFTitledComponent title="Model">
        <CFSelect
          options={models.map((model) => ({
            label: model.definition.name,
            value: model.definition.id,
            meta: { model },
          }))}
          isMulti={false}
          value={{ label: model?.definition.name || '', value: model?.definition.id || '' }}
          onSelected={handleModelChange}
          Item={ModelOption}
        />
      </CFTitledComponent>
    </div>
  );
});

export default NudgeRecommendForm;
