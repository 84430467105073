import React from 'react';

import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import { getProfile, validate } from '../../auth';
import { UserDetailedInfo } from '../../types';
import HexPatternImage from 'assets/cf-pattern-hexagon.png';
import CirclePatternImage from 'assets/cf-pattern-circle.png';

import Logo from './assets/logo.png';

import { useState } from 'react';
import MicrosoftLogin from '../../components/MicrosoftLogin';

import './login.scss';

/**
 * See https://developers.google.com/identity/gsi/web/guides/
 */
interface Props {
  serverBaseUrl: string;
  onError: () => void;
  onLogin: (userInfo: UserDetailedInfo, token: string) => void;
}

const Login = ({ serverBaseUrl, onLogin, onError }: Props) => {
  const [error, setError] = useState(false);

  const handleError = () => {
    setError(true);
    onError();
  };

  const handleTokenAndLogin = async (jwt: string) => {
    try {
      await validate(serverBaseUrl, jwt);
      const userInfo = await getProfile(serverBaseUrl);
      handleLogin(userInfo, jwt);
    } catch {
      handleError();
    }
  };

  const handleLogin = (userInfo: UserDetailedInfo, token: string) => {
    onLogin(userInfo, token);
  };

  const msalConfiguration: Configuration = {
    auth: {
      clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID || '', // the only mandatory field in this object, uniquely identifies your app
      redirectUri: '/',
    },
  };
  const pca = new PublicClientApplication(msalConfiguration);

  return (
    <div className="login-panel">
      <div className="hex-pattern">
        <img src={HexPatternImage} alt="causal foundry pattern" />
      </div>
      <div className="top-left-gradient"></div>

      <div className="circle-pattern">
        <img src={CirclePatternImage} alt="causal foundry pattern" />
      </div>

      <div className="bottom-right-gradient"></div>

      <div className="login-button">
        <img className="logo" src={Logo} alt="Causal Foundry Logo" />

        {error && (
          <div className="error">
            Your account does not exist. Sign in with a different Google Workspace or Microsoft 365 Workspace account or
            contact <a href="mailto:hello@causalfoundry.ai">hello@causalfoundry.ai</a>
          </div>
        )}

        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
          <GoogleLogin
            logo_alignment="center"
            width="300px"
            onSuccess={(credentialResponse) => {
              handleTokenAndLogin(credentialResponse.credential || '');
            }}
            onError={onError}
          />
        </GoogleOAuthProvider>
        <MsalProvider instance={pca}>
          <MicrosoftLogin
            onClick={() => {
              pca
                .loginPopup()
                .then((response) => {
                  if (response.idToken) {
                    handleTokenAndLogin(response.idToken);
                  } else {
                    onError();
                  }
                })
                .catch(() => {
                  onError();
                });
            }}
          />
        </MsalProvider>
      </div>
    </div>
  );
};

export default Login;
