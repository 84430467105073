import React, { useEffect } from 'react';

import CFCardPicker from 'components/CFCardPicker';

import { ProjectDefinition } from 'types';
import dayjs from 'dayjs';

import './project-picker.scss';

interface Props {
  projects: ProjectDefinition[];
  onSelect: (id: string) => void;
}

const ProjectPicker = ({ onSelect, projects }: Props) => {
  useEffect(() => {
    if (projects.length === 1) {
      onSelect(`${projects[0].id}`);
    }
  }, []);

  return (
    <div className="project-picker">
      <CFCardPicker title="projects">
        {projects.map(({ id, name, created_at }) => (
          <div className="card-item" key={`card-${id}`} onClick={() => onSelect(`${id}`)}>
            <div className="title">{name}</div>
            <div className="category">
              Last Updated: <span>___</span>
            </div>
            <div className="category">
              Date Created: <span>{dayjs(created_at || '').format('DD/MM/YYYY')}</span>
            </div>
          </div>
        ))}
      </CFCardPicker>
    </div>
  );
};

export default ProjectPicker;
