import React, { useCallback, useEffect, useState } from 'react';

import { Trait } from 'domain/traits.types';

import InterventionSection from 'views/intervention/interventions/NewIntervention/interventionSection';
import TraitListItem from 'views/model/components/TraitsListItem';

import { createTraitCode } from 'services/traits/helpers.traits';

import TraitsTable from 'connected-components/TraitsTable';

import CFTitledComponent, { TitleSize } from 'components/CFTitledComponent';
import CFLoadWrapper from 'components/CFLoadWrapper';
import CFTitledSection from 'components/CFTitledSection';
import TimeWindowPicker from 'components/TimeWindowPicker';

import { useServicesContext } from 'hooks/useServicesContext';

import { Steps, useModelContext } from '../../useContext';

import './features.scss';

const DEFAULT_FULL_DURATION = 90;
const DEFAULT_TEST_DURATION = 30;

const Item2VectFeatures = (): JSX.Element => {
  const { traitSessionService: traitService } = useServicesContext();
  const { setFullDuration, setTestDuration, setFeatures, selectedItemSubjectType, updateReadiness } = useModelContext();

  const [isReady, setIsReady] = useState(false);
  const [staticTraits, setStaticTraits] = useState<Trait[]>([]);
  const [selectedStatic, setSelectedStatic] = useState<Trait[]>([]);

  useEffect(() => {
    const isReady = selectedStatic.length !== 0;

    if (isReady) {
      setFeatures({
        dynamic: [],
        static: selectedStatic.map((trait) => trait.addr),
      });
    }

    updateReadiness(isReady, Steps.Features);
  }, [selectedStatic]);

  const fetchTraits = async () => {
    setIsReady(false);
    setStaticTraits(await traitService.getAllContextStaticTraits(selectedItemSubjectType));
    setIsReady(true);
  };

  useEffect(() => {
    fetchTraits();
  }, [selectedItemSubjectType]);

  const handleSelectStatic = useCallback(
    (trait: Trait) => {
      const newSelectedStatic = selectedStatic.filter((_trait) => createTraitCode(_trait) !== createTraitCode(trait));

      if (newSelectedStatic.length === selectedStatic.length) {
        newSelectedStatic.push(trait);
      }

      setSelectedStatic(newSelectedStatic);
    },
    [selectedStatic]
  );

  const handleFullDurationChange = useCallback((duration: number) => {
    setFullDuration(duration);
  }, []);

  const handleTestDurationChange = useCallback((duration: number) => {
    setTestDuration(duration);
  }, []);

  return (
    <CFLoadWrapper isLoading={!isReady}>
      <InterventionSection name={Steps.Features} title={'Features'} subtitle="Add features to your model">
        <div className="model-features">
          <TraitsTable
            selected={selectedStatic}
            handleSelectTrait={(row) => handleSelectStatic(row as Trait)}
            traits={staticTraits}
          />
          <CFTitledSection nested={true} title={`Selected Data (${selectedStatic.length})`}>
            <div className="selected-metrics-section">
              <CFTitledComponent title="Static Features" size={TitleSize.Big} className={'trait-list'}>
                {selectedStatic.length ? (
                  selectedStatic.map((trait) => (
                    <TraitListItem
                      key={createTraitCode(trait)}
                      trait={trait}
                      onDelete={(trait) => handleSelectStatic(trait)}
                    />
                  ))
                ) : (
                  <div className="empty-label">No Selected Static Features</div>
                )}
              </CFTitledComponent>
            </div>
          </CFTitledSection>

          <CFTitledComponent title="Full duration">
            <TimeWindowPicker defaultValue={DEFAULT_FULL_DURATION} onChange={handleFullDurationChange} />
          </CFTitledComponent>

          <CFTitledComponent title="Test duration">
            <TimeWindowPicker defaultValue={DEFAULT_TEST_DURATION} onChange={handleTestDurationChange} />
          </CFTitledComponent>
        </div>
      </InterventionSection>
    </CFLoadWrapper>
  );
};

export default Item2VectFeatures;
