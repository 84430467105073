import { ColAddr, TraitDataType } from 'domain/traits.types';

let getUniqueValuesForTraitFromRepo: (col: ColAddr) => Promise<Array<any>>;

export const init = (getUniqueValues: (col: ColAddr) => Promise<Array<any>>) => {
  getUniqueValuesForTraitFromRepo = getUniqueValues;
};

export const getUniqueValuesForTrait = async (traitAddr: ColAddr): Promise<Array<any>> => {
  if (traitAddr.dtype === TraitDataType.Boolean || traitAddr.dtype === TraitDataType.Bool) {
    return [true, false];
  }

  if (([TraitDataType.Float4, TraitDataType.Int4, TraitDataType.Number] as string[]).includes(traitAddr.dtype)) {
    return [];
  }

  if (traitAddr.dtype === TraitDataType.Varchar) {
    const uniqueValues = await getUniqueValuesForTraitFromRepo(traitAddr);

    return uniqueValues;
  }

  return [];
};
