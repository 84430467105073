import React, { ReactNode } from 'react';
import classNames from 'classnames';

import CFTextarea from 'components/textarea/CFTextarea';
import { TextAreaProps } from '..';

import { ReactComponent as SendButton } from '../images/send_button.svg';

import './cf-submit-textarea.scss';

interface Props extends TextAreaProps {
  children?: ReactNode;
}

const CFSubmitTextarea = React.forwardRef<HTMLTextAreaElement, Props>(function CFSubmitTextarea(props, ref) {
  const handleSubmit = () => {
    if (props.onSubmit && !props.disabled) {
      props.onSubmit();
    }
  };

  const propsWithoutChildren = { ...props, children: undefined };

  return (
    <div className="textarea-submit">
      {props.children && <div className="textarea-submit__cited">{props.children}</div>}
      <CFTextarea ref={ref} {...propsWithoutChildren} />
      <div className={classNames('textarea-submit__button', { disabled: props.disabled })} onClick={handleSubmit}>
        <SendButton />
      </div>
    </div>
  );
});

export default CFSubmitTextarea;
