import React, { Children, ReactNode } from 'react';

import TRENDING_NEUTRAL from 'assets/trending-neutral.png';

import { ReactComponent as ArrowDown } from './downward.svg';
import { ReactComponent as ArrowUp } from './upward.svg';

import CFSkeletonBlock from 'components/CFSkeletonBlock';
import { capitalize, round } from 'helpers/misc';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { KPI } from '..';

import './relative-info-box.scss';

interface Props extends KPI {
  reversedLogic?: boolean;
  isLoading?: boolean;
  onShowExtendedInfo?: () => void;
  children?: ReactNode;
}

const DECIMALS_FOR_PERCENTAGE = 2;
const DECIMALS_FOR_ANY = 0;
const NEUTRAL_RESULT = '0.00';

const InfoNote = (props: any) => {
  return <div> {props.children} </div>;
};

const RelativeInfoBox = ({
  label,
  value,
  variation,
  unit = '',
  reversedLogic = false,
  isLoading = false,
  children,
  onShowExtendedInfo,
}: Props) => {
  let trendingClassname;
  let dataId;
  let arrow;

  const arrayChildren = Children.toArray(children);
  const extendedTitle = arrayChildren.find((child) => (child as any).type.name === InfoNote.name);

  if (Math.abs(variation).toFixed(2) === NEUTRAL_RESULT) {
    arrow = <img src={TRENDING_NEUTRAL} alt="Trending neutral image" />;
    trendingClassname = 'neutral';
    dataId = trendingClassname;
  } else if (variation < 0) {
    arrow = <ArrowDown />;
    trendingClassname = reversedLogic ? 'positive' : 'negative';
    dataId = trendingClassname;
  } else if (variation > 0) {
    arrow = <ArrowUp />;
    trendingClassname = reversedLogic ? 'negative' : 'positive';
    dataId = trendingClassname;
  }

  return (
    <div className="relative-info-box">
      {onShowExtendedInfo && (
        <div className="extended" onClick={onShowExtendedInfo}>
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ cursor: 'pointer' }} />
        </div>
      )}
      <div className="content">
        {isLoading && (
          <>
            <CFSkeletonBlock width={96} height={16} />
            <CFSkeletonBlock width={62} height={30} />
            <CFSkeletonBlock width={96} height={16} />
          </>
        )}
        {!isLoading && (
          <>
            <div className="label">
              {capitalize(label)} {extendedTitle}{' '}
            </div>
            <div className="value">
              <span> {round(value as number, unit === '%' ? DECIMALS_FOR_PERCENTAGE : DECIMALS_FOR_ANY)}</span>
              <span className="unit">{value ? unit : ''} </span>
            </div>
            <div className={`relative-comparison ${trendingClassname}`} data-testid={dataId}>
              {arrow}
              <span>{variation ? Math.abs(variation).toFixed(2) : '0.00'} %</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

RelativeInfoBox.Info = InfoNote;

export default RelativeInfoBox;
