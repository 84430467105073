import React, { Children, ReactNode, useEffect, useMemo, useRef } from 'react';
import classnames from 'classnames';
import { ScatterSeriesOption } from 'echarts/charts';
import { getInstanceByDom } from 'echarts';

import { RGBColor } from 'domain/general.types';

import colors from 'common.scss';

import { EChartsOption, ReactECharts } from '..';

import CFTooltip, { CFTooltipPositions } from 'components/CFTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';

import '../charts.scss';
import './cf-scatter-plot-chart.scss';

export interface StackedValue {
  x: number;
  y: number;
  id: string;
}

export interface SeriesRange {
  min: number;
  max: number;
  label: string;
}

export interface SeriesItem {
  values: StackedValue[];
  name: string;
  color?: RGBColor;
  area?: SeriesRange;
}

export interface SplitConfig {
  label: string;
  labelColor: RGBColor;
  color: RGBColor;
  starts: number;
}

interface Props {
  title: string;
  description?: string;
  xLabel: string;
  yLabel: string;
  series: SeriesItem[];
  splits?: SplitConfig[];
  showSplit?: boolean;
  showLegend?: boolean;
  className?: string;
  lastIsInfinite?: boolean;
  step?: number;
  size?: number | string;
  square?: boolean;
  children?: ReactNode;
}

const CFScatterPlotChart = ({
  title,
  description,
  series,
  xLabel,
  yLabel,
  splits = [],
  showSplit: split = false,
  showLegend = false,
  lastIsInfinite = false,
  className,
  step = 1,
  size = 500,
  square = false,
  children,
}: Props) => {
  const chartRef = useRef<HTMLDivElement>(null);

  const scatterX = splits.map((config, i, array) => {
    if (!split) {
      return;
    }

    return {
      type: 'scatter',
      data: [],
      markArea: {
        silent: true,
        label: {
          show: true,
          position: 'right',
          color: config.labelColor,
          fontWeight: 'bold',
          fontSize: 13,
          distance: 10,
          textBorderColor: 'transparent',
        },
        itemStyle: {
          color: config.color,
          opacity: 0.2,
        },
        data: [
          [
            {
              name: config.label,
              yAxis: config.starts,
            },
            { yAxis: array[i + 1] ? array[i + 1].starts : 1 },
          ],
        ],
      },
      markLine: {
        silent: true,
        symbol: ['none', 'none'],
        lineStyle: {
          color: colors.dark40,
        },
        label: { show: false },
        data: [
          {
            name: 'split',
            yAxis: array[i + 1] ? array[i + 1].starts : 1,
          },
        ],
      },
    };
  });

  const scatterY = series.map((item) => {
    if (!item.area) {
      return;
    }

    return {
      type: 'scatter',
      data: [],
      markArea: {
        silent: true,
        label: {
          position: 'top',
          color: item.color,
          fontWeight: 'bold',
          fontSize: 13,
          distance: 5,
          textBorderColor: 'transparent',
        },
        itemStyle: {
          color: 'transparent',
        },
        data: [
          [
            {
              name: item.area?.label,
              xAxis: item.area?.min,
            },
            { xAxis: item.area?.max },
          ],
        ],
      },
      markLine: {
        silent: true,
        symbol: ['none', 'none'],
        lineStyle: {
          color: colors.dark40,
        },
        label: { show: false },
        data: [
          {
            name: item.area?.label,
            xAxis: item.area?.max,
          },
        ],
      },
    };
  });

  const allSeries = [
    ...scatterX,
    ...scatterY,
    ...series.map((seriesItem) => ({
      symbolSize: 3,
      color: seriesItem.color || colors.cfCyan,
      name: seriesItem.name,
      type: 'scatter',
      data: seriesItem.values.map(({ x, y, id }) => [x, y, id]),
    })),
  ];

  const maxValueX =
    Math.ceil(Math.max(...series.map((seriesItem) => seriesItem.values.map((value) => value.x)).flat()) / step) * step;
  const minValueX =
    Math.floor(Math.min(...series.map((seriesItem) => seriesItem.values.map((value) => value.x)).flat()) / step) * step;
  const maxValueY =
    Math.ceil(Math.max(...series.map((seriesItem) => seriesItem.values.map((value) => value.y)).flat()) / step) * step;
  const minValueY =
    Math.floor(Math.min(...series.map((seriesItem) => seriesItem.values.map((value) => value.y)).flat()) / step) * step;

  const options = useMemo(() => {
    const options: EChartsOption = {
      xAxis: {
        name: xLabel,
        nameLocation: 'middle',
        nameGap: 30,
        nameTextStyle: {
          color: colors.dark50,
          fontSize: 14,
          fontWeight: 600,
        },
        min: square ? Math.max(0, Math.min(minValueY, minValueX)) : undefined,
        max: square ? Math.max(maxValueY, maxValueX) : undefined,
        splitLine: { show: false },
        axisLabel: {
          formatter: function (value: any) {
            if (lastIsInfinite && value >= maxValueX) {
              return '{a|∞}';
            } else {
              return value;
            }
          },
          rich: {
            a: {
              fontSize: 16, // Ajusta el tamaño de la fuente para el símbolo infinito
              fontWeight: 'bold', // Otras propiedades de estilo opcionales
            },
          },
        },
      },
      yAxis: {
        name: yLabel,
        nameLocation: 'middle',
        nameGap: 30,
        min: square ? Math.min(minValueY, minValueX) : undefined,
        max: square ? Math.max(maxValueY, maxValueX) : undefined,
        nameTextStyle: {
          color: colors.dark50,
          fontSize: 14,
          fontWeight: 600,
        },
        splitLine: { show: false },
      },
      legend: {
        show: showLegend,
        top: 'top',
        right: '0px',
        textStyle: {
          color: '#fff',
          fontSize: 14,
        },
      },
      grid: {
        show: false,
        backgroundColor: '#1E212B',
        borderWidth: 0,
      },
      series: allSeries as ScatterSeriesOption,
      animation: false,

      tooltip: {
        show: false, // until backend is able to give IDs for each point
        trigger: 'item',
        axisPointer: {
          type: 'cross',
        },
        backgroundColor: colors.dark100,
        borderColor: colors.dark100,
        formatter: (params: any) => {
          return `<div class="tooltip">${params.value[2]}</div>`;
        },
      },
      brush: {
        toolbox: ['rect', 'clear'],
        xAxisIndex: 0,
      },
    };

    return options;
  }, [series, xLabel, yLabel]);

  useEffect(() => {
    if (chartRef.current) {
      const chart = getInstanceByDom(chartRef.current);

      chart?.on('brushSelected', (params: any) => {
        console.log(params);
        const serie = series.find((s) => s.name === params.batch[0].selected[0].seriesName);

        const data = params.batch[0].selected[0].dataIndex.map((idx: number) => serie?.values[idx]);

        console.log(data);
      });
    }
  }, [chartRef]);

  const arrayChildren = Children.toArray(children);

  const chartUI = useMemo(() => {
    // this is to avoid rerender when the input value changes,
    // but we want to keep previuos points
    return (
      <ReactECharts
        ref={chartRef}
        option={options}
        style={square ? { width: '100%', height: '100%' } : { width: '100%', height: size }}
      />
    );
  }, [options, chartRef]);

  return (
    <div className={classnames('cf-scatter-chart', className)}>
      <div className="chart-header">
        <div className="chart-header__title">
          <span>{title || ''} </span>
          {description && (
            <CFTooltip description={description} position={CFTooltipPositions.Right}>
              <FontAwesomeIcon icon={faCircleQuestion} size="xs" />
            </CFTooltip>
          )}
        </div>
        <div className="chart-header__controls">{arrayChildren}</div>
      </div>

      {chartUI}
    </div>
  );
};

export default CFScatterPlotChart;
