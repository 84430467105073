import { CallToAction, Nudge, NudgeType, RenderMethod, RenderPage } from 'services/nudging/nudge.types';

export const defaultNudge: Nudge = {
  id: 0,
  pid: 0,
  use: 0,
  created_by: '',
  created_at: 0,
  name: 'control',
  description: '',
  definition: {
    type: NudgeType.Message,
    message: {
      title: '-',
      body: '',
      tags: [],
    },
    render_method: RenderMethod.PushNotification,
    cta: CallToAction.AddToCart,
    render_page: RenderPage.None,
    noop: false,
  },
};
