import React from 'react';

import InterventionSection from 'views/intervention/interventions/NewIntervention/interventionSection';

import { AlgorithmClass } from 'services/intervention/intervention.types';

import { Steps, useModelContext } from '../useContext';

import CensoringTarget from './CensoringTarget';

import BanditTarget from './BanditTarget';

import './target.scss';

const ModelTarget = (): JSX.Element => {
  const { definition } = useModelContext();

  return (
    <InterventionSection name={Steps.Target} title={'Target'} subtitle="Select the targets for your model.">
      <div className="model-target">
        {definition?.class === AlgorithmClass.Censoring && <CensoringTarget />}

        {definition?.class === AlgorithmClass.Bandit && <BanditTarget />}
      </div>
    </InterventionSection>
  );
};

export default ModelTarget;
