import React, { createContext, useState, useContext } from 'react';

interface ArmsInfoContextType {
  armsIndexes: number[];
  setArmIndexes: React.Dispatch<React.SetStateAction<number[]>>;
}

interface Props extends React.PropsWithChildren {}

const ArmInfoContext = createContext<ArmsInfoContextType | undefined>(undefined);

export const ArmsInfoProvider: React.FC<Props> = ({ children }) => {
  const [armsIndexes, setArmIndexes] = useState<number[]>([]);

  return <ArmInfoContext.Provider value={{ armsIndexes, setArmIndexes }}>{children}</ArmInfoContext.Provider>;
};

export const useArmsInfoContext = (): ArmsInfoContextType => {
  const context = useContext(ArmInfoContext);
  if (!context) {
    throw new Error('useMessagesContext must be used within an MessagesContextProvider');
  }
  return context;
};

export default ArmsInfoProvider;
