import React, { ReactNode } from 'react';

import { CFNavList } from 'components/CFNavList';
import useCFNavigation from 'hooks/useCFNavigation';

import AnalyticsLayout from '../layout';
import { Tabs as AnalyticsTabs } from '../tabs';

import FilterControls from './components/FilterControls';
import { Tabs, allowedTabs, monitoringByTab } from './tabs';

import './user-engagement.scss';

interface Props {
  children: ReactNode | ReactNode[];
  className?: string;
  tab: Tabs;
}

const UserEngagementLayout = ({ children, tab }: Props) => {
  const navigate = useCFNavigation();

  return (
    <AnalyticsLayout className="user-engagement" tab={AnalyticsTabs.UserEngagement}>
      <FilterControls />
      <CFNavList
        className="sub-tabs"
        style="buttons"
        titles={allowedTabs()}
        selected={tab}
        onClick={(selectedTab) => {
          navigate(monitoringByTab[selectedTab]);
        }}
      />
      {children}
    </AnalyticsLayout>
  );
};

export default UserEngagementLayout;
