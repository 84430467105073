import React, { useEffect, useMemo, useState } from 'react';

import { useToast } from 'hooks';
import { useServicesContext } from 'hooks/useServicesContext';

import CFTitledComponent from 'components/CFTitledComponent';
import CFLoadWrapper from 'components/CFLoadWrapper';
import CFScatterPlotChart, { SeriesItem, SplitConfig } from 'components/charts/CFScatterPlotChart';
import { ToastType } from 'components/CFToast/types';

import { Model } from 'domain/model.types';
import { censoringPrediction } from 'repositories/analytic';

import { buildTTEAreas, fillTTEAreas } from './tte';
import { buildRiskConfig } from './risk';

import './detailed-churn-prediction.scss';

interface Props {
  onClose: () => void;
}

const DetailedChurnPrediction = ({ onClose }: Props) => {
  const [series, setSeries] = useState<SeriesItem[]>([]);
  const [splits, setSplits] = useState<SplitConfig[]>([]);
  const [model, setModel] = useState<Model | undefined>();
  const { addToast } = useToast();
  const { modelService } = useServicesContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const model = await modelService.getPinnedModel();

      if (!model) {
        return;
      }

      setModel(model);
    })();
  }, [modelService]);

  useEffect(() => {
    (async () => {
      const predictionData = await censoringPrediction();

      if (!predictionData.x) {
        onClose();

        addToast('No data for prediction chart. Make sure a model is selected', ToastType.WARNING);
        return;
      }

      const [series, maxLoyal] = buildTTEAreas(predictionData.tte_group_range);

      fillTTEAreas(predictionData, series, maxLoyal);

      const splitsConfig = buildRiskConfig(predictionData);

      setSplits(splitsConfig);
      setSeries(series);
      setIsLoading(false);
    })();
  }, []);

  const modelName = useMemo(() => {
    if (!model) {
      return ' (no model info found)';
    }

    return `${model?.definition.name} (${model.definition.id})`;
  }, [model]);

  return (
    <CFTitledComponent title={'Churn prediction'} subtitle={modelName} className="detailed-churn-prediction">
      <CFLoadWrapper isLoading={isLoading} background="solid">
        <CFScatterPlotChart
          className="churn-prediction-chart"
          title=""
          series={[...series]}
          splits={[...splits]}
          xLabel={'Lifetime prediction'}
          yLabel={'Churn risk'}
          showLegend={false}
          lastIsInfinite={true}
          showSplit={true}
          size={800}
        />
      </CFLoadWrapper>
    </CFTitledComponent>
  );
};

export default DetailedChurnPrediction;
