import React from 'react';

import { indexToNodeName } from 'services/markov/helpers.markov';
import { MarkovNode } from 'services/markov/markov.types';

import { useServicesContext } from 'hooks/useServicesContext';
import MarkovState from './MarkovState';

import './nodes-information.scss';

interface Props {
  nodes: MarkovNode[];
}

const NodesInformation = ({ nodes }: Props) => {
  const { traitSessionService: traitService } = useServicesContext();

  return (
    <div className="nodes-information">
      {nodes.map((node, i) => (
        <div key={node.name} className="nodes-information__item">
          <header>
            <div className="state-legend-item">{indexToNodeName(i)}</div>
            <span>{node.count} subjects</span>{' '}
          </header>

          <div className="state-list">
            {node.states.map((state) => (
              <MarkovState key={state.ptr} isLow={state.is_low} trait={traitService.getTraitDefinition(state.ptr)} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default NodesInformation;
