import React from 'react';

import './cf-message.scss';

interface Props {
  text: string;
  type: 'neutral' | 'error';
}

const CFMessage = ({ text, type }: Props) => {
  return <div className={`cf-message ${type}`}> {text} </div>;
};

export default CFMessage;
