import { useNavigate } from 'react-router-dom';
import { getOrganization, getProject } from 'services/session/session.service';

const objectToQueryString = (obj: Record<string, string>) => {
  const queryString = Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&');
  return queryString;
};

export const sessionQueryInfo = () => {
  let query = {};

  const orgId = getOrganization();
  const projId = getProject();

  if (orgId && projId) {
    query = { oid: orgId, pid: projId };
  }

  return query;
};

const useCFNavigation = () => {
  const navigate = useNavigate();

  const navigateKeepingSession = (target: string | object) => {
    const query = sessionQueryInfo();
    const queryString = objectToQueryString(query);

    const targetWithQuery = typeof target === 'string' ? `${target}?${queryString}` : target;

    return navigate(targetWithQuery);
  };

  return navigateKeepingSession;
};

export default useCFNavigation;
