import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Trait, TraitCategory, TraitSubject } from 'domain/traits.types';
import { Modules } from 'domain/general.types';

import { getCurrentProject } from 'services/session/session.service';
import { createTraitCode, getDisplayName } from 'services/traits/helpers.traits';

import CFSelect, { Option } from 'components/CFSelect';
import TraitItem from 'components/CFSelect/common/TraitItem';

import { useAnalyticsContext } from '../../context/useAnalyticsContext';
import { selectCohort } from '../../services/cohort';
import { useServicesContext } from 'hooks/useServicesContext';

import './filter-controls.scss';

const FilterControls = () => {
  const { cohortService, traitSessionService: traitService } = useServicesContext();

  const {
    subject,
    module,
    aggLevel,
    setModule,
    setSubject,
    selectTrait,
    setSelectedCohortIDs,
    selectedCohortIDs,
    selectedCharts,
    availableCohorts,
  } = useAnalyticsContext();
  const project = getCurrentProject();

  const [traitSearch, setTraitSearch] = useState('');
  const [cohortSearch, setCohortSearch] = useState('');
  const [traits, setTraits] = useState<Trait[]>([]);

  useEffect(() => {
    (async () => {
      const allTraits = await traitService.getTraits({
        subject,
        module: module as Modules,
        aggLevel,
        category: TraitCategory.Grouped,
        visibility: false,
      });
      setTraits(allTraits);
    })();
  }, [subject, module, aggLevel]);

  const selectableCohorts = (availableCohorts ?? []).map((item) => ({
    label: `${item.name} (${item.size})`,
    value: item.id.toString(),
  }));

  const selectableSubjects = (project?.subjects || []).map((subject_type) => ({
    label: subject_type,
    value: subject_type,
  }));

  const traitOptions = useMemo(() => {
    return traits
      .map((trait) => {
        return {
          label: getDisplayName(trait),
          value: createTraitCode(trait),
          meta: {
            trait: trait,
            simple: true,
          },
        };
      })
      .filter((option) => {
        return !traitSearch.trim() || option.label.toLowerCase().includes(traitSearch.toLowerCase());
      });
  }, [traits, traitSearch]);

  const handleSelectCohort = useCallback(
    (item: Option) => {
      setSelectedCohortIDs((prev) => {
        const newIDs = selectCohort(
          parseInt(item.value),
          prev.map((item) => parseInt(item)),
          availableCohorts ?? []
        );

        return newIDs.map((item) => `${item}`);
      });
    },
    [availableCohorts]
  );

  const handleModuleChange = useCallback((item: Option) => {
    setModule(item.value);
  }, []);

  const handleSelectSubject = useCallback((item: Option) => {
    setSubject(item.value as TraitSubject);
  }, []);

  const traitValues = useMemo(() => {
    return selectedCharts.map((traitCode) => {
      const trait = traitService.getTraitDefinition(traitCode);
      if (!trait) {
        return { label: '', value: '' };
      }

      return {
        label: getDisplayName(trait),
        value: traitCode,
      };
    });
  }, [selectedCharts, traitService]);

  return (
    <div className="control-container">
      <div className="control-form">
        <div className="form-group">
          <label>Subject</label>
          <CFSelect
            value={{
              label: subject,
              value: subject,
            }}
            options={selectableSubjects}
            onSelected={handleSelectSubject}
          />
        </div>
        <div className="form-group">
          <label>Module</label>
          <CFSelect
            options={project?.modules?.map((module) => ({ label: module, value: module })) || []}
            value={{ label: module, value: module }}
            onSelected={handleModuleChange}
          />
        </div>
        <div className="form-group">
          <label>Cohort</label>
          <CFSelect
            options={selectableCohorts.sort().filter((option) => {
              return !cohortSearch.trim() || option.label.toLowerCase().includes(cohortSearch.toLowerCase());
            })}
            value={selectedCohortIDs.map((cid) => ({
              label: cohortService.getCohort(cid)?.name || 'unknown',
              value: cid,
            }))}
            isMulti
            onSelected={handleSelectCohort}
            searchable
            onSearch={setCohortSearch}
          />
        </div>

        <div className="form-group">
          <label>Trait</label>
          <CFSelect
            options={traitOptions}
            value={traitValues}
            onSelected={(option: Option) => selectTrait(option.value)}
            isMulti
            searchable
            onSearch={setTraitSearch}
            Item={TraitItem}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterControls;
