import { AxiosResponse } from 'axios';
import {
  get as httpGet,
  post as httpPost,
  remove as httpRemove,
  put as httpPut,
} from '../../../repositories/drivers/http';

import { Role, RoleList, UserAdminRolesUpdateRequest, UserRolesInOrganization } from 'services/admin/users/user.types';
import { UserDefinition } from 'types';
import { ColAddr, TraitSubject } from 'domain/traits.types';
import { Module } from 'domain/general.types';

const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
const path = '/v1/user';

interface UserRepoConfig {
  token: string;
  oid: number;
  pid: number;
}

const userConfig = {
  token: '',
  oid: -1,
  pid: -1,
};

export interface CFUsersRepository {
  init: ({ token, oid, pid }: UserRepoConfig) => void;
  get: () => Promise<any>;
  getByUsername: (username: string) => Promise<any>;
  updateRoles: (username: string, roles: UserRolesInOrganization) => Promise<void>;
  removeFromOrganization: (username: string) => Promise<void>;
  removeFromPlatform: (username: string) => Promise<void>;
  invite: (username: string, roles: any) => Promise<void>;
  updateGDTSettings: (subject: TraitSubject, module: Module, traitAddresses: ColAddr[]) => Promise<void>;
  getGDTSettings: (subject: TraitSubject, module: Module) => Promise<ColAddr[]>;
}

export const init = ({ token, oid, pid }: UserRepoConfig) => {
  userConfig.token = token;
  userConfig.oid = oid;
  userConfig.pid = pid;
};

export const get = async () => {
  const config = {};

  const users = (await httpGet(
    `${serverBaseUrl}${path}/list-my-org?oid=${userConfig.oid}&pid=${userConfig.pid}`,
    config
  )) as AxiosResponse;

  // avoid null values when there are no roles
  users.data.forEach((user: UserDefinition) => {
    Object.values(user.roles).forEach((roles) => {
      if (roles.org_roles === null) {
        roles.org_roles = [];
      }

      if (roles.proj_roles === null) {
        roles.proj_roles = {};
      }
    });
  });

  return users.data || [];
};

export const getByUsername = async (username: string) => {
  // TODO: cache instead of making request again

  return (await get()).find((user: any) => user.username.trim() === username.trim());
};

export const removeFromPlatform = async (username: string) => {
  const config = {
    params: {
      oid: userConfig.oid,
      pid: userConfig.pid,
      username,
    },
  };

  await httpRemove(`${serverBaseUrl}${path}/delete`, config);
};

export const removeFromOrganization = async (username: string) => {
  const config = {
    params: {
      oid: userConfig.oid,
      pid: userConfig.pid,
      username,
    },
  };

  await httpRemove(`${serverBaseUrl}${path}/remove-from-org`, config);
};

export const listRoles = async (): Promise<RoleList> => {
  const config = {
    params: {
      oid: userConfig.oid,
      pid: userConfig.pid,
    },
  };

  const availableRoles = await httpGet(`${serverBaseUrl}${path}/available-roles`, config);

  return availableRoles?.data;
};

export const updateRoles = async (username: string, roles: UserRolesInOrganization) => {
  const config = {};

  const body = {
    username,
    role: roles,
  };

  await httpPut(`${serverBaseUrl}${path}/update-roles?oid=${userConfig.oid}&pid=${userConfig.pid}`, body, config);
};

export const updateAdminRoles = async (username: string, roles: Role[]) => {
  const config = {};

  const body: UserAdminRolesUpdateRequest = {
    username,
    roles,
  };

  await httpPut(`${serverBaseUrl}${path}/update-admin-roles?oid=${userConfig.oid}&pid=${userConfig.pid}`, body, config);
};

export const invite = async (username: string, roles: UserRolesInOrganization) => {
  const config = {};

  const body = {
    username,
    role: roles,
  };

  await httpPost(`${serverBaseUrl}${path}/invite?oid=${userConfig.oid}&pid=${userConfig.pid}`, body, config);
};

export const updateGDTSettings = async (subject: TraitSubject, module: Module, traitAddresses: ColAddr[]) => {
  const config = {};

  const body = {
    subject,
    module,
    traitds: traitAddresses,
  };

  await httpPut(`${serverBaseUrl}${path}/update-gdt-setting?oid=${userConfig.oid}&pid=${userConfig.pid}`, body, config);
};

export const getGDTSettings = async (subject: TraitSubject, module: Module): Promise<ColAddr[]> => {
  const config = {};

  const query = `oid=${userConfig.oid}&pid=${userConfig.pid}&sub=${subject}&module=${module}`;

  const availableCharts = await httpGet(`${serverBaseUrl}${path}/gdt-setting?${query}`, config);
  return availableCharts?.data || [];
};

export default {
  init,
  get,
  getByUsername,
  updateRoles,
  removeFromOrganization,
  removeFromPlatform,
  invite,
  updateGDTSettings,
  getGDTSettings,
};
