import React, {
  FC,
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose, faPen } from '@fortawesome/free-solid-svg-icons';

import './cf-editable-field.scss';

export interface CFEditableFieldRef {
  value: () => string;
}
interface CFEditableFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  onSave?: (value: any) => void;
}

const CFEditableField: FC<CFEditableFieldProps> = ({
  id,
  onSave,
  ...props
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [initialValue, setInitialValue] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  const closeEditMode = () => {
    if (inputRef.current) {
      inputRef.current.value = initialValue;
    }
    setEditMode(false);
  };

  const openEditMode = () => {
    if (inputRef.current) {
      setInitialValue(inputRef.current.value);
    }
    setEditMode(true);
  };

  const onEditHandler = async () => {
    const currentValue = inputRef?.current?.value;

    const onSavePromise = onSave?.(currentValue);

    if (currentValue) setInitialValue(currentValue);
    setEditMode(false);

    return onSavePromise;
  };

  useEffect(() => {
    if (!editMode) return;

    inputRef?.current?.focus();
  }, [editMode]);

  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === 'Enter') onEditHandler();
    else if (evt.key === 'Escape') closeEditMode();
  };

  return (
    <div className="cf-editable-field-wrapper">
      <input
        className="cf-editable-field--input"
        id={id}
        {...props}
        readOnly={!editMode}
        ref={inputRef}
        onKeyDown={handleKeyDown}
      />
      {editMode ? (
        <div className="cf-editable-field--actions">
          <a className="cf-editable-field--action" onClick={onEditHandler}>
            <FontAwesomeIcon
              icon={faCheck}
              color="white"
              size="sm"
              className="cf-editable-field--icon"
            />
          </a>
          <a className="cf-editable-field--action" onClick={closeEditMode}>
            <FontAwesomeIcon
              icon={faClose}
              color="white"
              size="sm"
              className="cf-editable-field--icon"
            />
          </a>
        </div>
      ) : (
        <a onClick={openEditMode}>
          <FontAwesomeIcon icon={faPen} color="white" size="lg" />
        </a>
      )}
    </div>
  );
};

export default CFEditableField;
