import React from 'react';

import CFTable, { ColumnType } from 'components/CFTable';

interface Props {
  response: Record<string, string>[];
}

const TableResponse = ({ response }: Props) => {
  const columns = Object.keys(response[0]).map((item) => ({
    title: item,
    field: item,
    type: ColumnType.STRING,
  }));

  return (
    <div className="table-response">
      <CFTable headers={columns} data={response} />
    </div>
  );
};

export default TableResponse;
