import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { Trait, TraitSubject, TraitUsage } from 'domain/traits.types';
import { useServicesContext } from 'hooks/useServicesContext';

import CFSelect, { Option } from 'components/CFSelect';
import TraitItem from 'components/CFSelect/common/TraitItem';
import TraitInputContainer from 'components/CFSelect/common/TraitInputContainer';
import CFInput, { CFInputType } from 'components/CFInput';

import { getDisplayName, getIdentifier } from 'services/traits/helpers.traits';

import CFTitledComponent from 'components/CFTitledComponent';

import { ThresholdTuple } from 'services/markov/markov.types';

import './tuple-creator.scss';

interface Props {
  showTitles?: boolean;
  notifyChange: (tuple: ThresholdTuple) => void;
}

export interface TupleCreatorRef {
  value: () => ThresholdTuple;
}

const TupleCreator = forwardRef<TupleCreatorRef, Props>(function TupleCreator(
  { showTitles = true, notifyChange }: Props,
  ref
) {
  const { traitSessionService: traitService } = useServicesContext();
  const [traits, setTraits] = useState<Trait[]>([]);
  const [selectedTrait, setSelectedTrait] = useState<Trait>();

  const weightRef = useRef<HTMLInputElement>(null);

  const buildTupleCreator = (trait?: Trait) => {
    return {
      weight: parseFloat(weightRef.current?.value || '0'),
      trait: (trait && getIdentifier(trait)) || '',
    };
  };

  useImperativeHandle(ref, () => ({
    value() {
      return buildTupleCreator(selectedTrait);
    },
  }));

  useEffect(() => {
    (async () => {
      const traits = await traitService.getTraits({ subject: TraitSubject.User, usage: TraitUsage.Restless });
      setTraits(traits);
      setSelectedTrait(traits[0]);
    })();
  }, []);

  const handleTraitChange = useCallback(
    async (item: Option) => {
      const newSelectedTrait = traitService.getTraitDefinition(item.value);

      if (!newSelectedTrait) {
        return;
      }

      setSelectedTrait(newSelectedTrait);

      notifyChange(buildTupleCreator(newSelectedTrait));
    },
    [selectedTrait]
  );

  const handleValueChange = () => {
    setTimeout(() => {
      notifyChange(buildTupleCreator());
    }, 0);
  };

  if (!selectedTrait) {
    return <div></div>;
  }

  return (
    <div className="tuple-creator">
      <CFTitledComponent title={showTitles ? 'Trait' : ''}>
        <CFSelect
          value={{
            label: getDisplayName(selectedTrait),
            value: getIdentifier(selectedTrait),
            meta: { trait: selectedTrait },
          }}
          onSelected={handleTraitChange}
          options={traits.map((trait) => ({
            label: getDisplayName(trait),
            value: getIdentifier(trait),
            meta: { trait },
          }))}
          Item={TraitItem}
          InputContainer={TraitInputContainer}
        />
      </CFTitledComponent>

      <CFTitledComponent title={showTitles ? 'Reward weight' : ''}>
        <CFInput
          ref={weightRef}
          type={CFInputType.Number}
          min={0}
          className="input-number"
          onChange={handleValueChange}
        />
      </CFTitledComponent>
    </div>
  );
});

export default TupleCreator;
