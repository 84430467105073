import { useEffect, useState } from 'react';
import { KEY } from 'repositories/storage.localstorage';
import { isIncognitoMode, subscribe, unsubscribe } from 'services/session/session.service';

const useIncognito = () => {
  const [incognito, setIncognito] = useState(isIncognitoMode());

  useEffect(() => {
    const handleIncognito = (isSet: boolean) => {
      setIncognito(isSet);
    };

    subscribe(KEY.INCOGNITO, handleIncognito);

    return () => unsubscribe(KEY.INCOGNITO, handleIncognito);
  }, []);

  return incognito;
};

export default useIncognito;
