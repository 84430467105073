import { RepoConfig } from './types';
import { ExceptionListObject } from '../domain/stats.types';
import { get as httpGet } from './drivers/http';
import { AxiosResponse } from 'axios/index';

const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
const path = '/v1/ingest';

const repoConfig = {
  token: '',
  oid: -1,
  pid: -1,
};

export const init = ({ token, oid, pid }: RepoConfig) => {
  repoConfig.token = token;
  repoConfig.oid = oid;
  repoConfig.pid = pid;
};

export const getExceptionList = async (): Promise<ExceptionListObject[]> => {
  const config = {};

  const exceptionsList = (await httpGet(
    `${serverBaseUrl}${path}/sdk/crash/list?oid=${repoConfig.oid}&pid=${repoConfig.pid}`,
    config
  )) as AxiosResponse;
  return exceptionsList.data;
};
