import React, { useEffect } from 'react';

import { OrgDefinition } from 'types';
import CFCardPicker from 'components/CFCardPicker';

import './organization-picker.scss';

interface Props {
  orgs: OrgDefinition[];
  onSelect: (id: string) => void;
}

const OrganizationPicker = ({ onSelect, orgs }: Props) => {
  useEffect(() => {
    if (orgs.length === 1) {
      onSelect(`${orgs[0].id}`);
    }
  }, []);

  return (
    <div className="organization-picker">
      <CFCardPicker title="organizations">
        {orgs.map(({ id, name }) => (
          <div className="card-item" key={`card-${id}`} onClick={() => onSelect(`${id}`)}>
            <div />
            <div>{name}</div>
          </div>
        ))}
      </CFCardPicker>
    </div>
  );
};

export default OrganizationPicker;
