import { TimeRFC3999 } from 'domain/general.types';
import { useServicesContext } from 'hooks/useServicesContext';
import { useEffect, useState } from 'react';
import { InterventionId } from 'services/intervention/intervention.types';

const useDecisionsPoints = (interventionId: InterventionId) => {
  const { interventionService } = useServicesContext();
  const [decisionPoints, setDecisionPoints] = useState<TimeRFC3999[]>();

  useEffect(() => {
    (async () => {
      const decisionPoints = await interventionService.getDecisionsPoints(interventionId);
      setDecisionPoints(decisionPoints);
    })();
  }, []);

  return decisionPoints;
};

export default useDecisionsPoints;
