export default class VOSampleId {
  private _id: string;

  constructor(id: string) {
    this._id = id;
  }

  get value() {
    return this._id;
  }

  valid() {
    return this._id !== '';
  }
}
