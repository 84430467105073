import { KPI } from 'components/kpis';
import { useServicesContext } from 'hooks/useServicesContext';
import { useEffect, useState } from 'react';

const GLOBAL_KPI_ROLE = 'All roles';

const useGlobalKpi = () => {
  const { userEngagementService } = useServicesContext();
  const [globalKPI, setGlobalKPI] = useState<KPI>({
    value: '-',
    unit: '',
    label: '',
    variation: 0,
  });

  const resetKPI = async () => {
    setGlobalKPI({ value: '-', unit: '', label: GLOBAL_KPI_ROLE, variation: 0 });
  };

  const updateKPI = async () => {
    userEngagementService.getGlobalKPI().then((globalGDT) => {
      const kpi = {
        value: globalGDT.cur,
        variation: globalGDT.prev ? ((globalGDT.cur - globalGDT.prev) / globalGDT.prev) * 100 : 0,
        unit: '',
        label: GLOBAL_KPI_ROLE,
      };

      setGlobalKPI(kpi);
    });
  };

  useEffect(() => {
    userEngagementService.subscribe(() => {
      resetKPI();
      updateKPI();
    });
  }, []);

  useEffect(() => {
    resetKPI();

    updateKPI();
  }, []);

  return globalKPI;
};

export default useGlobalKpi;
