import React, { useState } from 'react';

import { OrgInfo } from 'types';

import RightArrow from 'assets/arrow-right.png';

import CFPortal from 'components/CFPortal';

import './org-proj-picker.scss';

interface Props {
  currentOrg: number;
  orgprojs: OrgInfo[];
  top: number;
  left: number;
  cancel: () => void;
  onChange: (orgId: number, projId: number) => void;
}

const OrgProjPicker = ({ orgprojs, currentOrg, left, top, cancel, onChange }: Props) => {
  const [selectedOrg, setSelectedOrg] = useState(currentOrg);
  const [showOrganizations, setShowOrganizations] = useState(false);

  const selectableOrgs = orgprojs.map((orgproj) => ({
    value: orgproj.org.id,
    label: orgproj.org.name,
  }));

  const currentOrgInfo = orgprojs.find((orgproj) => orgproj.org.id === selectedOrg);
  const currentOrgName = currentOrgInfo?.org.name;

  const selectableProjects = currentOrgInfo?.projs.map((proj) => ({
    value: proj.id,
    label: proj.name,
  }));

  const handleOpenOrganizations = () => {
    setShowOrganizations((showOrganizations) => !showOrganizations);
  };

  const handleSelectOrg = (orgId: number) => {
    setSelectedOrg(orgId);
    setShowOrganizations(false);
  };

  const handleSelectProj = (projId: number) => {
    onChange(selectedOrg, projId);
  };

  return (
    <CFPortal onClickOutside={cancel} backdrop={false}>
      <div
        className={`org-proj-picker ${showOrganizations ? 'double-picker' : 'single-picker'}`}
        style={{ left: left - 150, top: top + 50 }}
      >
        <div className="project picker">
          <div onClick={handleOpenOrganizations}>
            <span> {currentOrgName} </span>
            <img src={RightArrow} />
          </div>

          {selectableProjects?.map((project) => (
            <div key={project.value} onClick={() => handleSelectProj(project.value)}>
              {project.label}
            </div>
          ))}
        </div>

        {showOrganizations && (
          <div className="org picker">
            {selectableOrgs.map((org) => (
              <div key={org.value} onClick={() => handleSelectOrg(org.value)}>
                {org.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </CFPortal>
  );
};

export default OrgProjPicker;
