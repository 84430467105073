import { Ptr } from 'services/cohort/cohort.types.api';

export enum TraitSummaryRepresentation {
  Percentage = 'percentage',
  Float = 'float',
}

export interface TraitSummaryResult {
  name: string;
  current: any;
  percentage: number;
  unit?: string;
}

export interface CuratedTraitSummaryResult extends TraitSummaryResult {
  value: number;
  unit: string;
  label: string;
  variation: number;
}

export type TraitSummaryResults = Record<Ptr, TraitSummaryResult>;

export interface InterventionStats {
  total: number;
  active: number;
}

export interface AnalyticSummary {
  landing_cohort_id: number;
  intervention_stats: InterventionStats;
  trait_summary_results: TraitSummaryResults;
}

export enum TTEGroup {
  Short = 'short',
  Average = 'average',
  Long = 'long',
  Loyal = 'loyal',
}

export enum EventRiskGroup {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export type TTEGroupRange = Record<TTEGroup, [number, number]>;

export interface ScatterPlot {
  x: number[];
  y: number[];
  tte_group_range: TTEGroupRange;
  event_risk_group_range: Record<EventRiskGroup, [number, number]>;
}
