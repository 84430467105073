import React from 'react';
import "./microsoft-login.scss";
import microsoftLogo from "../../assets/images/microsoft_logo.svg"

interface Props {
  onClick: () => void;
}

const MicrosoftLogin = ({ onClick}: Props) => {
  return (
    <div>
      <button className="microsoft-login-button" onClick={onClick}>
        <img src={microsoftLogo} alt="Microsoft logo" />
        Sign in with Microsoft
      </button>
    </div>
  );
};

export default MicrosoftLogin;
