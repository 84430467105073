import moment from 'moment';
import { DefinedRange } from './types';

export const today = (date: Date): DefinedRange => ({
  label: 'Today',
  startDate: date,
  endDate: date,
});

export const lastSevenDays = (date: Date): DefinedRange => ({
  label: 'Last 7 Days',
  startDate: moment(date).subtract(7, 'day').toDate(),
  endDate: date,
});

export const lastMonth = (date: Date): DefinedRange => ({
  label: 'Last 30 Days',
  startDate: moment(date).subtract(30, 'day').toDate(),
  endDate: date,
});

export const lastThreeMonths = (date: Date): DefinedRange => ({
  label: 'Last 3 months',
  startDate: moment(date).subtract(3, 'month').toDate(),
  endDate: date,
});

export const lastNineMonths = (date: Date): DefinedRange => ({
  label: 'Last 9 months',
  startDate: moment(date).subtract(9, 'month').toDate(),
  endDate: date,
});

export const getDefaultRanges = (date: Date): DefinedRange[] => [
  today(date),
  lastSevenDays(date),
  lastMonth(date),
  lastThreeMonths(date),
  lastNineMonths(date),
];

export const defaultRanges = getDefaultRanges(new Date());
