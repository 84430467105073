import React, { Component, ReactNode } from 'react';
import { Payload } from 'services/assistant/assistant.types';

interface ErrorBoundaryState {
  hasError: boolean;
}

interface Props extends React.PropsWithChildren {
  payloads: Payload[];
}

class PayloadErrorBoundary extends Component<Props, ErrorBoundaryState> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    //
  }

  render(): ReactNode {
    const str = JSON.stringify(this.props.payloads, undefined, 2);

    if (this.state.hasError) {
      return (
        <div className="payload-fallback">
          <div> Raw payload: </div>
          <div> {str} </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default PayloadErrorBoundary;
