import React, { Children } from 'react';

import useCFNavigation from 'hooks/useCFNavigation';
import { CFRoutes } from 'routes';

import './cf-nav-list.scss';

interface NavigationItem {
  title: string;
  target: CFRoutes;
}

interface Props {
  titles: NavigationItem[];
  selected: string;
  showSeparatorLine?: boolean;
  underlineSelected?: boolean;
  children?: JSX.Element | JSX.Element[] | boolean;
}

export const NavListAction = (props: any) => {
  return <div className="nav-list-action"> {props.children} </div>;
};

export const CFNavListWithLinks = ({
  titles,
  selected,
  showSeparatorLine = true,
  underlineSelected = true,
  children,
}: Props) => {
  const classes = ['cf-nav-list-links'];
  const navigate = useCFNavigation();

  if (showSeparatorLine) {
    classes.push('separator');
  }

  const arrayChildren = Children.toArray(children);
  const actions = arrayChildren.filter((child) => (child as any).type.name === NavListAction.name);

  return (
    <div className={classes.join(' ')}>
      {titles.map(({ title, target }) => (
        <span
          key={title.split(' ').join('-')}
          className={[
            'nav-list-item',
            title.toLowerCase() === selected.toLowerCase() ? 'selected' : '',
            title.toLowerCase() === selected.toLowerCase() && underlineSelected ? 'underline-selected' : '',
          ].join(' ')}
          onClick={() => navigate(target)}
        >
          {title}
        </span>
      ))}

      <div className="spacer"></div>
      <div className="actions">{actions}</div>
    </div>
  );
};
