import React, { ReactNode } from 'react';
import useCFNavigation from 'hooks/useCFNavigation';

import classnames from 'classnames';

import { CFNavList } from 'components/CFNavList';
import { allowedTabs, monitoringByTab, Tabs } from './tabs';

import './admin-view.scss';

interface Props {
  children: ReactNode | ReactNode[];
  className?: string;
  tab: Tabs;
}

const AdminLayout = ({ children, className, tab }: Props) => {
  const navigate = useCFNavigation();

  return (
    <div className={classnames(className)}>
      <CFNavList
        titles={allowedTabs()}
        selected={tab}
        onClick={(selectedTab) => {
          navigate(monitoringByTab[selectedTab]);
        }}
      />
      <div className="admin-container">{children}</div>
    </div>
  );
};

export default AdminLayout;
