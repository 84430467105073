import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';

import { Trait, TraitSubject, TraitUsage } from 'domain/traits.types';

import CohortService from 'services/cohort/cohort.service';
import TraitService from 'services/traits/traitSession.service';
import { FilterGroupOperation, Leaf } from 'services/cohort/cohort.types.api';
import Cohort from 'services/cohort/domain/Cohort';
import Filter from 'services/cohort/domain/Filter';

import LeafBuilder, { LeafRef } from 'connected-components/LeafBuilder';
import TreeViewer from 'connected-components/TreeViewer';

import CFTitledSection from 'components/CFTitledSection';
import CFTitledComponent from 'components/CFTitledComponent';
import CFSelect, { Option } from 'components/CFSelect';

import './cohort-selector.scss';

interface Props {
  cohortService: CohortService;
  traitService: TraitService;
  onCohortSelected: (cohort: Cohort) => void;
  onExtraFilterUpdated: (filter: Leaf<Filter>) => void;
  onRemove?: () => void;
  subject?: TraitSubject;
  defaultCohort?: Cohort;
  defaultFilters?: Leaf;
}

const COHORT_PAGE_SIZE = 100;

const CohortSelector = ({
  cohortService,
  traitService,
  onCohortSelected,
  onExtraFilterUpdated,
  onRemove,
  subject = TraitSubject.User,
  defaultCohort,
  defaultFilters,
}: Props) => {
  const [listOfCohorts, setListOfCohorts] = useState<Cohort[]>([]);
  const [traits, setTraits] = useState<Trait[]>([]);
  const [cohort, setCohort] = useState<Cohort>();
  const filterGroupRef = useRef<LeafRef>() as React.MutableRefObject<LeafRef>;

  useEffect(() => {
    if (!filterGroupRef || !filterGroupRef.current) {
      return;
    }
    if (defaultFilters) {
      onExtraFilterUpdated(filterGroupRef.current.value());
    }
  }, [cohort, defaultFilters, filterGroupRef]);

  useEffect(() => {
    setTimeout(() => {
      if (!filterGroupRef || !filterGroupRef.current) {
        return;
      }
      onExtraFilterUpdated(filterGroupRef.current.value());
    }, 30);
  }, [defaultFilters, filterGroupRef]);

  useEffect(() => {
    cohortService
      .getListOfCohorts(0, COHORT_PAGE_SIZE, subject)
      .then((cohortList) => {
        // TODO: handling pagination
        setListOfCohorts(cohortList.data);
      })
      .catch(() => {
        console.log('Error getting cohorts');
      });
  }, [subject]);

  useEffect(() => {
    if (!defaultCohort) {
      return;
    }

    setCohort(defaultCohort);
  }, [defaultCohort]);

  useEffect(() => {
    (async () => {
      if (!cohort) {
        return;
      }

      const filterTraits = await traitService.getTraits({
        subject: cohort.subject_type as TraitSubject,
        usage: TraitUsage.Filter,
      });

      setTraits(filterTraits);
    })();
  }, [cohort]);

  const handleFilterGroupChange = useCallback(() => {
    setTimeout(() => {
      if (!filterGroupRef.current) {
        return;
      }

      onExtraFilterUpdated(filterGroupRef.current.value());
    }, 30);
  }, [filterGroupRef]);

  const handleCohortSelected = async (option: Option) => {
    const cohort = listOfCohorts.find((cohort) => cohort.id === parseInt(option.value));

    if (!cohort) {
      return;
    }

    setCohort(cohort);

    onCohortSelected(cohort);
  };

  return (
    <div>
      <CFTitledComponent title={'Select Cohort'} className="model-cohort-select">
        <div>
          <CFSelect
            options={
              listOfCohorts?.map((cohort) => ({
                label: cohort.name,
                value: `${cohort.id}`,
                tags: [cohort.subject_type],
              })) || []
            }
            value={{ label: cohort?.name || '', value: `${cohort?.id}` }}
            onSelected={handleCohortSelected}
          />

          {onRemove && (
            <FontAwesomeIcon icon={faTrashCan} size="lg" className="model-cohort-select-icon" onClick={onRemove} />
          )}
        </div>
      </CFTitledComponent>

      {cohort && (
        <>
          <TreeViewer value={cohort?.tree} />

          <CFTitledSection title="Extra filter">
            <LeafBuilder
              subject={cohort.subject_type as TraitSubject}
              ref={filterGroupRef}
              fixedOperation={FilterGroupOperation.And}
              traits={traits}
              onLeafChange={handleFilterGroupChange}
              defaultFilters={defaultFilters}
            />
          </CFTitledSection>
        </>
      )}
    </div>
  );
};

export default CohortSelector;
