import React, { useEffect, useState } from 'react';

import { ExceptionListObject, ExceptionObject } from 'domain/stats.types';
import { Tag, TagTypes } from 'components/Tag';
import { getExceptionList } from 'repositories/exceptionview';
import CFDataTable from 'components/CFDataTable';
import { Column, ColumnType } from 'components/CFTable';
import { CFNavListWithLinks } from 'components/CFNavListWithLinks';
import { ToastType } from 'components/CFToast/types';
import CFLoadWrapper from 'components/CFLoadWrapper';
import { displayDate, getDate, getTime } from 'helpers/dates';
import ExpandedView from './expandedView';

import { CFRoutes } from 'routes';
import { useToast } from 'hooks';

import { Tabs } from '..';

import './exceptionview.scss';

interface ExceptionTableItem {
  u_id: string;
  app_version: string;
  device_id: string;
  date: string;
  time: string;
}

const columns: Column[] = [
  {
    title: 'Tag',
    field: 'tag',
    type: ColumnType.STRING,
    renderCell: (row) => {
      return <Tag text={row.event_type} type={TagTypes.System} />;
    },
    style: {
      maxWidth: '75px',
    },
  },
  {
    title: 'Description',
    field: 'title',
    type: ColumnType.STRING,
    style: {
      maxWidth: '300px',
    },
  },
  {
    title: 'Type',
    field: 'exception_type',
    type: ColumnType.STRING,
  },
  {
    title: 'Source',
    field: 'exception_source',
    type: ColumnType.STRING,
  },
  {
    title: 'Latest Incident Time',
    field: 'ts',
    type: ColumnType.OBJECT,
    renderCell: (row) => {
      return displayDate(new Date(row.recent_crashes[0].ts), '00:00', false, false, '');
    },
  },
  {
    title: 'Quantity',
    field: 'total_cnt',
    type: ColumnType.OBJECT,
    renderCell: (row) => {
      return `${row.total_cnt} exceptions`;
    },
  },
  {
    title: '',
    field: 'expanded',
    type: ColumnType.OBJECT,
    expandable: true,
    renderCell: (row) => (
      <ExpandedView
        exceptionHistory={row.recent_crashes.map(transformCrashToTableView)}
        stackTrace={row.recent_crashes[0].stack_trace}
      />
    ),
  },
];

function transformCrashToTableView(crash_item: ExceptionObject): ExceptionTableItem {
  return {
    u_id: crash_item.u_id,
    app_version: crash_item.app_info.version,
    device_id: crash_item.d_info.id,
    date: getDate(crash_item.ts),
    time: getTime(crash_item.ts),
  };
}

const ExceptionView = () => {
  const { addToast } = useToast();

  const [exceptions, setExceptions] = useState<ExceptionListObject[]>([]);
  const [currentPage, setCurrentPage] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  const pageSize = 10;

  useEffect(() => {
    getExceptionList()
      .then((data: ExceptionListObject[]) => {
        setExceptions(data.sort((a, b) => b.latest.localeCompare(a.latest)));
      })
      .catch((e) => {
        console.error(e);
        addToast(`Couldn't load exception elements`, ToastType.ERROR, 5000);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handlePageChange = (page: number) => {
    setCurrentPage(page - 1);
  };

  return (
    <div>
      <CFNavListWithLinks
        titles={[
          { title: Tabs.Monitoring, target: CFRoutes.integration },
          { title: Tabs.IngestHistory, target: CFRoutes.integration_ingest_history },
          { title: Tabs.CatalogHistory, target: CFRoutes.integration_catalog_history },
          { title: Tabs.SdkExceptions, target: CFRoutes.integration_exception_view },
          { title: Tabs.ApiKeys, target: CFRoutes.integration_keys },
        ]}
        selected={Tabs.SdkExceptions}
      />
      <CFLoadWrapper isLoading={isLoading}>
        <div className="exception-list-view">
          <CFDataTable
            headers={columns}
            total={exceptions.length}
            data={exceptions
              ?.slice(currentPage * pageSize, (currentPage + 1) * pageSize)
              .map((data, index) => ({ ...data, id: `${currentPage}:${index}` }))}
            onPaginated={handlePageChange}
          />
        </div>
      </CFLoadWrapper>
    </div>
  );
};

export default ExceptionView;
