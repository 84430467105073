import React, { useEffect, useState } from 'react';

import { CFGraph } from 'components/charts/CFGraph';

import { SubjectId } from 'domain/general.types';

import { GroupName, MarkovProcessId, NodeUpdate, SimpleGraph } from 'services/markov/markov.types';
import { getGraphPerSubject } from 'services/markov/markov.service';
import { indexToNodeName } from 'services/markov/helpers.markov';

interface Props {
  processId: MarkovProcessId;
  groupName: GroupName;
  subjectId: SubjectId;
  updates: NodeUpdate[];
}

const ScaledSubjectGraph = ({ groupName, processId, subjectId, updates }: Props) => {
  const [graph, setGraph] = useState<SimpleGraph>();

  useEffect(() => {
    (async () => {
      const graph = await getGraphPerSubject(subjectId, processId, groupName, updates);

      const simpleGraph: SimpleGraph = {};

      for (let i = 0; i < graph[groupName].probs.length; i++) {
        const currentNodeName = indexToNodeName(i);
        simpleGraph[currentNodeName] = {};

        for (let target = 0; target < graph[groupName].probs[i].length; target++) {
          simpleGraph[currentNodeName][indexToNodeName(target)] = [
            parseFloat(graph[groupName].probs[i][target].toFixed(3)),
            parseFloat(graph[groupName].rewards[i][target].toFixed(2)),
          ];
        }
      }

      setGraph(simpleGraph);
    })();
  }, [groupName, subjectId, updates]);

  if (!graph) {
    return <div></div>;
  }

  return (
    <div>
      <CFGraph
        title={groupName}
        prefix=""
        data={graph}
        onNodeClicked={() => {
          /* */
        }}
      />
    </div>
  );
};

export default ScaledSubjectGraph;
