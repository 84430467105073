import React, { useCallback } from 'react';

import classNames from 'classnames';
import { LegendItem } from '../..';

import { useScatterRangeContext } from '../../useScatterRange';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import './legend.scss';

interface Props {
  className?: string;
  items: LegendItem[];
}

const ScatterLegend = ({ className, items }: Props) => {
  const { selectedItems, setSelectedItems } = useScatterRangeContext();

  const handleClickOnItem = useCallback((index: number) => {
    setSelectedItems((items) => {
      if (items.has(index)) {
        items.delete(index);
      } else {
        items.add(index);
      }

      return new Set(items);
    });
  }, []);

  return (
    <div className={classNames(className, 'scatter-legend')}>
      {items.map((item, i) => (
        <div
          className={classNames('legend-item', { selected: selectedItems.has(i) })}
          key={item.name}
          onClick={() => handleClickOnItem(i)}
        >
          <div className="legend-item__circle" style={{ backgroundColor: item.color }}></div>
          <span>{item.name}</span>
          {selectedItems.has(i) && <FontAwesomeIcon icon={faCheck} />}
        </div>
      ))}
    </div>
  );
};

export default ScatterLegend;
