import React from 'react';

import './static-text.scss';

interface Props {
  text: string;
}

const StaticText = ({ text }: Props) => {
  if (!text) {
    return <div></div>;
  }

  return <div className="message-text">{text}</div>;
};

export default StaticText;
