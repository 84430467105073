import React, { useRef } from 'react';

import { RGBColor, TimeRFC3999 } from 'domain/general.types';

import dayjs from 'dayjs';
import { tooltipBarFormatter, X_AXIS_DATE_FORMAT } from '../chartCommon';
import { AggLevel } from 'domain/stats.types';

import { EChartsOption, ReactECharts } from '..';

import colors from 'common.scss';

import './cf-stacked-chart.scss';

export interface StackedValue {
  value: number;
  time: TimeRFC3999;
}

export interface SeriesItem {
  values: StackedValue[];
  name: string;
  color?: RGBColor;
}

export interface CFStackedChartProps {
  title?: string;
  xLabel?: string;
  yLabel?: string;
  xAxis: string[];
  series: SeriesItem[];
  isLoading: boolean;
  type?: 'bar' | 'line';
}

const CFStackedChart = ({ title, series, xLabel, type = 'line', isLoading = true }: CFStackedChartProps) => {
  const chartRef = useRef<HTMLDivElement>(null);

  const xAxisTimestampsMap: Record<TimeRFC3999, boolean> = series.reduce((acc: Record<TimeRFC3999, boolean>, curr) => {
    curr.values.map((value) => value.time).forEach((time) => (acc[time] = true));

    return acc;
  }, {});

  const xAxisTimestamps = Object.keys(xAxisTimestampsMap).sort();

  const options: EChartsOption = {
    grid: {
      top: series.length < 4 ? '15%' : '30%',
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: colors.dark80,
      borderColor: colors.dark80,
      formatter: tooltipBarFormatter,
    },
    legend: {
      show: true,
      top: 'top',
      right: '150px',
      textStyle: {
        color: '#fff',
        fontSize: 14,
      },
      inactiveColor: '#777',
      data: series.map((seriesItem) => ({
        name: seriesItem.name,
        icon: 'circle',
        itemStyle: {
          color: seriesItem.color,
        },
      })),
    },
    xAxis: {
      type: 'category',
      name: xLabel,
      nameLocation: 'middle',
      nameGap: 30,
      nameTextStyle: {
        color: colors.dark50,
        fontSize: 14,
        fontWeight: 600,
      },
      axisLabel: {
        formatter: function (value: any) {
          return dayjs(value).format(X_AXIS_DATE_FORMAT[AggLevel.Hour]);
        },
      },
    },
    yAxis: [
      {
        type: 'value',
        splitLine: {
          lineStyle: {
            color: colors.dark50,
          },
        },
      },
    ],
    series: series.map((seriesItem) => {
      return {
        name: seriesItem.name,
        type,
        stack: 'Total',
        lineStyle: {
          width: 0,
        },
        barMinHeight: 2,
        itemStyle: {
          color: seriesItem.color,
        },

        areaStyle: {
          color: seriesItem.color,
          opacity: 0.7,
        },
        data: xAxisTimestamps.map((tick) => {
          const value = seriesItem.values.find((item) => item.time === tick);

          return [tick, value?.value || 0];
        }),
        showSymbol: xAxisTimestamps.length <= 2,
        symbol: 'circle',
        symbolSize: 15,
        connectNulls: true,
        // show symbol if the number of items in the charts is less than 3. For the case of the interval chart,
        // it may have 2 points, but the last one is empty. So, in practice, there will be only one point with data.
        // That's why the comparison is with 3
      };
    }),
  };

  return (
    <div className="cf-stacked-area-chart">
      {title && <span className="chart-title"> {title || ''} </span>}
      <ReactECharts ref={chartRef} option={options} style={{ width: `100%`, height: '330px' }} loading={isLoading} />
    </div>
  );
};

export default CFStackedChart;
