import React from 'react';

import './cf-card.scss';

interface Props {
  children: JSX.Element | JSX.Element[];
}

const CFCard = ({ children }: Props) => {
  return <div className="cf-card"> {children}</div>;
};

export default CFCard;
