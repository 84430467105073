import { AxiosResponse } from 'axios';
import { get as httpGet } from 'repositories/drivers/http';

import { ModelId } from 'domain/model.types';
import { RepoConfig } from 'repositories/types';
import { ArmInfo } from './model.repo.types';

const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
const path = '/v1/model';

const repoConfig = {
  token: '',
  oid: -1,
  pid: -1,
};

export const init = ({ token, oid, pid }: RepoConfig) => {
  repoConfig.token = token;
  repoConfig.oid = oid;
  repoConfig.pid = pid;
};

export const getArmMetrics = async (modelId: ModelId): Promise<ArmInfo> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };

  try {
    const data = (await httpGet(
      `${serverBaseUrl}${path}/bandit/eval_plot/${modelId}`,
      config
    )) as AxiosResponse<ArmInfo>;

    return data.data;
  } catch (err) {
    console.error('error getting data for plots by id: ', err);
    throw new Error('error-model-metrics-by-id');
  }
};
