import React from 'react';

import { Option } from 'components/CFSelect';
import { Props as InputContoinerProps } from 'components/CFSelect/defaults/InputContainer';
import CFTraitItem from 'connected-components/traits/CFTraitItem';

import './trait-input-container.scss';

const TraitInputContainer = ({ selected }: InputContoinerProps): JSX.Element => {
  if (Array.isArray(selected)) {
    return <div className="cf-select-inputContainer trait-input-container">{selected.length} selected</div>;
  }

  const _selected = selected as Option;

  return (
    <div className="cf-select-inputContainer trait-input-container">
      {_selected.meta?.trait?.addr ? <CFTraitItem addr={_selected.meta?.trait?.addr} /> : _selected.label}
    </div>
  );
};

export default TraitInputContainer;
