import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import CFSelectLegacy, { SelectableItem } from 'components/CFSelectLegacy';
import CFTitledSection from 'components/CFTitledSection';
import CFLineChartWithConfidenceInterval from 'components/charts/CFLineChartWithConfidenceInterval';
import { DataItem, LineStyle } from 'components/charts/chartCommon';

import { InterventionViewExtended, MetricData } from 'services/intervention/intervention.types';
import { TsPoint } from 'services/intervention/intervention.types';
import { getMetric } from 'services/intervention/intervention.repo';
import { getDisplayName, getIdentifier } from 'services/traits/helpers.traits';
import TraitService from 'services/traits/traitSession.service';
import InterventionService from 'services/intervention/intervention.service';

import NudgeStats from './NudgeStats';

import { getColorForGroup } from './helpers';

import { CFRoutes } from 'routes';

import useCFNavigation from 'hooks/useCFNavigation';

import './metrics.scss';

const ALL_GROUPS = 'all_groups';

interface Props {
  interventionService: InterventionService;
  traitService: TraitService;
}

const InterventionABMetrics = ({ interventionService, traitService }: Props) => {
  const navigate = useCFNavigation();

  const [metrics, setMetrics] = useState<MetricData>();
  const [isWithConfidenceLoading, setIsWithConfidenceLoading] = useState<boolean>(true);
  const [intervention, setIntervention] = useState<InterventionViewExtended>();
  const [selectedTraitName, setSelectedTraitName] = useState<string>('');

  const params = useParams();

  const interventionId = useMemo(() => {
    return parseInt(params.id as string);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const intv = await interventionService.getView(interventionId);
        setIntervention(intv);
      } catch (err: any) {
        navigate(CFRoutes.intervention);
      }
    })();
  }, [interventionId]);

  useEffect(() => {
    (async () => {
      const metrics = await getMetric(interventionId);
      setMetrics(metrics);
      setIsWithConfidenceLoading(false);
    })();
  }, []);

  const traitNames = useMemo(() => {
    const traitNames = intervention?.intervention.metric_policy.ab?.metrics.map((metric) => metric.ptr);

    return traitNames;
  }, [intervention]);

  const traits = useMemo(() => {
    const traits = intervention?.intervention.metric_policy.ab?.metrics.map((metric) =>
      traitService.getTraitFromAddr(metric)
    );

    return traits;
  }, [intervention]);

  useEffect(() => {
    if (!traitNames) {
      return;
    }

    setSelectedTraitName(traitNames[0]);
  }, [traitNames]);

  const sortByControlGroup = useCallback(
    (groupName: string): number => {
      if (groupName === intervention?.controlGroup) {
        return -1;
      } else {
        return 1;
      }
    },
    [intervention]
  );

  const timeseriesForTrait = useMemo(() => {
    if (!metrics || !selectedTraitName) {
      return [];
    }

    if (!metrics.ab.metrics[selectedTraitName]) {
      return [];
    }

    return Object.keys(metrics.ab.metrics[selectedTraitName])
      .sort((x, y) => y.localeCompare(x))
      .sort(sortByControlGroup)
      .map((groupName, i) => ({
        name: groupName,
        color: getColorForGroup(groupName, i, intervention?.controlGroup === groupName),
        lineStyle: groupName === ALL_GROUPS ? LineStyle.DOTTED : LineStyle.SOLID,
        data: [
          ...metrics.ab.metrics[selectedTraitName][groupName].map((tspoint: TsPoint) => {
            const itemData: DataItem = {
              time: tspoint.t,
              value: tspoint.v,
            };

            return itemData;
          }),
        ],
      }));
  }, [metrics, selectedTraitName]);

  return (
    <>
      <CFTitledSection title={`Metrics (avg)`}>
        <div className="chart-action">
          <span>Trait</span>
          {traits?.length && (
            <CFSelectLegacy
              options={(traits || []).map((trait) => ({
                value: getIdentifier(trait),
                label: getDisplayName(trait, true),
              }))}
              defaultOption={[{ value: getIdentifier(traits[0]), label: getDisplayName(traits[0], true) }]}
              onSelected={(item: SelectableItem[]) => setSelectedTraitName(item[0].value)}
            />
          )}
        </div>

        <div className="reward-chart">
          <CFLineChartWithConfidenceInterval
            data={timeseriesForTrait}
            xLabel="Decision point"
            isLoading={isWithConfidenceLoading}
          />
        </div>
      </CFTitledSection>

      <CFTitledSection title={'Nudge stats'}>
        <NudgeStats id={interventionId} />
      </CFTitledSection>
    </>
  );
};

export default InterventionABMetrics;
