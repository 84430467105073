import React, { useEffect, useMemo } from 'react';
import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import CFSelect, { Option } from 'components/CFSelect';

import DatetimeRangePicker from 'components/DateTime/DatetimeRangePicker';
import { DateRange, DefinedRange } from 'components/DateTime/DatetimeRangePicker/types';
import { lastMonth, lastSevenDays } from 'components/DateTime/DatetimeRangePicker/default';

import LabeledComponent from 'views/analytics/components/LabeledComponent';

import { oneWeekAgo, pruneHours, today } from 'helpers/dates';
import { useServicesContext } from 'hooks/useServicesContext';

import { toString as dateToString } from 'helpers/dates';

import './filter-controls.scss';

const FilterControls = () => {
  const { userEngagementService } = useServicesContext();

  const [country, setCountry] = useState('');
  const [organization, setOrganization] = useState('');
  const [state, setState] = useState('');

  const [countries, setCountries] = useState<string[]>([]);
  const [states, setStates] = useState<string[]>([]);
  const [organizations, setOrganizations] = useState<string[]>([]);

  const [search, setSearch] = useState('');

  const [searchParams] = useSearchParams();

  const defaultStartDate = searchParams.get('startDate') || dateToString(oneWeekAgo());
  const defaultEndDate = searchParams.get('endDate') || pruneHours(today());

  useEffect(() => {
    (async () => {
      const countries = await userEngagementService.getCountries();
      const cities = await userEngagementService.getCities();
      const states = await userEngagementService.getStates();

      setStates(states);
      setOrganizations(cities);
      setCountries(countries);

      setState('');
      setCountry(countries[0] || '');
      setOrganization('');
    })();
  }, []);

  const handleSelectCountry = useCallback((item: Option) => {
    userEngagementService.country = item.value;
    setCountry(item.value);
  }, []);

  const handleSelectState = useCallback((item: Option) => {
    setState(item.value);
    userEngagementService.state = item.value;
  }, []);

  const handleSelectCity = useCallback((item: Option) => {
    setOrganization(item.value);
    userEngagementService.organizationName = item.value;
  }, []);

  const handleRangeChange = useCallback((dateRange: DateRange) => {
    if (dateRange.startDate && dateRange.endDate) {
      userEngagementService.endDate = pruneHours(dateRange.endDate);
      userEngagementService.startDate = pruneHours(dateRange.startDate);
    }
  }, []);

  const handleOrganizationSearch = useCallback((search: string) => {
    setSearch(search.toLowerCase());
  }, []);

  const now = new Date();
  const defaultDateRanges: DefinedRange[] = [lastSevenDays(now), lastMonth(now)];

  const filteredOrgs = useMemo(() => {
    const orgs = organizations.filter((org) => org.toLocaleLowerCase().includes(search));

    if (search !== '') {
      return orgs.map((org) => ({ label: org, value: org }));
    } else {
      return [{ label: '<all organizations>', value: '' }, ...orgs.map((org) => ({ label: org, value: org }))];
    }
  }, [search, organizations]);

  return (
    <div className="filter-controls">
      <LabeledComponent label="Country">
        <CFSelect
          options={countries.map((country) => ({ label: country, value: country }))}
          value={{ label: country, value: country }}
          onSelected={handleSelectCountry}
        />
      </LabeledComponent>

      <LabeledComponent label="Region state">
        <CFSelect
          options={states.map((state) => ({ label: state, value: state }))}
          value={{ label: state, value: state }}
          onSelected={handleSelectState}
        />
      </LabeledComponent>

      <LabeledComponent label="Organization">
        <CFSelect
          options={filteredOrgs}
          value={{ label: organization, value: organization }}
          onSelected={handleSelectCity}
          searchable={true}
          onSearch={handleOrganizationSearch}
        />
      </LabeledComponent>

      <LabeledComponent label="&nbsp;">
        <DatetimeRangePicker
          onChange={handleRangeChange}
          definedRanges={defaultDateRanges}
          initialDateRange={{
            startDate: new Date(defaultStartDate),
            endDate: new Date(defaultEndDate),
          }}
          maxDate={new Date(defaultEndDate)}
          showTime={false}
        />
      </LabeledComponent>
    </div>
  );
};

export default FilterControls;
