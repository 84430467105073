import React, { useEffect, useState } from 'react';

import { useServicesContext } from 'hooks/useServicesContext';

import { useSearchParams } from 'react-router-dom';

import sessionService from 'services/session/session.service';
import { URL_PARAMS } from '../constants';

export const SESSION_COHORT_KEY = 'cohorts';

const useInitializedCohorts = (): [string[], React.Dispatch<React.SetStateAction<string[]>>] => {
  const [searchParams] = useSearchParams();
  const { landingService } = useServicesContext();

  const defaultCohortIDs = searchParams.get(URL_PARAMS.Cohort)?.split(',') || [];

  const [selectedCohortIDs, setSelectedCohortIDs] = useState<string[]>(() => {
    const sessionCohortIDs = sessionService.getCustomValue(SESSION_COHORT_KEY);

    if (sessionCohortIDs) {
      return sessionCohortIDs;
    } else {
      return defaultCohortIDs;
    }
  });

  useEffect(() => {
    (async () => {
      const landingCohortId = await landingService.getLandingCohortId();

      const sessionCohortIDs = sessionService.getCustomValue(SESSION_COHORT_KEY);

      if (sessionCohortIDs && sessionCohortIDs.length) {
        setSelectedCohortIDs(sessionCohortIDs);
      } else if (defaultCohortIDs && defaultCohortIDs.length) {
        setSelectedCohortIDs(defaultCohortIDs);
      } else {
        setSelectedCohortIDs([landingCohortId.toString()]);
      }
    })();
  }, []);

  useEffect(() => {
    sessionService.saveCustomValue(SESSION_COHORT_KEY, selectedCohortIDs);
  }, [selectedCohortIDs]);

  return [selectedCohortIDs, setSelectedCohortIDs];
};

export default useInitializedCohorts;
