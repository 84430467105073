import React from 'react';

import './waiting-text.scss';

interface Props {
  steps: string[];
  animated?: boolean;
}

const WaitingText = ({ animated = true, steps }: Props) => {
  const splittedSteps = steps
    .map((step) => step.split('\n'))
    .flat()
    .filter((step) => step.trim());

  return (
    <div data-testid="message-waiting">
      {splittedSteps.map((step) => (
        <div key={Math.random()}>{step}</div>
      ))}

      {animated && <span className="waiting-text">...</span>}
    </div>
  );
};

export default WaitingText;
