import React, { useMemo } from 'react';

import CFBarChart from 'components/charts/CFBarChart';
import { KPI } from 'components/kpis';
import { isNumber } from 'lodash';

interface Props {
  kpis: KPI[];
}

const RoleDistributionChart = ({ kpis }: Props) => {
  const kpisValues = useMemo(() => {
    return kpis.map((kpi) => ({ count: isNumber(kpi.value) ? (kpi.value as number) : 0, type: kpi.label }));
  }, [kpis]);

  return (
    <CFBarChart barWidth={15} data={kpisValues} xLabel="Active users" title="Distribution by role" isLoading={false} />
  );
};

export default RoleDistributionChart;
