export const armConfidenceDescription = `Confidence in the arm assignment at the last decision point for each 
participant, shown as a t-sne visualization based on the values of contextual
 traits used for the assignment. Confidence here is understood as the difference
  between the probabilities of assignment between the best arm and the second best arm`;

export const bestArmAssignments = `Arm picked at the last decision point for each participant, 
  shown as a t-sne visualization based on the values of 
  contextual traits used for the assignment`;

export const nudgeStats = `Number of nudges sent at each decision point stratified by nudge status,
 where open, discard and show refer to nudges that were displayed
  to the user and either tapped on, closed or not interacted with respectively; 
  block those nudges that were requested and didn’t reach the user to them having
   notifications blocked; and error and unknown those that were never
    sent to the user due to either an identified or unidentified error respectively`;

export const groupProportions = `Fraction of participants assigned to each arm at each decision point`;

export const supportingMetrics = `Average value of the transformed supporting metrics 
(i.e., ln(1+x) where x is the supporting metric) 
for each arm at the different decision points,
 for users in the train and test splits, and considering all of them together (full)`;

export const objectiveMetric = `Average reward (i.e., ln(1+x) where x is the objective metric)
 for each arm at the different decision points, 
 for users in the train and test splits,
  and considering all of them together (full)`;

export const sensitivityChart = `Rows are the different traits in the context, 
columns are the arms. Each cell represents the sensitivity of that arm’s probability 
to that trait, depending on the sign (positive or negative) 
and scale (negligible, small, medium or large). Sensitivity is 
computed as the Jacobian of the arm probability based on Thompson sampling approximation 
soft-thresholded using half the sample’s standard deviation and averaged across the participants`;

export const advantageScoring = `The plot displays the relationship between the relative changes in trait
                         values during the monitoring period (y-axis) and treatment advantage scores (x-axis). 
                        The change in the trait value is relative to the average over the previous decision points, 
 and the advantage quantifies the increase in the estimated long-term reward,
  had a subject been assigned "treatment" at the time of the decision. 
  If the Markov graphs adequately model the benefits of treatment and state dynamics, 
  then in the treatment group the high advantage scores should correlate with positive changes in the trait value.`;
