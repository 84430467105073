import React, { useState, useMemo, useEffect } from 'react';

import { semibold } from 'styles/colors';
import Select, { components, OptionProps, SingleValueProps } from 'react-select';
import { CFRole } from 'domain/general.types';

import colors from 'common.scss';
import { Tag, TagSize, TagTypes } from 'components/Tag';

import './cf-select.scss';

const MultiValueRemove = (props: any) => {
  if (!props.data || !props.data.value) {
    return null;
  }
  if (props.data.value.toLowerCase() === 'user') {
    return null;
  }
  return <components.MultiValueRemove {...props} />;
};

interface CFPropsOptions {
  tags: string[];
  value: string;
}

const CFOption = ({ tags, value }: CFPropsOptions) => (
  <div className="custom-value">
    {tags?.map((tag) => (
      <Tag key={tag} text={tag} type={TagTypes.System} size={TagSize.extrasmall} />
    ))}
    <div>{value}</div>
  </div>
);

const CustomOption: React.FC<OptionProps<any>> = ({ innerProps, ...props }) => {
  return (
    <components.Option innerProps={innerProps} {...props}>
      <CFOption tags={props.data.tags || []} value={props.data.label} />
    </components.Option>
  );
};

const CustomInputValue: React.FC<SingleValueProps<SelectableItem>> = ({ ...props }: any) => {
  return (
    <components.SingleValue {...props}>
      <CFOption tags={props.data.tags} value={props.data.label} />
    </components.SingleValue>
  );
};

export interface SelectableItem {
  value: string;
  label: string;
  tags?: string[];
  selected?: boolean;
}

interface Props {
  options: SelectableItem[];
  role?: CFRole;
  placeholder?: string;
  className?: string;
  value?: SelectableItem[];
  defaultOption?: SelectableItem[];
  mandatoryValueOptions?: string[];
  isDisabled?: boolean;
  testId?: string;
  isSearchable?: boolean;
  showSelectedCount?: boolean;
  isMulti?: boolean;
  onSelected?: (item: SelectableItem[]) => void;
}

const CFSelectLegacy = React.forwardRef<any, Props>(function CFSelect(
  {
    options,
    placeholder,
    className = '',
    value,
    defaultOption = [],
    mandatoryValueOptions = [],
    isSearchable = false,
    role = CFRole.Secondary,
    isMulti = false,
    isDisabled = false,
    testId,
    showSelectedCount = false,
    onSelected,
  }: Props,
  ref
) {
  const [selectedItems, setSelectedItems] = useState<SelectableItem[]>();

  const handleSelectedChange = (newValue: SelectableItem | SelectableItem[]) => {
    let newValueArr = Array.isArray(newValue) ? newValue : [newValue];

    if (newValueArr.length < mandatoryValueOptions.length) {
      newValueArr = [...newValueArr, ...mandatoryValueOptions.map((value) => ({ value, label: value }))];
    }

    setSelectedItems(newValueArr);
    onSelected?.(newValueArr);
  };

  useEffect(() => {
    if (!value) {
      return;
    }

    setSelectedItems(value);
  }, [value]);

  const stringifiedOptions = useMemo(
    () => options.map((option) => ({ ...option, label: option.label.toString() })),
    [options]
  );

  return (
    <Select
      className={`cf-select ${className}`}
      ref={ref}
      isMulti={isMulti}
      isDisabled={isDisabled}
      isSearchable={isSearchable}
      defaultValue={defaultOption}
      value={selectedItems}
      placeholder={placeholder || ''}
      components={{
        IndicatorSeparator: () => null,
        MultiValueRemove,
        Option: CustomOption,
        SingleValue: CustomInputValue,
        MultiValue: showSelectedCount
          ? ({ index }) => {
              // eslint-disable-next-line react/prop-types
              if (index > 0) {
                // this function will be called as many times as items
                // are selected. We only want one
                return null;
              }
              return <div className="selected-value">{value?.length} selected</div>;
            }
          : components.MultiValue,
      }}
      options={stringifiedOptions}
      onChange={handleSelectedChange}
      data-testid={testId}
      isClearable={isMulti && !showSelectedCount}
      styles={{
        multiValue: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: colors.cfCyan,
          borderRadius: '24px',
          margin: '1px',
          border: `1px solid ${colors.cfCyan}`,
        }),
        multiValueLabel: (baseStyles) => ({
          ...baseStyles,
          fontSize: '14px',
          fontWeight: semibold,
          color: colors.dark100,
        }),
        multiValueRemove: (baseStyles) => ({
          ...baseStyles,
          color: colors.dark100,
        }),
        container: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: '#00000000',
        }),
        control: (baseStyles, state) => ({
          ...baseStyles,
          minHeight: '40px',
          borderRadius: '6px',
          border: state.isFocused ? `1px solid ${colors.cfCyan}` : `1px solid ${colors.dark65}`,
          color: role === CFRole.Primary ? colors.dark100 : colors.dark30,
          backgroundColor: role === CFRole.Primary ? colors.dark30 : colors.dark100,
          boxShadow: state.isFocused ? `0px 0px 6px 0px rgba(${colors.cfCyan}, 0.25)` : 'none',
          '&:hover': {
            border: `1px solid ${colors.cfCyan}`,
            boxShadow: `0px 0px 6px 0px rgba(${colors.cfCyan}, 0.25)`,
          },
        }),
        singleValue: (baseStyles) => ({
          ...baseStyles,
          textAlign: 'left',
          color: role === CFRole.Primary ? colors.dark100 : colors.dark30,
          backgroundColor: role === CFRole.Primary ? colors.dark30 : colors.dark100,
          fontWeight: '600',
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: colors.dark90,
          zIndex: 100000,
        }),
        dropdownIndicator: (baseStyles) => ({
          ...baseStyles,
          padding: '0',
          paddingRight: '6px',
          color: role === CFRole.Primary ? colors.dark100 : colors.dark30,
        }),
        indicatorsContainer: (baseStyles) => ({
          ...baseStyles,
        }),
        option: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: colors.dark90,
          '&:hover': {
            backgroundColor: colors.dark70,
          },
        }),
        group: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: colors.dark90,
          '&:hover': {
            backgroundColor: colors.dark70,
          },
        }),
      }}
    />
  );
});

export default CFSelectLegacy;
