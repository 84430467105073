import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import { getMetric } from 'services/intervention/intervention.repo';

import InterventionService from 'services/intervention/intervention.service';
import TraitService from 'services/traits/traitSession.service';
import { getDisplayName, getIdentifier, getIdentifierFromAddr } from 'services/traits/helpers.traits';

import CFSelectLegacy, { SelectableItem } from 'components/CFSelectLegacy';
import CFStackedChart from 'components/charts/CFStackedChart';
import CFTitledSection, { SectionAction } from 'components/CFTitledSection';
import { DataItem, LineStyle } from 'components/charts/chartCommon';
import CFLineChartWithConfidenceInterval from 'components/charts/CFLineChartWithConfidenceInterval';
import CFGenericErrorBoundary from 'components/CFGenericErrorBoundary';

import NudgeStats from './NudgeStats';
import ArmsInfo from './ArmsInfo/arms-info';

import SensitivityChart from 'views/intervention/interventions/monitoring/metrics/SensitivityChart';

import {
  BanditMetrics,
  InterventionId,
  InterventionViewExtended,
  MetricData,
  SplitType,
} from 'services/intervention/intervention.types';

import { TsPoint } from 'services/intervention/intervention.types';

import { getColorForGroup } from './helpers';

import { CFRoutes } from 'routes';

import useCFNavigation from 'hooks/useCFNavigation';

import { groupProportions, nudgeStats, objectiveMetric, supportingMetrics } from './constants';
import { Trait } from 'domain/traits.types';

import './metrics.scss';

const ALL_GROUPS = 'all_groups';

interface Props {
  interventionService: InterventionService;
  traitService: TraitService;
  id?: InterventionId;
}

const InterventionBanditMetrics = ({ interventionService, traitService, id }: Props) => {
  const navigate = useCFNavigation();

  const [splitType, setSplitType] = useState<SplitType>();
  const [metrics, setMetrics] = useState<MetricData>();
  const [isWithConfidenceLoading, setIsWithConfidenceLoading] = useState<boolean>(true);
  const [isStactedLoading, setIsStactedLoading] = useState<boolean>(true);
  const [intervention, setIntervention] = useState<InterventionViewExtended>();
  const [selectedSupportingTraitName, setSelectedSupportingTraitName] = useState<string>('');

  const params = useParams();

  const interventionId = useMemo(() => {
    return id || parseInt(params.id as string);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const intv = await interventionService.getView(interventionId);
        setIntervention(intv);
      } catch (err: any) {
        navigate(CFRoutes.intervention);
      }
    })();
  }, [interventionId]);

  useEffect(() => {
    (async () => {
      const metrics = await getMetric(interventionId);
      setMetrics(metrics);

      setIsWithConfidenceLoading(false);
      setIsStactedLoading(false);
    })();
  }, []);

  const objectiveTraitName = useMemo(() => {
    if (!intervention?.intervention.metric_policy.bandit) {
      return '';
    }

    const traitName = getIdentifierFromAddr(intervention.intervention.metric_policy.bandit.objective);
    return traitName;
  }, [intervention]);

  const objectiveDisplayTrait = useMemo(() => {
    if (!intervention) {
      return;
    }

    return traitService.getTraitFromAddr((intervention.intervention.metric_policy.bandit as BanditMetrics).objective);
  }, [intervention]);

  const supportingTraitNames = useMemo(() => {
    const traitNames = intervention?.intervention.metric_policy.bandit?.supporting.map((metric) =>
      getIdentifierFromAddr(metric)
    );

    return traitNames;
  }, [intervention]);

  const supportingTraits = useMemo(() => {
    if (!intervention) {
      return [];
    }

    const traits = (intervention.intervention.metric_policy.bandit as BanditMetrics).supporting.map((metric) =>
      traitService.getTraitFromAddr(metric)
    );

    return traits.filter((trait) => !!trait);
  }, [intervention]);

  useEffect(() => {
    if (!supportingTraitNames) {
      return;
    }

    setSelectedSupportingTraitName(supportingTraitNames[0]);
  }, [supportingTraitNames]);

  const handleSplitTypeChange = useCallback(([selectedSplitType]: SelectableItem[]) => {
    setSplitType(selectedSplitType.value as SplitType);
  }, []);

  const splitTypeOptions = useMemo(() => {
    if (!metrics) {
      return [];
    }

    if (!objectiveTraitName) {
      return [];
    }

    const availableSplitTypes = Object.keys(metrics.bandit.metrics[objectiveTraitName] || []);

    return availableSplitTypes.map((splitType) => ({
      value: splitType,
      label: splitType,
    }));
  }, [metrics, intervention, objectiveTraitName]);

  useEffect(() => {
    const hasFull = splitTypeOptions.some((type) => type.value === SplitType.FullSplit);

    if (hasFull) {
      setSplitType(SplitType.FullSplit);
    } else {
      setSplitType(SplitType.TrainSplit);
    }
  }, [splitTypeOptions]);

  // adding last empty point to confidence interval chart to have it aligned with the rest
  const groupNames = useMemo(() => {
    if (!metrics) {
      return [];
    }

    return Object.keys(metrics?.bandit.split_ratio);
  }, [metrics]);

  const lastTime = useMemo(() => {
    if (!metrics) {
      return '';
    }

    const lastTimes = Object.keys(metrics?.bandit.split_ratio).map((groupName) => {
      const last = metrics?.bandit.split_ratio[groupName].slice(-1);

      if (last.length === 0) {
        return '0';
      }

      return last[0].t;
    });

    return lastTimes.sort().slice(-1)[0];
  }, [metrics]);

  const sortByControlGroup = useCallback(
    (groupName: string): number => {
      if (groupName === intervention?.controlGroup) {
        return -1;
      } else {
        return 1;
      }
    },
    [intervention]
  );

  const populateValuesInSlots = (traitName: string, groupName: string) => {
    if (!metrics || !lastTime || !traitName || !metrics.bandit) {
      return [];
    }

    if (!metrics.bandit.metrics[traitName]) {
      return [];
    }

    if (!splitType) {
      return [];
    }

    const timestamps = Object.keys(intervention?.schedule.slots);

    // first approach: brute force. When working, do it in just one loop
    const data = timestamps.map((timestampValue) => {
      const item = metrics.bandit.metrics[traitName][splitType][groupName].find(
        (timeValuePair) => timeValuePair.t === timestampValue
      );

      const itemStd = metrics.bandit.metrics_std[traitName][splitType][groupName].find(
        (timeValuePair) => timeValuePair.t === timestampValue
      );

      let itemData: DataItem = {
        time: timestampValue,
        value: item?.v === undefined ? null : item?.v,
      };

      if (groupName !== ALL_GROUPS && item && itemStd) {
        itemData = {
          ...itemData,
          upper: item.v + itemStd.v,
          lower: item.v - itemStd.v,
        };
      }

      return itemData;
    });

    return data;
  };

  const computeConfidentIntervalTimeseriesForTrait = (traitName: string) => {
    if (!metrics || !lastTime || !traitName || !metrics.bandit) {
      return [];
    }

    if (!metrics.bandit.metrics[traitName]) {
      return [];
    }

    if (!splitType) {
      return [];
    }

    return Object.keys(metrics.bandit.metrics[traitName][splitType] || {})
      .sort((x, y) => y.localeCompare(x))
      .sort(sortByControlGroup)
      .map((groupName, i) => ({
        name: groupName,
        color: getColorForGroup(groupName, i, intervention?.controlGroup === groupName),
        lineStyle: groupName === ALL_GROUPS ? LineStyle.DOTTED : LineStyle.SOLID,
        data: populateValuesInSlots(traitName, groupName),
      }));
  };

  return (
    <>
      <CFTitledSection
        title={`Objective metric (avg) - ${getDisplayName(objectiveDisplayTrait as Trait)}`}
        description={objectiveMetric}
        underlined={true}
      >
        <SectionAction>
          <div className="chart-action">
            <span>Split Type:</span>
            <CFSelectLegacy
              options={splitTypeOptions}
              value={[{ value: splitType as string, label: splitType as string }]}
              onSelected={handleSplitTypeChange}
            />
          </div>
        </SectionAction>

        <div className="reward-chart">
          <CFLineChartWithConfidenceInterval
            data={computeConfidentIntervalTimeseriesForTrait(objectiveTraitName || '')}
            xLabel="Decision point"
            isLoading={isWithConfidenceLoading}
          />
        </div>
      </CFTitledSection>

      <CFTitledSection title={'Supporting metrics (avg)'} description={supportingMetrics} underlined={true}>
        <SectionAction>
          <div className="chart-action">
            <span>Trait</span>
            {supportingTraits?.length && (
              <CFSelectLegacy
                options={(supportingTraits || []).map((trait) => ({
                  value: getIdentifier(trait),
                  label: getDisplayName(trait, true),
                }))}
                defaultOption={[
                  {
                    value: getIdentifier(supportingTraits[0]),
                    label: getDisplayName(supportingTraits[0], true),
                  },
                ]}
                onSelected={(item: SelectableItem[]) => setSelectedSupportingTraitName(item[0].value)}
              />
            )}
          </div>
        </SectionAction>

        <div className="reward-chart">
          <CFLineChartWithConfidenceInterval
            data={computeConfidentIntervalTimeseriesForTrait(selectedSupportingTraitName || '')}
            xLabel="Decision point"
            isLoading={isWithConfidenceLoading}
          />
        </div>
      </CFTitledSection>

      <CFTitledSection title={'Group proportions'} description={groupProportions} underlined={true}>
        <CFGenericErrorBoundary>
          <CFStackedChart
            key="group-proportions"
            title=""
            xLabel="Decision point"
            xAxis={((metrics?.bandit.split_ratio[groupNames[0]] ?? []) as TsPoint[]).map((point) => point.t)}
            series={Object.keys(metrics?.bandit.split_ratio || {})
              .filter((groupName) => groupName !== 'all_groups')
              .sort((x, y) => y.localeCompare(x))
              .sort(sortByControlGroup)
              .map((groupName, i) => ({
                name: groupName,
                color: getColorForGroup(groupName, i, intervention?.controlGroup === groupName),
                values: (metrics?.bandit.split_ratio[groupName] || []).map((tsPoint: TsPoint) => ({
                  value: tsPoint.v,
                  time: tsPoint.t,
                })),
              }))}
            isLoading={isStactedLoading}
          />
        </CFGenericErrorBoundary>
      </CFTitledSection>

      <SensitivityChart interventionId={interventionId} />

      <ArmsInfo interventionId={interventionId} />

      <CFTitledSection title={'Nudge stats'} description={nudgeStats} underlined={true}>
        <NudgeStats id={interventionId} />
      </CFTitledSection>
    </>
  );
};

export default InterventionBanditMetrics;
