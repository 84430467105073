import React from 'react';

import useCFNavigation from 'hooks/useCFNavigation';

import CFButton from 'components/buttons/CFButton';
import CFInput from 'components/CFInput';
import CFTitledComponent from 'components/CFTitledComponent';

import { create as createOrganization } from 'repositories/organizations';
import { create as createProject } from 'repositories/projects';

import { TraitSubject } from 'domain/traits.types';
import CFSelectLegacy, { SelectableItem } from 'components/CFSelectLegacy';
import { CFRoutes } from 'routes';
import { CFRole, Modules } from 'domain/general.types';

import pagePlusIcon from 'assets/icons/pagePlus.svg';

import './new-organization.scss';
import AdminLayout from 'views/admin/layout';
import { Tabs } from 'views/admin/tabs';

const NewOrganization = () => {
  const navigate = useCFNavigation();

  const nameInputRef = React.createRef<HTMLInputElement>();
  const descriptionInputRef = React.createRef<HTMLInputElement>();
  const subjectsRef = React.createRef<any>();

  const projectNameInputRef = React.createRef<HTMLInputElement>();
  const projectDescriptionInputRef = React.createRef<HTMLInputElement>();

  const handleCancelNewOrg = async () => {
    navigate(CFRoutes.admin_org);
  };

  const handleNewOrganization = async () => {
    try {
      const orgId = await createOrganization(
        nameInputRef.current?.value || '',
        descriptionInputRef.current?.value || ''
      );

      await createProject(
        projectNameInputRef.current?.value || '',
        projectDescriptionInputRef.current?.value || '',
        orgId,
        (subjectsRef.current as any).getValue().map((item: SelectableItem) => item.value.toLowerCase()),
        [Modules.Core]
      );
    } catch (err) {
      console.log('error creating organization: ', err);
    }

    navigate(CFRoutes.admin_org);
  };

  return (
    <AdminLayout tab={Tabs.Organizations}>
      <div className="new-org-container">
        <div className="input">
          <CFTitledComponent title={'Name'}>
            <CFInput ref={nameInputRef} />
          </CFTitledComponent>

          <CFTitledComponent title={'Description'}>
            <CFInput ref={descriptionInputRef} />
          </CFTitledComponent>

          <CFTitledComponent title={'Default project name'}>
            <CFInput ref={projectNameInputRef} />
          </CFTitledComponent>

          <CFTitledComponent title={'Default project description'}>
            <CFInput ref={projectDescriptionInputRef} />
          </CFTitledComponent>

          <CFTitledComponent title={'Subjects'}>
            <CFSelectLegacy
              ref={subjectsRef}
              options={Object.values(TraitSubject).map((subject) => ({
                value: subject,
                label: subject,
              }))}
              isMulti={true}
              mandatoryValueOptions={[TraitSubject.User]}
              defaultOption={[{ value: TraitSubject.User, label: TraitSubject.User }]}
            />
          </CFTitledComponent>
        </div>

        <div className="controls">
          <CFButton value={'Cancel'} onClick={handleCancelNewOrg} />
          <CFButton
            value={'Create Organization'}
            onClick={handleNewOrganization}
            role={CFRole.Primary}
            iconCustom={<img src={pagePlusIcon} />}
          />
        </div>
      </div>
    </AdminLayout>
  );
};

export default NewOrganization;
