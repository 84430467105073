import React, { useCallback, useState } from 'react';

import CFInput from 'components/CFInput';

import CFTextarea from 'components/textarea/CFTextarea';
import CFButton from 'components/buttons/CFButton';

import AdminLayout from '../layout';
import { Tabs } from '../tabs';

import { makeGenericRequest } from 'services/admin/internal.repo';
import { JSONTree } from 'react-json-tree';

import CFSelect, { Option } from 'components/CFSelect';
import { HTTPMethod } from 'repositories/drivers/http';

import './internal-admin-tab.scss';

enum FormNames {
  REQUEST_BODY = 'request-body',
  REQUEST_URL = 'request-url',
}

const InternalAdminTab = () => {
  const [response, setResponse] = useState({});
  const [method, setMethod] = useState(HTTPMethod.POST);
  const [loading, setLoading] = useState(false);

  const handleSubmitForm = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      setLoading(true);
      const formData = new FormData(event.currentTarget);

      const body = formData.get(FormNames.REQUEST_BODY) || {};
      const url = formData.get(FormNames.REQUEST_URL) as string;

      try {
        const data = await makeGenericRequest(method, url, body);
        setResponse(data);
        setLoading(false);
      } catch (err: any) {
        setResponse(err);
        setLoading(false);
      }
    },
    [method]
  );

  const handleMethodSelected = useCallback((option: Option) => {
    setMethod(option.value as HTTPMethod);
  }, []);

  return (
    <AdminLayout tab={Tabs.Internal}>
      <form onSubmit={handleSubmitForm}>
        <div className="internal-admin-tab">
          <div className="url">
            <CFSelect
              options={Object.values(HTTPMethod).map((method) => ({ label: method, value: method }))}
              isMulti={false}
              value={{ label: method, value: method }}
              onSelected={handleMethodSelected}
            />
            <CFInput name={FormNames.REQUEST_URL} />
          </div>

          <CFTextarea name={FormNames.REQUEST_BODY} className="request" />

          <div className="response">
            <JSONTree data={response} />
          </div>
          <div className="controls">
            <CFButton
              isLoading={loading}
              value={'Send'}
              onClick={() => {
                /**/
              }}
            />
          </div>
        </div>
      </form>
    </AdminLayout>
  );
};

export default InternalAdminTab;
