import { AlgorithmType } from 'services/intervention/intervention.types';
import { Steps } from '.';

export const QUERY_PARAM_DRAFT = 'draft';
export const QUERY_PARAM_CLONE = 'from';

export const defaultReadinessStates = {
  [AlgorithmType.ABTest]: {
    [Steps.Definition]: false,
    [Steps.Participants]: false,
    [Steps.Nudge]: false,
    [Steps.Metrics]: false,
    [Steps.Scheduling]: false,
    [Steps.States]: true,
    [Steps.GroupTraits]: true,
    [Steps.Score]: true,
  },

  [AlgorithmType.Bandit]: {
    [Steps.Definition]: false,
    [Steps.Participants]: false,
    [Steps.Nudge]: false,
    [Steps.Metrics]: false,
    [Steps.Scheduling]: false,
    [Steps.States]: true,
    [Steps.GroupTraits]: true,
    [Steps.Score]: true,
  },
  [AlgorithmType.RestlessBandit]: {
    [Steps.Definition]: false,
    [Steps.Participants]: false,
    [Steps.Nudge]: false,
    [Steps.Metrics]: false,
    [Steps.Scheduling]: false,
    [Steps.States]: false,
    [Steps.GroupTraits]: false,
    [Steps.Score]: true,
  },
};
