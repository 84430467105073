import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getByUsername,
  listRoles,
  removeFromOrganization,
  updateAdminRoles,
  updateRoles,
} from 'services/admin/users/users.repo';
import { UserDetailedInfo } from 'types';
import CFRolesEditor, { CFRolesEditorRef } from 'components/CFRolesEditor';
import { Role, UserInfo, UserRolesInOrganization } from 'services/admin/users/user.types';
import CFButton from 'components/buttons/CFButton';
import { CFRoutes } from 'routes';

import { CFRole } from 'domain/general.types';
import CFConfirmableButton from 'components/CFConfirmableButton';

import useCFNavigation from 'hooks/useCFNavigation';

import AdminLayout from 'views/admin/layout';
import { Tabs } from 'views/admin/tabs';

import './user-detail.scss';
import { useToast } from 'hooks';
import { ToastType } from 'components/CFToast/types';
import { AuthAction, isAllowedTo } from 'services/authorization.service';

interface Props {
  userDetailedInfo: UserDetailedInfo;
  orgId: number;
}

const UserDetail = ({ userDetailedInfo, orgId }: Props) => {
  const params = useParams();
  const navigate = useCFNavigation();
  const rolesEditorRef = useRef<CFRolesEditorRef>(null);
  const [user, setUser] = useState<UserInfo | undefined>(undefined);
  const [availableRoles, setAvailableRoles] = useState<Role[]>([]);
  const [availableAdminRoles, setAvailableAdminRoles] = useState<Role[]>([]);

  const { addToast } = useToast();

  useEffect(() => {
    (async () => {
      const roles = await listRoles();

      setAvailableRoles(roles.roles);
      setAvailableAdminRoles(roles.admin_roles);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const user = await getByUsername(params.username as string);
      setUser(user);
    })();
  }, []);

  if (!user) {
    return <div></div>;
  }

  const handleDeleteUser = () => {
    try {
      removeFromOrganization(user.username);
      navigate(CFRoutes.admin_users);
    } catch (err) {
      console.log('error removing user: ', err);
    }
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    const configuredAdminRoles = availableAdminRoles
      .map((role) => formData.get(role))
      .map((state, i) => (state === 'on' ? i : undefined))
      .filter((index) => index !== undefined)
      .map((index) => availableAdminRoles[index as number]);

    try {
      await updateRoles(user.username, rolesEditorRef.current?.value() as UserRolesInOrganization);

      await updateAdminRoles(user.username, configuredAdminRoles);
      navigate(CFRoutes.admin_users);
    } catch (err) {
      addToast('Error updating user details', ToastType.ERROR);
      console.log('error updating roles: ', err);
    }
  };

  return (
    <AdminLayout tab={Tabs.Users}>
      <div className="user-detail-container">
        <div className="section-title"> {params.username} </div>
        <form onSubmit={handleFormSubmit} className="user-detail-form">
          <CFRolesEditor
            ref={rolesEditorRef}
            orgprojs={userDetailedInfo.available_orgprojs}
            orgId={orgId}
            currentRoles={user?.roles[orgId]}
            currentAdminRoles={user.admin_roles}
            availableRoles={availableRoles}
            availableAdminRoles={availableAdminRoles}
            includeAdmin={isAllowedTo(AuthAction.EditAdminRoles)}
          />

          <div className="controls">
            <CFConfirmableButton
              question={`Are you user you want delete ${params.username} from this organization?`}
              title={'Remove user from organization'}
            >
              <CFButton type="button" value={'Remove from organization'} onClick={handleDeleteUser} />
            </CFConfirmableButton>

            <CFButton
              value={'Update roles'}
              onClick={() => {
                /** */
              }}
              role={CFRole.Primary}
            />
          </div>
        </form>
      </div>
    </AdminLayout>
  );
};

export default UserDetail;
