import { AxiosResponse } from 'axios';

import { post as httpPost } from './drivers/http';
import { RepoConfig } from './types';
import { CatalogHistoryObject } from '../domain/cataloghistory.types';

const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
const path = '/v1/trait';

const repoConfig = {
  token: '',
  oid: -1,
  pid: -1,
};

export const init = ({ token, oid, pid }: RepoConfig) => {
  repoConfig.token = token;
  repoConfig.oid = oid;
  repoConfig.pid = pid;
};

export const getCatalogHistory = async (subject_name: string, subject_id: string): Promise<CatalogHistoryObject> => {
  const config = {};

  const body = {
    sub:subject_name,
    id:subject_id,
  };

  const catalogHistory = (await httpPost(
    `${serverBaseUrl}${path}/catalog/search?oid=${repoConfig.oid}&pid=${repoConfig.pid}`,
    body,
    config
  )) as AxiosResponse;
  return catalogHistory.data || {};
};
