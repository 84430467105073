import React, { forwardRef } from 'react';

import { AppModel } from 'domain/model.types';
import { Props as ItemProps } from 'components/CFSelect/defaults/Item';

import classNames from 'classnames';

import './model-option.scss';

const ModelOption = forwardRef<HTMLDivElement, ItemProps>(function ModelOption(
  { option, active, onClick, focused }: ItemProps,
  ref
) {
  return (
    <div
      ref={ref}
      key={option.value}
      className={classNames('cf-select-item model-option', { active, focused })}
      onClick={() => onClick?.(option)}
    >
      {(option.meta?.model as AppModel) ? (
        <div className="model-option__item">
          {(option.meta.model as AppModel).definition.name}{' '}
          <span>#{(option.meta.model as AppModel).definition.id}</span>
        </div>
      ) : (
        option.label
      )}
    </div>
  );
});

export default ModelOption;
