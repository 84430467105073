import { PaginatedElement } from 'types';

export const downloadAll = async <T>(
  cb: (page: number, page_size: number) => Promise<PaginatedElement<T>>,
  page_size = 1000
): Promise<T[]> => {
  let subjects = [];
  const pageRequests = [];

  const firstPage = await cb(0, page_size);

  const totalSubjects = firstPage.total;

  const numberOfPages = Math.ceil(totalSubjects / page_size);

  // first page has been already downloaded
  for (let i = 1; i < numberOfPages; i++) {
    pageRequests.push(cb(i, page_size));
  }

  const pages = await Promise.all(pageRequests);

  subjects = [...firstPage.data, ...pages.map((page) => [...page.data])];

  return subjects.flat() as T[];
};
