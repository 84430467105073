import { AggLevel, AggLevelMapping, AggLevelPtr } from 'domain/stats.types';
import { ColAddr, Trait, TraitCategory, TraitCode, TraitDataType, TraitSubject, TraitType } from 'domain/traits.types';
import { postgresqlTypeToHuman } from 'helpers/misc';
import { Ptr } from 'services/cohort/cohort.types.api';

interface PtrFields {
  name: string;
  aggLevel: AggLevel;
  category: TraitCategory;
  subject: TraitSubject;
  subtype: string;
  tags: string[];
}

type TraitAnchor = Ptr | ColAddr;

// gdt_chwsite_general.active_chw_count#1d
const parsePTR = (ptr: Ptr): PtrFields => {
  const [traitInfo, agg] = ptr.split('#');

  const [traitFields, traitName] = traitInfo.split('.');
  const [category, subject, itemType, itemSubtype, ...rest] = traitFields.split('_');

  let subjectExtended = subject;
  let aggLevel = AggLevel.NA;

  if (agg) {
    aggLevel = AggLevelMapping[agg.slice(-1) as AggLevelPtr];
  }

  if (subject === TraitSubject.User && itemType && itemType === 'chw') {
    subjectExtended = `${subject} (chw)`;
  }

  if (subject === TraitSubject.User && itemType && itemType === 'item') {
    if (itemSubtype) {
      subjectExtended = `${subject} / ${itemSubtype}`;
    } else {
      subjectExtended = `${subject} / ${itemType}`;
    }
  }

  const tags = [itemSubtype, ...rest].filter((item) => item !== undefined);

  return {
    name: traitName,
    aggLevel,
    category: category as TraitCategory,
    subject: subjectExtended as TraitSubject,
    subtype: itemSubtype,
    tags,
  };
};

export function getIdentifierFromAddr(addr: ColAddr): string {
  return addr.ptr;
}

export function getIdentifier(trait: Trait): Ptr {
  return trait.addr.ptr;
}

export function getTraitNameFromAddr(addr: ColAddr): string {
  const { name } = parsePTR(addr.ptr);

  return name;
}

export function getDataType(addr: ColAddr): string {
  return addr.display_dtype || postgresqlTypeToHuman(addr.dtype);
}

export function getTraitName(trait: Trait): string {
  const { name } = parsePTR(trait.addr.ptr);

  return name;
}

export function getDisplayName(trait: Trait | undefined, withAggLevel = false): string {
  if (!trait) {
    return '';
  }

  const displayName = trait.meta.display_name || trait.addr.ptr.split('.')[1].split('#')[0];

  if (withAggLevel) {
    const { aggLevel } = parsePTR(trait.addr.ptr);

    return `${displayName} (${aggLevel})`;
  } else {
    return displayName;
  }
}

export function getSubType(anchor: TraitAnchor): string {
  let ptr: Ptr = '';

  if (typeof anchor === 'object') {
    ptr = anchor.ptr;
  } else {
    ptr = anchor;
  }

  const { subtype } = parsePTR(ptr);

  return subtype;
}

export function createTraitCode(trait: Trait | ColAddr): TraitCode {
  if (!trait) {
    return '';
  }

  if ((trait as Trait).addr) {
    const completeTrait = trait as Trait;

    return completeTrait.addr.ptr;
  } else {
    const addr = trait as ColAddr;

    return addr.ptr;
  }
}

export function getTags(trait: Trait) {
  const parts = trait.addr.ptr.split('.')[0].split('_');
  const tags = [parts[0]];

  if (parts[0] === TraitType.CatalogTrait && parts[1] === 'user' && parts[2]) {
    tags.push(`${trait.meta.subject}(${parts[2]})`);
  } else {
    tags.push(trait.meta.subject);
  }

  tags.push(trait.addr.display_dtype || trait.addr.dtype);

  return tags;
}

export function getTraitSubject(anchor: TraitAnchor): TraitSubject {
  let ptr: Ptr = '';

  if (typeof anchor === 'object') {
    ptr = anchor.ptr;
  } else {
    ptr = anchor;
  }

  const { subject } = parsePTR(ptr);
  return subject;
}

export function getTraitCategory(anchor: TraitAnchor): TraitCategory {
  let ptr: Ptr = '';

  if (typeof anchor === 'object') {
    ptr = anchor.ptr;
  } else {
    ptr = anchor;
  }

  const { category } = parsePTR(ptr);
  return category;
}

export function getAggLevel(anchor: TraitAnchor): AggLevel {
  let ptr: Ptr = '';

  if (typeof anchor === 'object') {
    ptr = anchor.ptr;
  } else {
    ptr = anchor;
  }

  const { aggLevel } = parsePTR(ptr);

  return aggLevel;
}

export function isAggLevel(addr: ColAddr, aggLevel: AggLevel): boolean {
  const { aggLevel: level } = parsePTR(addr.ptr);

  return level === aggLevel;
}

export function isNumeric(trait: Trait): boolean {
  if (!trait) {
    return false;
  }

  return (
    trait.addr.dtype === TraitDataType.Float4 ||
    trait.addr.dtype === TraitDataType.Int4 ||
    trait.addr.dtype === TraitDataType.Int2 ||
    trait.addr.dtype === TraitDataType.Number
  );
}

export function isTimestamp(trait: Trait): boolean {
  return trait?.addr.dtype === TraitDataType.Timestamp;
}

export const areEqual = (trait1: Trait, trait2: Trait) => {
  return trait1.addr.ptr === trait2.addr.ptr;
};
