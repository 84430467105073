import { MutableRefObject, useEffect, useState } from 'react';
import useSampleSize from '../../hooks/useSampleSize';
import { TupleCreatorRef } from '../TupleCreator';

interface Props {
  tuplesRef: MutableRefObject<TupleCreatorRef[]>;
  budgetInputRef: MutableRefObject<HTMLInputElement | undefined>;
  populationBlendInputRef: MutableRefObject<HTMLInputElement | undefined>;
  lastChange: number;
}

const useReadyForGeneration = ({ budgetInputRef, populationBlendInputRef, tuplesRef, lastChange }: Props) => {
  const sampleSize = useSampleSize();
  const [allowGeneration, setAllowGeneration] = useState(false);

  const MIN_REWARD = -1;
  const MAX_REWARD = 1;

  const checkNumber = (value: string | undefined, min: number, max: number) => {
    if (!value || !value?.length) {
      return false;
    }

    if (isNaN(Number(value))) {
      return false;
    }

    const budgetNumber = parseFloat(value);
    if (budgetNumber < min || budgetNumber > max) {
      setAllowGeneration(false);
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (tuplesRef === null || !tuplesRef.current.length) {
      setAllowGeneration(false);
      return;
    }

    const uncompleted = tuplesRef.current.find((tuple) => {
      const weightOK =
        !isNaN(tuple.value().weight) && tuple.value().weight >= MIN_REWARD && tuple.value().weight <= MAX_REWARD;

      return !weightOK;
    });

    if (uncompleted !== undefined) {
      setAllowGeneration(false);
      return;
    }

    const budget = budgetInputRef.current?.value;
    if (!checkNumber(budget, 0, sampleSize)) {
      setAllowGeneration(false);
      return;
    }

    const populationBlend = populationBlendInputRef.current?.value;
    if (!checkNumber(populationBlend, 0, 1)) {
      setAllowGeneration(false);
      return;
    }

    setAllowGeneration(true);
  }, [tuplesRef, sampleSize, budgetInputRef, populationBlendInputRef, lastChange]);

  return allowGeneration;
};

export default useReadyForGeneration;
