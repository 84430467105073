import React from 'react';

import AnalyticsInternal from './analytics';
import AnalyticsContext from './context/useAnalyticsContext';

const Analytics = () => {
  return (
    <AnalyticsContext>
      <AnalyticsInternal />
    </AnalyticsContext>
  );
};

export default Analytics;
