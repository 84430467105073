import classNames from 'classnames';
import React from 'react';
import { useTabContext } from './TabContext';

export interface Props extends React.PropsWithChildren {
  value: string;
  className?: string;
}

const Tab: React.FC<Props> = ({ children, value, className }) => {
  const { currentTab, handleChangeTab } = useTabContext();

  return (
    <div
      className={classNames('cf-tab', className, { active: currentTab === value })}
      onClick={() => handleChangeTab(value)}
    >
      {children}
    </div>
  );
};

export default Tab;
