import { CFRoutes } from 'routes';

export enum Tabs {
  Definition = 'definition',
  Metrics = 'metrics',
}

export const monitoringByTab: Record<string, CFRoutes> = {
  [Tabs.Definition]: CFRoutes.intervention_monitoring_definition,
  [Tabs.Metrics]: CFRoutes.intervention_monitoring_metrics,
};
