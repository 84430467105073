import React, { useCallback, useMemo, useState } from 'react';

import { CFRole } from 'domain/general.types';

import { useInterventionContext } from 'views/intervention/useContext';

import { AlgorithmName } from 'services/intervention/intervention.types';
import { generateAdvantageHistogram } from 'services/markov/markov.service';

import CFButton from 'components/buttons/CFButton';
import CFHistogram from 'components/charts/CFHistogram';
import CFInput, { CFInputType } from 'components/CFInput';
import { ToastType } from 'components/CFToast/types';

import InterventionSection from '../interventionSection';
import { Steps } from '..';

import useToast from 'hooks/useToast';

import './advantage-score.scss';

const defaultBinCount = 7;

const AdvantageScore = React.memo(function AdvantageScore() {
  const { algorithmName, markovStatesRef, groupFiltersRef, tuplesRef, tupleItems, offset } = useInterventionContext();
  const [scores, setScores] = useState<number[]>([]);
  const [binCount, setBinCount] = useState<number>(defaultBinCount);
  const { addToast } = useToast();

  const handleShowScoreButton = useCallback(() => {
    (async () => {
      const tuples = tupleItems.map((_, i) => {
        return tuplesRef.current[i].value();
      });

      const markovData = markovStatesRef.current.value();

      const treatmentGroup = Object.keys(markovData.edits)[0];

      try {
        const advantageScores = await generateAdvantageHistogram(
          tuples,
          markovData.budget,
          algorithmName as AlgorithmName,
          markovData.id,
          treatmentGroup,
          Object.values(markovData.edits)[0],
          groupFiltersRef.current.value(),
          offset
        );

        setScores(advantageScores.advantage);
      } catch {
        addToast('Error getting histogram. Check algorithm', ToastType.ERROR);
      }
    })();
  }, [algorithmName, tupleItems]);

  const handleBinCountChange = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    let newBinCount;

    try {
      newBinCount = parseInt(evt.currentTarget.value);
    } catch {
      return;
    }

    if (isNaN(newBinCount)) {
      return;
    }

    setBinCount(newBinCount);
  }, []);

  const bars = useMemo(() => {
    const min = Math.min(...scores);
    const max = Math.max(...scores);

    const step = (max - min) / binCount;

    if (step === 0 || binCount < 1) {
      return [];
    }

    const bins = Array.from({ length: binCount }, (_, i) => ({
      range: [min + i * step, min + (i + 1) * step].map((num) => num.toExponential(2)).join(', '),
      count: 0,
    }));

    scores.forEach((value) => {
      let index = Math.floor((value - min) / step);

      if (index === binCount) {
        index--;
      }

      bins[index].count++;
    });

    return bins.map((bin) => {
      return { count: bin.count, type: bin.range };
    });
  }, [scores, binCount]);

  return (
    <InterventionSection name={Steps.Score} title={'Advantage scoring'} className="advantage-score">
      <div className="controls">
        <CFInput type={CFInputType.Number} defaultValue={defaultBinCount} onChange={handleBinCountChange} />
        <CFButton value={'Show scores'} onClick={handleShowScoreButton} role={CFRole.Primary} />
      </div>

      <CFHistogram data={bars} isLoading={false} title="" barWidth={30} />
    </InterventionSection>
  );
});

export default AdvantageScore;
