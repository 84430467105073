import { put as httpPut } from 'repositories/drivers/http';
import { RepoConfig } from 'repositories/types';

const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
const path = '/v1/cohort';

const repoConfig = {
  token: '',
  oid: -1,
  pid: -1,
};

export const init = ({ token, oid, pid }: RepoConfig) => {
  repoConfig.token = token;
  repoConfig.oid = oid;
  repoConfig.pid = pid;
};

export const updateLandingCohort = async (cohortId: string): Promise<void> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };
  try {
    await httpPut(`${serverBaseUrl}${path}/set-landing/${cohortId}`, {}, config);
  } catch (err) {
    console.error('error setting landing cohort: ', err);
    throw new Error('error-setting-landing-cohort-analytics');
  }
};
