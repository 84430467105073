import React, { ReactNode } from 'react';

import { InterventionViewExtended } from 'services/intervention/intervention.types';
import MonitoringSection from '../monitoringSection';
import NudgeDetail from 'views/intervention/nudges/ListOfNudges/NudgeDetail';

import './groups.scss';

interface Props {
  intervention: InterventionViewExtended;
  className: string;
}

interface GroupInfoProps {
  groupName: string;
  nudgeName: string;
  children?: ReactNode;
}

const GroupInfo = ({ groupName, nudgeName, children }: GroupInfoProps) => {
  return (
    <div key={'control'} className="group-definition">
      <div className="group-title">
        Group: <span className="value"> {groupName} </span>
      </div>
      <div className="group-title">
        Nudge: <span className="value"> {nudgeName} </span>
      </div>

      {children}
    </div>
  );
};

const GroupsDefinition = ({ intervention, className = '' }: Props) => {
  return (
    <MonitoringSection title="Groups" className={className}>
      {Object.keys(intervention.intervention.nudge_policy.group_allocation).map((groupKey) => {
        return (
          <GroupInfo
            key={groupKey}
            groupName={groupKey}
            nudgeName={intervention.nudge_group_allocation[groupKey]?.name || '<no name>'}
          >
            <NudgeDetail {...intervention.nudge_group_allocation[groupKey]} />
          </GroupInfo>
        );
      })}
    </MonitoringSection>
  );
};

export default GroupsDefinition;
