import React from 'react';

import classNames from 'classnames';

import { ReactComponent as LlmIcon } from 'views/assistant/images/coloredchat.svg';

import './chat-switch.scss';

export enum IconMode {
  Dark = 'dark',
  Light = 'light',
}

interface Props {
  className?: string;
  mode?: IconMode;
}

const ChatSwitcher = ({ className, mode }: Props) => {
  return (
    <div className={classNames('chat-switch', 'system', className, mode)}>
      <LlmIcon className="profile-icon_image" />
    </div>
  );
};

export default ChatSwitcher;
