import React, { useCallback, useMemo, useState } from 'react';

import { KPI } from 'components/kpis';
import RelativeInfoBox from 'components/kpis/RelativeInfoBox';
import CFPagination from 'components/CFPagination';

import './kpi-matrix.scss';

const PAGE_SIZE = 12;

interface Props {
  totalKPI: KPI;
  kpis: KPI[];
}

const KPIMatrix = ({ kpis, totalKPI }: Props) => {
  const [currentPage, setCurPage] = useState(0);

  const sortedKPIs = kpis.sort((a, b) => b.label.localeCompare(a.label));

  const handlePageChange = useCallback((e: any, pageNumber: number) => {
    setCurPage(pageNumber - 1);
  }, []);

  const numberOfPages = useMemo(() => {
    return Math.ceil(kpis.length / PAGE_SIZE);
  }, [kpis]);

  return (
    <div className="kpi-matrix-container">
      <RelativeInfoBox
        key={totalKPI.label}
        value={totalKPI.value}
        unit={totalKPI.unit}
        label={totalKPI.label}
        variation={totalKPI.variation}
        isLoading={totalKPI.value === '-'}
      />

      <div className="kpi-matrix">
        {Array.from({ length: PAGE_SIZE }).map((_, i) => {
          const kpiIndex = currentPage * PAGE_SIZE + i;

          if (!sortedKPIs[kpiIndex]) {
            return '';
          }

          return (
            <RelativeInfoBox
              key={sortedKPIs[kpiIndex].label}
              value={sortedKPIs[kpiIndex].value}
              unit={sortedKPIs[kpiIndex].unit}
              label={sortedKPIs[kpiIndex].label}
              variation={sortedKPIs[kpiIndex].variation}
              isLoading={sortedKPIs[kpiIndex].value === '-'}
            />
          );
        })}
      </div>
      <div className="kpi-footer">
        <div className="note">
          <div className="icon">i</div>
          Percentage changes displayed correspond to the same length of time in the preceding period
        </div>

        {numberOfPages > 1 && (
          <CFPagination pageCount={numberOfPages} currentPage={currentPage + 1} onPageChange={handlePageChange} />
        )}
      </div>
    </div>
  );
};

export default KPIMatrix;
