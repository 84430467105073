import React from 'react';

import classNames from 'classnames';
import { intervals } from '../../constants';

import './visual-map.scss';

interface Props {
  className?: string;
}

const VisualMap = ({ className }: Props) => {
  return (
    <div className={classNames(className, 'visual-map')}>
      <div className="steps"> {Array(intervals.length).fill(<div>&nbsp;</div>)}</div>
      <div className="values">
        <span>0</span>
        <span>0.5</span>
        <span>1</span>
      </div>
    </div>
  );
};

export default VisualMap;
