import React from 'react';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CFPortal, { ContentPosition } from 'components/CFPortal';

import './cf-modal.scss';
import classNames from 'classnames';

interface Props {
  children: JSX.Element | JSX.Element[];
  onClose: () => void;
  closeIcon?: boolean;
  className?: string;
}

const CFModal = ({ children, onClose, closeIcon = true, className }: Props) => {
  return (
    <CFPortal onClickOutside={onClose} mode={ContentPosition.Center}>
      <div className={classNames('cf-modal', className)}>
        {closeIcon && <FontAwesomeIcon className="cf-modal-xmark" icon={faXmark} onClick={onClose} />}
        {children}
      </div>
    </CFPortal>
  );
};

export default CFModal;
