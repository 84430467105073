import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import { faRefresh } from '@fortawesome/free-solid-svg-icons';

import { useServicesContext } from 'hooks/useServicesContext';
import { toString } from 'helpers/dates';

import { TraitCategory, TraitDefinition, TraitSubject } from 'domain/traits.types';
import { CFRole, TimeRFC3999 } from 'domain/general.types';

import { Ptr } from 'services/cohort/cohort.types.api';
import { getCurrentProject } from 'services/session/session.service';

import CFSelectLegacy from 'components/CFSelectLegacy';
import CFTable, { Column, ColumnType } from 'components/CFTable';
import { ToastType } from 'components/CFToast/types';
import CFButton from 'components/buttons/CFButton';
import DatetimeRangePicker from 'components/DateTime/DatetimeRangePicker';
import { DateRange, DefinedRange } from 'components/DateTime/DatetimeRangePicker/types';
import { defaultRanges } from 'components/DateTime/DatetimeRangePicker/default';

import TraitsLayout from '../layout';
import { Tabs } from '../tabs';

import { useToast } from 'hooks';

import './trait-definitions.scss';

const TraitDefinitions = () => {
  const project = getCurrentProject();

  const { addToast } = useToast();
  const { traitSessionService: traitService, backfillService } = useServicesContext();
  const [category, setCategory] = useState(TraitCategory.Catalogue);
  const [subject, setSubject] = useState(TraitSubject.User);
  const [definitions, setDefinitions] = useState<TraitDefinition[]>([]);
  const [startDate, setStartDate] = useState<TimeRFC3999>(project?.created_at || '');
  const [endDate, setEndDate] = useState<TimeRFC3999>(toString(new Date()));
  const [isBackfilling, setIsBackfilling] = useState<Record<Ptr, boolean>>({});

  useEffect(() => {
    (async () => {
      const definitions = await traitService.getTraitDefinitions(subject, category);

      setDefinitions(definitions);
    })();
  }, [category, subject]);

  const handleBackfill = async (ptr: Ptr) => {
    console.log('setting backfilling to true');
    setIsBackfilling((isBackfilling) => {
      isBackfilling[ptr] = true;
      return { ...isBackfilling };
    });

    try {
      await backfillService.backfillTrait(startDate, endDate, [], true, ptr);
    } catch {
      addToast('Error backfilling', ToastType.ERROR);
    } finally {
      console.log('setting backfilling to false');

      setIsBackfilling((isBackfilling) => {
        isBackfilling[ptr] = false;
        return { ...isBackfilling };
      });
    }
  };

  const headers: Column[] = [
    {
      title: 'Name',
      type: ColumnType.STRING,
      field: 'name',
    },
    {
      title: 'Description',
      type: ColumnType.STRING,
      field: 'description',
      style: { maxWidth: '300px', textAlign: 'left' },
    },
    {
      title: 'Unit',
      type: ColumnType.STRING,
      field: 'unit',
    },
    {
      title: 'Category',
      type: ColumnType.STRING,
      field: 'category',
    },
    {
      title: 'Actions',
      type: ColumnType.OBJECT,
      field: '',
      style: { textAlign: 'right' },
      renderCell: (row: any) => {
        if (!backfillService.isBackfillable(category)) {
          return <div></div>;
        }

        return (
          <div className="trait-actions">
            <CFButton
              onClick={() => handleBackfill(row.ptr)}
              disabled={isBackfilling[row.ptr]}
              animate={isBackfilling[row.ptr]}
              description="Recompute values"
              value=""
              iconName={faRefresh}
              role={CFRole.OnlyIcon}
            />
          </div>
        );
      },
    },
  ];

  const handleRangeChange = useCallback((dateRange: DateRange) => {
    if (dateRange.startDate && dateRange.endDate) {
      setStartDate(toString(dateRange.startDate));
      setEndDate(toString(dateRange.endDate));
    }
  }, []);

  const defaultDateRanges: DefinedRange[] = [
    ...defaultRanges,
    {
      label: 'Full history',
      startDate: moment(project?.created_at ?? 0).toDate(),
      endDate: new Date(),
    },
  ];

  return (
    <TraitsLayout className="trait-definitions" tab={Tabs.Traits}>
      <div className="controls">
        <span> Subject </span>
        <CFSelectLegacy
          defaultOption={[{ label: subject, value: subject }]}
          options={Object.values(TraitSubject).map((subject) => ({ label: subject, value: subject }))}
          onSelected={([subjectItem]) => setSubject(subjectItem.value as TraitSubject)}
        />

        <span> Category </span>
        <CFSelectLegacy
          defaultOption={[{ label: category, value: category }]}
          options={Object.values(TraitCategory).map((subject) => ({ label: subject, value: subject }))}
          onSelected={([categoryItem]) => setCategory(categoryItem.value as TraitCategory)}
        />

        {backfillService.isBackfillable(category) && (
          <div className="range-picker">
            <DatetimeRangePicker
              onChange={handleRangeChange}
              definedRanges={defaultDateRanges}
              initialDateRange={{
                startDate: new Date(startDate),
                endDate: new Date(endDate),
              }}
              maxDate={new Date()}
              showTime={false}
            />
          </div>
        )}
      </div>

      <CFTable
        headers={headers}
        data={definitions.map((definition) => ({ ...definition, name: definition.display_name || definition.name }))}
        emptyLabel="No Selected Traits"
      />
    </TraitsLayout>
  );
};

export default TraitDefinitions;
