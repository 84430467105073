import React, { useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';

import { CFRole } from 'domain/general.types';

import AssistantWall from './AssistantWall';
import AssistantSplash from './AssistantSplash';
import PromptHints from './PromptHints';

import { useMessagesContext } from './context/useMessagesContext';

import CFConfirmableButton from 'components/CFConfirmableButton';
import CFButton from 'components/buttons/CFButton';

import AssistantInput from './AssistantInput';

import './assistant.scss';

interface Props {}

const AssistantInternal = ({}: Props) => {
  const textareaRef = React.createRef<HTMLTextAreaElement>();

  const { messages, reset, embedded, currentMessage } = useMessagesContext();

  useEffect(() => {
    textareaRef.current?.focus();
  }, [currentMessage]);

  useEffect(() => {
    textareaRef.current?.focus();
  }, [messages]);

  const handleReset = useCallback(async () => {
    reset();
  }, []);

  return (
    <div className={classnames('assistant', { embedded: embedded })}>
      {messages.length ? <AssistantWall /> : <AssistantSplash embedded={embedded} />}

      <PromptHints />

      <div className={classnames('assistant__footer', { embedded: embedded })}>
        <AssistantInput />

        <div className="controls-container">
          <CFConfirmableButton question={`Are you sure you want to reset chat history?`} title={'Reset chat'}>
            <CFButton value="Reset" role={CFRole.Borderless} iconName={faArrowRotateRight} onClick={handleReset} />
          </CFConfirmableButton>
        </div>
      </div>
    </div>
  );
};

export default AssistantInternal;
