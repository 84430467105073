export interface CFStorage {
  get: (key: KEY | string) => string | null;
  set: (key: KEY | string, value: string | null) => void;
}

export enum KEY {
  TOKEN_KEY = 'cftoken',
  AUTH_TOKEN_KEY = 'auth_token',
  ORG_KEY = 'cforg',
  PROJECT_KEY = 'cfproject',
  INCOGNITO = 'incognito',
}

export const get = (key: KEY | string) => {
  return localStorage.getItem(key);
};

export const set = (key: KEY | string, value: string | null) => {
  if (value === null) {
    localStorage.removeItem(key);
  } else {
    localStorage.setItem(key, value);
  }
};

export default {
  get,
  set,
};
