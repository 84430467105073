import React from 'react';

import CFButton from '../CFButton';

import { faTrashCan } from '@fortawesome/free-regular-svg-icons/faTrashCan';
import { CFRole } from 'domain/general.types';

import './cf-trash-button.scss';

interface Props {
  disabled?: boolean;
  onClick: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const CFTrashButton = ({ onClick, disabled = false }: Props) => {
  return (
    <div className="cf-trash-button">
      <CFButton
        value=""
        role={CFRole.Borderless}
        iconName={faTrashCan}
        disabled={disabled}
        onClick={onClick}
        testId="trash-button"
      />
    </div>
  );
};

export default CFTrashButton;
